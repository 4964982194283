import { getHttpClient } from "@/http-client";
import {
  setBacknineCaseCommissionFromRequest,
  setProductCommissionFromRequest
} from "@/models/Commissions";

const baseUrl = "api/boss/commissions";
let cancelToken = null;
export async function getCommissions(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  return {
    items: response.data.products.map(setProductCommissionFromRequest),
    meta: response.data.meta
  };
}
export function createCommission(commission) {
  return getHttpClient().post(baseUrl, { commission });
}
export function updateCommission(id, commission) {
  return getHttpClient().put(`${baseUrl}/${id}`, { commission });
}
export function deleteCommission(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

let commissionsCancelToken = null;
export async function getBackNineCaseCommissions(params) {
  if (commissionsCancelToken !== null) {
    commissionsCancelToken.cancel("Operation canceled due to new request.");
  }
  commissionsCancelToken = getHttpClient().CancelToken.source();
  const res = await getHttpClient().get(`${baseUrl}/dashboard`, {
    params,
    cancelToken: commissionsCancelToken.token
  });

  if (!res?.data) return;

  return {
    items: res.data.cases.map(setBacknineCaseCommissionFromRequest),
    meta: res.data.meta
  };
}
