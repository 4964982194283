<template>
  <v-card>
    <v-card-title>Webhook Editor</v-card-title>
    <v-card-text>
      <text-field
        v-model="url"
        data-lpignore="true"
        :prepend-inner-icon="mdiFileDocument"
        prefix="https://"
        autofocus
        label="Desired Endpoint"
        clearable
        data-testid="webhook-editor-url"
        :success="Boolean(urlValidation.success && url)"
        :error-messages="urlValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="save-webhook"
        :loading="saving"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import { computed,  ref } from "vue";
import { mdiFileDocument } from "@mdi/js";
import useVuelidate from "@vuelidate/core";

import { validationComputeV2, parseErrorMessage } from "@/util/helpers";

import { unsubscribe, update } from "@/api/webhooks.service";
import { createAgentWebhook } from "@/api/agents.service";
import { createAgencyWebhook } from "@/api/agencies.service";

import {
  Webhook,
  WebhookToCreateRequest,
  WebhookToUpdateRequest
} from "@/models/Webhook";

import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useSettingsViewStore } from "@/stores/settings-view";

const props = defineProps({
  webhook: {
    type: Object,
    required: true
  },
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;
if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}
const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const url = ref(props.webhook.url);
const saving = ref(false);

const v$ = useVuelidate(
  {
    url: {
      zapierAbsent: value => {
        if (!value) return true;
        return value.toLowerCase().indexOf("zapier") === -1;
      },
      doesNotStartWithHttp: value => {
        if (!value) return true;
        return !value.includes("https://") && !value.includes("http://");
      },
      url: value => {
        if (!value) return true;

        const urlRegex =
          /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        return urlRegex.test(`https://${value}`);
      }
    }
  },
  { url },
  { $autoDirty: true, $scope: false }
);

const urlValidation = computed(() => {
  return validationComputeV2(v$.value.url, [
    { key: "zapierAbsent", message: "Cannot contain Zapier" },
    {
      key: "doesNotStartWithHttp",
      message: "Must not start with http:// or https://"
    },
    {
      key: "url",
      message: "Invalid URL"
    }
  ]);
});

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  const webhook = Webhook({ ...props.webhook, url: url.value });
  saving.value = true;
  try {
    if (props.webhook.id && url.value) await updateWebhook(webhook);
    else if (props.webhook.id && !url.value) await deleteWebhook(webhook);
    else if (url.value) await createWebhook(webhook);
    dialog.closeDialog({ updated: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

function createWebhook(webhook) {
  let func = createAgentWebhook;
  if (props.module === AGENCY_SETTINGS) func = createAgencyWebhook;
  return func(store.id, WebhookToCreateRequest(webhook));
}

function updateWebhook(webhook) {
  return update(props.webhook.id, WebhookToUpdateRequest(webhook));
}

function deleteWebhook() {
  return unsubscribe(props.webhook.id);
}
</script>
