<template>
  <v-menu :disabled="loading">
    <template #activator="{ props: templateProps }">
      <app-button
        v-bind="templateProps"
        variant="text"
        density="comfortable"
        :icon="activeStatus.icon"
        :class="{ 'opacity-100': activeStatus.color }"
        class="default-btn"
        data-testid="status-selector"
        :loading="loading"
        :color="activeStatus.color"
      />
    </template>
    <v-card>
      <v-card-title> Select Form Status</v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item
            v-for="{ icon, title, text, value, color } in selectableStatuses"
            :key="value"
            data-testid="status-option"
            @click="emit('status-update', value)"
          >
            <template #prepend>
              <v-icon
                :color="color"
                size="24"
                :icon="icon"
                class="opacity-100"
              />
            </template>

            <v-list-item-title> {{ title }} </v-list-item-title>
            <v-list-item-subtitle>
              {{ text }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script setup>
import { FORM_STATUS } from "@/models/FormMapping";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import {
  mdiAlertDecagramOutline,
  mdiCheckDecagram,
  mdiCheckDecagramOutline,
  mdiDecagramOutline
} from "@mdi/js";
import { computed, toRefs } from "vue";

const STATUSES = {
  [FORM_STATUS.INCOMPLETE]: {
    value: FORM_STATUS.INCOMPLETE,
    title: "Incomplete",
    text: "Pull this form from production for editing",
    icon: mdiDecagramOutline,
    color: null
  },
  [FORM_STATUS.COMPLETE]: {
    value: FORM_STATUS.COMPLETE,
    title: "Complete",
    text: "This form is the latest form and is ready for production.",
    icon: mdiCheckDecagram,
    color: "accent"
  },
  [FORM_STATUS.COMPLETE_NOT_LATEST]: {
    value: FORM_STATUS.COMPLETE_NOT_LATEST,
    title: "Complete, but not latest",
    text: "Keep this form in production, but it's not the latest form.",
    icon: mdiAlertDecagramOutline,
    color: "accent"
  },
  [FORM_STATUS.COMPLETE_READY_FOR_REVIEW]: {
    value: FORM_STATUS.COMPLETE_READY_FOR_REVIEW,
    title: "Complete, ready for approval",
    text: "This form is ready to be approved for production.",
    icon: mdiCheckDecagramOutline,
    color: "accent"
  }
};

const props = defineProps({
  status: {
    type: String,
    required: true
  },
  loading: Boolean
});
const emit = defineEmits(["status-update"]);

const { status } = toRefs(props);

const user = useUserStore();
const { isGroupFour } = storeToRefs(user);

const activeStatus = computed(() => STATUSES[status.value]);

const selectableStatuses = computed(() => {
  const selectableStatuses = [
    STATUSES[FORM_STATUS.INCOMPLETE],
    STATUSES[FORM_STATUS.COMPLETE_NOT_LATEST],
    STATUSES[FORM_STATUS.COMPLETE_READY_FOR_REVIEW]
  ];

  if (isGroupFour.value) {
    selectableStatuses.push(STATUSES[FORM_STATUS.COMPLETE]);
  }

  return selectableStatuses;
});
</script>
