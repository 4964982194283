<template>
  <v-col cols="12" style="padding-left: 12px">
    <div
      style="border-left: 5px solid #4caf50 !important"
      :style="{
        borderLeftColor: isErrored ? 'red' : '#4caf50'
      }"
    >
      <v-card flat tile color="transparent">
        <v-card-title>Reasons</v-card-title>
        <v-card-text class="pb-0">
          At least one reason is required for why you answered "Yes" to the
          question "{{ question.questionText }}"
        </v-card-text>
        <v-card-text>
          <v-row dense>
            <v-col
              v-for="(_, explanationId) in explanations"
              :key="explanationId"
              cols="12"
            >
              <contracting-question-explanation
                :question-id="questionId"
                :explanation-id="explanationId"
                :readonly="props.readonly"
                @edit="editExplanation(explanationId)"
                @delete="deleteExplanation(explanationId)"
              />
            </v-col>
          </v-row>
          <app-button
            v-if="!readonly"
            class="text-none my-2"
            color="accent"
            :data-testid="`${question.questionId}-add-explanation`"
            @click="addExplanation"
          >
            Add Explanation
          </app-button>
        </v-card-text>
      </v-card>
    </div>
  </v-col>
</template>

<script setup>
import ContractingQuestionExplanation from "@/components/settings/background-questions/ContractingQuestionExplanation.vue";

import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useDialogStore } from "@/stores/dialog";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import ContractingQuestionExplanationDialog from "@/dialogs/ContractingQuestionExplanationDialog.vue";

import { computed, markRaw } from "vue";
const props = defineProps({
  readonly: Boolean,
  questionId: { type: String, default: null }
});

const agent = useAgentSettingsStore();

const question = agent.contractingQuestions[props.questionId];

const explanations = computed(
  () => agent.contractingQuestions[props.questionId].explanations
);

const isErrored = computed(() => !Object.keys(explanations.value).length);

const dialog = useDialogStore();
function addExplanation() {
  dialog.showDialog({
    component: markRaw(ContractingQuestionExplanationDialog),
    questionId: props.questionId,
    scrollable: true
  });
}
function editExplanation(explanationId) {
  dialog.showDialog({
    component: markRaw(ContractingQuestionExplanationDialog),
    questionId: props.questionId,
    explanationId,
    scrollable: true
  });
}

function deleteExplanation(explanationId) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Explanation?",
    subtitle: "Please confirm your intent.",
    func: () =>
      agent.deleteAndRemoveExplanation(props.questionId, explanationId)
  });
}
</script>
