<template>
  <v-card color="section" flat tile>
    <v-card-title class="text-h4"> Integrations </v-card-title>
    <v-card-subtitle>
      Enable third party functionality on your website
    </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col
          v-for="{ title, value, action, text } in availableIntegrations"
          :key="title"
          cols="12"
          sm="6"
          md="4"
          :data-testid="`${title}-integration`"
        >
          <v-card variant="flat" class="border-thin" @click="action">
            <v-card-title>
              <v-row class="ma-0">
                <div>{{ title }}</div>
                <v-spacer />
                <div v-if="value.id" :data-testid="`${title}-enabled-text`">
                  <div
                    v-if="value.enabled"
                    style="font-weight: 300"
                    class="text-success"
                  >
                    Enabled
                  </div>
                  <div v-else style="font-weight: 300" class="text-error">
                    Disabled
                  </div>
                </div>
              </v-row>
            </v-card-title>
            <v-card-text> {{ text }} </v-card-text>
            <v-card-actions>
              <app-button
                v-if="value.id"
                class="text-none"
                color="primary"
                variant="outlined"
                block
                :data-testid="`${title}-modify`"
              >
                Modify {{ title }}
              </app-button>
              <app-button
                v-else
                :data-testid="`${title}-create`"
                class="text-none"
                color="primary"
                variant="elevated"
                block
              >
                Add {{ title }}
              </app-button>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import BasicIntegrationDialog from "@/dialogs/BasicIntegrationDialog.vue";
import { INTEGRATIONS } from "@/models/ApprovedDomain";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";
import { computed, markRaw } from "vue";

const dialog = useDialogStore();
const approvedDomain = useApprovedDomainView();
const { integrations } = storeToRefs(approvedDomain);

const availableIntegrations = computed(() => [
  {
    title: INTEGRATIONS.COBROWSE_IO,
    text: "Support your high-value customers. See your apps from their perspective and jump directly in to help.",
    value: integrations.value.cobrowseIo,
    action: modifyCobrowseIntegration
  },
  {
    title: INTEGRATIONS.ZOHO_CHAT,
    text: "This all-in-one live chat and analytics platform promotes proactive customer engagement, allowing you to initiate the right kind of conversation at exactly the right time.",
    value: integrations.value.zohoChat,
    action: modifyZohoIntegration
  },
  {
    title: INTEGRATIONS.ZENDESK_CLASSIC,
    text: "Zendesk's legacy embeddable customer support interface. Web Widget (Classic) allows you to add features from other Zendesk products you're using - Guide, Support, Talk, and Chat - to your website or help center, so that your customers can get immediate help from a single interface, in whatever form they like best.",
    value: integrations.value.zendeskClassic,
    action: modifyZendeskIntegration
  }
]);

async function modifyCobrowseIntegration() {
  const res = await dialog.showDialog({
    component: markRaw(BasicIntegrationDialog),
    title: INTEGRATIONS.COBROWSE_IO,
    keyName: "License Key",
    modelValue: integrations.value.cobrowseIo
  });

  if (res?.integration) {
    Object.keys(integrations.value.cobrowseIo).forEach(key => {
      integrations.value.cobrowseIo[key] = res.integration[key];
    });
  }
}

async function modifyZohoIntegration() {
  const res = await dialog.showDialog({
    component: markRaw(BasicIntegrationDialog),
    title: INTEGRATIONS.ZOHO_CHAT,
    keyName: "widgetcode",
    modelValue: integrations.value.zohoChat,
    showPhone: true
  });

  if (res?.integration) {
    Object.keys(integrations.value.zohoChat).forEach(key => {
      integrations.value.zohoChat[key] = res.integration[key];
    });
  }
}

async function modifyZendeskIntegration() {
  const res = await dialog.showDialog({
    component: markRaw(BasicIntegrationDialog),
    modelValue: integrations.value.zendeskClassic,
    title: INTEGRATIONS.ZENDESK_CLASSIC,
    keyName: "key"
  });

  if (res?.integration) {
    Object.keys(integrations.value.zendeskClassic).forEach(key => {
      integrations.value.zendeskClassic[key] = res.integration[key];
    });
  }
}
</script>
