import { getHttpClient } from "@/http-client";
import {
  setAppointmentCaseFromRequest,
  setRequestFromAppointmentCase,
  setUpdateRequestFromAppointmentCase
} from "@/models/Case";

const baseUrl = "api/boss/appointment_cases";

export async function createAppointmentCase(appointmentCase) {
  const { data } = await getHttpClient().post(baseUrl, {
    appointment_case: setRequestFromAppointmentCase(appointmentCase)
  });

  return setAppointmentCaseFromRequest(data);
}

export async function updateAppointmentCase(appointmentCase) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${appointmentCase.appointmentCaseId}`,
    {
      appointment_case: setUpdateRequestFromAppointmentCase(appointmentCase)
    }
  );

  return setAppointmentCaseFromRequest(data);
}

export function destroyAppointmentCase(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
