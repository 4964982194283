import { getHttpClient } from "@/http-client";

import { StatisticItem } from "@/models/home/HomeStatistic";
import { HomeGoal } from "@/models/home/HomeGoal";
import { setRecentAdvisorFromRequest } from "@/models/home/HomeRecentAdvisor";
import { setRecentCaseFromRequest } from "@/models/home/HomeRecentCase";
import { setRecentElectronicApplicationFromRequest } from "@/models/home/HomeRecentEApp";
import { setRecentRequirementFromRequest } from "@/models/home/HomeRecentRequirement";
import { useUserStore } from "@/stores/user";

const baseURL = "api/boss/dashboard";

export const getAdvisorCards = async () => {
  const { data } = await getHttpClient().get(`${baseURL}/cards`);
  const pending = StatisticItem({
    type: "pending_cases",
    value: data?.pending_cases
  });
  const downline = StatisticItem({
    type: "downline_lifetime_active_cases",
    value: data?.downline_lifetime_active_cases
  });
  const placement = StatisticItem({
    type: "placement_ratio",
    value: data?.placement_ratio
  });
  const productionCredit = StatisticItem({
    type: "pending_production_credit",
    value: data?.pending_production_credit
  });
  return [pending, downline, placement, productionCredit];
};

export const getAdvisorGoals = async () => {
  const { data } = await getHttpClient().get(`${baseURL}/goals`);
  const user = useUserStore();
  const goals = [
    HomeGoal({
      type: "complete_walkthrough",
      completed: user.tutorials.series_walkthrough
    }),
    HomeGoal({
      type: "start_a_quote_and_apply_eapp",
      completed: data?.start_a_quote_and_apply_eapp
    }),
    HomeGoal({
      type: "complete_contracting",
      completed: data?.complete_contracting
    }),
    HomeGoal({
      type: "submit_a_case",
      completed: data?.submit_a_case
    }),
    HomeGoal({
      type: "earn_a_commission",
      completed: data?.earn_a_commission
    }),
    HomeGoal({
      type: "request_a_quote",
      completed: data?.request_a_quote
    }),
    HomeGoal({
      type: "create_an_impaired_risk_quote",
      completed: data?.create_an_impaired_risk_quote
    })
  ];

  return goals;
};

export const getAdvisorRecents = async () => {
  const { data } = await getHttpClient().get(`${baseURL}/recents`);
  const cases = data?.cases?.map(setRecentCaseFromRequest)?.slice(0, 5) || [];

  const advisors =
    data?.advisors?.map(setRecentAdvisorFromRequest)?.slice(0, 5) || [];

  const eApps =
    data?.electronic_applications
      ?.map(setRecentElectronicApplicationFromRequest)
      ?.slice(0, 5) || [];

  const tasks =
    data?.requirements?.map(setRecentRequirementFromRequest)?.slice(0, 5) || [];

  return { cases, advisors, eApps, tasks };
};
