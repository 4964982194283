<template>
  <v-card>
    <v-card-title>
      <template v-if="editing"> Update </template>
      <template v-else> Add </template> Employment History Record
    </v-card-title>
    <v-card-subtitle>
      <template v-if="editing">
        Make any necessary changes to this employment history record and then
        press "Save".
      </template>
      <template v-else>
        Enter information about a current or previous employment history and
        then press "Create"
      </template>
    </v-card-subtitle>
    <v-card-text id="occupation-container">
      <v-row dense class="py-3">
        <v-col cols="12" md="6">
          <date-input
            v-model="occupation.startDate"
            autofocus
            label="Start Date"
            :prepend-inner-icon="mdiCalendarStart"
            data-testid="employment-start-date"
            :disabled="loading"
            :success="startDateValidation.success"
            :error-messages="startDateValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="occupation.endDate"
            label="End Date (Leave Blank if Current)"
            data-testid="employment-end-date"
            :success="Boolean(occupation.endDate)"
            :disabled="loading"
            :prepend-inner-icon="mdiCalendarEnd"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="occupation.occupation"
            data-lpignore="true"
            label="Position"
            data-testid="employment-occupation"
            :prepend-inner-icon="mdiCardAccountDetails"
            :disabled="loading"
            :success="occupationValidation.success"
            :error-messages="occupationValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="occupation.employerName"
            data-lpignore="true"
            label="Employer Name"
            :prepend-inner-icon="mdiDomain"
            data-testid="employer-name"
            :disabled="loading"
            :success="employerNameValidation.success"
            :error-messages="employerNameValidation.errorMessages"
          />
        </v-col>

        <v-col cols="12">
          <basic-address-input
            v-model:street-address-model="
              occupation.employerAddress.street_address
            "
            v-model:city-model="occupation.employerAddress.city"
            v-model:state-model="occupation.employerAddress.state"
            v-model:zip-model="occupation.employerAddress.zip"
            autocomplete
            autofill-placeholder="Employer Address"
            autofill-label="Employer Address"
            data-testid="employer-address"
            :disabled="loading"
            :street-address-validation="streetAddressValidation"
            :city-validation="cityValidation"
            :state-validation="stateValidation"
            :zip-validation="zipValidation"
            @autofill="handleAutofill"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        color="primary"
        class="text-none"
        :loading="loading"
        data-testid="employment-save"
        @click="save"
      >
        <template v-if="editing">Save</template>
        <template v-else>Create</template>
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import DateInput from "@/components/shared/DateInput.vue";

import {
  Occupation,
  setRawCreateFromOccupation,
  setRawUpdateFromOccupation
} from "@/models/AgentSettings";
import { computedValidation, parseErrorMessage } from "@/util/helpers";

import { createOccupation, updateOccupation } from "@/api/occupations.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiCalendarStart,
  mdiCalendarEnd,
  mdiCardAccountDetails,
  mdiDomain
} from "@mdi/js";
import { nextTick, ref } from "vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  readonly: Boolean,
  currentOccupation: {
    type: Object,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const occupation = ref(Occupation(props.currentOccupation));
const loading = ref(false);
const editing = Boolean(props.currentOccupation?.id);

const v$ = useVuelidate(
  {
    occupation: {
      employerName: { required: Boolean },
      occupation: { required: Boolean },
      startDate: { required: Boolean },
      endDate: {
        required: true // it's optional
      },
      employerAddress: {
        street_address: {
          required: Boolean
        },
        city: {
          required: Boolean
        },
        state: {
          required: Boolean
        },
        zip: {
          required: Boolean
        }
      }
    }
  },
  { occupation },
  { $autoDirty: true, $scope: null }
);

const startDateValidation = computedValidation(v$.value.occupation.startDate, {
  required: "Required"
});
const occupationValidation = computedValidation(
  v$.value.occupation.occupation,
  { required: "Required" }
);
const employerNameValidation = computedValidation(
  v$.value.occupation.employerName,
  { required: "Required" }
);
const streetAddressValidation = computedValidation(
  v$.value.occupation.employerAddress.street_address,
  { required: "Required" }
);
const cityValidation = computedValidation(
  v$.value.occupation.employerAddress.city,
  { required: "Required" }
);
const stateValidation = computedValidation(
  v$.value.occupation.employerAddress.state,
  { required: "Required" }
);
const zipValidation = computedValidation(
  v$.value.occupation.employerAddress.zip,
  { required: "Required" }
);

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    nextTick(() => goToInvalid());
    return;
  }
  loading.value = true;
  let func = create;
  if (editing) func = update;
  try {
    const newOccupation = await func();
    dialog.closeDialog({ occupation: newOccupation });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
function create() {
  return createOccupation(setRawCreateFromOccupation(occupation.value));
}
function update() {
  return updateOccupation(
    occupation.value.id,
    setRawUpdateFromOccupation(occupation.value)
  );
}
function goToInvalid() {
  const els = document.getElementsByClassName("v-input error-field");
  if (!els?.length) return;

  els[0].scrollIntoView();
}
function handleAutofill({ street_address, city, state, zip }) {
  occupation.value.employerAddress.street_address = street_address;
  occupation.value.employerAddress.city = city;
  occupation.value.employerAddress.state = state;
  occupation.value.employerAddress.zip = zip;
}
</script>
