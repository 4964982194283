import { getHttpClient } from "@/http-client";
import {
  setRequestFromSmokingType,
  setSmokingTypeFromRequest
} from "@/models/SmokingType";

const baseUrl = "api/boss/smoking_types";
let cancelToken = null;

export async function getSmokingTypes(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const result = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!result?.data) return;

  return {
    items: result.data.smoking_types.map(setSmokingTypeFromRequest),
    meta: result.data.meta
  };
}

export function createSmokerType(smokingType) {
  return getHttpClient().post(baseUrl, {
    smoking_type: setRequestFromSmokingType(smokingType)
  });
}

export function updateSmokerType(smokingType) {
  return getHttpClient().put(`${baseUrl}/${smokingType.id}`, {
    smoking_type: setRequestFromSmokingType(smokingType)
  });
}

export function deleteSmokerType(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
