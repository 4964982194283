<template>
  <v-row dense>
    <v-col
      v-for="(item, index) in resources"
      :key="index"
      cols="12"
      lg="3"
      md="4"
      sm="6"
    >
      <quote-method v-bind="item" />
    </v-col>
  </v-row>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import CreateQuoteAndApplySite from "@/dialogs/CreateQuoteAndApplySite.vue";
import QuoteMethod from "@/components/quotes/QuoteMethod.vue";

import { getRangeOfExposureLink } from "@/api/boss.service";
import {
  navigateToExternalLink,
  openWindowWithPost,
  parseErrorMessage
} from "@/util/helpers";
import { getResource } from "@/api/boss.service";

import { getAgentSummary } from "@/api/agents.service";
import { getAgencySummary } from "@/api/agencies.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiApplicationImport, mdiPageNext } from "@mdi/js";

import annuityRateWatch from "@/assets/img/annuity-rate-watch.png";
import quoteAndApplyLogo from "@/assets/img/quote-and-apply-logo.png";
import ipipeline from "@/assets/img/ipipeline.png";
import stratecision from "@/assets/img/stratecision.jpg";
import oneamerica from "@/assets/img/oneamerica.jpeg";
import zinnia from "@/assets/img/zinnia.png";
import ilia from "@/assets/img/ilia.svg";
import { computed, markRaw, ref } from "vue";
import { useRouter } from "vue-router";
import { executeStratecisionSSO } from "@/components/settings/security/sso.composable";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const router = useRouter();

const ltciRangeOfExposure = {
  text: "LTC Range of Exposure",
  description: "Determine how costly long-term care can be.",
  type: "LTC",
  loading: false,
  icon: mdiApplicationImport,
  iconColor: "primary",
  click: () => openRangeOfExposure(ltciRangeOfExposure)
};

const ltciCostBenefit = {
  text: "LTC Cost & Benefit",
  description:
    "Cost & Benefit analyzer helps client make informed final decisions in 15-20 minutes",
  type: "LTC",
  loading: false,
  icon: mdiApplicationImport,
  iconColor: "primary",
  click: () => openCostBenefit(ltciCostBenefit)
};

const quoteAndApplyCard = {
  text: "Quote & Apply",
  cardClass: "quote-and-apply-card",
  description:
    "Agents and consumers can quote accidental death, IUL, final expense, linked benefit, long term care, term, universal life, whole life, and income rider annuities.",
  type: "Life, LTC, and Linked Benefit",
  img: quoteAndApplyLogo,
  href: null,
  target: null,
  click: null
};

let npn = user.loginable.npn;
if (user.loginable?.signer?.id) npn = user.loginable.signer?.npn;

if (user.loginable.approved_domain_url) {
  quoteAndApplyCard.href = user.loginable.approved_domain_url;
  quoteAndApplyCard.target = "_blank";
} else if (npn) {
  quoteAndApplyCard.click = () =>
    dialog.showDialog({
      component: markRaw(CreateQuoteAndApplySite),
      scrollable: true
    });
} else {
  quoteAndApplyCard.click = () =>
    dialog.showDialog({
      component: markRaw(ConfirmationDialog),
      title: "Missing NPN",
      subtitle:
        "You'll need your National Producer Number (NPN) added to use Quote & Apply. To do this, set a NPN in your settings.",
      func: () => {
        if (user.loginable?.signer?.id) {
          router.push({
            name: "AdvisorSettings",
            params: {
              id: user.loginable.signer.id,
              type: user.loginable.signer.type
            },
            query: { page: "basics" }
          });
        } else {
          router.push({
            name: "LoggedInUserSettings",
            query: { page: "basics" }
          });
        }
      }
    });
}

function stratecisionPrompt() {
  const approvedDomainUrl = user.loginable.approved_domain_url;

  if (approvedDomainUrl) {
    dialog.showDialog({
      component: markRaw(ConfirmationDialog),
      title: "Continue to Stratecision?",
      subtitle: "Some applications may be completed in Quote & Apply",
      func: executeStratecisionSSO,
      confirmText: "Launch Stratecision",
      secondaryHref: approvedDomainUrl,
      secondaryText: "Launch Quote & Apply"
    });
  } else {
    dialog.showDialog({
      component: markRaw(ConfirmationDialog),
      title: "Continue to Stratecision?",
      subtitle: "Please confirm your intent",
      func: executeStratecisionSSO,
      confirmText: "Continue"
    });
  }
}

const rawResources = [
  quoteAndApplyCard,
  {
    text: "Request a Quote",
    description:
      "Still need help? Create a quote request for BackNine to quote your case.",
    type: "All Lines",
    key: "requestAQuote",
    buttonColor: "primary",
    cardClass: "request-a-quote-card",
    to: { name: "CreateQuote" },
    img: "https://portal-pictures.back9ins.com/BOSS-Logo.png"
  },
  {
    text: "Impaired Risk",
    description:
      "Send client's information to multiple carriers and compare underwriting offers.",
    type: "Life",
    key: "impairedRiskQuote",
    buttonColor: "orange",
    cardClass: "impaired-risk-card",
    to: { name: "ImpairedRiskQuoteCreate" },
    img: "https://portal-pictures.back9ins.com/BOSS-Logo.png"
  },
  {
    text: "More Quoting Options",
    description:
      "Choose other life, long term care, and annuity quoting engines.",
    type: "All Lines",
    click: () => (showAllOptions.value = true),
    icon: mdiPageNext,
    dataTestid: "more-options",
    iconColor: "primary"
  },
  {
    text: "iPipeline",
    href: "//lifepipe.ipipeline.com/LTSearch.aspx?GAID=2479",
    target: "_blank",
    description:
      "Agents can quote guaranteed universal life, return of premium term, and term. Requires a BackNine iPipeline Account.",
    type: "Life",
    img: ipipeline
  },
  {
    text: "Annuity Quotes",
    loading: false,
    description:
      "Quote MYGAs, Indexed Annuities, Income Riders, and more within Annuity Rate Watch.",
    type: "Annuity",
    img: annuityRateWatch,
    click: async () => {
      try {
        const link = await getResource("annuity_rate_watch");
        if (!link) return;

        navigateToExternalLink(link);
      } catch (e) {
        snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    }
  },
  {
    text: "Traditional LTCi",
    click: stratecisionPrompt,
    description: "Quote one or more insurers side-by-side",
    type: "LTC",
    dataTestid: "stratecision",
    img: stratecision
  },
  ltciRangeOfExposure,
  ltciCostBenefit,
  {
    text: "State Life Care Solutions Calculator",
    href: "https://www.oneamerica.com/caresolutionscalculator/index",
    target: "_blank",
    description:
      "Quickly and efficiently quote key figures for Asset-Care. Print the quotes in a simple format for clients to digest and see a visual on how the product works",
    type: "Linked Benefit",
    img: oneamerica
  },
  {
    text: "WinFlex Web",
    href: "https://www.winflexweb.com/",
    target: "_blank",
    description:
      "A standard entry to generate illustrations for multiple life insurance carriers",
    type: "Life",
    img: zinnia
  },
  {
    text: "ilia",
    href: "https://www.myilia.com",
    target: "_blank",
    description:
      "NIW Companies specializes in innovative estate, business, and retirement planning solutions for high net-worth or high-income earning professionals and often used for Kai-Zen®.",
    type: "Life",
    img: ilia
  }
].filter(Boolean);

const showAllOptions = ref(false);

const resources = computed(() => {
  if (showAllOptions.value)
    return rawResources.filter(v => v.text !== "More Quoting Options");
  return rawResources.slice(0, 4);
});

async function openRangeOfExposure(item) {
  item.loading = true;

  try {
    const url = await getRangeOfExposureLink();
    navigateToExternalLink(url);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    item.loading = false;
  }
}
async function openCostBenefit(item) {
  item.loading = true;

  try {
    const func =
      user.loginable.type === "Agent" ? getAgentSummary : getAgencySummary;
    const { advisor } = await func(user.loginable.id);
    const {
      phoneWork: phone,
      businessAddress,
      firstName,
      lastName,
      name,
      email
    } = advisor;

    if (!phone) {
      item.loading = false;
      return snackbar.showErrorSnackbar({
        message:
          "Please add a work phone number to use this tool (available under Settings > Basics)"
      });
    } else if (!businessAddress.state) {
      item.loading = false;
      return snackbar.showErrorSnackbar({
        message:
          "Please add a business address to use this tool (available under Settings > Basics)"
      });
    }

    openWindowWithPost("https://www.ltcicalc.com/sso.cfm?agency=BackNine", {
      UserName: email,
      FirstName: firstName || name,
      LastName: lastName || name,
      Email: email,
      Phone: phone,
      State: businessAddress.state,
      LogoutReturnUrl: "https://app.back9ins.com"
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    item.loading = false;
  }
}
</script>
