<template>
  <v-card color="section" flat tile>
    <v-card-title> Access </v-card-title>
    <v-card-text>
      <access-table
        v-model="room"
        v-model:new-access="newAccess"
        data-testid="contract-party-access-table"
        :checkboxes="CHECKBOXES"
        :update-func="updateContractPartyAccess"
        :delete-func="deleteContractPartyAccess"
        :add-func="addContractPartyAccess"
      >
        <template #new-access>
          <advisor-search
            v-model="newAccess"
            hide-details
            data-testid="access-advisor-search"
          />
        </template>
      </access-table>
    </v-card-text>
  </v-card>
</template>
<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import AccessTable from "@/components/shared/AccessTable.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { useContractPartyView } from "@/stores/contract-party-view";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { markRaw, ref } from "vue";

const CHECKBOXES = [
  { text: "Email", value: "email" },
  { text: "Agent", value: "agent" }
];

const newAccess = ref(null);

const dialog = useDialogStore();
const contractPartyView = useContractPartyView();
const { room } = storeToRefs(contractPartyView);

function addContractPartyAccess(advisor) {
  return contractPartyView.createAccess(advisor);
}

function deleteContractPartyAccess(access) {
  return dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure that you want remove this client access?",
    subtitle: "Please confirm your intent",
    func: () => contractPartyView.deleteAccess(access.contractPartyAccessId)
  });
}

function updateContractPartyAccess(access, attribute) {
  return contractPartyView.updateAccess(
    access.contractPartyAccessId,
    attribute
  );
}
</script>
