<template>
  <v-card flat color="section">
    <v-card-title>
      API Key
      <span class="text-grey ml-1" style="font-size: 16px"
        >({{ primaryLogin.userEmail }})</span
      >
    </v-card-title>
    <v-card-text>
      This is {{ name }}'s API Key which is stored on their primary login,
      {{ primaryLogin.userEmail }}. An API key is needed when fetching data off
      of BackNine's API. Some available requests include getting quotes and text
      messaging. See the
      <a href="https://docs.back9ins.com/#api-setup" target="_blank">
        API docs
      </a>
      for more details.
    </v-card-text>
    <v-fade-transition mode="out-in">
      <v-card-text v-if="primaryLogin.hasApiKey" key="has-api-key" class="py-1">
        API Key:
        <v-fade-transition mode="out-in">
          <code :key="apiKey" data-testid="api-key-display" class="mr-1">{{
            apiKey
          }}</code>
        </v-fade-transition>
        <div class="mb-2 inline-block">
          <v-fade-transition>
            <app-button
              v-if="apiKey === APIKEY_DISPLAY"
              key="fetching"
              variant="text"
              density="comfortable"
              :icon="mdiEye"
              color="accent"
              data-testid="fetch-api-key"
              :disabled="Boolean(loading && loading !== FETCHING_KEY)"
              :loading="loading === FETCHING_KEY"
              @click="fetchApiKey"
            />
            <app-button
              v-else
              key="copy-key"
              variant="text"
              density="comfortable"
              :icon="mdiContentCopy"
              color="accent"
              data-testid="copy-api-key"
              :disabled="Boolean(loading)"
              @click="copyKeyToClipboard"
            />
          </v-fade-transition>
          <v-menu>
            <template #activator="{ props: templateProps }">
              <app-button
                data-testid="api-key-menu"
                variant="text"
                density="comfortable"
                v-bind="templateProps"
                :icon="mdiDotsHorizontal"
              />
            </template>
            <v-list nav dense>
              <v-list-item
                data-testid="api-key-cycle"
                @click="confirmAndCycleKey"
              >
                <template #prepend>
                  <v-icon color="orange" :icon="mdiRefresh" />
                </template>

                <v-list-item-title> Cycle Key </v-list-item-title>
              </v-list-item>
              <v-list-item
                data-testid="api-key-delete"
                @click="confirmAndDeleteKey"
              >
                <template #prepend>
                  <v-icon color="error" :icon="mdiDelete" />
                </template>

                <v-list-item-title> Delete Key </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-text>
      <v-card-text v-else key="no-api-key" class="py-1">
        <app-button
          class="mb-2 text-none"
          color="primary"
          data-testid="generate-api-key"
          :disabled="Boolean(loading && loading !== GENERATING_KEY)"
          :loading="loading === GENERATING_KEY"
          :prepend-icon="mdiPlus"
          text="Generate API Key"
          @click="generateApiKey"
        />
      </v-card-text>
    </v-fade-transition>
  </v-card>
</template>

<script setup>
import { markRaw, ref } from "vue";

import { getApiKey, cycleApiKey, deleteApiKey } from "@/api/logins.service";

import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useSettingsViewStore } from "@/stores/settings-view";
import {
  mdiEye,
  mdiContentCopy,
  mdiDotsHorizontal,
  mdiRefresh,
  mdiDelete,
  mdiPlus
} from "@mdi/js";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const APIKEY_DISPLAY = "* * * * * * * * * * * * * * * * * * ";
const FETCHING_KEY = "fetching-key";
const CYCLING_KEY = "cycling-key";
const DELETING_KEY = "deleting-key";
const GENERATING_KEY = "generating-key";

const props = defineProps({
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

let store;
if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const { primaryLogin, name } = storeToRefs(store);

const loading = ref("");
const apiKey = ref(APIKEY_DISPLAY);

async function copyKeyToClipboard() {
  try {
    await navigator.clipboard.writeText(apiKey.value);
    snackbar.showSuccessSnackbar({ message: "Copied to clipboard" });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: "Permission denied to copy to clipboard"
    });
  }
}

async function generateApiKey() {
  loading.value = GENERATING_KEY;
  try {
    if (!(await cycleKey())) return;
    if (!(await fetchApiKey())) return;
    primaryLogin.value.hasApiKey = true;
    snackbar.showSuccessSnackbar({ message: "Successfully generated API Key" });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = "";
  }
}

async function fetchApiKey() {
  loading.value = FETCHING_KEY;
  let error;
  try {
    apiKey.value = await getApiKey(primaryLogin.value.id);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    error = e;
  } finally {
    loading.value = "";
  }

  return !error;
}

async function cycleKey() {
  loading.value = CYCLING_KEY;
  let error;
  try {
    await cycleApiKey(primaryLogin.value.id);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    error = e;
  } finally {
    loading.value = "";
  }
  return !error;
}

async function confirmAndCycleKey() {
  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Request New API Key",
    subtitle:
      "You can only have one API key per advisor. This will invalidate your old key and issue you a new one. Are you sure want to do this?",
    func: async () => {
      if (!(await cycleKey())) return;
      snackbar.showSuccessSnackbar({ message: "Successfully updated API Key" });
    }
  });

  if (res?.confirm) fetchApiKey();
}

async function deleteKey() {
  loading.value = DELETING_KEY;
  try {
    await deleteApiKey(primaryLogin.value.id);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = "";
  }
}

async function confirmAndDeleteKey() {
  await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete API Key",
    subtitle:
      "This will invalidate your api key. Are you sure want to do this?",
    func: async () => {
      apiKey.value = APIKEY_DISPLAY;
      primaryLogin.value.hasApiKey = false;
      await deleteKey();
      snackbar.showSuccessSnackbar({ message: "Successfully updated API Key" });
    }
  });
}
</script>
