import { getHttpClient } from "@/http-client";
import { setEntityFromRequest } from "@/models/Party";

const baseUrl = "api/boss/entities";
export async function fetchEntity(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setEntityFromRequest(data.entity);
}

export async function getSensitiveEntityInformation(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/sensitive`);
  return data.tin;
}
