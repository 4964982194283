<template>
  <v-card>
    <v-card-title> {{ title }} </v-card-title>
    <v-card-subtitle v-if="subtitle"> {{ subtitle }} </v-card-subtitle>
    <v-card-text>
      <div
        v-if="jpeg"
        id="copy"
        contenteditable
        style="background-color: transparent"
        @click="copyData"
      >
        <img ref="copy" :src="jpeg" height="70" width="244" />
      </div>
      <textarea-field
        v-else
        ref="copy"
        data-testid="copy-textarea"
        :label="title"
        :model-value="text"
        readonly
        rows="4"
        @click="copyData"
      />
      <v-alert v-if="copied" type="success" class="mt-4" variant="outlined">
        Copied to clipboard!
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        color="grey"
        data-testid="copy-dialog-close"
        @click="dialog.closeDialog()"
      >
        Close
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="copy-button"
        @click="copyData"
        >Copy</app-button
      >
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { selectImage } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";
const props = defineProps({
  text: { type: String, default: null },
  title: { type: String, default: null },
  subtitle: { type: String, default: null },
  jpeg: Boolean
});

const dialog = useDialogStore();
const copied = ref(false);
const copy = ref(null);

let copyTimer;
function copyData() {
  if (props.jpeg) {
    selectImage(document.getElementById("copy"));
  } else {
    copy.value.$el.querySelector("textarea").select();
  }

  document.execCommand("copy");
  window.getSelection().removeAllRanges();

  copied.value = true;
  if (copyTimer) clearTimeout(copyTimer);
  copyTimer = setTimeout(() => {
    copied.value = false;
  }, 8000);
}
</script>
