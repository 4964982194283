<template>
  <v-row v-if="friendlyFilters.length" class="ma-0" align="center">
    <h4 class="mr-3" style="font-size: 1rem">Applied Filters:</h4>
    <v-chip
      v-for="(filter, index) in friendlyFilters"
      :key="`${index}-${filter.value}`"
      :data-testid="`${filter.name}-active`"
      :closable="filter.clearable && !loading"
      class="mr-1 mb-1"
      @click:close="emit('clear-filter', filter.filterKey)"
      @click="emit('clicked', filter.filterKey)"
    >
      <template
        v-if="typeof filter.value === 'boolean' || filter.hideDisplayValue"
      >
        {{ filter.name }}
      </template>
      <template v-else>
        {{ filter.name }}: <strong class="ml-2"> {{ filter.value }} </strong>
      </template>
    </v-chip>
  </v-row>
</template>

<script setup>
import { getDescendantProp, timestampFormatter } from "@/util/helpers";
import { numberFilters } from "@/constants/number-filter.constants";
import TableHeader from "@/classes/data-table/TableHeader";
import { computed, toRefs } from "vue";
const SELECT_TYPES = [TableHeader.SELECT_TYPE, TableHeader.AUTOCOMPLETE_TYPE];
const props = defineProps({
  activeFilter: { type: Object, default: () => {} },
  headers: { type: Array, default: () => [] },
  loading: Boolean
});

const emit = defineEmits(["clicked", "clear-filter"]);

const { headers, activeFilter } = toRefs(props);

const friendlyFilters = computed(() => {
  const friendlyFilters = [];
  if (!Object.keys(activeFilter.value).length) return [];

  Object.keys(activeFilter.value).forEach(key => {
    if (!activeFilter.value[key]) return;
    const h = headers.value.find(h => h.value === key);

    if (!h) return;

    const isObject = typeof activeFilter.value[key] === "object";

    if (isObject && !Object.keys(activeFilter.value[key]).length === 0) return;

    let value = activeFilter.value[key];

    if (Array.isArray(activeFilter.value[key])) {
      value = getArrayFilter(h, value);
    } else if (h.filterType === TableHeader.DATE_TYPE) {
      value = getDateFilter(h, value);
    } else if (h.filterType === TableHeader.NUMBER_RANGE) {
      value = getNumberFilter(h, value);
    } else if (SELECT_TYPES.includes(h.filterType)) {
      value = getSelectFilter(h, value);
    } else if (isObject) {
      value = getObjectFilter(h, value);
    }

    if (!value) return;

    friendlyFilters.push({
      name: h.text || h?.title,
      filterKey: h.value,
      clearable: !h.isMandatory,
      hideDisplayValue: h.hideDisplayValue,
      value
    });
  });

  return friendlyFilters;
});
function getDateFilter(h, value) {
  let display = [];
  if (value.value.start) {
    display.push(timestampFormatter(value.value.start, "sole-day"));
  }

  if (value.value.finish && value.value.start !== value.value.finish) {
    display.push(timestampFormatter(value.value.finish, "sole-day"));
  }
  if (!display.length) return;

  return display.join(" - ");
}
function getNumberFilter(h, value) {
  const type = value.type;
  const filterType = numberFilters.find(f => f.value === type);
  if (!filterType) return;

  const nullOrUndefined = v => [null, undefined].includes(v);
  if (nullOrUndefined(value.value.min) && nullOrUndefined(value.value.max))
    return;

  const display = filterType.displayMap(
    value.value.min,
    value.value.max,
    h.isCurrency
  );

  return display;
}
function getArrayFilter(h, value) {
  let items = h.selectableOptions;
  if (h.selectableOptionsFn) {
    items = h.selectableOptionsFn(activeFilter.value);
  }
  return value.map(v => getFilterItem(items, v)).join(", ");
}
function getSelectFilter(h, value) {
  if (h.displayMap) return getDescendantProp(value, h.displayMap);

  return getFilterItem(h.selectableOptions, value);
}
function getObjectFilter(h, value) {
  return h.displayMap ? getDescendantProp(value, h.displayMap) : value.name;
}
function getFilterItem(options, value) {
  const res = options.find(o => o === value || o.value === value);
  return res?.text || res?.title || res;
}
</script>
