import { getHttpClient } from "@/http-client";

import { setTrainingFromRaw } from "@/models/Training";

const baseUrl = "api/boss/trainings";
export async function getTrainings() {
  const { data } = await getHttpClient().get(baseUrl);
  return data
    .map(setTrainingFromRaw)
    .toSorted((a, b) => a.name.localeCompare(b.name));
}

export async function updateTraining(id, training) {
  const { data } = await getHttpClient().put(`${baseUrl}/${id}`, { training });
  return setTrainingFromRaw(data);
}

export async function createTraining(training) {
  const { data } = await getHttpClient().post(baseUrl, { training });

  return setTrainingFromRaw(data);
}

export function deleteTraining(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
