import { setAddressFromRaw } from "@/models/Address";
import { setCommunicationPreferencesFromRaw } from "@/models/CommunicationPreferences";
import { setConnectionFromRaw } from "@/models/Connection";
import { setDocumentFromRequest } from "@/models/Document";
import { setEftFromRaw } from "@/models/Eft";
import {
  setErrorsOmissionsFromRaw,
  ErrorsOmissions
} from "@/models/Eo";
import { setLoginFromRaw } from "@/models/Login";
import {
  CommissionOptions,
  CommissionSchedules
} from "@/models/CommissionSchedules";
import {
  setCommissionOptionsFromRaw,
  setCommissionSchedulesFromRaw
} from "@/models/CommissionSchedules";

export function AgencySettings() {
  return {
    avatar: null,
    loading: false,
    name: null,
    category: null,
    email: null,
    id: null,
    tin: null,
    trustedSenders: [],
    legalStructure: null,
    communicationPreferences: null,
    phoneWork: null,
    businessAddress: null,
    efts: {},
    lockCommissions: null,
    hideCommission: null,
    annualization: null,
    commissions: {
      current: CommissionSchedules(),
      options: CommissionOptions()
    },
    commissionSplits: null,
    logins: {},
    connections: {},
    relatedDocuments: {},
    legalName: null,
    marketingManager: null,
    primaryLogin: null,
    npn: null,
    eo: ErrorsOmissions(),
    pageValidation: {
      "agency-basics": null,
      "agency-eo": null,
      "agency-commissions": null
    }
  };
}

export function setAgencySettingsFromRaw(raw) {
  const agency = AgencySettings();

  agency.avatar = raw?.avatar_url;
  agency.marketingManager = raw?.marketing_manager;
  agency.legalName = raw?.legal_name;
  agency.category = raw?.category;
  agency.tin = raw?.tin;
  agency.name = raw?.name;
  agency.email = raw?.email;
  agency.id = raw?.id;
  agency.legalStructure = raw?.legal_structure;
  agency.phoneWork = raw?.phones?.work;
  agency.npn = raw?.npn;
  agency.annualization = raw?.annualization;

  agency.eo = setErrorsOmissionsFromRaw(raw?.eo);
  if (raw?.primary_login) {
    agency.primaryLogin = setLoginFromRaw(raw.primary_login);
  }

  raw?.efts?.forEach(rawEft => {
    const eft = setEftFromRaw(rawEft);
    agency.efts[eft.id] = eft;
  });

  agency.lockCommissions = raw?.commission_lock;
  agency.hideCommission = raw?.hide_commission;

  if (raw?.commissions?.current) {
    agency.commissions.current = setCommissionSchedulesFromRaw(
      raw.commissions.current
    );
  }

  if (raw?.commissions?.options) {
    agency.commissions.options = setCommissionOptionsFromRaw(
      raw.commissions.options
    );
  }

  const rawBusinessAddress = raw?.addresses?.find(
    a => a?.address_type === "business"
  );
  agency.businessAddress = setAddressFromRaw(rawBusinessAddress);
  agency.communicationPreferences = setCommunicationPreferencesFromRaw(
    raw?.communication_preference
  );

  raw?.logins?.forEach(rlogin => {
    const login = setLoginFromRaw(rlogin);
    agency.logins[login.id] = login;
  });

  raw?.connections?.forEach(rawConnection => {
    const connection = setConnectionFromRaw(rawConnection);
    agency.connections[connection.id] = connection;
  });

  raw?.related_documents?.forEach(rdoc => {
    const document = setDocumentFromRequest(rdoc);
    agency.relatedDocuments[document.id] = document;
  });

  return agency;
}
