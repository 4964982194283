import { Statement } from "@/models/Statement";
import { defineStore } from "pinia";
import {
  getBackNineStatement,
  updateStatement,
  deleteStatement,
  uploadStatementFile
} from "@/api/statements.service";

export const useStatementView = defineStore("statement-view", {
  state: () => Statement(),
  actions: {
    async initialize(id) {
      this.$reset();
      const statement = await getBackNineStatement(id);
      this.$patch(statement);
    },
    updateAmount() {
      return updateStatement(this.id, { amount: this.amount });
    },
    updatePremium() {
      return updateStatement(this.id, { premium: this.premium });
    },
    async uploadNonImportableDocument(file) {
      const result = await uploadStatementFile(this.id, {
        file,
        category: "Non Importable Transactions"
      });
      this.$patch({ nonImportableDocument: result });
    },
    deleteStatement() {
      return deleteStatement(this.id);
    }
  }
});
