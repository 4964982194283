<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="table in tables" :key="table.title">
      <v-expansion-panel-title> {{ table.title }} </v-expansion-panel-title>
      <v-expansion-panel-text class="no-expansion-panel-content-padding">
        <component :is="table.component" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import VendorTable from "@/components/vendors/VendorTable.vue";
import UsersTable from "@/components/users/UsersTable.vue";
import TrainingsTable from "@/components/trainings/TrainingsTable.vue";

import { markRaw } from "vue";

const tables = [
  { title: "Vendors", component: markRaw(VendorTable) },
  { title: "Trainings", component: markRaw(TrainingsTable) },
  { title: "Users", component: markRaw(UsersTable) }
];
</script>
