import { getHttpClient } from "@/http-client";

const baseUrl = "/api/boss/advisors";

import { setAdvisorTableItemFromRequest } from "@/models/AdvisorTableItem";
function SignerRelationship({ id, type, name } = {}) {
  const key = `${id}-${name}`;
  return {
    key,
    name,
    id,
    type
  };
}

export const getSignerRelationships = async (
  ownable_id = null,
  ownable_type = null
) => {
  const params = new URLSearchParams();
  if (ownable_id && ownable_type) {
    params.append("ownable_id", ownable_id);
    params.append("ownable_type", ownable_type);
  }

  const { data } = await getHttpClient().get(
    `${baseUrl}/signer-relationships`,
    {
      params
    }
  );
  return data.map(SignerRelationship);
};

let dashboardCancelToken = null;
export async function getAdvisors(params) {
  if (dashboardCancelToken !== null) {
    dashboardCancelToken.cancel("Operation canceled due to new request.");
  }

  dashboardCancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: dashboardCancelToken.token
  });

  if (!response?.data) return;
  const data = response.data;

  const items = data.advisors.map(setAdvisorTableItemFromRequest);
  return { meta: data.meta, items };
}
export function emailAdvisors(body) {
  return getHttpClient().post(`${baseUrl}/email`, body);
}
export async function npnVerify(params) {
  const { data } = await getHttpClient().post(`${baseUrl}/npn`, params);
  return {
    alreadyExists: data.valid,
    loginable: data.loginable
  };
}
