<template>
  <v-card flat color="section">
    <v-card-title>Basic Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="4">
          <text-field
            v-model="contractParty.firstName"
            autofocus
            label="First Name"
            data-lpignore="true"
            data-testid="first-name"
            :prepend-inner-icon="mdiAccount"
            :success="firstNameValidation.success"
            :error-messages="firstNameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <text-field
            v-model="contractParty.middleName"
            label="Middle Name"
            data-lpignore="true"
            data-testid="middle-name"
            :prepend-inner-icon="mdiAccount"
            :success="middleNameValidation.success"
            :error-messages="middleNameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <text-field
            v-model="contractParty.lastName"
            label="Last Name"
            data-lpignore="true"
            data-testid="last-name"
            :prepend-inner-icon="mdiAccountMultiple"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <select-field
            v-model="contractParty.gender"
            label="Gender"
            placeholder="Choose a Gender"
            data-testid="gender"
            :prepend-inner-icon="mdiGenderMaleFemale"
            :items="GENDERS"
            :success="genderValidation.success"
            :error-messages="genderValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <select-field
            v-model="contractParty.maritalStatus"
            label="Marital Status"
            placeholder="Choose a Marital Status"
            clearable
            data-testid="marital-status"
            :prepend-inner-icon="mdiRing"
            :success="maritalStatusValidation.success"
            :error-messages="maritalStatusValidation.errorMessages"
            :items="INDIVIDUAL_MARITAL_STATUSES"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Contact Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <phone-input
            v-model="contractParty.phoneMobile"
            label="Mobile Phone"
            data-lpignore="true"
            data-testid="phone-mobile"
            :prepend-inner-icon="mdiPhone"
            :success="phoneMobileValidation.success"
            :error-messages="phoneMobileValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <basic-email-input
            v-model="contractParty.email"
            label="Email"
            data-lpignore="true"
            data-testid="email"
            :prepend-inner-icon="mdiEmail"
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
            @valid="setEmailIsValid"
          />
        </v-col>
        <v-col cols="12">
          <basic-address-input
            key="contract-party-home-address"
            v-model:street-address-model="
              contractParty.homeAddress.street_address
            "
            v-model:city-model="contractParty.homeAddress.city"
            v-model:state-model="contractParty.homeAddress.state"
            v-model:zip-model="contractParty.homeAddress.zip"
            autofill-label="Home Address"
            autofill-placeholder="Home Address"
            data-testid="home"
            :street-address-validation="homeStreetValidation"
            :city-validation="homeCityValidation"
            :state-validation="homeStateValidation"
            :zip-validation="homeZipValidation"
            @autofill="autofillHomeAddress"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Private Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <input-ssn
            v-model="contractParty.ssn"
            required
            data-testid="ssn"
            :success="ssnValidation.success"
            :error-messages="ssnValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="contractParty.birthdate"
            label="Birthdate"
            data-testid="birthdate"
            :prepend-inner-icon="mdiCalendar"
            :success="birthdateValidation.success"
            :error-messages="birthdateValidation.errorMessages"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="4">
          <currency-input
            v-model="contractParty.income"
            include-decimals
            label="Income"
            data-testid="income"
            :decimal-length="0"
            :prepend-inner-icon="mdiCurrencyUsd"
            :success="incomeValidation.success"
            :error-messages="incomeValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <currency-input
            v-model="contractParty.assets"
            label="Assets"
            include-decimals
            data-testid="assets"
            :decimal-length="0"
            :prepend-inner-icon="mdiCurrencyUsd"
            :success="assetsValidation.success"
            :error-messages="assetsValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <currency-input
            v-model="contractParty.liabilities"
            label="Liabilities"
            include-decimals
            data-testid="liabilities"
            :decimal-length="0"
            :prepend-inner-icon="mdiCurrencyUsd"
            :success="liabilitiesValidation.success"
            :error-messages="liabilitiesValidation.errorMessages"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <text-field
            v-model="contractParty.driversLicenseNumber"
            label="Drivers License"
            data-lpignore="true"
            data-testid="drivers-license-number"
            :prepend-inner-icon="mdiCardAccountDetails"
            :success="driversLicenseNumberValidation.success"
            :error-messages="driversLicenseNumberValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="contractParty.driversLicenseState"
            label="Drivers License State"
            placeholder="Choose DL State"
            clearable
            data-testid="drivers-license-state"
            :items="STATES"
            :success="driversLicenseStateValidation.success"
            :error-messages="driversLicenseStateValidation.errorMessages"
            :prepend-inner-icon="mdiCardAccountDetailsOutline"
            @click:clear="driversLicenseState = null"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="contractParty.height"
            label="Height"
            data-testid="height"
            :items="HEIGHTS"
            :prepend-inner-icon="mdiHumanMaleHeight"
            :success="heightValidation.success"
            :error-messages="heightValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <integer-input
            v-model="contractParty.weight"
            label="Weight"
            data-lpignore="true"
            mask="###"
            data-testid="weight"
            :prepend-inner-icon="mdiScale"
            :success="weightValidation.success"
            :error-messages="weightValidation.errorMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup>
import InputSsn from "@/components/shared/InputSsn.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiCalendar,
  mdiCardAccountDetails,
  mdiCardAccountDetailsOutline,
  mdiCurrencyUsd,
  mdiEmail,
  mdiGenderMaleFemale,
  mdiHumanMaleHeight,
  mdiPhone,
  mdiRing,
  mdiScale
} from "@mdi/js";

import { GENDERS } from "@/data/genders";
import { STATES } from "@/data/states";
import { HEIGHTS } from "@/data/heights";
import { computedValidation, someTextValidator } from "@/util/helpers";
import {
  Individual,
  INDIVIDUAL_MARITAL_STATUSES
} from "@/models/ContractParty";
import useVuelidate from "@vuelidate/core";
import { ref,  watch } from "vue";
import { maritalStatusItems } from "@/data/quote-request-items";
import {
  phone as phoneValidator,
  ssn as ssnValidator
} from "@/util/vuelidateValidators";

const emit = defineEmits(["update:model-value"]);

const emailIsValid = ref(null);

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  validationScope: {
    type: String,
    required: true
  }
});

const contractParty = ref(Individual(props.modelValue));

const v$ = useVuelidate(
  {
    contractParty: {
      assets: {
        required: v => !v || v >= 0
      },
      birthdate: {
        required: true
      },
      driversLicenseNumber: {
        required: v => !v || someTextValidator(true, v, 2)
      },
      driversLicenseState: {
        required: v => !v || STATES.some(s => s.value === v)
      },
      email: {
        required: v => !v || emailIsValid.value
      },
      firstName: {
        required: v => someTextValidator(true, v, 2)
      },
      gender: {
        required: v => !v || GENDERS.includes(v)
      },
      height: {
        required: v => !v || HEIGHTS.some(h => h.value === v)
      },
      homeAddress: {
        street_address: { required: v => !v || someTextValidator(true, v, 2) },
        city: { required: v => !v || someTextValidator(true, v, 2) },
        state: { required: v => !v || STATES.some(s => s.value === v) },
        zip: { required: v => !v || `${v}`.length === 5 }
      },
      income: {
        required: v => !v || v >= 0
      },
      lastName: {
        required: v => someTextValidator(true, v, 2)
      },
      liabilities: {
        required: v => !v || v >= 0
      },
      maritalStatus: {
        required: v => !v || maritalStatusItems.includes(v)
      },
      middleName: {
        required: v => !v || someTextValidator(true, v, 1)
      },
      phoneMobile: {
        required: v => !v || phoneValidator(v)
      },
      ssn: {
        required: v => !v || ssnValidator(v)
      },
      weight: {
        required: v => !v || v >= 0
      }
    }
  },
  { contractParty },
  { $autoDirty: true, $scope: props.validationScope }
);

const assetsValidation = computedValidation(v$.value.contractParty.assets, {
  required: "Required"
});
const birthdateValidation = computedValidation(
  v$.value.contractParty.birthdate,
  {
    required: "Required"
  }
);
const driversLicenseNumberValidation = computedValidation(
  v$.value.contractParty.driversLicenseNumber,
  { required: "Required" }
);
const driversLicenseStateValidation = computedValidation(
  v$.value.contractParty.driversLicenseState,
  { required: "Required" }
);
const emailValidation = computedValidation(v$.value.contractParty.email, {
  required: "Required"
});
const firstNameValidation = computedValidation(
  v$.value.contractParty.firstName,
  {
    required: "Required"
  }
);
const genderValidation = computedValidation(v$.value.contractParty.gender, {
  required: "Required"
});
const heightValidation = computedValidation(v$.value.contractParty.height, {
  required: "Required"
});
const homeCityValidation = computedValidation(
  v$.value.contractParty.homeAddress.city,
  {
    required: "Required"
  }
);
const homeStateValidation = computedValidation(
  v$.value.contractParty.homeAddress.state,
  {
    required: "Required"
  }
);
const homeStreetValidation = computedValidation(
  v$.value.contractParty.homeAddress.street_address,
  {
    required: "Required"
  }
);
const homeZipValidation = computedValidation(
  v$.value.contractParty.homeAddress.zip,
  {
    required: "Required"
  }
);
const incomeValidation = computedValidation(v$.value.contractParty.income, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.contractParty.lastName, {
  required: "Required"
});
const liabilitiesValidation = computedValidation(
  v$.value.contractParty.liabilities,
  {
    required: "Required"
  }
);
const maritalStatusValidation = computedValidation(
  v$.value.contractParty.maritalStatus,
  {
    required: "Required"
  }
);
const middleNameValidation = computedValidation(
  v$.value.contractParty.middleName,
  {
    required: "Required"
  }
);
const phoneMobileValidation = computedValidation(
  v$.value.contractParty.phoneMobile,
  {
    required: "Required"
  }
);
const ssnValidation = computedValidation(v$.value.contractParty.ssn, {
  required: "Required"
});
const weightValidation = computedValidation(v$.value.contractParty.weight, {
  required: "Required"
});

function setEmailIsValid(isValid) {
  emailIsValid.value = isValid;
}

function autofillHomeAddress({ street_address, city, state, zip }) {
  contractParty.value.homeAddress.street_address = street_address;
  contractParty.value.homeAddress.city = city;
  contractParty.value.homeAddress.state = state;
  contractParty.value.homeAddress.zip = zip;
}

watch(contractParty, () => emit("update:model-value", contractParty.value), {
  deep: true
});
</script>
