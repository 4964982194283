<template>
  <chat-view
    title="Support Ticket Notes"
    :pusher-id="pusherId"
    :messages="messages"
    :create-func="sendChatMessage"
    @remove-message="v => supportTicketView.deleteNote(v.id)"
    @new-note="supportTicketView.addNote"
  />
</template>

<script setup>
import ChatView from "@/components/shared/chat/ChatView.vue";

import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { parseErrorMessage } from "@/util/helpers";

import { useSupportTicketView } from "@/stores/support-ticket-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useUserStore } from "@/stores/user";

const loadingStatus = ref(null);

const user = useUserStore();
const snackbar = useSnackbarStore();
const supportTicketView = useSupportTicketView();
const { notes, pusherId } = storeToRefs(supportTicketView);

const messages = computed(() => {
  return [
    {
      note: `Hi ${user.loginable.name}, you can make notes here.`,
      ownable: { name: "BackNine" }
    },
    ...notes.value
  ];
});

async function sendChatMessage({ files, message }) {
  loadingStatus.value = "submit";

  try {
    const note = await supportTicketView.createNote({ files, message });
    loadingStatus.value = "";
    return note;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    loadingStatus.value = "";
  }
}
</script>
