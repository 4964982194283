<template>
  <v-card>
    <v-card-title class="py-5"> Banner Instant Decisions </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row class="ma-0" align="center" dense>
        <v-col cols="6">
          <v-card
            data-testid="instant-decisions-received"
            variant="outlined"
            class="pa-2"
          >
            <v-card-title class="text-center text-h5 darker-color">
              <number-formatter :model-value="received" />
            </v-card-title>
            <v-card-subtitle class="text-center">
              Instant Decisions Received
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            data-testid="instant-decisions-eligible"
            variant="outlined"
            class="pa-2"
          >
            <v-card-title class="text-center text-h5 darker-color">
              <number-formatter :model-value="eligible" />
            </v-card-title>
            <v-card-subtitle class="text-center">
              Instant Decisions Eligible
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <div class="pl-1 py-3">
        <p class="mb-0">Instant Decision Eligibility:</p>
        <ul>
          <li class="ml-7">Standard Plus or Better</li>
          <li class="ml-7">$4,000,000 or less Death Benefit</li>
          <li class="ml-7">60 and under</li>
        </ul>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";
import { getInstantDecisionsReport } from "@/api/reports.service";
export default {
  components: { NumberFormatter },
  props: {
    all: Boolean
  },
  data: () => ({ eligible: 0, received: 0 }),
  created() {
    this.getReportData();
  },
  methods: {
    async getReportData() {
      try {
        const params = new URLSearchParams();
        if (this.all) params.append("view", "all");
        const response = await getInstantDecisionsReport(params);
        this.eligible = response.eligible;
        this.received = response.received;
      } catch (e) {
        // Do nothing.
      } finally {
        this.loaded = true;
      }
    }
  }
};
</script>
