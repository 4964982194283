import { getHttpClient } from "@/http-client";
import { setApplicationVersionTableItemFromRequest } from "@/models/ApplicationVersion";
const baseUrl = "api/boss/application_versions";

export async function getApplicationVersions(urlParams, cancelToken) {
  let params = urlParams;
  if (!params) params = new URLSearchParams();
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  const { data } = res;

  const items = data.application_versions.map(
    setApplicationVersionTableItemFromRequest
  );

  return { items, meta: data.meta };
}
