<template>
  <v-list>
    <template v-for="item in items" :key="item">
      <!-- Change Access -->
      <v-list-item
        v-if="item === 'Change Access'"
        link
        :prepend-icon="mdiKeyChange"
        title="Change Access"
        @click="changeAccess"
      />
      <v-list-item
        v-else-if="item === 'Profile'"
        link
        exact
        :to="{
          name: 'LoggedInUserProfile'
        }"
        :prepend-icon="mdiAccount"
        title="Profile"
      />
      <!-- Advisor Edit -->
      <v-list-item
        v-else-if="item === 'Advisor Edit'"
        id="v-step-2"
        link
        :to="{
          name: 'LoggedInUserSettings'
        }"
        :prepend-icon="mdiCog"
        title="Settings"
      />
      <!-- Schedule Meeting -->
      <v-list-item
        v-else-if="item === 'Schedule Meeting' && schedulingLink.url"
        link
        target="_blank"
        :href="schedulingLink.url"
        :prepend-icon="mdiCalendar"
        :title="schedulingLink.text"
      />
      <!-- BackNine Contacts -->
      <v-list-item
        v-else-if="item === 'BackNine Contacts'"
        :to="{ name: 'Contact' }"
        link
        title="BackNine Contacts"
      >
        <template #prepend>
          <v-icon class="opacity-100">
            <img
              :src="backnines"
              class="greyscale"
              style="height: 24px; width: 24px; object-fit: contain"
            />
          </v-icon>
        </template>
      </v-list-item>
      <!-- Light/Dark Mode -->
      <v-list-item
        v-else-if="item === 'LightDark Mode'"
        :prepend-icon="darkModeAction.icon"
        :title="darkModeAction.title"
        @click="toggleDarkMode"
        @click.stop
      />
      <!-- Divider -->
      <v-divider v-else-if="item === 'Divider'" />
      <!-- Logout -->
      <logout-menu-item v-else-if="item === 'Logout'" />
    </template>
  </v-list>
</template>

<script setup>
import ChangeAccessDialog from "@/dialogs/ChangeAccessDialog.vue";
import LogoutMenuItem from "@/components/LogoutMenuItem.vue";
import { updateUser } from "@/api/users.service";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import backnines from "@/assets/img/backnines.png";
import {
  mdiKeyChange,
  mdiAccount,
  mdiCog,
  mdiCalendar,
  mdiWeatherSunny,
  mdiThemeLightDark,
  mdiWeatherNight
} from "@mdi/js";
import { computed, markRaw } from "vue";
import { parseErrorMessage } from "@/util/helpers";

defineProps({
  items: {
    type: Array,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { darkMode, id: userId, loginable } = storeToRefs(useUserStore());

const schedulingLinks = computed(
  () => loginable.value?.marketing_manager?.scheduling_links || []
);
const schedulingLink = computed(
  () => schedulingLinks.value?.[0] || { url: "", text: "" }
);
const darkModeAction = computed(() => {
  if (darkMode.value === true) {
    return { icon: mdiWeatherSunny, title: "Enable Light Mode", value: false };
  }
  if (darkMode.value === false) {
    return {
      icon: mdiThemeLightDark,
      title: "Enable Auto Light/Dark Mode",
      value: null
    };
  }
  return { icon: mdiWeatherNight, title: "Enable Dark Mode", value: true };
});

function changeAccess() {
  dialog.showDialog({
    component: markRaw(ChangeAccessDialog)
  });
}

async function toggleDarkMode() {
  darkMode.value = darkModeAction.value.value;
  try {
    await updateUser(userId.value, { dark_mode: darkMode.value });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
</script>
