<template>
  <v-row dense>
    <v-col v-if="props.quoteSave" cols="12">
      <autocomplete-field
        v-model="quoteData.status"
        data-testid="input-status"
        :prepend-inner-icon="mdiListStatus"
        label="Status"
        :disabled="props.disabled"
        :items="STATUSES"
        :success="statusValidation.success"
        :error-messages="statusValidation.errorMessages"
        @update:model-value="savingBuffer.status.update(saveWrapper('status'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.status.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" md="4">
      <autocomplete-field
        v-model="quoteData.state"
        data-testid="input-state"
        :prepend-inner-icon="mdiSignRealEstate"
        label="State"
        :disabled="props.disabled"
        :items="STATES"
        :success="stateValidation.success"
        :error-messages="stateValidation.errorMessages"
        @update:model-value="savingBuffer.state.update(saveWrapper('state'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.state.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" md="4">
      <autocomplete-field
        v-model="quoteData.typeOfFunds"
        data-testid="input-type-of-funds"
        :prepend-inner-icon="mdiSack"
        label="Type of Funds"
        :disabled="props.disabled"
        :items="TYPE_OF_FUNDS"
        :success="typeOfFundsValidation.success"
        :error-messages="typeOfFundsValidation.errorMessages"
        @update:model-value="savingBuffer.status.update(saveWrapper('typeOfFunds'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.typeOfFunds.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>

    <v-col cols="12" md="4">
      <currency-input
        v-model="quoteData.premium"
        data-testid="input-premium"
        :prepend-inner-icon="mdiCurrencyUsd"
        label="Premium"
        :disabled="props.disabled"
        :success="premiumValidation.success"
        :error-messages="premiumValidation.errorMessages"
        @update:model-value="savingBuffer.premium.debounceUpdate(saveWrapper('premium'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.premium.controller.value"
          />
        </template>
      </currency-input>
    </v-col>

    <v-col
      cols="12"
      class="rounded"
      :class="{
        'error-sheet-outlined': productTypeValidation.errorMessages.length
      }"
    >
      <v-expansion-panels v-model="expandedTypes" multiple>
        <quote-request-product-type
          v-for="(type, index) in types"
          :key="index"
          v-bind="type.props"
          v-on="type.listeners"
          @expand="expand(index)"
        />
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script setup>
import QuoteRequestProductType from "@/components/quotes/quote-request/product-type/QuoteRequestProductType.vue";
import {
  basicTypeEnabled,
  addEnabled,
  addIncomeDurationType,
  addAnnuityGuaranteeType,
  addCertainPeriod,
  addIncomeStartDate,
  addSurrenderPeriods,
  addIncomeStartAge,
  baseTypeGenerator
} from "@/components/quotes/quote-request/product-type/product-type-util.mjs";

import { STATES } from "@/data/states";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import {
  STATUSES,
  TYPE_OF_FUNDS,
  SURRENDER_PERIODS,
  INCOME_DURATION_ITEMS,
  INCOME_DURATIONS,
  REFUND_OPTION_ITEMS,
  REFUND_OPTIONS,
  QuoteRequestAnnuityInfo
} from "@/models/Quote";
import { computedValidation } from "@/util/helpers";
import { ref,   computed, toRefs, watch } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import useVuelidate from "@vuelidate/core";
import {
  mdiListStatus,
  mdiSignRealEstate,
  mdiSack,
  mdiCurrencyUsd
} from "@mdi/js";

const props = defineProps({
  disabled: Boolean,
  state: {
    type: String,
    default: ""
  },
  typeOfFunds: {
    type: String,
    default: ""
  },
  productType: {
    type: Array,
    default: null
  },
  premium: {
    type: [String, Number],
    default: null
  },
  status: {
    type: String,
    default: "Pending"
  },
  surrenderPeriods: {
    type: Array,
    default: () => [],
    required: false
  },
  incomeDurationType: {
    type: String,
    default: null,
    required: false
  },
  incomeStartDate: {
    type: String,
    default: null,
    required: false
  },
  incomeStartAge: {
    type: Number,
    default: null,
    required: false
  },
  incomeEndAge: {
    type: Number,
    default: null,
    required: false
  },
  annuityGuaranteeType: {
    type: String,
    default: null,
    required: false
  },
  certainPeriod: {
    type: Number,
    default: null,
    required: false
  },
  birthdate: {
    type: Date,
    required: false,
    default: null
  },
  quoteSave: {
    type: Function,
    required: false,
    default: null
  }
});

const expandedTypes = ref([]);

const { birthdate } = toRefs(props);

const quoteData = ref(QuoteRequestAnnuityInfo(props));
const savingBuffer = {
  status: useActiveSave(),
  state: useActiveSave(),
  typeOfFunds: useActiveSave(),
  premium: useActiveSave()
};
const emit = defineEmits([
  ...Object.keys(QuoteRequestAnnuityInfo()).map(key => `update:${key}`),
  "save"
]);

const v$ = useVuelidate(
  {
    quoteData: {
      state: { required: v => !!v },
      typeOfFunds: { required: v => !!v },
      productType: { required: v => v.length },
      premium: { required: v => !!v },
      status: { required: v => !props.quoteSave || !!v }
    }
  },
  {
    quoteData
  },
  {
    $autoDirty: true,
    $scope: "quote-request"
  }
);

const singlePremiumIncomeAnnuityEnabled = basicTypeEnabled(
  quoteData,
  "Single Premium Income Annuity"
);
const fixedEnabled = basicTypeEnabled(quoteData, "Fixed");
const indexedEnabled = basicTypeEnabled(quoteData, "Indexed");
const indexedIncomeEnabled = basicTypeEnabled(
  quoteData,
  "Indexed Income Rider"
);

const enabledProps = { save: saveInjector("productType") };
const incomeDurationProps = {
  items: INCOME_DURATION_ITEMS,
  save: saveInjector("incomeDurationType")
};

const surrenderPeriodProps = {
  items: SURRENDER_PERIODS,
  save: saveInjector("surrenderPeriods")
};

const singlePremiumConfig = computed(() => {
  const config = baseTypeGenerator(
    "Single Premium Income Annuity",
    props.disabled
  );

  addEnabled(config, singlePremiumIncomeAnnuityEnabled, enabledProps);
  addIncomeDurationType(config, quoteData, incomeDurationProps);
  addAnnuityGuaranteeType(config, quoteData, annuityGuaranteeTypeProps.value);
  if (certainPeriodProps.value) {
    addCertainPeriod(config, quoteData, certainPeriodProps.value);
  }
  addIncomeStartDate(config, quoteData, incomeStartDateProps.value);

  return config;
});

const mygaConfig = computed(() => {
  const config = baseTypeGenerator(
    "Fixed / Multi Year Guaranteed Annuity (MYGA)",
    props.disabled
  );

  addEnabled(config, fixedEnabled, enabledProps);
  addSurrenderPeriods(config, quoteData, surrenderPeriodProps);

  return config;
});

const indexedConfig = computed(() => {
  const config = baseTypeGenerator("Indexed", props.disabled);

  addEnabled(config, indexedEnabled, enabledProps);
  addSurrenderPeriods(config, quoteData, surrenderPeriodProps);

  return config;
});

const indexedIncomeRiderConfig = computed(() => {
  const config = baseTypeGenerator("Indexed Income Rider", props.disabled);

  addEnabled(config, indexedIncomeEnabled, enabledProps);
  addIncomeStartAge(config, quoteData, {
    save: saveInjector("incomeStartAge")
  });
  return config;
});

const types = computed(() => {
  return [
    singlePremiumConfig.value,
    mygaConfig.value,
    indexedConfig.value,
    indexedIncomeRiderConfig.value
  ];
});

const statusValidation = computedValidation(v$.value.quoteData.status, [
  { key: "required", message: "Required" }
]);

const typeOfFundsValidation = computedValidation(
  v$.value.quoteData.typeOfFunds,
  [{ key: "required", message: "Required" }]
);

const productTypeValidation = computedValidation(
  v$.value.quoteData.productType,
  [{ key: "required", message: "Required" }]
);

const stateValidation = computedValidation(v$.value.quoteData.state, [
  { key: "required", message: "Required" }
]);

const premiumValidation = computedValidation(v$.value.quoteData.premium, [
  { key: "required", message: "Required" }
]);

const annuityGuaranteeTypeProps = computed(() =>
  quoteData.value.incomeDurationType === INCOME_DURATIONS.LIFE
    ? { items: REFUND_OPTION_ITEMS, save: saveInjector("annuityGuaranteeType") }
    : null
);

const certainPeriodProps = computed(() => {
  if (
    quoteData.value.annuityGuaranteeType === REFUND_OPTIONS.CERTAIN_PERIOD ||
    quoteData.value.incomeDurationType === INCOME_DURATIONS.CERTAIN_ONLY
  ) {
    return {
      save: saveInjector("certainPeriod")
    };
  }
  return null;
});

const incomeStartDateProps = computed(() => {
  if (birthdate.value)
    return {
      birthdate: birthdate.value,
      save: saveInjector("incomeStartDate")
    };
  return null;
});

function expand(index) {
  const expandedIndex = expandedTypes.value.indexOf(index);
  if (expandedIndex === -1) {
    expandedTypes.value.push(index);
  }
}

function saveInjector(key) {
  if (props.quoteSave) return () => props.quoteSave(key);
  return null;
}

function saveWrapper(key) {
  if (props.quoteSave) return () => props.quoteSave(key);
  return () => {};
}

Object.keys(QuoteRequestAnnuityInfo()).forEach(key => {
  watch(
    () => quoteData.value[key],
    v => {
      if (JSON.stringify(v) === props[key]) return;
      emit(`update:${key}`, v);
    },
    { deep: true }
  );
});
</script>
