import { defineStore } from "pinia";
import { fetchIndividual } from "@/api/individual.service";
import { fetchEntity } from "@/api/entities.service";
import { Party } from "@/models/Party";

export const usePartyView = defineStore("party-view", {
  state: () => Party(),
  actions: {
    async initialize(id, type) {
      this.$reset();

      let fetchedParty;
      if (type === "Individual") {
        fetchedParty = await fetchIndividual(id);
      } else if (type === "Entity") {
        fetchedParty = await fetchEntity(id);
      }

      this.$patch(fetchedParty);
      Object.keys(fetchedParty).forEach(key => {
        this[key] = fetchedParty[key];
      });
    }
  }
});
