<template>
  <v-card flat class="ma-3">
    <v-card-title class="text-h5">Create a Quote Request</v-card-title>
    <v-divider />
    <v-card variant="flat">
      <v-card-title class="text-left"> General Information </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" lg="6">
            <quote-request-insurance-type />
          </v-col>
          <v-col cols="12" lg="6">
            <quote-request-agent-info />
          </v-col>
          <v-col v-if="showConcept" cols="12">
            <quote-request-concepts />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card variant="flat">
      <v-card-title class="text-left">Insured Information</v-card-title>
      <v-card-text>
        <quote-request-insured-info />
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card v-if="displayHasJointInsured" variant="flat">
      <v-card-title class="text-left"> Joint Insured Information </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <div class="checkbox-width">
              <checkbox-field
                v-model="hasJointInsured"
                data-testid="joint-toggle"
                label="Has Joint Insured"
                class="pa-3 mt-1"
              />
            </div>
          </v-col>
          <v-col v-if="hasJointInsured" cols="12">
            <quote-request-insured-info is-joint />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card variant="flat">
      <v-card-title class="text-left"> Illustration Information </v-card-title>
      <v-card-text>
        <component
          :is="lineComponent"
          v-model:type-of-funds="typeOfFunds"
          v-model:product-type="productType"
          v-model:premium="premium"
          v-model:riders="riders"
          v-model:existing-coverage="existingCoverage"
          v-model:description="description"
          v-model:existing-benefit-maximum="existingBenefitMaximum"
          v-model:existing-benefit-period="existingBenefitPeriod"
          v-model:existing-elimination-period="existingEliminationPeriod"
          v-model:existing-employer-paid="existingEmployerPaid"
          v-model:existing-percentage-maximum="existingPercentageMaximum"
          v-model:monthly-benefit="monthlyBenefit"
          v-model:monthly-benefit-solve="monthlyBenefitSolve"
          v-model:mode="mode"
          v-model:benefit-period="benefitPeriod"
          v-model:elimination-period="eliminationPeriod"
          v-model:smoker="smoker"
          v-model:status="status"
          v-model:state="state"
          v-model:face-amount="faceAmount"
          v-model:inflation="inflation"
          v-model:discount="discount"
          v-model:files="files"
          v-model:surrender-periods="surrenderPeriods"
          v-model:income-duration-type="incomeDurationType"
          v-model:income-enabled="incomeEnabled"
          v-model:annuity-guarantee-type="annuityGuaranteeType"
          v-model:certain-period="certainPeriod"
          v-model:income-start-date="incomeStartDate"
          v-model:income-start-age="incomeStartAge"
          v-model:income-end-age="incomeEndAge"
          v-model:pay-durations="payDurations"
          v-model:business-owner="businessOwner"
          :birthdate="birthdate"
          @prompt-for-quote-and-apply="promptForQuoteAndApply"
        />
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card variant="flat">
      <v-card-title class="text-left"> Additional Information </v-card-title>
      <v-card-text>
        <quote-request-additional-info />
      </v-card-text>
    </v-card>
    <v-card-actions class="pl-5 pb-5">
      <app-button
        color="primary"
        class="text-none"
        data-testid="create-quote"
        :loading="loading"
        @click="createNewQuote"
      >
        <v-icon class="mr-1" :icon="mdiPlus" /> Create Quote Request
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import QuoteRequestInsuranceType from "@/components/quotes/quote-request/QuoteRequestInsuranceType.vue";
import QuoteRequestAgentInfo from "@/components/quotes/quote-request/QuoteRequestAgentInfo.vue";
import QuoteRequestInsuredInfo from "@/components/quotes/quote-request/QuoteRequestInsuredInfo.vue";
import QuoteRequestConcepts from "@/components/quotes/quote-request/QuoteRequestConcepts.vue";
import QuoteRequestAdditionalInfo from "@/components/quotes/quote-request/QuoteRequestAdditionalInfo.vue";
import QuoteRequestAnnuityInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestAnnuityInfo.vue";
import QuoteRequestDisabilityInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestDisabilityInfo.vue";
import QuoteRequestLifeInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestLifeInfo.vue";
import QuoteRequestLtcInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestLtcInfo.vue";

import * as QUOTE_TYPE from "@/constants/quote-types.constants";
import { parseErrorMessage } from "@/util/helpers";
import { useHead } from "@unhead/vue";
import { computed, ref, markRaw, watch } from "vue";
import { storeToRefs } from "pinia";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "vue-router";

import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useQuoteRequestStore } from "@/stores/quote-request";
import { mdiPlus } from "@mdi/js";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
useHead({ title: "Create a Quote" });

const user = useUserStore();
const loading = ref(false);
const router = useRouter();

const quoteRequest = useQuoteRequestStore();
const {
  hasJointInsured,
  agent,
  displayHasJointInsured,
  typeOfFunds,
  files,
  productType,
  premium,
  riders,
  existingCoverage,
  description,
  existingBenefitMaximum,
  existingBenefitPeriod,
  existingEliminationPeriod,
  existingEmployerPaid,
  existingPercentageMaximum,
  monthlyBenefit,
  monthlyBenefitSolve,
  mode,
  benefitPeriod,
  eliminationPeriod,
  smoker,
  status,
  state,
  faceAmount,
  inflation,
  discount,
  showConcept,
  line,
  surrenderPeriods,
  businessOwner,
  birthdate,
  incomeDurationType,
  incomeEnabled,
  incomeStartDate,
  incomeStartAge,
  incomeEndAge,
  annuityGuaranteeType,
  certainPeriod,
  payDurations
} = storeToRefs(quoteRequest);

const v$ = useVuelidate({}, {}, { $scope: "quote-request" });

const lineComponent = computed(() => {
  switch (line.value) {
    case QUOTE_TYPE.ANNUITY:
      return markRaw(QuoteRequestAnnuityInfo);
    case QUOTE_TYPE.LIFE:
      return markRaw(QuoteRequestLifeInfo);
    case QUOTE_TYPE.LTC:
      return markRaw(QuoteRequestLtcInfo);
    default:
      return markRaw(QuoteRequestDisabilityInfo);
  }
});

const hasPrompted = {
  [QUOTE_TYPE.LIFE]: false,
  [QUOTE_TYPE.LTC]: false
};

const promptSubtitle = {
  [QUOTE_TYPE.LIFE]:
    "Don't wait. This product is available for instant quoting within Quote & Apply",
  [QUOTE_TYPE.LTC]:
    "Don't wait. Long Term Care and Linked Benefit products are available for instant quoting within Quote & Apply"
};

const promptTitle = {
  [QUOTE_TYPE.LIFE]: "Want Faster Quotes?",
  [QUOTE_TYPE.LTC]: "Want Faster Quotes?"
};

function promptForQuoteAndApply() {
  if (!agent.value) return;
  if (!(line.value in hasPrompted) || hasPrompted[line.value]) return;

  hasPrompted[line.value] = true;

  const url = new URL(agent.value.approved_domain_url);

  return dialog.showDialog({
    persistent: true,
    component: markRaw(ConfirmationDialog),
    title: promptTitle[line.value],
    subtitle: promptSubtitle[line.value],
    confirmText: "Continue to Quote & Apply",
    confirmHref: url.toString(),
    cancelText: "Continue with Quote Request"
  });
}

async function createNewQuote() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  return submit();
}
async function submit() {
  try {
    loading.value = true;
    const quoteId = await quoteRequest.submit();
    if (router?.push) {
      router.push({
        name: "QuoteView",
        params: {
          id: quoteId
        }
      });
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function initializeQuoteRequest() {
  const a =
    user.loginable.type === "Agency" ? user.loginable.signer : user.loginable;
  quoteRequest.$patch({
    line: QUOTE_TYPE.LIFE,
    agent: {
      name: a.name,
      id: a.id,
      type: a.type,
      email: a.email,
      approved_domain_url: a.approved_domain_url
    }
  });
}

watch(line, () => {
  productType.value.splice(0, productType.value.length);
  files.value.splice(0, files.value.length);
  payDurations.value.splice(0, payDurations.value.length);

  typeOfFunds.value = null;
  premium.value = null;
  riders.value = null;
  existingCoverage.value = null;
  description.value = null;
  existingBenefitMaximum.value = null;
  existingBenefitPeriod.value = null;
  existingEliminationPeriod.value = null;
  existingEmployerPaid.value = null;
  existingPercentageMaximum.value = null;
  monthlyBenefit.value = null;
  monthlyBenefitSolve.value = null;
  mode.value = null;
  benefitPeriod.value = null;
  eliminationPeriod.value = null;
  smoker.value = null;
  status.value = null;
  state.value = null;
  faceAmount.value = null;
  inflation.value = null;
  discount.value = null;
  surrenderPeriods.value = null;
  incomeDurationType.value = null;
  annuityGuaranteeType.value = null;
  certainPeriod.value = null;
  incomeStartDate.value = null;
  incomeStartAge.value = null;
  incomeEndAge.value = null;
  incomeEnabled.value = null;
  businessOwner.value = null;
});

quoteRequest.$reset();
initializeQuoteRequest();
</script>
