import { getHttpClient } from "@/http-client";
import {
  setAppointmentFromRaw,
  setAppointmentAccessFromRaw,
  setAppointmentHierarchyFromRaw,
  setAppointmentAssignmentFromSearch,
  setAppointmentTableItemFromRaw
} from "@/models/Appointment";
import { setChatTemplateFromRequest } from "@/models/ChatTemplate";
import { useInstanceStore } from "@/stores/instance";

const baseUrl = "api/boss/appointments";

export async function getAppointments(params, cancelToken) {
  params.append("model", "appointments");
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;
  return {
    items: response.data.appointments.map(setAppointmentTableItemFromRaw),
    meta: response.data.meta,
    stats: response.data.meta.stats
  };
}

export function emailAppointments(params) {
  return getHttpClient().post(`${baseUrl}/email`, params);
}

export async function getAppointment(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setAppointmentFromRaw(data.appointment);
}

export async function getCode(advisorId, advisorType, carrierId) {
  const body = {
    advisor: { id: advisorId, type: advisorType },
    carrier: carrierId
  };

  const { data } = await getHttpClient().post(`${baseUrl}/codes`, body);
  return data.codes;
}

// pending - entered - life
export async function appointmentCreate(params) {
  const { data } = await getHttpClient().post(`${baseUrl}`, params);
  const {
    lines_of_authority,
    name: appointmentName,
    status,
    id
  } = data.appointment;

  const name = [status, appointmentName, lines_of_authority.join(",")].join(
    " - "
  );
  return {
    id,
    name
  };
}

export function appointmentUpdate(id, params) {
  return getHttpClient().put(`${baseUrl}/${id}`, params);
}

export function appointmentDelete(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export function downloadContractingForms(id, includedFormIds) {
  return getHttpClient().post(`${baseUrl}/${id}/packet`, {
    download: true,
    include: includedFormIds.join(",")
  });
}

export function emailContractingForms(id, includedFormIds) {
  return getHttpClient().post(`${baseUrl}/${id}/packet`, {
    email: true,
    include: includedFormIds.join(",")
  });
}

export async function authorizeContractingFormDownloadChannel(
  id,
  { channelName, socketId, callback } = {}
) {
  try {
    const { data } = await getHttpClient().post(
      `${baseUrl}/${id}/authorize-packet-subscription`,
      {
        channel_name: channelName,
        socket_id: socketId
      }
    );
    callback(null, data);
  } catch (e) {
    callback(e, { auth: false });
  }
}

export function updateAppointmentAccess(
  appointmentId,
  accessId,
  appointment_access
) {
  return getHttpClient().put(
    `${baseUrl}/${appointmentId}/accesses/${accessId}`,
    {
      appointment_access
    }
  );
}

export function removeAppointmentAccess(appointmentId, id) {
  return getHttpClient().delete(`${baseUrl}/${appointmentId}/accesses/${id}`);
}

export async function createAppointmentAccess(
  appointmentId,
  appointment_access
) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${appointmentId}/accesses`,
    {
      appointment_access
    }
  );
  return setAppointmentAccessFromRaw(data.appointment_access);
}

export async function getAppointmentTemplates(appointmentId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${appointmentId}/chat_templates`
  );

  return data.map(setChatTemplateFromRequest);
}

export async function getAppointmentTemplate(appointmentId, templateId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${appointmentId}/chat_templates/${templateId}`,
    {
      headers: {
        Accept: "application/json"
      }
    }
  );

  return setChatTemplateFromRequest(data);
}

export function downloadAppointmentTemplateUrl(appointmentId, templateId) {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${appointmentId}/chat_templates/${templateId}/download`;
}

export async function addAppointmentHierarchy(appointmentId, body) {
  const res = await getHttpClient().post(
    `${baseUrl}/${appointmentId}/hierarchies`,
    {
      appointment_hierarchy: body
    }
  );

  return setAppointmentHierarchyFromRaw(res.data.appointment_hierarchy);
}

export async function updateAppointmentHierarchy(appointmentId, body) {
  return getHttpClient().put(
    `${baseUrl}/${appointmentId}/hierarchies/update_many`,
    body
  );
}

export async function deleteAppointmentHierarchy(appointmentId, hierarchyId) {
  return getHttpClient().delete(
    `${baseUrl}/${appointmentId}/hierarchies/${hierarchyId}`
  );
}

export async function searchAppointments(appointmentId, search) {
  const params = new URLSearchParams();
  params.append("name", search);
  return getHttpClient().get(
    `${baseUrl}/${appointmentId}/search_add_hierarchy`,
    {
      params
    }
  );
}

export async function searchAddHierarchy(id, name) {
  const params = new URLSearchParams();
  params.append("name", name);
  const { data } = await getHttpClient().get(
    `${baseUrl}/${id}/search_add_hierarchy`,
    {
      params
    }
  );

  return data.appointments.map(setAppointmentAssignmentFromSearch);
}
