import { defineStore } from "pinia";
import { useMappedFormStore } from "@/stores/mapped-form";

export const useFormMappingView = defineStore("form-mapping-view", {
  state: () => ({
    editingFormId: null,
    copyingFromFormId: null,
    copyingField: false
  }),
  getters: {
    isReadonly() {
      if (!this.editingFormId) return false;
      const mappedForm = useMappedFormStore(this.editingFormId);
      return mappedForm.readonly;
    }
  },
  actions: {
    async copy(field) {
      const mappedForm = useMappedFormStore(this.editingFormId);
      this.copyingField = true;
      try {
        await mappedForm.copyField(field, this.copyingFromFormId);
        this.copyingField = false;
      } catch (e) {
        this.copyingField = false;
        throw e;
      }
    }
  }
});
