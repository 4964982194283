<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-row class="ma-0" dense>
        <v-col cols="12">
          <integer-input
            v-model="maxCases"
            mask="####"
            label="Max Cases"
            clearable
            data-testid="max-cases-input"
            :prepend-inner-icon="mdiBriefcase"
            :success="maxCasesValidation.success"
            :error-messages="maxCasesValidation.errorMessages"
            :active-save-controller="savingBuffer.maxCases.controller"
            @update:model-value="updateMaxCases"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="title"
            label="Title"
            data-testid="title-select"
            :items="titles"
            :prepend-inner-icon="mdiFormatTitle"
            :active-save-controller="savingBuffer.title.controller"
            v-bind="titleValidation"
            @update:model-value="updateTitle"
          />
        </v-col>
        <v-col cols="12" md="6">
          <advisor-search
            v-model="manager"
            label="Manager"
            data-testid="manager-search"
            :clearable="false"
            :prepend-inner-icon="mdiAccountSupervisor"
            :success="Boolean(manager?.id)"
            :active-save-controller="savingBuffer.manager.controller"
            @update:model-value="updateManager"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation } from "@/util/helpers";
import { getTitlesForDepartment } from "@/models/Employee";
import { useEmployeeView } from "@/stores/employee-view";
import { storeToRefs } from "pinia";
import { useActiveSave } from "@/composables/active-save.composable";
import { mdiAccountSupervisor, mdiBriefcase, mdiFormatTitle } from "@mdi/js";

const employeeView = useEmployeeView();
const { maxCases, manager, department, title } = storeToRefs(employeeView);
const titles = getTitlesForDepartment(department.value);
const savingBuffer = {
  maxCases: useActiveSave(),
  manager: useActiveSave(),
  title: useActiveSave()
};

const v$ = useVuelidate(
  {
    maxCases: {
      validAmount: v => v === null || (v >= 1 && v <= 2000)
    },
    manager: {
      validManager: v => v?.id
    },
    title: {
      validTitle: v => titles.includes(v)
    }
  },
  {
    maxCases,
    manager,
    title
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const maxCasesValidation = computedValidation(v$.value.maxCases, {
  validAmount: "Must be from 1-2,000 or empty"
});

const titleValidation = computedValidation(v$.value.title, {
  validTitle: `Must be in the list: ${titles.join(", ")}`
});

function updateMaxCases() {
  savingBuffer.maxCases.debounceUpdate(employeeView.updateMaxCases);
}

function updateTitle() {
  savingBuffer.title.update(employeeView.updateTitle);
}

function updateManager() {
  savingBuffer.manager.update(employeeView.updateManager);
}
</script>
