import { Personnel } from "@/models/Personnel";
import {
  updatePersonnel,
  getPersonnel,
  deletePersonnel
} from "@/api/personnel.service";
import { defineStore } from "pinia";

export const usePersonnelView = defineStore("personnel-view", {
  state: () => Personnel(),
  getters: {
    name() {
      return [this.firstName, this.lastName].filter(Boolean).join(" ");
    }
  },
  actions: {
    async initialize(id) {
      this.$reset();
      const personnel = await getPersonnel(id);
      this.$patch(personnel);
    },
    deletePersonnel() {
      return deletePersonnel(this.id);
    },
    updatePersonnel(body) {
      return updatePersonnel(this.id, body);
    },
    updatePhoneWork() {
      let phone_work = this.phoneWork;
      if (this.phoneWorkExtension) {
        phone_work += `x${this.phoneWorkExtension}`;
      }

      return this.updatePersonnel({
        phone_work
      });
    },
    updatePhoneMobile() {
      return this.updatePersonnel({
        phone_mobile: this.phoneMobile
      });
    },
    updateFirstName() {
      return this.updatePersonnel({
        first_name: this.firstName
      });
    },
    updateLastName() {
      return this.updatePersonnel({
        last_name: this.lastName
      });
    },
    updateTitle() {
      return this.updatePersonnel({
        title: this.title
      });
    },
    updateEmail() {
      return this.updatePersonnel({
        email: this.email
      });
    },
    updateCarrier() {
      return this.updatePersonnel({
        carrier_id: this.carrier.id
      });
    },
    updateDepartment() {
      return this.updatePersonnel({
        department: this.department
      });
    },

    updateHomeAddressAttribute(body) {
      return this.updatePersonnel({
        addresses_attributes: [
          {
            id: this.homeAddress?.id,
            address_type: "home",
            ...body
          }
        ]
      });
    },
    updateBusinessAddressAttribute(body) {
      return this.updatePersonnel({
        addresses_attributes: [
          {
            id: this.businessAddress?.id,
            address_type: "business",
            ...body
          }
        ]
      });
    },
    updateHomeAddress() {
      return this.updateHomeAddressAttribute({
        id: this.homeAddress?.id,
        street_address: this.homeAddress?.street_address,
        city: this.homeAddress?.city,
        state: this.homeAddress?.state,
        zip: this.homeAddress?.zip
      });
    },
    updateHomeStreet() {
      return this.updateHomeAddressAttribute({
        street_address: this.homeAddress?.street_address
      });
    },
    updateHomeCity() {
      return this.updateHomeAddressAttribute({
        city: this.homeAddress?.city
      });
    },
    updateHomeState() {
      return this.updateHomeAddressAttribute({
        state: this.homeAddress?.state
      });
    },
    updateHomeZip() {
      return this.updateHomeAddressAttribute({
        zip: this.homeAddress?.zip
      });
    },
    updateBusinessAddress() {
      return this.updateBusinessAddressAttribute({
        id: this.businessAddress?.id,
        street_address: this.businessAddress?.street_address,
        city: this.businessAddress?.city,
        state: this.businessAddress?.state,
        zip: this.businessAddress?.zip
      });
    },
    updateBusinessStreet() {
      return this.updateBusinessAddressAttribute({
        street_address: this.businessAddress?.street_address
      });
    },
    updateBusinessCity() {
      return this.updateBusinessAddressAttribute({
        city: this.businessAddress?.city
      });
    },
    updateBusinessState() {
      return this.updateBusinessAddressAttribute({
        state: this.businessAddress?.state
      });
    },
    updateBusinessZip() {
      return this.updateBusinessAddressAttribute({
        zip: this.businessAddress?.zip
      });
    }
  }
});
