<template>
  <v-card hover :to="routerLink">
    <div style="padding-right: 40px">
      <v-card-title
        class="text-body-1"
        style="margin-right: 50px"
        data-testid="title"
      >
        {{ card.insuredName }}
      </v-card-title>

      <v-card-subtitle
        v-if="timestamps.length"
        class="text-grey pb-2"
        data-testid="subtitle"
      >
        <div v-for="timestamp in timestamps" :key="timestamp.label">
          {{ timestamp.label }}: {{ timestamp.timestamp }}
        </div>
      </v-card-subtitle>

      <div
        style="position: absolute; top: 10px; right: 10px"
        class="text-center"
      >
        <v-img
          lazy
          contain
          width="40px"
          height="40px"
          class="v-sheet--outlined"
          style="border-radius: 50%"
          :src="card.agentAvatar"
        />
        <v-tooltip v-if="card.followUpIsScheduled" location="top">
          <template #activator="{ props: templateProps }">
            <v-icon
              v-bind="templateProps"
              data-testid="appointment-scheduled-indicator"
              color="primary"
              class="mt-3"
              :icon="mdiCalendar"
            />
          </template>
          <span>Appointment is scheduled</span>
        </v-tooltip>
      </div>
    </div>

    <v-card-text v-if="props.showStats" class="py-0">
      <v-row
        dense
        style="line-height: 1.1 !important; font-weight: 300; font-size: 12px"
      >
        <v-col data-testid="referred" cols="12">
          <div style="border-left: solid green 3px" class="pl-2">
            Referred
            <div style="font-weight: 400; font-size: 14px">
              {{ card.refer ? "Yes" : "No" }}
            </div>
          </div>
        </v-col>
        <v-col v-if="card.website" cols="12">
          <div
            data-testid="website"
            style="border-left: solid orange 3px; padding-left: 6px"
          >
            Website
            <div style="font-weight: 400; font-size: 14px">
              {{ card.website }}
            </div>
          </div>
        </v-col>
        <v-col v-if="card.stepDisplayName" cols="12">
          <div
            data-testid="step"
            style="border-left: solid purple 3px; padding-left: 6px"
          >
            Step
            <div style="font-weight: 400; font-size: 14px">
              {{ card.stepDisplayName }}
            </div>
          </div>
        </v-col>
        <v-col v-if="card.bestTimeToCall" cols="12">
          <div
            data-testid="best-time-to-call"
            class="primary"
            style="
              border-left-width: 3px;
              border-left-style: solid;
              border-top: none;
              border-bottom: none;
              border-right: none;
              background-color: transparent !important;
              padding-left: 6px;
            "
          >
            Best Time to Call
            <div style="font-weight: 400; font-size: 14px">
              {{ card.bestTimeToCall }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="px-0 pb-0">
      <v-row dense no-gutters>
        <v-col v-if="props.showStats" cols="6">
          <app-button
            v-if="card.insuredPhoneMobile"
            class="text-none w-100"
            data-testid="phone"
            variant="text"
            tile
            color="primary"
            :prepend-icon="mdiCellphone"
            :text="card.insuredPhoneMobile"
            :href="`tel:${card.insuredPhoneMobile}`"
            data-outbound-type="Individual"
            :data-outbound-id="card.insuredId"
            :data-outbound-number="card.insuredPhoneMobile"
            @click.stop
          />
        </v-col>
        <v-col :cols="props.showStats ? 6 : 12">
          <app-button
            class="text-none w-100"
            data-testid="view-eapp"
            variant="text"
            tile
            :to="routerLink"
            target="_blank"
            :prepend-icon="mdiOpenInNew"
            text="View eApp"
            @click.stop
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ElectronicApplicationTableItem } from "@/models/ElectronicApplication";
import { timestampFormatter } from "@/util/helpers";
import { mdiCalendar, mdiCellphone, mdiOpenInNew } from "@mdi/js";

const props = defineProps({
  showStats: Boolean,
  eapp: { type: Object, required: true },
  leadType: {
    type: String,
    validator: v =>
      ["New", "Contacted", "Sold", "Inactive", "Awaiting Signatures"].includes(
        v.toString()
      ),
    required: true
  }
});
const card = ElectronicApplicationTableItem(props.eapp);
const times = {
  New: ["created"],
  Contacted: ["created", "followUpAt"],
  "Awaiting Signatures": ["created", "updatedAt"],
  Sold: ["created", "completedAt"],
  Inactive: ["created", "updatedAt"]
}[props.leadType];

const format = {
  created: "full-date",
  followUpAt: "full-date-time",
  completedAt: "full-date",
  updatedAt: "full-date"
};

const label = {
  created: "Created",
  followUpAt: card.followUpIsScheduled ? "Appointment" : "Follow Up",
  completedAt: "Completed",
  updatedAt: "Last Updated"
};

const timestamps = [];

times.forEach(t => {
  timestamps.push({
    timestamp: timestampFormatter(card[t], "none", format[t]),
    label: label[t]
  });
});

const routerLink = {
  name: "ElectronicApplicationView",
  params: {
    id: card.eAppId
  }
};
</script>
