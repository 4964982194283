<template>
  <v-card flat tile>
    <v-card-text class="pb-0">
      <v-row class="pa-3">
        <v-spacer />
        <app-button
          v-if="canRefetchLicenses"
          variant="outlined"
          class="text-none ma-1 rounded-pill"
          color="primary"
          data-testid="refetch-licenses"
          text="Refetch Licenses"
          :block="smAndDown"
          :prepend-icon="mdiCardAccountDetails"
          @click="advisorView.refetchLicenses"
        />
        <app-button
          variant="outlined"
          class="text-none ma-1 rounded-pill"
          color="primary"
          data-testid="referral-code"
          text="Referral Link"
          :prepend-icon="mdiExportVariant"
          :block="smAndDown"
          @click="showAdvisorCode"
        />
        <app-button
          v-if="canLaunchQuoteAndApply"
          variant="outlined"
          class="text-none ma-1 rounded-pill"
          color="primary"
          target="_blank"
          data-testid="approved-domain-url"
          :text="launchText"
          :prepend-icon="mdiRocketLaunch"
          :block="smAndDown"
          :href="approvedDomainUrl"
        />
        <app-button
          v-if="canViewContracting"
          variant="outlined"
          class="text-none ma-1 rounded-pill"
          color="accent"
          data-testid="settings"
          :text="editText"
          :prepend-icon="mdiCog"
          :block="smAndDown"
          :to="editAdvisorLink"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CopyDialog from "@/dialogs/CopyDialog.vue";

import { getRouterLink } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { computed, markRaw } from "vue";
import { useRouter } from "vue-router";
import {
  mdiCardAccountDetails,
  mdiExportVariant,
  mdiRocketLaunch,
  mdiCog
} from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";

const advisorView = useAdvisorProfileView();
const user = useUserStore();
const dialog = useDialogStore();
const router = useRouter();

const { smAndDown } = useDisplay();

const { isGroupFour, loginable } = storeToRefs(user);
const {
  canViewContracting,
  id,
  type,
  name,
  referralCode,
  approvedDomainUrl,
  niprSubscribed
} = storeToRefs(advisorView);

const canRefetchLicenses = computed(
  () => isGroupFour.value && niprSubscribed.value
);
const isCurrentUser = computed(
  () => id.value === loginable.value.id && type.value === loginable.value.type
);
const canLaunchQuoteAndApply = computed(
  () => niprSubscribed.value && Boolean(approvedDomainUrl.value)
);

const editAdvisorLink = computed(() => {
  if (isCurrentUser.value) return { name: "LoggedInUserSettings" };
  return getRouterLink(type.value + "Settings", id.value);
});

const editText = computed(() => {
  return isCurrentUser.value ? "Edit My Settings" : "Advisor Settings";
});
const launchText = computed(() => {
  return isCurrentUser.value
    ? "Launch Quote & Apply"
    : `Launch ${name.value}'s Quote & Apply`;
});

function showAdvisorCode() {
  const route = router.resolve({
    name: "ReferralSignUp",
    params: { referralCode: referralCode.value }
  });

  const link = new URL(route.href, window.location.origin).href;

  dialog.showDialog({
    component: markRaw(CopyDialog),
    text: link,
    title: "Referral Code",
    subtitle: "Just copy and send this where you'd like!"
  });
}
</script>
