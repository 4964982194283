<template>
  <v-card class="ma-3">
    <v-card-title class="text-h5">Create a New Carrier Personnel</v-card-title>
    <v-divider />
    <v-card-title>Personnel Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <text-field
            v-model="personnel.firstName"
            label="First Name"
            data-lpignore="true"
            data-testid="first-name"
            :prepend-inner-icon="mdiAccount"
            :success="firstNameValidation.success"
            :error-messages="firstNameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="personnel.lastName"
            label="Last Name"
            data-lpignore="true"
            data-testid="last-name"
            :prepend-inner-icon="mdiAccountMultiple"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
          />
        </v-col>

        <v-col cols="12">
          <carrier-search
            v-model="personnel.carrier"
            data-testid="carrier-search"
            :success="carrierValidation.success"
            :error-messages="carrierValidation.errorMessages"
          />
        </v-col>

        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="personnel.department"
            label="Department"
            placeholder="Select Department"
            data-testid="department"
            :prepend-inner-icon="mdiOfficeBuilding"
            :items="DEPARTMENTS"
            :success="departmentValidation.success"
            :error-messages="departmentValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="personnel.title"
            data-lpignore="true"
            label="Title"
            data-testid="title"
            :prepend-inner-icon="mdiCardAccountDetails"
            :success="Boolean(personnel.title)"
          />
        </v-col>
        <v-col cols="12">
          <text-field
            v-model="personnel.email"
            label="Email"
            data-lpignore="true"
            data-testid="email"
            :prepend-inner-icon="mdiEmail"
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="8">
          <phone-input
            v-model="personnel.phoneWork"
            data-lpignore="true"
            data-testid="phone-work"
            label="Work Phone"
            :prepend-inner-icon="mdiDeskphone"
            :success="Boolean(personnel.phoneWork)"
          />
        </v-col>
        <v-col cols="12" md="4">
          <integer-input
            v-model="personnel.phoneWorkExtension"
            data-lpignore="true"
            data-testid="phone-work-extension"
            label="Work Phone Extension"
            mask="######"
            :prepend-inner-icon="mdiDeskphone"
            :success="Boolean(personnel.phoneWorkExtension)"
          />
        </v-col>
        <v-col cols="12">
          <phone-input
            v-model="personnel.phoneMobile"
            data-lpignore="true"
            data-testid="phone-mobile"
            label="Mobile Phone"
            :prepend-inner-icon="mdiCellphone"
            :success="Boolean(personnel.phoneMobile)"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Home Address</v-card-title>
    <v-card-text>
      <basic-address-input
        key="personnel-home-address"
        v-model:street-address-model="personnel.homeAddress.street_address"
        v-model:city-model="personnel.homeAddress.city"
        v-model:state-model="personnel.homeAddress.state"
        v-model:zip-model="personnel.homeAddress.zip"
        data-testid="home-address"
        autofill-label="Home Address"
        autofill-placeholder="Home Address"
        :street-address-validation="homeStreetValidation"
        :city-validation="homeCityValidation"
        :state-validation="homeStateValidation"
        :zip-validation="homeZipValidation"
        @autofill="handleHomeAutofill"
      />
    </v-card-text>
    <v-divider />
    <v-card-title>Business Address</v-card-title>
    <v-card-text>
      <basic-address-input
        key="personnel-business-address"
        v-model:street-address-model="personnel.businessAddress.street_address"
        v-model:city-model="personnel.businessAddress.city"
        v-model:state-model="personnel.businessAddress.state"
        v-model:zip-model="personnel.businessAddress.zip"
        data-testid="business-address"
        autofill-label="Business Address"
        autofill-placeholder="Business Address"
        :street-address-validation="businessStreetValidation"
        :city-validation="businessCityValidation"
        :state-validation="businessStateValidation"
        :zip-validation="businessZipValidation"
        @autofill="handleBusinessAutofill"
      />
    </v-card-text>
    <v-card-actions>
      <app-button
        color="primary"
        class="text-none"
        data-testid="create-personnel"
        :loading="loading"
        @click="create"
      >
        <v-icon class="mr-1" :icon="mdiPlus" /> Create Personnel
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import PhoneInput from "@/components/shared/PhoneInput.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";

import {
  DEPARTMENTS,
  Personnel,
  PersonnelToCreateRequest
} from "@/models/Personnel";

import {
  computedValidation,
  getRouterLink,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { createPersonnel } from "@/api/personnel.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiCardAccountDetails,
  mdiDeskphone,
  mdiEmail,
  mdiCellphone,
  mdiOfficeBuilding,
  mdiPlus
} from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import { ref } from "vue";
import { useRouter } from "vue-router";

useHead({ title: "Create Personnel" });

const snackbar = useSnackbarStore();
const router = useRouter();
const personnel = ref(Personnel());
const loading = ref(false);

const v$ = useVuelidate(
  {
    personnel: {
      firstName: { required: Boolean },
      lastName: { required: Boolean },
      email: { required: Boolean },
      carrier: { required: v => v?.id },
      department: { required: Boolean },
      homeAddress: {
        street_address: { required: v => !v || someTextValidator(true, v, 1) },
        state: { required: v => !v || someTextValidator(true, v, 1) },
        city: { required: v => !v || someTextValidator(true, v, 1) },
        zip: { validLength: v => !v || `${v}`.length === 5 }
      },
      businessAddress: {
        street_address: { required: v => !v || someTextValidator(true, v, 1) },
        state: { required: v => !v || someTextValidator(true, v, 1) },
        city: { required: v => !v || someTextValidator(true, v, 1) },
        zip: { validLength: v => !v || `${v}`.length === 5 }
      }
    }
  },
  {
    personnel
  },
  { $autoDirty: true, $scope: null }
);

const firstNameValidation = computedValidation(v$.value.personnel.firstName, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.personnel.lastName, {
  required: "Required"
});
const emailValidation = computedValidation(v$.value.personnel.email, {
  required: "Required"
});
const carrierValidation = computedValidation(v$.value.personnel.carrier, {
  required: "Required"
});
const departmentValidation = computedValidation(v$.value.personnel.department, {
  required: "Required"
});
const homeStreetValidation = computedValidation(
  v$.value.personnel.homeAddress.street_address,
  { required: "Required" }
);
const homeCityValidation = computedValidation(
  v$.value.personnel.homeAddress.city,
  {
    required: "Required"
  }
);
const homeStateValidation = computedValidation(
  v$.value.personnel.homeAddress.state,
  {
    required: "Required"
  }
);
const homeZipValidation = computedValidation(
  v$.value.personnel.homeAddress.zip,
  {
    validLength: "Must be 5 numbers long"
  }
);
const businessStreetValidation = computedValidation(
  v$.value.personnel.businessAddress.street_address,
  { required: "Required" }
);
const businessCityValidation = computedValidation(
  v$.value.personnel.businessAddress.city,
  { required: "Required" }
);
const businessStateValidation = computedValidation(
  v$.value.personnel.businessAddress.state,
  { required: "Required" }
);
const businessZipValidation = computedValidation(
  v$.value.personnel.businessAddress.zip,
  {
    validLength: "Must be 5 numbers long"
  }
);

function handleHomeAutofill({ street_address, city, state, zip }) {
  personnel.value.homeAddress.street_address = street_address;
  personnel.value.homeAddress.city = city;
  personnel.value.homeAddress.state = state;
  personnel.value.homeAddress.zip = zip;
}

function handleBusinessAutofill({ street_address, city, state, zip }) {
  personnel.value.businessAddress.street_address = street_address;
  personnel.value.businessAddress.city = city;
  personnel.value.businessAddress.state = state;
  personnel.value.businessAddress.zip = zip;
}

async function create() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  try {
    const response = await createPersonnel(
      PersonnelToCreateRequest(personnel.value)
    );
    router.push(getRouterLink("Personnel", response.id));
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
</script>
