<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-row class="ma-0">
        <template v-if="isGroupTwoPlus">
          <v-col cols="12">
            <textarea-field
              v-model="details"
              label="Notes"
              auto-grow
              data-testid="details"
              :prepend-inner-icon="mdiText"
              :success="Boolean(details)"
              @update:model-value="updateDetails"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.details.controller.value"
                />
              </template>
            </textarea-field>
          </v-col>

          <v-col cols="12">
            <select-field
              v-model="addressee"
              dense
              data-testid="addressee"
              label="Default Addressee"
              :disabled="!isGroupThreePlus"
              :success="Boolean(addressee)"
              :prepend-inner-icon="mdiAccount"
              :items="DEFAULT_ADDRESSEE_ITEMS"
              @update:model-value="updateAddressee"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.addressee.controller.value"
                />
              </template>
            </select-field>
          </v-col>
        </template>

        <v-col v-if="isGroupThreePlus" cols="12">
          <h3 class="mb-3">
            <v-row class="ma-0" align="center">
              Quoting States
              <app-button
                :icon="editableQuotingStates ? mdiLock : mdiPencil"
                color="accent"
                variant="text"
                density="comfortable"
                data-testid="quoting-states-edit-toggle"
                @click="editableQuotingStates = !editableQuotingStates"
              />
              <active-save-indicator
                :controller="savingBuffer.quotingStates.controller.value"
              />
            </v-row>
          </h3>
          <v-chip-group
            v-model="quotingStates"
            column
            multiple
            selected-class="bg-primary"
            @update:model-value="updateQuotingStates"
          >
            <v-chip
              v-for="state in STATES"
              :key="state.value"
              :data-testid="`quoting-state-${state.value}`"
              :class="{
                primary: inQuotingStates(state.value)
              }"
              :value="state.value"
              :text="state.title"
              :disabled="!editableQuotingStates"
            />
          </v-chip-group>
        </v-col>
        <v-col v-else data-testid="quoting-states-text" cols="12">
          Approved for quoting in {{ quotingStates.join(", ") }}
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <checkbox-field
              v-model="supportsInstantAps"
              data-testid="supports-instant-aps"
              :disabled="!isGroupThreePlus"
              @update:model-value="updateSupportsInstantAps"
            >
              <template #label>
                Supports Human API
                <active-save-indicator
                  :controller="savingBuffer.supportsInstantAps.controller.value"
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <checkbox-field
              v-model="autoSubmitContracting"
              data-testid="auto-submit-contracting"
              :disabled="!isGroupThreePlus"
              @update:model-value="updateAutoSubmitContracting"
            >
              <template #label>
                Auto Submit Contracting
                <active-save-indicator
                  :controller="
                    savingBuffer.autoSubmitContracting.controller.value
                  "
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <checkbox-field
              v-model="holdForAppointment"
              data-testid="hold-for-appointment"
              :disabled="!isGroupThreePlus"
              @update:model-value="updateHoldForAppointment"
            >
              <template #label>
                Hold for Appointment
                <active-save-indicator
                  :controller="savingBuffer.holdForAppointment.controller.value"
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>
        <v-col v-if="isGroupThreePlus" cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <checkbox-field
              v-model="emailUnderwriter"
              data-testid="email-underwriter"
              @update:model-value="updateEmailUnderwriter"
            >
              <template #label>
                Email Underwriter
                <active-save-indicator
                  :controller="savingBuffer.emailUnderwriter.controller.value"
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>
        <v-col v-if="isGroupThreePlus" cols="12" lg="3" md="4">
          <div class="checkbox-width">
            <checkbox-field
              v-model="emailEo"
              data-testid="email-eo"
              @update:model-value="updateEmailEo"
            >
              <template #label>
                Email E&O
                <active-save-indicator
                  :controller="savingBuffer.emailEo.controller.value"
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>
        <v-col v-if="isGroupTwoPlus" cols="12">
          <textarea-field
            v-model="strifeDisclosure"
            label="Quote & Apply Disclosure"
            data-testid="strife-disclosure"
            :disabled="!isGroupThreePlus"
            :prepend-inner-icon="mdiSubtitles"
            :success="Boolean(strifeDisclosure)"
            @update:model-value="updateStrifeDisclosure"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.strifeDisclosure.controller.value"
              />
            </template>
          </textarea-field>
        </v-col>
        <v-col v-if="isGroupTwoPlus" cols="12" md="6">
          <textarea-field
            v-model="eftCommissionSchedule"
            data-testid="eft-commission-schedule"
            :prepend-inner-icon="mdiText"
            :disabled="!isGroupThreePlus"
            :success="Boolean(eftCommissionSchedule)"
            :label="`${name}'s EFT Commission Schedule`"
            auto-grow
            @update:model-value="updateEftCommissionSchedule"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="
                  savingBuffer.eftCommissionSchedule.controller.value
                "
              />
            </template>
          </textarea-field>
        </v-col>
        <v-col v-if="isGroupTwoPlus" cols="12" md="6">
          <textarea-field
            v-model="checkCommissionSchedule"
            data-testid="check-commission-schedule"
            :disabled="!isGroupThreePlus"
            :success="Boolean(checkCommissionSchedule)"
            :prepend-inner-icon="mdiText"
            :label="`${name}'s Check Commission Schedule`"
            auto-grow
            @update:model-value="updateCheckCommissionSchedule"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="
                  savingBuffer.checkCommissionSchedule.controller.value
                "
              />
            </template>
          </textarea-field>
        </v-col>
        <v-col v-if="isGroupThreePlus" cols="12">
          <agent-search
            v-model="defaultAppointmentManager"
            label="Default Appointment Manager"
            data-testid="default-appointment-manager"
            :success="Boolean(defaultAppointmentManager?.id)"
            @update:model-value="updateDefaultAppointmentManager"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="
                  savingBuffer.defaultAppointmentManager.controller.value
                "
              />
            </template>
          </agent-search>
        </v-col>
        <template v-if="isGroupFour">
          <v-col cols="12">
            <h3>Parameds Integration Settings</h3>
          </v-col>
          <v-col cols="12" md="6">
            <text-field
              v-model="paramedsCode"
              data-lpignore="true"
              label="Code"
              clearable
              maxlength="10"
              data-testid="parameds-code"
              :prepend-inner-icon="mdiPound"
              :success="Boolean(paramedsCode)"
              @update:model-value="updateParamedsCode"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.paramedsCode.controller.value"
                />
              </template>
            </text-field>
          </v-col>
          <v-col cols="12" md="6">
            <text-field
              v-model="paramedsAccountNumber"
              data-lpignore="true"
              label="Account Number"
              clearable
              maxlength="10"
              data-testid="parameds-account-number"
              :prepend-inner-icon="mdiPound"
              :success="Boolean(paramedsAccountNumber)"
              @update:model-value="updateParamedsAccountNumber"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="
                    savingBuffer.paramedsAccountNumber.controller.value
                  "
                />
              </template>
            </text-field>
          </v-col>
          <v-col cols="12">
            <h3>NAIC</h3>
          </v-col>
          <v-col cols="12" md="6">
            <text-field
              v-model="naic"
              data-lpignore="true"
              label="NAIC Code"
              clearable
              maxlength="10"
              data-testid="naic"
              :prepend-inner-icon="mdiPound"
              :success="Boolean(naic)"
              @update:model-value="updateNaic"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.naic.controller.value"
                />
              </template>
            </text-field>
          </v-col>
          <v-col cols="12" md="6">
            <text-field
              v-model="amBestRating"
              data-lpignore="true"
              label="AM Best Rating"
              clearable
              maxlength="10"
              data-testid="am-best-rating"
              :prepend-inner-icon="mdiMedal"
              :success="Boolean(amBestRating)"
              @update:model-value="updateAmBestRating"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.amBestRating.controller.value"
                />
              </template>
            </text-field>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup>
import AgentSearch from "@/components/shared/AgentSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { STATES } from "@/data/states";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useActiveSave } from "@/composables/active-save.composable";
import { ref } from "vue";

import {
  mdiMedal,
  mdiText,
  mdiAccount,
  mdiSubtitles,
  mdiPound,
  mdiPencil,
  mdiLock
} from "@mdi/js";
import { useCarrierView } from "@/stores/carrier-view";

const DEFAULT_ADDRESSEE_ITEMS = [
  { title: "Agent", value: "Agent" },
  { title: "General Agent", value: "General Agent" }
];

const carrierView = useCarrierView();

const { isGroupTwoPlus, isGroupThreePlus, isGroupFour } = storeToRefs(
  useUserStore()
);

const {
  addressee,
  autoSubmitContracting,
  checkCommissionSchedule,
  defaultAppointmentManager,
  details,
  eftCommissionSchedule,
  emailEo,
  emailUnderwriter,
  holdForAppointment,
  name,
  paramedsAccountNumber,
  paramedsCode,
  quotingStates,
  strifeDisclosure,
  supportsInstantAps,
  naic,
  amBestRating
} = storeToRefs(carrierView);

const savingBuffer = {
  addressee: useActiveSave(),
  amBestRating: useActiveSave(),
  autoSubmitContracting: useActiveSave(),
  checkCommissionSchedule: useActiveSave(),
  defaultAppointmentManager: useActiveSave(),
  details: useActiveSave(),
  eftCommissionSchedule: useActiveSave(),
  emailEo: useActiveSave(),
  emailUnderwriter: useActiveSave(),
  holdForAppointment: useActiveSave(),
  naic: useActiveSave(),
  paramedsAccountNumber: useActiveSave(),
  paramedsCode: useActiveSave(),
  quotingStates: useActiveSave(),
  strifeDisclosure: useActiveSave(),
  supportsInstantAps: useActiveSave()
};

const editableQuotingStates = ref(false);

function updateDetails() {
  savingBuffer.details.debounceUpdate(carrierView.updateDetails);
}
function updateAddressee() {
  savingBuffer.addressee.debounceUpdate(carrierView.updateAddressee);
}
function updateQuotingStates() {
  savingBuffer.quotingStates.debounceUpdate(carrierView.updateQuotingStates);
}
function updateSupportsInstantAps() {
  savingBuffer.supportsInstantAps.debounceUpdate(
    carrierView.updateSupportsInstantAps
  );
}
function updateAutoSubmitContracting() {
  savingBuffer.autoSubmitContracting.debounceUpdate(
    carrierView.updateAutoSubmitContracting
  );
}

function updateHoldForAppointment() {
  savingBuffer.holdForAppointment.debounceUpdate(
    carrierView.updateHoldForAppointment
  );
}

function updateEmailUnderwriter() {
  savingBuffer.emailUnderwriter.debounceUpdate(
    carrierView.updateEmailUnderwriter
  );
}

function updateEmailEo() {
  savingBuffer.emailEo.debounceUpdate(carrierView.updateEmailEo);
}
function updateStrifeDisclosure() {
  savingBuffer.strifeDisclosure.debounceUpdate(
    carrierView.updateStrifeDisclosure
  );
}

function updateEftCommissionSchedule() {
  savingBuffer.eftCommissionSchedule.debounceUpdate(
    carrierView.updateEftCommissionSchedule
  );
}

function updateCheckCommissionSchedule() {
  savingBuffer.checkCommissionSchedule.debounceUpdate(
    carrierView.updateCheckCommissionSchedule
  );
}

function updateDefaultAppointmentManager() {
  savingBuffer.defaultAppointmentManager.debounceUpdate(
    carrierView.updateDefaultAppointmentManager
  );
}

function updateParamedsCode() {
  savingBuffer.paramedsCode.debounceUpdate(carrierView.updateParamedsCode);
}

function updateParamedsAccountNumber() {
  savingBuffer.paramedsAccountNumber.debounceUpdate(
    carrierView.updateParamedsAccountNumber
  );
}

function updateNaic() {
  savingBuffer.naic.debounceUpdate(carrierView.updateNaic);
}

function updateAmBestRating() {
  savingBuffer.amBestRating.debounceUpdate(carrierView.updateAmBestRating);
}

function inQuotingStates(state) {
  if (!quotingStates.value) return false;
  return quotingStates.value.includes(state);
}
</script>
