<template>
  <high-dpi-canvas
    v-if="window.height"
    :id="`field-editor-canvas-${formId}`"
    :height="window.height"
    :width="window.width"
    :scale="scale"
    @mousedown="handleMouseDown"
    @mousemove="handleMouseMove"
    @mouseup="handleMouseUp"
    @mouseout="handleMouseOut"
    @redraw="drawFields"
    @ready="instantiate"
  />
</template>

<script setup>
import HighDpiCanvas from "@/components/shared/HighDpiCanvas.vue";

import {
  CHECKBOX_OPTION_TYPE,
  CHECKBOX_RADII,
  CHECKBOX_SIDE_LENGTH,
  RADIO_OPTION_RADIUS
} from "@/models/FormMapping";
import {
  clickedWithinBoundingBox,
  drawCheckbox,
  drawCircle,
  drawRectangle,
  parseErrorMessage
} from "@/util/helpers";
const SOFT_OFFSET = 4;
import { computed, onMounted, ref, watch } from "vue";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";

import { useSnackbarStore } from "@/stores/snackbar";
import { useFormMappingView } from "@/stores/form-mapping-view";
const props = defineProps({
  formId: { type: [Number, String], required: true },
  allVisible: Boolean,
  currentPage: { type: [Number, String], required: true },
  rectangleIsVisible: Boolean
});

const emit = defineEmits(["click"]);

const snackbar = useSnackbarStore();
const formMappingStore = useFormMappingView();
const formStore = useMappedFormStore(props.formId);

const {
  status,
  currentCoordinateIndex,
  activeField,
  currentFieldHasChanges,
  currentFieldIsValid,
  viewport,
  window,
  scale
} = storeToRefs(formStore);

const canEdit = computed(() => formMappingStore.editingFormId === props.formId);

const isDragging = ref(false);
const isDown = ref(false);
const startX = ref(null);
const startY = ref(null);
const moveOffsetX = ref(null);
const moveOffsetY = ref(null);
const alignment = ref({
  n: false,
  s: false,
  e: false,
  w: false,
  center: false
});

const currentFieldOnPage = computed(() => {
  if (!rectangles.value.length) return;
  return activeField.value.coordinates.some(
    ({ page }) => page === props.currentPage
  );
});

const virtualRectangles = computed(() =>
  rectangles.value.filter(({ virtual }) => virtual)
);

const rectangleCount = computed(() => rectangles.value.length);

const rectangles = computed(() => {
  if (!activeField.value?.coordinates) return [];
  return activeField.value.coordinates.map(
    ({ y, isFieldOption, height, ...optionValues }) => {
      let rectY = viewport.value.height - y;
      if (!isFieldOption) rectY -= height;
      return {
        isFieldOption,
        y: rectY,
        height,
        ...optionValues
      };
    }
  );
});

const cursor = computed(() => {
  let resize = "";
  if (alignment.value.n) resize += "n";
  if (alignment.value.s) resize += "s";
  if (alignment.value.e) resize += "e";
  if (alignment.value.w) resize += "w";
  if (resize) return `${resize}-resize`;
  else if (alignment.value.center && isDown.value) return "none";
  else if (alignment.value.center) return "grab";
  return "default";
});

const isMoving = computed(() => {
  return (
    !alignment.value.n &&
    !alignment.value.s &&
    !alignment.value.e &&
    !alignment.value.w &&
    isDown.value &&
    alignment.value.center
  );
});

const rectangle = computed({
  get() {
    return rectangles.value[currentCoordinateIndex.value];
  },
  set({ x, y, height, width }) {
    if (currentCoordinateIndex.value === null) return;

    let rectX = x,
      rectY = y,
      rectWidth = width,
      rectHeight = height;
    if (width < 0) {
      rectWidth = Math.abs(width);
      rectX = Math.abs(width + x);
    }
    if (height < 0) {
      rectHeight = Math.abs(height);
      rectY = Math.abs(height + y);
    }

    activeField.value.coordinates[currentCoordinateIndex.value].x = rectX;
    rectY = viewport.value.height - rectY;
    if (!rectangle.value.isFieldOption) {
      rectY -= rectHeight;
      activeField.value.coordinates[currentCoordinateIndex.value].height =
        rectHeight;
      activeField.value.coordinates[currentCoordinateIndex.value].width =
        rectWidth;
    }
    activeField.value.coordinates[currentCoordinateIndex.value].y = rectY;

    currentFieldHasChanges.value = true;
  }
});

function instantiate() {
  drawFields();
  setAlignment();
}

let savingTimer;
function debounceAndSave() {
  if (savingTimer) clearTimeout(savingTimer);
  savingTimer = setTimeout(() => {
    if (!currentFieldIsValid.value) return;
    if (!currentFieldHasChanges.value) return;
    statusWrapper(updateFieldPosition);
  }, 300);
}
function orderFieldOptions() {
  const options = [...activeField.value.coordinates];
  options.sort((a, b) => {
    let similarY = Math.abs(a.y - b.y) < 10;
    if (similarY) return a.x - b.x > 0 ? 1 : -1;
    return a.y - b.y < 0 ? 1 : -1;
  });

  options.forEach((option, index) => {
    const cIndex = activeField.value.coordinates.findIndex(
      ({ uuid }) => option.uuid === uuid
    );
    activeField.value.coordinates[cIndex].order = index + 1;
  });
}

async function statusWrapper(func) {
  if (status.value !== "Complete") return func();

  try {
    await formStore.updateFormStatus("Incomplete");
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    return;
  }

  await func();

  try {
    await formStore.updateFormStatus("Complete");
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function updateFieldPosition() {
  try {
    await formStore.updateApplicationQuestionLink(activeField.value);
    snackbar.showSuccessSnackbar({
      message: "Saved field positions"
    });
    currentFieldHasChanges.value = false;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

watch(
  () => props.rectangleIsVisible,
  () => {
    if (props.rectangleIsVisible) drawFields();
    else clearScreen();
  }
);

function getCurrentCanvas() {
  return document.getElementById(`field-editor-canvas-${props.formId}`);
}
function drawFields() {
  if (!rectangles.value?.length) return;
  if (!currentFieldOnPage.value) return;
  const canvas = getCurrentCanvas();
  if (!canvas) return;
  const ctx = canvas.getContext("2d");

  rectangles.value.forEach(
    ({ height, width, x, y, isFieldOption, virtual, text, value }, index) => {
      const isActive = index === currentCoordinateIndex.value;
      const strokeColor = isActive ? "#1487e2" : "#0a4e84";

      const textOrValue = text || value;

      if (activeField.value.pdfFieldType === CHECKBOX_OPTION_TYPE) {
        if (virtual) return;
        let checkboxText = null;
        if (props.showAllRadioValues || isActive) {
          checkboxText = textOrValue;
        }

        drawCheckbox(ctx, {
          x,
          y,
          strokeColor,
          sideLength: CHECKBOX_SIDE_LENGTH,
          isDashed: isActive,
          radii: CHECKBOX_RADII,
          text: checkboxText
        });
      } else if (isFieldOption) {
        if (virtual) return;

        let radioText = null;
        if (props.showAllRadioValues || isActive) {
          radioText = textOrValue;
        }

        drawCircle(ctx, {
          x,
          y,
          strokeColor,
          isDashed: isActive,
          radius: RADIO_OPTION_RADIUS,
          text: radioText
        });
      } else {
        let fillColor;
        if (activeField.value.obscureBackground) fillColor = "#ffffff";
        drawRectangle(ctx, {
          height,
          width,
          x,
          y,
          comb: activeField.value.comb,
          fillColor,
          strokeColor,
          isDashed: isActive
        });
      }
    }
  );
}
function handleMouseUp(e) {
  e.preventDefault();
  e.stopPropagation();
  isDown.value = false;
  if (!isDragging.value) return;
  isDragging.value = false;
  if (!rectangle.value) return;
  orderFieldOptions();
  debounceAndSave();
}

function handleMouseOut(e) {
  e.preventDefault();
  e.stopPropagation();

  // the drag is over, clear the dragging flag
  isDown.value = false;
  isDragging.value = false;
}

function handleMouseDown(e) {
  e.preventDefault();
  e.stopPropagation();

  const { x: currentX, y: currentY } = getRelativeMousePosition(e);
  const index = rectangles.value.findIndex(rect =>
    clickedWithinBoundingBox({
      mouseX: currentX,
      mouseY: currentY,
      rectX: rect.x,
      rectY: rect.y,
      rectWidth: Math.abs(rect.width),
      rectHeight: Math.abs(rect.height),
      isCircle: rect.isFieldOption,
      radius: RADIO_OPTION_RADIUS
    })
  );
  if (index > -1 && canEdit.value) {
    currentCoordinateIndex.value = index;
    startX.value = rectangle.value.x;
    startY.value = rectangle.value.y;
    isDown.value = true;
  } else {
    clearAlignment();

    if (props.allVisible) emit("click", e);
    isDown.value = false;
  }

  clearScreen();
  drawFields();
  if (isMoving.value) {
    moveOffsetX.value = startX.value - currentX;
    moveOffsetY.value = startY.value - currentY;
  } else {
    if (alignment.value.n) setNAlignedStart();
    else if (alignment.value.s) setSAlignedStart();
    if (alignment.value.e) setEAlignedStart();
    else if (alignment.value.w) setWAlignedStart();
  }
}

function setNAlignedStart() {
  startY.value = rectangle.value.y;
  if (rectangle.value.height > 0) startY.value += rectangle.value.height;
}

function setSAlignedStart() {
  startY.value = rectangle.value.y;
  if (rectangle.value.height < 0) startY.value += rectangle.value.height;
}

function setEAlignedStart() {
  startX.value = rectangle.value.x;
  if (rectangle.value.width < 0) startX.value += rectangle.value.width;
}

function setWAlignedStart() {
  startX.value = rectangle.value.x;
  if (rectangle.value.width >= 0) startX.value += rectangle.value.width;
}

function getRelativeMousePosition(e) {
  const yRatio = viewport.value.height / window.value.height;
  const xRatio = viewport.value.width / window.value.width;
  const y = yRatio * e.offsetY;
  const x = xRatio * e.offsetX;

  return { x, y };
}

function handleMouseMove(e) {
  e.preventDefault();
  e.stopPropagation();
  if (!canEdit.value) return;
  const { x: newStartX, y: newStartY } = getRelativeMousePosition(e);
  if (!isDown.value) {
    if (rectangle.value) setAlignment(newStartX, newStartY);
    return;
  }
  isDragging.value = true;

  let width = newStartX - startX.value;
  let height = newStartY - startY.value;

  if (isMoving.value) {
    startX.value = newStartX + moveOffsetX.value;
    startY.value = newStartY + moveOffsetY.value;
    width = rectangle.value.width;
    height = rectangle.value.height;
  } else {
    const nsAligned = alignment.value.n || alignment.value.s;
    const ewAligned = alignment.value.e || alignment.value.w;
    if (nsAligned && !ewAligned) width = rectangle.value.width;
    if (ewAligned && !nsAligned) height = rectangle.value.height;
  }

  clearScreen();
  rectangle.value = {
    x: Math.trunc(startX.value),
    y: Math.trunc(startY.value),
    width: Math.trunc(width),
    height: Math.trunc(height)
  };
  drawFields();
}

function setAlignment(x, y) {
  if (!rectangles.value?.length) return;
  if (!rectangle.value?.y) return;
  let n = rectangle.value.y,
    s = rectangle.value.y + rectangle.value.height,
    e = rectangle.value.x + rectangle.value.width,
    w = rectangle.value.x;

  if (rectangle.value.height < 0) {
    n = rectangle.value.y + rectangle.value.height;
    s = rectangle.value.y;
  }

  if (rectangle.value.width < 0) {
    e = rectangle.value.x;
    w = rectangle.value.x + rectangle.value.width;
  }

  const inRange = (start, direction) =>
    start >= direction - SOFT_OFFSET && start <= direction + SOFT_OFFSET;

  const inBetween = (start, top, bottom) =>
    (start <= bottom || inRange(start, bottom)) &&
    (start >= top || inRange(start, top));

  alignment.value.n =
    inRange(y, n) && inBetween(x, w, e) && !rectangle.value.isFieldOption;
  alignment.value.s =
    inRange(y, s) && inBetween(x, w, e) && !rectangle.value.isFieldOption;
  alignment.value.e =
    inRange(x, e) && inBetween(y, n, s) && !rectangle.value.isFieldOption;
  alignment.value.w =
    inRange(x, w) && inBetween(y, n, s) && !rectangle.value.isFieldOption;

  alignment.value.center = clickedWithinBoundingBox({
    mouseX: x,
    mouseY: y,
    rectX: rectangle.value.x,
    rectY: rectangle.value.y,
    rectWidth: rectangle.value.width,
    rectHeight: rectangle.value.height,
    radius: RADIO_OPTION_RADIUS,
    isCircle: rectangle.value.isFieldOption
  });
}

function clearAlignment() {
  alignment.value.n = false;
  alignment.value.s = false;
  alignment.value.e = false;
  alignment.value.w = false;
  alignment.value.center = false;
}

function clearScreen() {
  const canvas = getCurrentCanvas();
  if (!canvas) return;
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
}

watch(
  [
    () => props.currentPage,
    () => props.currentCoordinateIndex,
    () => activeField.value.comb,
    () => activeField.value.obscureBackground,
    () => props.showAllRadioValues
  ],
  () => {
    clearScreen();
    drawFields();
  }
);

watch([virtualRectangles, rectangleCount], () => {
  orderFieldOptions();
  clearScreen();
  drawFields();
});

watch(cursor, () => {
  const canvas = getCurrentCanvas();
  canvas.style.cursor = cursor.value;
});

onMounted(() => {
  if (rectangles.value.length) orderFieldOptions();
});
</script>
