<template>
  <v-row dense>
    <v-col v-if="props.quoteSave" cols="12">
      <autocomplete-field
        v-model="quoteData.status"
        data-testid="input-status"
        :prepend-inner-icon="mdiListStatus"
        label="Status"
        :disabled="props.disabled"
        :items="STATUSES"
        :success="statusValidation.success"
        :error-messages="statusValidation.errorMessages"
        @update:model-value="savingBuffer.status.update(saveWrapper('status'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.status.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" md="6">
      <autocomplete-field
        v-model="productType"
        :prepend-inner-icon="mdiCube"
        data-testid="input-product-type"
        label="Disability Type"
        item-value="text"
        :disabled="props.disabled"
        :items="disabilityTypeItems"
        :success="productTypeValidation.success"
        :error-messages="productTypeValidation.errorMessages"
        @update:model-value="savingBuffer.productType.update(saveWrapper('productType'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.productType.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" md="6">
      <autocomplete-field
        v-model="quoteData.state"
        data-testid="input-state"
        :prepend-inner-icon="mdiSignRealEstate"
        label="State"
        :disabled="props.disabled"
        :items="STATES"
        :success="stateValidation.success"
        :error-messages="stateValidation.errorMessages"
        @update:model-value="savingBuffer.state.update(saveWrapper('state'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.state.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" lg="6">
      <autocomplete-field
        v-model="quoteData.smoker"
        data-testid="input-smoker"
        :prepend-inner-icon="mdiSmoking"
        label="Tobacco Use"
        :disabled="props.disabled"
        :items="tobaccoUseItems"
        :success="smokerValidation.success"
        :error-messages="smokerValidation.errorMessages"
        @update:model-value="savingBuffer.smoker.update(saveWrapper('smoker'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.smoker.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" lg="6">
      <autocomplete-field
        v-model="quoteData.mode"
        data-testid="input-mode"
        :prepend-inner-icon="mdiCalendar"
        label="Mode"
        :disabled="props.disabled"
        :items="MODES"
        :success="modeValidation.success"
        :error-messages="modeValidation.errorMessages"
        @update:model-value="savingBuffer.mode.update(saveWrapper('mode'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.mode.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" md="6">
      <autocomplete-field
        v-model="quoteData.monthlyBenefitSolve"
        data-testid="input-monthly-benefit-solve"
        :prepend-inner-icon="mdiCalendar"
        label="Monthly Benefit Solve"
        item-value="text"
        :disabled="props.disabled"
        :items="monthlyBenefitSolveItems"
        :success="monthlyBenefitSolveValidation.success"
        :error-messages="monthlyBenefitSolveValidation.errorMessages"
        @update:model-value="
          savingBuffer.monthlyBenefitSolve.update(
            saveWrapper('monthlyBenefitSolve')
          )
        "
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.monthlyBenefitSolve.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col v-if="displayMonthlyBenefit" cols="12" md="6">
      <currency-input
        v-model="quoteData.monthlyBenefit"
        :prepend-inner-icon="mdiCurrencyUsd"
        label="Monthly Benefit"
        data-testid="input-monthly-benefit"
        include-decimals
        :disabled="props.disabled"
        :success="monthlyBenefitValidation.success"
        :error-messages="monthlyBenefitValidation.errorMessages"
        @update:model-value="
          savingBuffer.monthlyBenefit.debounceUpdate(
            saveWrapper('monthlyBenefit')
          )
        "
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.monthlyBenefit.controller.value"
          />
        </template>
      </currency-input>
    </v-col>
    <v-col cols="12">
      <textarea-field
        v-model="quoteData.riders"
        data-testid="input-riders"
        :prepend-inner-icon="mdiCog"
        label="Riders (optional)"
        counter
        maxlength="255"
        :disabled="props.disabled"
        :success="Boolean(riders)"
        @update:model-value="savingBuffer.riders.debounceUpdate(saveWrapper('riders'))"
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.riders.controller.value"
          />
        </template>
      </textarea-field>
    </v-col>
    <v-col cols="12" lg="6">
      <autocomplete-field
        v-model="quoteData.benefitPeriod"
        data-testid="input-benefit-period"
        :prepend-inner-icon="mdiCalendar"
        label="Benefit Period"
        :disabled="props.disabled"
        :items="DISABILITY_BENEFIT_PERIODS"
        :success="benefitPeriodValidation.success"
        :error-messages="benefitPeriodValidation.errorMessages"
        @update:model-value="
          savingBuffer.benefitPeriod.update(saveWrapper('benefitPeriod'))
        "
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.benefitPeriod.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" lg="6">
      <autocomplete-field
        v-model="quoteData.eliminationPeriod"
        data-testid="input-elimination-period"
        :prepend-inner-icon="mdiCalendar"
        label="Elimination Period"
        :disabled="props.disabled"
        :items="ELIMINATION_PERIODS"
        :success="eliminationPeriodValidation.success"
        :error-messages="eliminationPeriodValidation.errorMessages"
        @update:model-value="
          savingBuffer.eliminationPeriod.update(
            saveWrapper('eliminationPeriod')
          )
        "
      >
        <template v-if="props.quoteSave" #append-inner>
          <active-save-indicator
            :controller="savingBuffer.eliminationPeriod.controller.value"
          />
        </template>
      </autocomplete-field>
    </v-col>
    <v-col cols="12" md="6">
      <div class="checkbox-width">
        <checkbox-field
          v-model="quoteData.businessOwner"
          data-testid="input-business-owner"
          dense
          class="mt-1"
          :disabled="props.disabled"
          :success="Boolean(quoteData.businessOwner)"
          @update:model-value="
            savingBuffer.businessOwner.update(saveWrapper('businessOwner'))
          "
        >
          <template #label>
            Insured is Business Owner
            <active-save-indicator
              v-if="props.quoteSave"
              :controller="savingBuffer.businessOwner.controller.value"
            />
          </template>
        </checkbox-field>
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="checkbox-width">
        <checkbox-field
          v-model="quoteData.existingCoverage"
          data-testid="input-existing-coverage"
          dense
          class="mt-1"
          :disabled="props.disabled"
          :success="Boolean(existingCoverage)"
          @update:model-value="
            savingBuffer.existingCoverage.update(
              saveWrapper('existingCoverage')
            )
          "
        >
          <template #label>
            Insured Has Existing Coverage
            <active-save-indicator
              v-if="props.quoteSave"
              :controller="savingBuffer.existingCoverage.controller.value"
            />
          </template>
        </checkbox-field>
      </div>
    </v-col>
    <template v-if="existingCoverage">
      <v-col cols="12">
        <h3 style="text-align: left">Existing Coverage Details</h3>
      </v-col>
      <v-col cols="12" md="6">
        <div class="checkbox-width">
          <checkbox-field
            v-model="quoteData.existingEmployerPaid"
            data-testid="input-existing-employer-paid"
            dense
            class="mt-1"
            :disabled="props.disabled"
            :success="Boolean(existingEmployerPaid)"
            @update:model-value="
              savingBuffer.existingEmployerPaid.update(
                saveWrapper('existingEmployerPaid')
              )
            "
          >
            <template #label>
              Existing Coverage is Employer Paid
              <active-save-indicator
                v-if="props.quoteSave"
                :controller="savingBuffer.existingEmployerPaid.controller.value"
              />
            </template>
          </checkbox-field>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <autocomplete-field
          v-model="quoteData.existingEliminationPeriod"
          data-testid="input-existing-elimination-period"
          :prepend-inner-icon="mdiCalendar"
          label="Elimination Period"
          :disabled="props.disabled"
          :items="ELIMINATION_PERIODS"
          :success="Boolean(existingEliminationPeriod)"
          @update:model-value="
            savingBuffer.existingEliminationPeriod.update(
              saveWrapper('existingEliminationPeriod')
            )
          "
        >
          <template #append-inner>
            <active-save-indicator
              :controller="
                savingBuffer.existingEliminationPeriod.controller.value
              "
            />
          </template>
        </autocomplete-field>
      </v-col>
      <v-col cols="12" md="6">
        <autocomplete-field
          v-model="quoteData.existingBenefitPeriod"
          data-testid="input-existing-benefit-period"
          :prepend-inner-icon="mdiCalendar"
          label="Benefit Period"
          :disabled="props.disabled"
          :items="DISABILITY_BENEFIT_PERIODS"
          :success="Boolean(existingBenefitPeriod)"
          @update:model-value="
            savingBuffer.existingBenefitPeriod.update(
              saveWrapper('existingBenefitPeriod')
            )
          "
        >
          <template v-if="props.quoteSave" #append-inner>
            <active-save-indicator
              :controller="savingBuffer.existingBenefitPeriod.controller.value"
            />
          </template>
        </autocomplete-field>
      </v-col>
      <v-col cols="12" md="6">
        <currency-input
          v-model="quoteData.existingBenefitMaximum"
          data-testid="input-existing-benefit-maximum"
          :prepend-inner-icon="mdiCurrencyUsd"
          label="Benefit Maximum"
          include-decimals
          :disabled="props.disabled"
          :success="Boolean(existingBenefitMaximum)"
          @update:model-value="
            savingBuffer.existingBenefitMaximum.debounceUpdate(
              saveWrapper('existingBenefitMaximum')
            )
          "
        >
          <template v-if="props.quoteSave" #append-inner>
            <active-save-indicator
              :controller="savingBuffer.existingBenefitMaximum.controller.value"
            />
          </template>
        </currency-input>
      </v-col>
      <v-col cols="12" md="6">
        <integer-input
          v-model="quoteData.existingPercentageMaximum"
          data-testid="input-existing-percentage-maximum"
          data-lpignore="true"
          :prepend-inner-icon="mdiPercent"
          label="Percentage Maximum"
          :max="100"
          type="text"
          inputmode="numeric"
          :disabled="props.disabled"
          :success="Boolean(existingPercentageMaximum)"
          @update:model-value="
            savingBuffer.existingPercentageMaximum.debounceUpdate(
              saveWrapper('existingPercentageMaximum')
            )
          "
        >
          <template v-if="props.quoteSave" #append-inner>
            <active-save-indicator
              :controller="
                savingBuffer.existingPercentageMaximum.controller.value
              "
            />
          </template>
        </integer-input>
      </v-col>
    </template>
  </v-row>
</template>

<script setup>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { STATES } from "@/data/states";
import { ELIMINATION_PERIODS } from "@/data/case-data";
import {
  monthlyBenefitSolveItems,
  DISABILITY_BENEFIT_PERIODS,
  tobaccoUseItems,
  disabilityTypeItems
} from "@/data/quote-request-items";

import { STATUSES, MODES, QuoteRequestDisabilityInfo } from "@/models/Quote";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, ref,   watch } from "vue";
import { computedValidation } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";

import {
  mdiListStatus,
  mdiCube,
  mdiSignRealEstate,
  mdiSmoking,
  mdiCalendar,
  mdiCurrencyUsd,
  mdiCog,
  mdiPercent
} from "@mdi/js";

const props = defineProps({
  disabled: Boolean,
  riders: { type: String, default: "" },
  businessOwner: Boolean,
  existingCoverage: Boolean,
  existingBenefitMaximum: { type: Number, default: null },
  existingBenefitPeriod: { type: String, default: "" },
  existingEliminationPeriod: { type: String, default: "" },
  existingEmployerPaid: Boolean,
  existingPercentageMaximum: { type: [String, Number], default: null },
  monthlyBenefit: { type: Number, default: null },
  monthlyBenefitSolve: { type: String, default: "" },
  mode: { type: String, default: null },
  benefitPeriod: { type: String, default: null },
  eliminationPeriod: { type: String, default: null },
  smoker: { type: Boolean, default: null },
  productType: { type: Array, default: () => [], required: false },
  status: { type: String, default: "Pending" },
  state: {
    type: String,
    default: ""
  },
  quoteSave: {
    type: Function,
    required: false,
    default: null
  }
});

const quoteData = ref(QuoteRequestDisabilityInfo(props));

const savingBuffer = {};
Object.keys(QuoteRequestDisabilityInfo()).forEach(key => {
  savingBuffer[key] = useActiveSave();
});

const emit = defineEmits([
  ...Object.keys(QuoteRequestDisabilityInfo()).map(key => `update:${key}`),
  "save"
]);

const displayMonthlyBenefit = computed(
  () => quoteData.value.monthlyBenefitSolve === "Specify Amount"
);

const v$ = useVuelidate(
  {
    quoteData: {
      state: { required: v => !!v },
      monthlyBenefitSolve: { required: v => !!v },
      mode: { required: v => !!v },
      smoker: { required: v => [true, false].includes(v) },
      benefitPeriod: { required: v => !!v },
      eliminationPeriod: { required: v => !!v },
      productType: { required: v => Boolean(v.length) },
      monthlyBenefit: { required: v => !displayMonthlyBenefit.value || !!v },
      status: { required: v => !props.quoteSave || !!v }
    }
  },
  { quoteData },
  { $scope: "quote-request", $autoDirty: true }
);

const productType = computed({
  get() {
    if (quoteData.value.productType.length) {
      return quoteData.value.productType[0];
    }
    return null;
  },
  set(v) {
    quoteData.value.productType.splice(0, quoteData.value.productType.length);
    quoteData.value.productType.push(v);
  }
});

const productTypeValidation = computedValidation(
  v$.value.quoteData.productType,
  [{ key: "required", message: "Required" }]
);

const stateValidation = computedValidation(v$.value.quoteData.state, [
  { key: "required", message: "Required" }
]);

const statusValidation = computedValidation(v$.value.quoteData.status, [
  { key: "required", message: "Required" }
]);

const smokerValidation = computedValidation(v$.value.quoteData.smoker, [
  { key: "required", message: "Required" }
]);

const modeValidation = computedValidation(v$.value.quoteData.mode, [
  { key: "required", message: "Required" }
]);

const monthlyBenefitValidation = computedValidation(
  v$.value.quoteData.monthlyBenefit,
  [{ key: "required", message: "Required" }]
);

const monthlyBenefitSolveValidation = computedValidation(
  v$.value.quoteData.monthlyBenefitSolve,
  [{ key: "required", message: "Required" }]
);

const benefitPeriodValidation = computedValidation(
  v$.value.quoteData.benefitPeriod,
  [{ key: "required", message: "Required" }]
);

const eliminationPeriodValidation = computedValidation(
  v$.value.quoteData.eliminationPeriod,
  [{ key: "required", message: "Required" }]
);

function saveWrapper(key) {
  if (props.quoteSave) return () => props.quoteSave(key);
  return () => {};
}

Object.keys(QuoteRequestDisabilityInfo()).forEach(key => {
  watch(
    () => quoteData.value[key],
    v => {
      emit(`update:${key}`, v);
    },
    { deep: true }
  );
});
</script>
