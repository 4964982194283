<template>
  <v-card flat>
    <v-card-title> Create New E&O </v-card-title>
    <v-card-subtitle>
      E&O explicitly stating {{ requiredNames }} with a case limit of $1,000,000
      or more is required.
    </v-card-subtitle>
    <v-card-text id="eo-container">
      <v-row dense class="my-2">
        <v-col cols="12" md="6">
          <date-input
            v-model="eo.startDate"
            :prepend-inner-icon="mdiCalendarStart"
            autofocus
            label="Start Date"
            data-testid="eo-start-date"
            :disabled="disabled || creatingEo"
            :success="startDateValidation.success"
            :error-messages="startDateValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="eo.expirationDate"
            :prepend-inner-icon="mdiCalendarEnd"
            label="End Date"
            data-testid="eo-end-date"
            :disabled="disabled || creatingEo"
            :success="expirationDateValidation.success"
            :error-messages="expirationDateValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="eo.carrier"
            data-lpignore="true"
            :prepend-inner-icon="mdiDomain"
            label="Carrier"
            data-testid="eo-carrier"
            :disabled="disabled || creatingEo"
            :success="carrierValidation.success"
            :error-messages="carrierValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="eo.policyNumber"
            data-lpignore="true"
            :prepend-inner-icon="mdiPound"
            label="Policy Number"
            data-testid="eo-policy-number"
            :disabled="disabled || creatingEo"
            :success="policyNumberValidation.success"
            :error-messages="policyNumberValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            v-model="eo.caseLimit"
            label="Case Limit"
            :prepend-inner-icon="mdiCurrencyUsd"
            data-testid="eo-case-limit"
            :disabled="disabled || creatingEo"
            :decimal-length="0"
            :success="caseLimitValidation.success"
            :error-messages="caseLimitValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            v-model="eo.aggregateLimit"
            type="currency"
            label="Aggregate Limit"
            :prepend-inner-icon="mdiCurrencyUsd"
            data-testid="eo-aggregate-limit"
            :decimal-length="0"
            :disabled="disabled || creatingEo"
            :success="aggregateLimitValidation.success"
            :error-messages="aggregateLimitValidation.errorMessages"
          />
        </v-col>
        <v-col
          v-if="options.length > 1 || applyToAll"
          cols="12"
          :md="showApplyToAll ? 8 : 12"
        >
          <select-field
            v-model="policyHolders"
            multiple
            label="Policy Holder(s)"
            :prepend-inner-icon="mdiAccountMultiple"
            return-object
            deletable-chips
            data-testid="eo-policy-holders"
            :items="options"
            :disabled="disabled || creatingEo"
            :success="policyHolderValidation.success"
            :error-messages="policyHolderValidation.errorMessages"
          />
        </v-col>
        <v-col v-if="showApplyToAll" cols="12" md="4">
          <div class="checkbox-width">
            <checkbox-field
              v-model="applyToAll"
              label="Apply to All Agents"
              class="mt-1 ml-2"
              data-testid="eo-apply-to-all"
              :success="Boolean(applyToAll)"
              :disabled="disabled || creatingEo"
            />
          </div>
        </v-col>
        <v-alert v-if="applyToAll" type="warning" class="mb-3" data-testid="apply-to-all-warning">
          "Apply to All Agents" is a Group 2+ feature that allows you to apply
          this E&O to all agents that assign to {{ agencyAssignment.name }}. Use
          with caution as several carriers require the agent's name to be listed
          on the E&O.
        </v-alert>

        <v-col cols="12">
          <file-drag-and-drop
            v-model="eo.file"
            data-testid="eo-document"
            label="E&O Upload"
            :disabled="disabled || creatingEo"
            :success="fileValidation.success"
            :error-messages="fileValidation.errorMessages"
          />
        </v-col>
        <v-col v-if="agencyAssignment?.id" cols="12" class="my-3">
          <select-field
            v-model="isAgencyEo"
            label="Is this an Agency E&O?"
            data-testid="agency-eo-confirmation"
            :disabled="disabled"
            :items="yesNoOptions"
            :success="isAgencyEoValidation.success"
            :error-messages="isAgencyEoValidation.errorMessages"
          />
          <v-alert
            v-if="isAgencyEo"
            :type="agreed ? 'success' : 'warning'"
            variant="outlined"
          >
            <checkbox-field
              v-model="agreed"
              class="mt-0"
              data-testid="agency-eo-confirmation-checkbox"
              :disabled="disabled"
              :label="agreementText"
              :success="agreedValidation.success"
              :error-messages="agreedValidation.errorMessages"
              :hide-details="!agreedValidation.errorMessages.length"
            />
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none mr-1"
        :disabled="creatingEo"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="eo-create"
        :loading="creatingEo"
        @click="createEo"
      >
        Create E&O
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import yesNoOptions from "@/data/yes-no-options";
import differenceInDays from "date-fns/differenceInDays";
import differenceInMonths from "date-fns/differenceInMonths";
import isAfter from "date-fns/isAfter";
import parse from "date-fns/parse";
import {
  listToSentence,
  parseErrorMessage,
  validationComputeV2
} from "@/util/helpers";

import {
  ErrorsOmissions,
  setRawFromErrorsOmissions
} from "@/models/Eo";

import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import { computed, ref, watch, nextTick } from "vue";
import { createNewEo } from "@/api/eos.service";
import {
  mdiCalendarStart,
  mdiCalendarEnd,
  mdiDomain,
  mdiPound,
  mdiCurrencyUsd,
  mdiAccountMultiple
} from "@mdi/js";

const MIN_CASE_LIMIT = 1000000;
const props = defineProps({
  disabled: Boolean,
  policyHolderOptions: { type: Array, default: () => [] },
  agencyAssignment: { type: Object, default: () => {} }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const user = useUserStore();
const eo = ref(ErrorsOmissions());

const policyHolders = ref([]);

props.policyHolderOptions.forEach(v => {
  if (v.selectedByDefault) policyHolders.value.push(v);
});

const applyToAll = ref(false);
const creatingEo = ref(false);
const agreed = ref(false);
const isAgencyEo = ref(null);

const state = {
  policyHolders,
  isAgencyEo,
  agreed,
  eo
};

const rules = {
  policyHolders: {
    required
  },
  isAgencyEo: {
    required: v => !props.agencyAssignment?.id || [true, false].includes(v)
  },
  agreed: {
    required: (v, parent) => !parent.isAgencyEo.value || Boolean(v)
  },
  eo: {
    file: {
      required,
      validSize: val => val?.size > 0
    },
    startDate: {
      required,
      startsWithinAMonth,
      validMonthDifference,
      lessThanAYearOld
    },
    expirationDate: {
      required,
      validMonthDifference,
      isAfterToday
    },
    carrier: { required },
    policyNumber: { required },
    caseLimit: {
      required,
      correctValue: value => value >= MIN_CASE_LIMIT
    },
    aggregateLimit: {
      required,
      correctValue: (value, parent) =>
        value >= parent.caseLimit && value >= MIN_CASE_LIMIT
    }
  }
};

const v$ = useVuelidate(rules, state, { $autoDirty: true, $scope: false });

watch(applyToAll, v => {
  if (v) removeAgent();
});

const agreementText = computed(() => {
  if (policyHolders.value.length === 0) return "";
  const names = listToSentence(policyHolders.value.map(({ title }) => title));
  let nameText = `the names "${names}" are noted`;
  if (policyHolders.value.length === 1) {
    nameText = `"${names}" is noted`;
  }
  return `I agree that ${nameText} under the certificate holder section of the declaration page. I understand that if this E&O is not compliant I will need to provide the correct E&O before submitting business.`;
});
const options = computed(() => {
  if (!applyToAll.value) return props.policyHolderOptions;
  return props.policyHolderOptions.filter(({ type }) => type === "Agency");
});

const showApplyToAll = computed(() => {
  return user.isGroupTwoPlus && props.agencyAssignment?.id;
});

const requiredNames = computed(() => {
  if (policyHolders.value.length === 0) return " the policy holder's name";
  if (policyHolders.value.length === 1)
    return policyHolders.value[0].text + "'s name ";
  return `${policyHolders.value[0].text} and ${policyHolders.value[1].text} names`;
});

const isAgencyEoValidation = computed(() => {
  const model = v$.value.isAgencyEo;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const agreedValidation = computed(() => {
  const model = v$.value.agreed;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const aggregateLimitValidation = computed(() => {
  const model = v$.value.eo.aggregateLimit;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "correctValue",
      message:
        "Must be at greater than or equal to Case Limit and at least $1,000,000"
    }
  ]);
});

const caseLimitValidation = computed(() => {
  const model = v$.value.eo.caseLimit;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "correctValue",
      message: "Must be at least $1,000,000"
    }
  ]);
});

const policyNumberValidation = computed(() => {
  const model = v$.value.eo.policyNumber;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const carrierValidation = computed(() => {
  const model = v$.value.eo.carrier;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const expirationDateValidation = computed(() => {
  const model = v$.value.eo.expirationDate;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "validMonthDifference",
      message:
        "Difference between start and end dates must be at most 13 months"
    },
    { key: "isAfterToday", message: "Must expire after today" }
  ]);
});
const startDateValidation = computed(() => {
  const model = v$.value.eo.startDate;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "lessThanAYearOld",
      message: "Must be less than a year from today"
    },
    {
      key: "validMonthDifference",
      message:
        "Difference between start and end dates must be at most 13 months"
    }
  ]);
});

const fileValidation = computed(() => {
  const model = v$.value.eo.file;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "validSize",
      message: "Please confirm this file has data or try re-uploading the file"
    }
  ]);
});

const policyHolderValidation = computed(() => {
  const model = v$.value.policyHolders;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

function removeAgent() {
  const agencyPolicyHolders = policyHolders.value.filter(
    ({ type }) => type === "Agency"
  );

  policyHolders.value.splice(0, policyHolders.value.length);
  policyHolders.value.push(...agencyPolicyHolders);
}

function goToInvalid() {
  nextTick(() => {
    const els = document.getElementsByClassName("v-input error-field");
    if (!els?.length) return;

    document.querySelector("#eo-container").scrollTo(0, els[0].offsetTop);
  });
}

async function createEo() {
  if (props.readonly) return;
  v$.value.$touch();
  if (v$.value.$invalid) {
    snackbar.showErrorSnackbar({
      message: "Invalid fields detected",
      timeout: 10000
    });
    goToInvalid();
    return;
  }
  creatingEo.value = true;

  try {
    for (let i = 0; i < policyHolders.value.length; i++) {
      const body = setRawFromErrorsOmissions(
        eo.value,
        policyHolders.value[i],
        applyToAll.value
      );

      await createNewEo(body);
    }

    snackbar.showSuccessSnackbar({ message: "Created E&O" });
    dialog.closeDialog({ created: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creatingEo.value = false;
  }
}

function validMonthDifference(eo, parent) {
  const difference = differenceInMonths(
    parse(parent.expirationDate, "yyyy-MM-dd", new Date()),
    parse(parent.startDate, "yyyy-MM-dd", new Date())
  );
  return difference > 0 && difference <= 13;
}
function startsWithinAMonth(value) {
  return (
    differenceInDays(parse(value, "yyyy-MM-dd", new Date()), new Date()) < 30
  );
}

function lessThanAYearOld(value) {
  const difference = differenceInMonths(
    new Date(),
    parse(value, "yyyy-MM-dd", new Date())
  );
  return difference < 12;
}

function isAfterToday(value) {
  return isAfter(parse(value, "yyyy-MM-dd", new Date()), new Date());
}
</script>
