import {
  getAgencySummary,
  refreshAgencyLicenses,
  getAgencyLicenses
} from "@/api/agencies.service";
import {
  getAgentSummary,
  refreshAgentLicenses,
  getAgentLicenses
} from "@/api/agents.service";
import { getAgentSources } from "@/api/agent-sources.service";

import { AdvisorProfile, AdvisorProfileAdapter } from "@/models/AdvisorProfile";

import { formatAddress } from "@/models/Address";
import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { markRaw } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

export const useAdvisorProfileView = defineStore("advisor-profile-view", {
  state: () => ({
    ...AdvisorProfile(),
    canViewContracting: false,
    affiliations: [],
    licenses: [],
    fetchingLicenses: false,
    todoCount: 0
  }),
  getters: {
    businessAddressDisplay() {
      return formatAddress(this.businessAddress);
    },
    isAgency() {
      return this.type === "Agency";
    },
    getResourceParams() {
      if (this.isAgency) return { agency_id: this.id };
      return { agent_id: this.id };
    },
    tableFilterData() {
      return {
        id: this.id,
        type: this.type,
        name: this.name
      };
    },
    pusherId() {
      return `private-${this.id}-${this.type}`;
    }
  },
  actions: {
    async getAdvisorInfo(id, type) {
      this.$reset();
      const isAgency = type === "Agency";
      let func = getAgentSummary;
      if (isAgency) {
        func = getAgencySummary;
      }

      const { advisor, canViewContracting } = await func(id);
      const user = useUserStore();

      advisor.notes.sort((a, b) => a.createdAt - b.createdAt);
      advisor.notes.unshift({
        note: `Hi ${user.loginable.name}, feel free to make notes that only group 2+ users can view.`,
        ownable: { name: "BackNine" }
      });

      this.$patch(AdvisorProfileAdapter(advisor));
      this.canViewContracting = canViewContracting;
    },
    async getAffiliations() {
      this.affiliations.splice(0, this.affiliations.length);
      this.affiliations.push(...(await getAgentSources(this.id)));
    },
    refetchLicenses() {
      const dialog = useDialogStore();
      dialog.showDialog({
        component: markRaw(ConfirmationDialog),
        showErrorMessage: true,
        title: `Refetch ${this.name}'s Licenses?`,
        subtitle:
          "This will refetch licenses from NIPR and automatically charges BackNine $1.30. Please confirm for free with the state's department of insurance first. New licenses typically take a week to receive the changes from NIPR and fetching will have no effect.",
        func: this.refreshLicenses,
        checkboxText: "I verified the license exists and understand the charge"
      });
    },
    async refreshLicenses() {
      if (this.isAgency) {
        await refreshAgencyLicenses(this.id);
      } else {
        await refreshAgentLicenses(this.id);
      }
      await this.getLicenses();
      this.niprSubscribed = true;
    },
    async getLicenses() {
      try {
        this.licenses.splice(0, this.licenses.length);
        const func = this.isAgency ? getAgencyLicenses : getAgentLicenses;
        this.fetchingLicenses = true;
        const licenses = await func(this.id);
        this.licenses.push(...licenses);
        this.fetchingLicenses = false;
      } catch (e) {
        this.fetchingLicenses = false;
        throw e;
      }
    }
  }
});
