import { Address, setAddressFromRaw } from "@/models/Address";
import { setNoteFromRequest } from "@/models/Note";
import { setRawFromAddress } from "@/models/Address"

export const DEPARTMENTS = [
  "Case Management",
  "Commissions",
  "Customer Service",
  "Licensing",
  "IT",
  "Management",
  "Marketing",
  "New Application Email",
  "Sales",
  "Underwriting"
];

export function Personnel(model = {}) {
  return {
    avatar: model?.avatar || null,
    homeAddress: Address({
      ...model?.homeAddress,
      addressType: "home"
    }),
    businessAddress: Address({
      addressType: "business",
      ...model?.businessAddress
    }),
    carrier: Carrier(model?.carrier),
    department: model?.department || null,
    email: model?.email || null,
    firstName: model?.firstName || null,
    lastName: model?.lastName || null,
    notes: model?.notes || [],
    phoneFax: model?.phoneFax || null,
    phoneMobile: model?.phoneMobile || null,
    phoneWork: model?.phoneWork || null,
    phoneWorkExtension: model?.phoneWorkExtension || null,
    title: model?.title || null,
    id: model?.id || null
  };
}

export function setPersonnelFromRequest(raw = {}) {
  const model = Personnel();
  model.id = raw?.id;
  model.avatar = raw?.avatar_url;
  model.department = raw?.department;
  model.email = raw?.email;
  model.firstName = raw?.first_name;
  model.lastName = raw?.last_name;
  model.phoneFax = raw?.phone_fax;
  model.phoneMobile = raw?.phone_mobile;
  model.title = raw?.title;

  if (raw?.phone_work) {
    const [phoneWork, extension] = raw.phone_work.split("x");
    model.phoneWork = phoneWork;
    model.phoneWorkExtension = extension;
  }

  model.homeAddress = setAddressFromRaw(raw?.addresses?.home);
  model.businessAddress = setAddressFromRaw(raw?.addresses?.business);
  model.carrier = setCarrierFromRequest(raw?.carrier);

  model.notes = raw?.notes?.map(setNoteFromRequest);

  return model;
}

export function PersonnelToCreateRequest(model = {}) {
  let phoneWork = model.phoneWork;
  if (model.phoneWork && model.phoneWorkExtension) {
    phoneWork = `${model.phoneWork}x${model.phoneWorkExtension}`;
  }
  return {
    first_name: model.firstName,
    last_name: model.lastName,
    email: model.email,
    title: model.title,
    carrier_id: model.carrier.id,
    department: model.department,
    phone_work: phoneWork,
    phone_mobile: model.phoneMobile,
    addresses_attributes: [
      setRawFromAddress(model.homeAddress),
      setRawFromAddress(model.businessAddress)
    ]
  };
}

function Carrier(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    avatarUrl: model?.avatarUrl || null,
    email: model?.email || null
  };
}

function setCarrierFromRequest(raw = {}) {
  const model = Carrier();
  model.id = raw.id;
  model.name = raw.name;
  model.avatarUrl = raw.avatar_url;
  model.email = raw.email;
  return model;
}
