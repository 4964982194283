<template>
  <v-card>
    <v-card-title> {{ modelId ? "Update" : "Create" }} To-Do </v-card-title>
    <v-divider />
    <v-fade-transition mode="out-in">
      <v-card-text v-if="loading" key="loading">
        <v-row class="ma-0" justify="center" align="center">
          <v-progress-circular indeterminate />
        </v-row>
      </v-card-text>
      <v-card-text v-else key="edit">
        <h3 class="text-subtitle-1 mb-3 opacity-60">To-Do Details</h3>
        <v-row>
          <v-col cols="12">
            <text-field
              v-model="model.title"
              label="Title"
              hide-details
              data-testid="title"
              :prepend-inner-icon="mdiFormatTitle"
              v-bind="titleValidation"
            />
          </v-col>
          <v-col cols="12">
            <textarea-field
              v-model="model.description"
              label="Description (optional)"
              rows="3"
              auto-grow
              hide-details
              data-testid="description"
              :prepend-inner-icon="mdiText"
              :success="Boolean(model.description)"
            />
          </v-col>
          <v-col cols="12">
            <date-time-input
              v-model="model.followUpAt"
              v-model:date="dateHint"
              v-model:time="timeHint"
              :date-props="{
                label: `Follow Up${dateHint || timeHint ? '' : ' (optional)'}`,
                clearable: true,
                hideDetails: 'auto',
                'data-testid': 'follow-up-date',
                success: followUpAtValidation.success,
                errorMessages: timeHint
                  ? followUpAtValidation.errorMessages
                  : []
              }"
              :time-props="{
                label: `Time${dateHint || timeHint ? '' : ' (optional)'}`,
                clearable: true,
                menuProps: { offsetY: true, auto: true },
                hideDetails: 'auto',
                'data-testid': 'follow-up-time',
                success: followUpAtValidation.success,
                errorMessages: dateHint
                  ? followUpAtValidation.errorMessages
                  : []
              }"
            />
          </v-col>

          <v-col cols="12">
            <h3 class="text-subtitle-1 mb-1 opacity-60">To-Do Documents</h3>
            <file-drag-and-drop
              v-model="newFile"
              label="Related Documents (optional)"
              hide-details
              data-testid="document-input"
              @update:model-value="addDocument"
            />
            <v-list dense class="py-0">
              <v-list-item
                v-for="({ key, value: document }, index) in model.documents"
                :key="key"
                style="min-height: 28px"
                class="pl-1 pr-0"
                :data-testid="`document-${index}`"
              >
                <v-list-item-title>
                  {{ document.name }}
                </v-list-item-title>

                <template #append>
                  <app-button
                    :icon="mdiDelete"
                    variant="text"
                    color="error"
                    data-testid="remove-document"
                    @click="removeDocument({ key })"
                  />
                </template>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <div>
              <h3 class="text-subtitle-1 mb-1 opacity-60">To-Do Subjects</h3>
              <v-row dense>
                <v-col cols="12">
                  <sitewide-search
                    v-model="newSubject"
                    hide-details
                    label="Subject"
                    data-testid="subject-search"
                    :prepend-inner-icon="mdiBookOpenPageVariant"
                    @update:model-value="addSubject"
                  />
                  <v-list dense class="py-0">
                    <v-list-item
                      v-for="({ key, value: subject }, index) in model.subjects"
                      :key="key"
                      :data-testid="`subject-${index}`"
                      style="min-height: 28px"
                      class="pl-1 pr-0"
                    >
                      <v-list-item-title>
                        {{ subject.name || subject.title }}
                      </v-list-item-title>

                      <template #append>
                        <app-button
                          v-if="!subject.required"
                          variant="text"
                          :icon="mdiDelete"
                          color="error"
                          data-testid="remove-subject"
                          @click="removeSubject({ key })"
                        />
                      </template>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-divider class="hidden-md-and-up" />
          <v-col cols="12" md="6">
            <div>
              <h3 class="text-subtitle-1 mb-1 opacity-60">To-Do Assignees</h3>
              <v-row dense>
                <v-col cols="12">
                  <advisor-search
                    v-model="newAssignee"
                    label="Assignee"
                    hide-details
                    data-testid="assignee-search"
                    @update:model-value="addAssignee"
                  />
                  <v-list dense class="py-0">
                    <v-list-item
                      v-for="(
                        { key, value: assignee }, index
                      ) in model.assignables"
                      :key="key"
                      style="min-height: 28px"
                      class="pl-1 pr-0"
                      :data-testid="`assignee-${index}`"
                    >
                      <v-list-item-title>
                        {{ assignee.name }}
                      </v-list-item-title>

                      <template #append>
                        <app-button
                          :icon="mdiDelete"
                          variant="text"
                          color="error"
                          data-testid="remove-assignee"
                          @click="removeAssignee({ key })"
                        />
                      </template>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-fade-transition>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        color="primary"
        class="text-none"
        data-testid="save-button"
        :loading="saving"
        @click="saveToDo"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import SitewideSearch from "@/components/shared/SitewideSearch.vue";
import DateTimeInput from "@/components/shared/DateTimeInput.vue";

import { mdiFormatTitle, mdiText, mdiBookOpenPageVariant } from "@mdi/js";

import {
  computedValidation,
  parseErrorMessage,
  someTextValidator,
  uuidv4
} from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref } from "vue";
import {
  createTodo,
  updateTodo,
  deleteTodoSubject,
  createTodoSubject,
  createTodoAssignee,
  deleteTodoAssignee,
  createTodoDocument,
  getTodo
} from "@/api/todos.service.js";
import useVuelidate from "@vuelidate/core";
import {
  AssigneeToRequest,
  CastToDoToNewToDo,
  NewToDo,
  NewToDoToRequest,
  NewToDoToRequestUpdate,
  SubjectToRequest
} from "@/models/ToDo.js";
import { deleteDocument } from "@/api/documents.service";
import { mdiDelete } from "@mdi/js";
const props = defineProps({
  modelValue: {
    type: Object,
    required: false,
    default: () => NewToDo()
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const saving = ref(false);
const loading = ref(false);

// eslint-disable-next-line vue/no-setup-props-destructure
const modelId = props.modelValue.id;
const newSubject = ref(null);
const newAssignee = ref(null);
const newFile = ref(null);

const dateHint = ref(null);
const timeHint = ref(null);

function modelToKey(m) {
  return `${m.id}-${m.type}`;
}

let originalModel;
const model = ref(NewToDo());

async function initializeTodo() {
  if (modelId) {
    let todo;
    try {
      loading.value = true;
      todo = CastToDoToNewToDo(await getTodo(modelId));
    } catch (e) {
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
      return dialog.closeDialog();
    } finally {
      loading.value = false;
    }
    model.value = todo;
  } else {
    model.value = props.modelValue;
  }
  if (model.value.documents.length) {
    model.value.documents = model.value.documents.map(d => ({
      key: uuidv4(),
      value: d
    }));
  }

  if (model.value.subjects.length) {
    model.value.subjects = model.value.subjects.map(s => ({
      key: modelToKey(s),
      value: s
    }));
  }

  if (model.value.assignables.length) {
    model.value.assignables = model.value.assignables.map(a => ({
      key: modelToKey(a),
      value: a
    }));
  }

  originalModel = JSON.parse(JSON.stringify(model.value));
}

const v$ = useVuelidate(
  {
    model: {
      title: {
        required: v => someTextValidator(true, v, 2),
        maxLength: v => v.length <= 255
      },
      subjects: { required: v => v.length > 0 },
      followUpAt: {
        required: () => {
          if (dateHint.value && !timeHint.value) return false;
          if (!dateHint.value && timeHint.value) return false;
          return true;
        }
      }
    }
  },
  { model },
  { $scope: "model", $autoDirty: true }
);

const titleValidation = computedValidation(v$.value.model.title, {
  required: "Title is required"
});

const followUpAtValidation = computedValidation(v$.value.model.followUpAt, {
  required: "A date and time is required for a follow up"
});

const addDocument = () => addModel("documents", () => uuidv4(), newFile);
const addSubject = () => addModel("subjects", modelToKey, newSubject);
const addAssignee = () => addModel("assignables", modelToKey, newAssignee);
function addModel(key, keygen, m) {
  setTimeout(() => {
    if (!m.value) return;
    if (!model.value[key].some(v => v.key === keygen(m.value))) {
      model.value[key].push({ value: m.value, key: keygen(m.value) });
    }
    m.value = null;
  }, 20);
}

const removeDocument = rm => removeModel("documents", rm);
const removeSubject = rm => removeModel("subjects", rm);
const removeAssignee = rm => removeModel("assignables", rm);
function removeModel(key, rm) {
  const index = model.value[key].findIndex(s => s.key === rm.key);
  model.value[key].splice(index, 1);
}

async function saveToDo() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  if (modelId) return update();
  else return create();
}

async function create() {
  try {
    saving.value = true;
    const res = await createTodo(
      NewToDoToRequest({
        ...model.value,
        subjects: model.value.subjects.map(({ value }) => value),
        assignables: model.value.assignables.map(({ value }) => value),
        documents: model.value.documents.map(({ value }) => value)
      })
    );
    dialog.closeDialog({ id: res });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

/**
 * Need to update base todo
 *
 * Delete missing subjects
 * Create new subjects
 *
 * Delete missing assignables
 * Create new assignables
 *
 * Create new documents
 * Delete missing documents
 */
async function update() {
  try {
    saving.value = true;
    await updateBaseTodo();
    await reconcileSubjects();
    await reconcileAssignees();
    await reconcileDocuments();
    dialog.closeDialog({ id: modelId });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

function updateBaseTodo() {
  return updateTodo(modelId, NewToDoToRequestUpdate(model.value));
}

function reconcileSubjects() {
  return reconcileModels(
    "subjects",
    v => createTodoSubject(modelId, SubjectToRequest(v.value)),
    v => deleteTodoSubject(modelId, v.value.subjectId)
  );
}

function reconcileAssignees() {
  return reconcileModels(
    "assignables",
    v => createTodoAssignee(modelId, AssigneeToRequest(v.value)),
    v => deleteTodoAssignee(modelId, v.value.assigneeId)
  );
}

async function reconcileDocuments() {
  return reconcileModels(
    "documents",
    v => createTodoDocument(modelId, v.value),
    v => deleteDocument(v.value.uid)
  );
}

async function reconcileModels(key, createFn, deleteFn) {
  const newConnections = model.value[key].reduce((acc, s) => {
    acc[s.key] = s;
    return acc;
  }, {});

  const oldConnections = originalModel[key].reduce((acc, s) => {
    acc[s.key] = s;
    return acc;
  }, {});

  const toDelete = Object.keys(oldConnections).filter(k => !newConnections[k]);
  const toCreate = Object.keys(newConnections).filter(k => !oldConnections[k]);

  for (let i = 0; i < toCreate.length; i++) {
    await createFn(newConnections[toCreate[i]]);
  }

  for (let i = 0; i < toDelete.length; i++) {
    await deleteFn(oldConnections[toDelete[i]]);
  }
}

initializeTodo();
</script>
