<template>
  <v-card>
    <v-card-title> {{ editing ? "Edit" : "Create" }} Commission </v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <template v-if="!paid">
          <v-col cols="12" md="6">
            <advisor-search
              v-model="commission.payable"
              label="Payable"
              placeholder="Search Payables"
              data-testid="commission-dialog-payable"
              v-bind="payableValidation"
            />
          </v-col>
          <v-col cols="12" md="6">
            <advisor-search
              v-model="commission.assignedPayable"
              label="Assigned Payable"
              placeholder="Search Payables"
              data-testid="commission-dialog-assigned-payable"
              v-bind="assignedPayableValidation"
            />
          </v-col>
          <v-col cols="12" md="6">
            <payor-search
              v-model="commission.payor"
              label="Payor"
              placeholder="Search Payors"
              data-testid="commission-dialog-payor"
              v-bind="payorValidation"
            />
          </v-col>
          <v-col cols="12" md="6">
            <autocomplete-field
              v-model="commission.appointmentCase"
              label="Appointment Case"
              item-title="advisor.name"
              data-testid="commission-dialog-appointment-case"
              return-object
              :items="appointmentCases"
              :prepend-inner-icon="mdiOfficeBuilding"
              v-bind="appointmentCaseValidation"
            />
          </v-col>
          <v-col cols="12" md="6">
            <autocomplete-field
              v-model="commission.commissionType"
              label="Commission Type"
              data-testid="commission-dialog-commission-type"
              :prepend-inner-icon="mdiChartAreaspline"
              :items="commissionTypes"
              v-bind="commissionTypeValidation"
            />
          </v-col>
          <v-col cols="12" md="6">
            <autocomplete-field
              v-model="commission.paymentType"
              label="Payment Type"
              data-testid="commission-dialog-payment-type"
              :prepend-inner-icon="mdiCash"
              :items="PAYMENT_TYPES"
              v-bind="paymentTypeValidation"
            />
          </v-col>
        </template>
        <v-col cols="12" md="6">
          <text-field
            v-model="commission.percent"
            data-lpignore="true"
            label="Percent"
            type="text"
            inputmode="numeric"
            data-testid="commission-dialog-percent"
            :prepend-inner-icon="mdiPercent"
            v-bind="percentValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="commission.startYear"
            data-lpignore="true"
            label="Start Year"
            type="text"
            inputmode="numeric"
            data-testid="commission-dialog-start-year"
            :prepend-inner-icon="mdiCalendarStart"
            v-bind="startYearValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="commission.endYear"
            data-lpignore="true"
            label="End Year"
            type="text"
            inputmode="numeric"
            data-testid="commission-dialog-end-year"
            :prepend-inner-icon="mdiCalendarEnd"
            v-bind="endYearValidation"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        color="grey"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="commission-dialog-save"
        :loading="loading"
        @click="save"
      >
        {{ editing ? "Save" : "Create" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import PayorSearch from "@/components/shared/PayorSearch.vue";

import { COMMISSION_TYPES, PAYMENT_TYPES } from "@/data/transaction-data";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiOfficeBuilding,
  mdiChartAreaspline,
  mdiCash,
  mdiPercent,
  mdiCalendarStart,
  mdiCalendarEnd
} from "@mdi/js";
import { computed, ref } from "vue";
import {
  Commission,
  CommissionToCreateRequest,
  CommissionToPaidUpdateRequest,
  CommissionToUnpaidUpdateRequest
} from "@/models/Commissions";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import useVuelidate from "@vuelidate/core";
import { createCommission, updateCommission } from "@/api/commissions.service";

const props = defineProps({
  modelValue: { type: Object, required: true }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const editing = Boolean(props.modelValue?.id);
const loading = ref(false);
// eslint-disable-next-line vue/no-setup-props-destructure
const paid = props.modelValue.paid;

const commission = ref(Commission(props.modelValue));

const appointmentCases = computed(() => {
  if (commission.value.policy?.appointmentCases)
    return commission.value.policy.appointmentCases;
  if (commission.value.appointmentCase)
    return [commission.value.appointmentCase];
  return [];
});

const commissionTypes = computed(() =>
  COMMISSION_TYPES.map(
    value => `${toLine(commission.value.policy.line)} ${value}`
  )
);

const v$ = useVuelidate(
  {
    commission: {
      startYear: { required: Boolean },
      endYear: { required: Boolean },
      percent: { required: Boolean },
      payable: {
        required: v => {
          if (editing && paid) return true;
          return Boolean(v?.id);
        }
      },
      assignedPayable: {
        required: v => {
          if (editing && paid) return true;
          return Boolean(v?.id);
        }
      },
      payor: {
        required: v => {
          if (editing && paid) return true;
          return Boolean(v?.id);
        }
      },
      commissionType: {
        required: v => {
          if (editing && paid) return true;
          return Boolean(v);
        }
      },
      paymentType: {
        required: v => {
          if (editing && paid) return true;
          return Boolean(v);
        }
      },
      appointmentCase: {
        required: v => {
          if (editing && paid) return true;
          return Boolean(v?.id);
        }
      }
    }
  },
  { commission },
  { $scope: null, $autoDirty: true }
);

const payableValidation = computedValidation(v$.value.commission.payable, {
  required: "Required"
});

const assignedPayableValidation = computedValidation(
  v$.value.commission.assignedPayable,
  {
    required: "Required"
  }
);

const payorValidation = computedValidation(v$.value.commission.payor, {
  required: "Required"
});

const appointmentCaseValidation = computedValidation(
  v$.value.commission.appointmentCase,
  {
    required: "Required"
  }
);

const commissionTypeValidation = computedValidation(
  v$.value.commission.commissionType,
  {
    required: "Required"
  }
);

const paymentTypeValidation = computedValidation(
  v$.value.commission.paymentType,
  {
    required: "Required"
  }
);

const percentValidation = computedValidation(v$.value.commission.percent, {
  required: "Required"
});

const startYearValidation = computedValidation(v$.value.commission.startYear, {
  required: "Required"
});

const endYearValidation = computedValidation(v$.value.commission.endYear, {
  required: "Required"
});

function toLine(value) {
  if (value === "life") return "Life";
  if (value === "linked_benefit") return "Linked Benefit";
  if (value === "Variable Life") return "Variable Life";
  if (value === "disability") return "Disability";
  if (value === "annuity") return "Annuity";
  if (value === "ltc") return "LTC";
  return value;
}

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  return editing ? update() : create();
}

async function create() {
  if (loading.value) return;
  loading.value = true;
  try {
    await createCommission(CommissionToCreateRequest(commission.value));
    dialog.closeDialog({ commission: commission.value });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

async function update() {
  if (loading.value) return;
  loading.value = true;

  try {
    const body = paid
      ? CommissionToPaidUpdateRequest(commission.value)
      : CommissionToUnpaidUpdateRequest(commission.value);

    await updateCommission(props.modelValue.id, body);
    dialog.closeDialog({ commission: commission.value });
  } catch (e) {
    dialog.closeDialog();
  } finally {
    loading.value = false;
  }
}
</script>
