<template>
  <v-card class="ma-3">
    <v-card-title class="text-h5">Submit Case</v-card-title>
    <v-card-subtitle>
      Securely upload non-Quote & Apply applications.
    </v-card-subtitle>
    <v-card-text>
      <v-col cols="12" class="pa-0">
        <text-field
          v-model="caseSubmission.insuredName"
          data-testid="insured-name"
          data-lpignore="true"
          label="Client Name"
          :prepend-inner-icon="mdiAccount"
          :success="insuredNameValidation.success"
          :error-messages="insuredNameValidation.errorMessages"
        />
        <file-drag-and-drop
          v-model="caseSubmission.documents"
          data-testid="documents"
          label="Application Document(s)"
          multiple
          :prepend-inner-icon="mdiFileDocumentMultiple"
          :success="documentsValidation.success"
          :error-messages="documentsValidation.errorMessages"
        />
        <text-field
          v-model="caseSubmission.policyNumber"
          data-testid="policy-number"
          data-lpignore="true"
          label="Policy Number (optional)"
          :success="Boolean(caseSubmission.policyNumber)"
          :prepend-inner-icon="mdiPound"
        />
        <textarea-field
          v-model="caseSubmission.description"
          label="Additional Application Details (optional)"
          data-testid="description"
          :success="Boolean(caseSubmission.description)"
          :prepend-inner-icon="mdiText"
        />
      </v-col>
    </v-card-text>
    <v-card-actions>
      <app-button
        color="primary"
        class="text-none"
        data-testid="submit-case"
        :loading="submitting"
        @click="submitCase"
      >
        <v-icon :icon="mdiPlus" />
        Submit
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { createCaseUpload } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { CaseSubmission } from "@/models/CaseSubmission";
import {
  mdiAccount,
  mdiFileDocumentMultiple,
  mdiPound,
  mdiText,
  mdiPlus
} from "@mdi/js";
import { nextTick, ref } from "vue";
import useVuelidate from "@vuelidate/core";
useHead({ title: "Case Submission" });

const snackbar = useSnackbarStore();
const caseSubmission = ref(CaseSubmission());
const submitting = ref(false);

const v$ = useVuelidate(
  {
    caseSubmission: {
      insuredName: { required: v => someTextValidator(true, v, 2) },
      documents: { required: v => v.length > 0 }
    }
  },
  {
    caseSubmission
  },
  {
    $scope: null,
    $autoDirty: true
  }
);

const insuredNameValidation = computedValidation(
  v$.value.caseSubmission.insuredName,
  {
    required: "Required"
  }
);

const documentsValidation = computedValidation(
  v$.value.caseSubmission.documents,
  {
    required: "Required"
  }
);

async function submitCase() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  submitting.value = true;

  try {
    await createCaseUpload({
      description: caseSubmission.value.description,
      documents: caseSubmission.value.documents,
      policyNumber: caseSubmission.value.policyNumber,
      insuredName: caseSubmission.value.insuredName
    });
    caseSubmission.value = CaseSubmission();
    snackbar.showSuccessSnackbar({
      message: "Successfully Submitted Case!",
      timeout: 10000
    });
    await nextTick();
    v$.value.$reset();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 10000
    });
  } finally {
    submitting.value = false;
  }
}
</script>
