import { getHttpClient } from "@/http-client";
import { setIndividualFromRequest } from "@/models/Party";

const baseUrl = "api/boss/individuals";
export async function fetchIndividual(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setIndividualFromRequest(data?.individual);
}

export async function getSensitiveIndividualInformation(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/sensitive`);
  return data.ssn;
}

export function updateIndividual(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, body);
}
