<template>
  <sitewide-search
    id="menu-search"
    :key="$route.path"
    :show-nav-helpers="!inDialog"
    :placeholder="placeholder"
    :density="inDialog ? 'default' : 'compact'"
    :prepend-inner-icon="$slots['prepend-inner'] ? null : mdiMagnify"
    :autofocus="autofocus"
    :variant="!inDialog ? 'outlined' : 'underlined'"
    :class="{
      'mt-0': inDialog,
      'menu-w-100': inDialog,
      'pt-3': !inDialog
    }"
    :attach="inDialog"
    :dense="!inDialog"
    :clearable="!inDialog"
    :label="label"
    as-router-link
    autocomplete="off"
    style="min-width: 300px"
    return-object
    hide-no-data
    hide-selected
    tabindex="0"
    inputmode="search"
    data-testid="search-bar"
    @update:model-value="emit('close')"
    @clicked="emit('close')"
  >
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
  </sitewide-search>
</template>

<script setup>
import { mdiMagnify } from "@mdi/js";
import SitewideSearch from "@/components/shared/SitewideSearch.vue";
defineProps({
  inDialog: Boolean,
  autofocus: Boolean,
  label: {
    default: "Search BOSS",
    type: String
  },
  placeholder: {
    default: "Type to search BOSS",
    type: String
  }
});
const emit = defineEmits(["close"]);
</script>
