<template>
  <div>
    <v-list v-if="!items.length && !loading">
      <v-list-item>
        <v-list-item-title>Nothing To-Do!</v-list-item-title>
      </v-list-item>
    </v-list>
    <div v-else-if="items.length">
      <v-alert
        v-for="group in itemGroups"
        :key="group.title"
        v-bind="group.props"
        :data-testid="group.title"
      >
        <h3 :class="`text-${group.props.color} text-h6 mb-3`">
          <v-icon
            :color="group.props.color"
            :icon="group.icon"
            size="24"
            class="mb-1"
          />
          {{ group.title }}
        </h3>
        <div
          v-for="item in group.items"
          :id="`todo-${item.id}`"
          :key="item.id"
          class="rounded"
          :data-testid="`todo-item-${item.id}`"
          :class="{ 'highlight-todo': item.highlight }"
        >
          <v-card class="mb-1" style="z-index: 2" @click="viewTodo(item)">
            <div class="todo-item">
              <div>
                <checkbox-field
                  data-testid="todo-status"
                  color="primary"
                  class="px-3"
                  style="padding-top: 6px"
                  hide-details
                  readonly
                  :model-value="group.value"
                  @click.stop="
                    () =>
                      toggleStatusDialog({ id: item.id, ...group.statusProps })
                  "
                />
              </div>
              <div class="py-1">
                <div
                  data-testid="todo-title"
                  class="text-body-1 font-weight-medium"
                >
                  {{ item.title }}
                </div>
                <div
                  v-if="item.subtitle"
                  data-testid="todo-subtitle"
                  class="text-body-2"
                >
                  {{ item.subtitle }}
                </div>
              </div>
              <div class="pa-2 flex-row">
                <app-button
                  data-testid="todo-edit"
                  color="accent"
                  variant="text"
                  density="comfortable"
                  class="inline-block"
                  :icon="mdiPencil"
                  @click.stop="editToDo(item)"
                />
                <app-button
                  data-testid="todo-delete"
                  color="error"
                  variant="text"
                  density="comfortable"
                  class="inline-block"
                  :icon="mdiDelete"
                  @click.stop="deleteToDo(item)"
                />
              </div>
            </div>
          </v-card>
          <div :key="item.id + 'details'" style="z-index: 1">
            <v-slide-y-transition mode="out-in">
              <v-card
                v-if="item.render"
                v-show="item.visible"
                class="mb-3 mt-n3"
              >
                <v-divider />
                <to-do-item-view :id="item.id" />
              </v-card>
            </v-slide-y-transition>
          </div>
        </div>
      </v-alert>
    </div>
    <app-button
      class="text-none mt-3"
      color="primary"
      data-testid="create-todo"
      @click="addTodo"
    >
      <v-icon class="mr-2" :icon="mdiPlus" /> Add To-Do
    </app-button>
  </div>
</template>

<script setup>
import { getHttpClient } from "@/http-client";

import ToDoItemDialog from "@/components/todo/ToDoItemDialog.vue";
import ToDoItemView from "@/components/todo/ToDoItemView.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { NewToDo } from "@/models/ToDo.js";
import { getTodos, updateTodo, deleteTodo } from "@/api/todos.service";
import { parseErrorMessage, timestampFormatter } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, computed, markRaw, nextTick, watch, toRef } from "vue";
import {
  mdiPlus,
  mdiAlertCircleOutline,
  mdiCalendar,
  mdiCalendarClockOutline,
  mdiCheckCircleOutline,
  mdiPencil,
  mdiDelete
} from "@mdi/js";
import { useUserStore } from "@/stores/user";
import { useRouter } from "vue-router";
import { useNotificationsStore } from "@/stores/notifications";

const OVERDUE = "Overdue";
const DUE_TODAY = "Due Today";
const UPCOMING = "Upcoming";

const props = defineProps({
  subject: {
    type: Object,
    required: false,
    default: () => null
  },
  assignable: {
    type: Object,
    required: false,
    default: () => null
  },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  }
});

const emit = defineEmits(["due-count"]);

const router = useRouter();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { loginable } = useUserStore();
const notifications = useNotificationsStore();

const highlightTodo = toRef(props, "highlightTodo");

const loading = ref(false);
const items = ref([]);

watch([loading, highlightTodo], () => {
  if (loading.value || !highlightTodo.value) return;
  handleHighlight();
});

const itemGroups = computed(() => {
  const incomplete = {
    title: UPCOMING,
    icon: markRaw(mdiCalendar),
    items: [],
    value: false,
    statusProps: {
      title: "Mark as complete",
      complete: true
    },
    props: {
      color: "info",
      variant: "outlined",
      rounded: true,
      class: "mb-3 pa-3"
    }
  };

  const complete = {
    title: "Complete",
    icon: markRaw(mdiCheckCircleOutline),
    items: [],
    value: true,
    statusProps: {
      title: "Mark as incomplete",
      complete: false
    },
    props: {
      color: "success",
      variant: "outlined",
      rounded: true,
      class: "mb-3 pa-3"
    }
  };

  const dueToday = {
    title: DUE_TODAY,
    icon: markRaw(mdiCalendarClockOutline),
    items: [],
    value: false,
    statusProps: {
      title: "Mark as complete",
      complete: true
    },
    props: {
      color: "warning",
      variant: "outlined",
      rounded: true,
      class: "mb-3 pa-3"
    }
  };

  const overdue = {
    title: OVERDUE,
    icon: markRaw(mdiAlertCircleOutline),
    items: [],
    value: false,
    statusProps: {
      title: "Mark as complete",
      complete: true
    },
    props: {
      color: "error",
      variant: "outlined",
      rounded: true,
      class: "mb-3 pa-3"
    }
  };

  const today = new Date();

  items.value.forEach(i => {
    if (i.complete) return complete.items.push(i);
    if (i.followUpAt) {
      const followUpAtDate = new Date(i.followUpAt);

      if (followUpAtDate < today) {
        return overdue.items.push(i);
      } else if (followUpAtDate.toDateString() === today.toDateString()) {
        return dueToday.items.push(i);
      }
    }
    incomplete.items.push(i);
  });

  return [overdue, dueToday, incomplete, complete].filter(v => v.items.length);
});

let cancelToken = null;
async function fetchToDos() {
  loading.value = true;
  try {
    const params = new URLSearchParams();
    if (props.subject?.id && props.subject?.type) {
      params.append("subject_id", props.subject.id);
      params.append("subject_type", props.subject.type);
    }
    if (props.assignable?.id && props.assignable.type) {
      params.append("assignee_id", props.assignable.id);
      params.append("assignee_type", props.assignable.type);
    }

    if (cancelToken !== null) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = getHttpClient().CancelToken.source();

    const { items: todos } = await getTodos(params, cancelToken);
    items.value.splice(0, items.value.length);

    const formatTime = v => timestampFormatter(v, "none", "date-time");
    todos.forEach(i => {
      let subtitle;
      if (i.complete) {
        subtitle = `Completed on ${formatTime(i.completedAt)}`;
      } else if (i.followUpAt) {
        subtitle = `Follow up on ${formatTime(i.followUpAt)}`;
      } else {
        subtitle = `Created on ${formatTime(i.createdAt)}`;
      }
      items.value.push({
        ...i,
        subtitle,
        render: false,
        visible: false,
        highlight: false
      });
    });

    todos.sort((a, b) => {
      if (a.followUpAt && b.followUpAt) {
        return new Date(a.followUpAt) - new Date(b.followUpAt);
      } else if (a.followUpAt) return -1;
      else if (b.followUpAt) return 1;
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    nextTick().then(() => handleHighlight());
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function handleHighlight() {
  if (!highlightTodo.value || loading.value) return;
  if (router) {
    const { "highlight-todo": _, ...query } = router.currentRoute.value.query; // eslint-disable-line
    router.replace({ ...router.currentRoute, query });
  }
  const index = items.value.findIndex(i => i.id === highlightTodo.value);
  if (index === -1) return;
  items.value[index].highlight = true;
  items.value[index].render = true;
  items.value[index].visible = true;
  const todo = document.getElementById(`todo-${highlightTodo.value}`);
  if (todo) todo.scrollIntoView({ behavior: "smooth" });
  setTimeout(() => {
    items.value[index].highlight = false;
  }, 5000);
}

function toggleStatusDialog({ title, complete, id }) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    scrollable: true,
    persistent: true,
    title,
    subtitle: "Please confirm to proceed",
    func: async () => {
      await updateTodo(id, { complete });
      await fetchToDos();
      await notifications.getDueTodos();
    }
  });
}

function deleteToDo({ id }) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    scrollable: true,
    persistent: true,
    title: "Delete To-Do",
    subtitle: "Are you sure you want to delete this To-Do?",
    func: async () => {
      await deleteTodo(id);
      await fetchToDos();
    }
  });
}

async function editToDo(todo) {
  const res = await dialog.showDialog({
    component: markRaw(ToDoItemDialog),
    modelValue: { id: todo.id }
  });
  if (res?.id) fetchToDos();
}

function viewTodo(todo) {
  const index = items.value.findIndex(i => i.id === todo.id);
  if (index === -1) return;
  items.value[index].render = true;
  items.value[index].visible = !items.value[index].visible;
}

async function addTodo() {
  const value = NewToDo();
  if (props.subject?.id && props.subject?.type && props.subject?.name) {
    value.subjects.push({
      id: props.subject.id,
      type: props.subject.type,
      name: props.subject.name,
      required: true
    });
  }

  if (
    props.assignable?.id &&
    props.assignable?.type &&
    props.assignable?.name
  ) {
    value.assignables.push({
      id: props.assignable.id,
      type: props.assignable.type,
      name: props.assignable.name
    });
  } else {
    value.assignables.push({
      id: loginable.id,
      type: loginable.type,
      name: loginable.name
    });
  }

  const res = await dialog.showDialog({
    component: markRaw(ToDoItemDialog),
    scrollable: true,
    persistent: true,
    modelValue: value
  });
  if (res?.id) fetchToDos();
}

fetchToDos();

const dueCount = computed(() => {
  return itemGroups.value.reduce(
    (accumulator, group) =>
      [DUE_TODAY, UPCOMING, OVERDUE].includes(group.title)
        ? accumulator + group.items.length
        : accumulator,
    0
  );
});
watch(dueCount, v => emit("due-count", v || 0));
</script>

<style lang="scss">
.todo-item {
  display: grid;
  grid-template-columns: 48px auto 140px;
}

@keyframes blink {
  0% {
    outline-color: transparent;
  }
  50% {
    outline-color: rgb(var(--v-theme-primary));
  }
  100% {
    outline-color: transparent;
  }
}

.highlight-todo {
  animation: blink 1s infinite;
  outline: 2px solid rgb(var(--v-theme-primary));
}
</style>
