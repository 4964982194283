<template>
  <v-card color="section" flat tile>
    <v-card-title>
      <v-row class="ma-0">
        Comp Builders
        <v-spacer />
        <app-button
          v-if="isGroupFour"
          class="text-none"
          color="accent"
          data-testid="add-comp-builder-button"
          :prepend-icon="mdiPlus"
          text="Add Comp Builder"
          @click="addCompBuilder"
        />
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        data-testid="comp-builders-table"
        item-value="additional.key"
        :mobile="null"
        mobile-breakpoint="sm"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
      >
        <template #[`item.street`]="{ item }">
          <v-icon
            v-if="item.street"
            data-testid="is-street"
            color="success"
            :icon="mdiCheckCircle"
          />
        </template>
        <template #expanded-row="{ columns, item }">
          <td :colspan="columns.length" class="py-4">
            <v-row dense>
              <v-col cols="12" md="4">
                <v-card height="100%" variant="outlined">
                  <v-card-title> Comp Builder Details </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <carrier-comp-builder-form
                      class="my-2"
                      :disabled="!isGroupFour"
                      :validation-scope="item.additional.key"
                      :model-value="item.additional"
                      @update:model-value="
                        updateCompBuilder(item.additional.key, $event)
                      "
                    />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <v-card variant="outlined">
                  <carrier-comp-builder-notes
                    :key="item.additional.key + 'notes'"
                    :comp-builder-key="item.additional.key"
                  />
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="isGroupFour" class="ma-0 mt-3">
              <v-spacer />
              <app-button
                variant="outlined"
                color="error"
                class="text-none"
                data-testid="delete-comp-builder"
                @click="destroyCompBuilder(item.additional.key)"
              >
                <v-icon :icon="mdiDelete" />
                Delete Comp Builder
              </app-button>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CarrierCompBuilderCreate from "@/components/carriers/CarrierCompBuilderCreate.vue";
import CarrierCompBuilderForm from "@/components/carriers/CarrierCompBuilderForm.vue";
import CarrierCompBuilderNotes from "@/components/carriers/CarrierCompBuilderNotes.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { deleteCompBuilder } from "@/api/comp-builders.service";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import TableHeader from "@/classes/data-table/TableHeader";
import { mdiCheckCircle, mdiPlus, mdiDelete } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useCarrierView } from "@/stores/carrier-view";
import { markRaw, watch } from "vue";
import { CarrierCompBuilderKey } from "@/models/Carrier";
import { useUserStore } from "@/stores/user";

const {
  compBuilders,
  id: carrierId,
  name: carrierName
} = storeToRefs(useCarrierView());

const user = useUserStore();
const { isGroupFour } = storeToRefs(user);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name"
    }),
    new TableHeader({
      text: "Street",
      value: "street",
      map: "street"
    }),
    new TableHeader({
      text: "",
      value: "data-table-expand"
    })
  ]
});

function updateCompBuilder(key, value) {
  compBuilders.value.by_id[key] = value;
}

const dialog = useDialogStore();
async function addCompBuilder() {
  const res = await dialog.showDialog({
    component: markRaw(CarrierCompBuilderCreate),
    carrier: { id: carrierId.value, name: carrierName.value }
  });
  if (!res?.compBuilder) return;
  const key = CarrierCompBuilderKey(res.compBuilder.id);
  compBuilders.value.by_id[key] = res.compBuilder;
  compBuilders.value.ids.push(key);
}

function destroyCompBuilder(key) {
  const compBuilder = compBuilders.value.by_id[key];
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure that you want to delete this CompBuilder?",
    subtitle: "This cannot be undone",
    func: async () => {
      await deleteCompBuilder(compBuilder.id);
      delete compBuilders.value.by_id[key];
      const index = compBuilders.value.ids.indexOf(key);
      if (index === -1) return;
      compBuilders.value.ids.splice(index, 1);
    }
  });
}

function setItems() {
  table.items.value.splice(0, table.items.value.length);
  compBuilders.value.ids.forEach(id => {
    table.items.value.push({
      ...compBuilders.value.by_id[id],
      key: id
    });
  });
}

watch(compBuilders, setItems, { immediate: true, deep: true });
</script>
