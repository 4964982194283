import { getHttpClient } from "@/http-client";
import { setProductCitizenshipProfileFromRequest } from "@/models/Product";

const baseUrl = "api/boss/product_citizenship_profiles";
export async function addProductCitizenshipProfile(params) {
  const { data } = await getHttpClient().post(baseUrl, params);
  return setProductCitizenshipProfileFromRequest(
    data.product_citizenship_profile
  );
}
export function removeProductCitizenshipProfile(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
