<template>
  <v-card flat tile>
    <v-card-title>Commission </v-card-title>
    <v-card-text>
      Modify Commission Assignment, Payment Method, and Commission Splits.
      <a target="_blank" @click="showCommissionFeaturesArticle">
        Learn about Commission Features.
      </a>
    </v-card-text>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <commission-assignment :is-viewing-self="isViewingSelf" />
        </v-col>
        <v-fade-transition mode="out-in">
          <v-col v-if="canSeePaymentMethods" cols="12">
            <commission-payment-method :module="props.module" />
          </v-col>
        </v-fade-transition>
        <v-fade-transition mode="out-in">
          <v-col v-if="canSeeCommissionRates" cols="12">
            <commission-rates
              :disabled="agent.lockCommissions"
              :module="props.module"
            />
          </v-col>
        </v-fade-transition>
        <v-fade-transition mode="out-in">
          <v-col v-if="canSeeCommissionHierarchy" cols="12">
            <commission-hierarchy
              :readonly="props.readonly"
              @go-to-admin="emit('go-to', 'admin')"
            />
          </v-col>
        </v-fade-transition>
        <v-col cols="12">
          <agent-commission-splits />
        </v-col>
        <v-col cols="12">
          <commission-admin
            :readonly="props.readonly"
            :module="props.module"
            :is-viewing-self="props.isViewingSelf"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import CommissionHierarchy from "@/components/settings/commissions/CommissionHierarchy.vue";
import CommissionAssignment from "@/components/settings/commissions/CommissionAssignment.vue";
import CommissionRates from "@/components/settings/commissions/CommissionRates.vue";
import CommissionAdmin from "@/components/settings/commissions/CommissionAdmin.vue";
import AgentCommissionSplits from "@/components/settings/commissions/AgentCommissionSplits.vue";
import CommissionPaymentMethod from "@/components/settings/commissions/CommissionPaymentMethod.vue";

import { useUserStore } from "@/stores/user";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import { computed,  watch, onMounted, markRaw } from "vue";
import { useDialogStore } from "@/stores/dialog";
import { ARTICLES } from "@/data/articles";
import { storeToRefs } from "pinia";
import HelpCenterReader from "@/components/help-center/HelpCenterReader.vue";
const props = defineProps({
  editing: Boolean,
  type: { type: String, default: null },
  readonly: Boolean,
  isViewingSelf: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS].includes(val),
    type: String
  }
});

const emit = defineEmits(["go-to", "mounted"]);

const agent = useAgentSettingsStore();
const { assignment } = storeToRefs(agent);

const user = useUserStore();

const canSeeCommissionRates = computed(() => !props.isViewingSelf);

const canSeePaymentMethods = computed(() => {
  if (props.readonly) return false;
  return !assignment.value?.id;
});

const canSeeCommissionHierarchy = computed(() => {
  return user.isGroupTwoPlus || !props.isViewingSelf;
});

// TODO: Defer to payment method for this?
const validEft = computed(() => {
  if (assignment.value?.id) return true;
  const eftOptions = Object.values(agent.efts);
  const activeEft = eftOptions.find(val => val.current === 1 && val.eftDocument);
  return Boolean(activeEft?.id) && !activeEft?.errors;
});

watch(validEft, () => setValidPage());

function setValidPage() {
  agent.setValidPage({ page: "commissions", valid: validEft.value });
}

const dialog = useDialogStore();
function showCommissionFeaturesArticle() {
  dialog.showDialog({
    component: markRaw(HelpCenterReader),
    id: ARTICLES.COMMISSION_FEATURES
  });
}

setValidPage();

onMounted(() => emit("mounted"));
</script>
