import { InformalOffer } from "@/models/InformalOffer";
import { getOffer } from "@/api/quick-quotes.service";
import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";

export const useImpairedRiskQuoteOffer = defineStore(
  "impaired-risk-quote-offer",
  {
    state: () => ({ ...InformalOffer(), activeOfferKey: null }),
    getters: {
      pusherId() {
        return `private-${this.id}-InformalOffer`;
      }
    },
    actions: {
      async initialize(quoteId, offerId) {
        const offer = await getOffer(quoteId, offerId);
        this.$patch(offer);
        this.activeOfferKey = offerId;
        this.notes.sort((a, b) => a.createdAt - b.createdAt);

        const user = useUserStore();
        this.notes.unshift({
          note: `Hi ${user.loginable.name}, feel free to make notes here.`,
          ownable: { name: "BackNine" }
        });
      }
    }
  }
);
