<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    :row-props="v => ({ class: v.item.additional.rowClass })"
    multi-sort
    item-value="additional.key"
    data-testid="simulated-commissions"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="simulatingTransaction"
  >
    <template #[`item.coverage`]="{ item }">
      {{ item.additional.startYear }} - {{ item.additional.endYear }}
    </template>
    <template #[`item.payment`]="{ item }">
      <div class="truncate-300">
        {{ item.additional.commissionType }},
        <percentage-formatter
          :model-value="item.additional.percent"
          is-decimal
          :decimal-length="3"
        />,
        {{ item.additional.paymentType }}
      </div>
    </template>
    <template
      #[`item.data-table-expand`]="{ item, toggleExpand, internalItem }"
    >
      <v-menu>
        <template #activator="{ props: templateProps }">
          <app-button
            data-testid="commission-actions"
            v-bind="templateProps"
            variant="icon"
            :icon="mdiDotsHorizontal"
            :color="item.additional.isErrored ? 'white' : null"
          />
        </template>
        <v-list>
          <v-list-item
            v-for="action in item.additional.actions"
            :key="action.text"
            :data-testid="action.text"
            @click="action.action()"
          >
            <template #prepend>
              <v-icon :icon="action.icon" />
            </template>

            <v-list-item-title> {{ action.text }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <app-button
        v-if="item.additional.isErrored"
        icon
        data-testid="commission-errors"
        @click.stop="toggleExpand(internalItem)"
      >
        <v-icon color="white" :icon="mdiInformation" />
      </app-button>
    </template>
    <template #expanded-row="{ columns, item }">
      <td data-testid="commission-error-messages" :colspan="columns.length">
        {{ item.additional.errors }}
      </td>
    </template>
  </v-data-table>
</template>

<script setup>
import CommissionDialog from "@/dialogs/CommissionDialog.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { deleteCommission } from "@/api/commissions.service";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiInformation,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete
} from "@mdi/js";
import { Commission } from "@/models/Commissions";
import { markRaw, watch } from "vue";
import { useTransactionCreate } from "@/stores/transaction-create";
import { useTable } from "@/composables/table.composable";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const transactionCreateStore = useTransactionCreate();
const { commissions, transaction, simulatingTransaction } = storeToRefs(
  transactionCreateStore
);
const dialog = useDialogStore();

const emit = defineEmits(["refresh"]);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Agent",
      value: "agent",
      map: "appointmentCase.advisor.name"
    }),
    new TableHeader({
      text: "Payable",
      value: "payable",
      map: "payable.name"
    }),
    new TableHeader({
      text: "Assigned Payable",
      value: "assignedPayable",
      map: "assignedPayable.name"
    }),
    new TableHeader({
      text: "Payor",
      value: "payor",
      map: "payor.name"
    }),
    new TableHeader({
      text: "Payment",
      value: "payment",
      map: "paymentPercent"
    }),
    new TableHeader({
      text: "Coverage",
      value: "coverage",
      map: "coverage"
    }),
    new TableHeader({
      text: "Actions",
      value: "data-table-expand",
      map: "data-table-expand"
    })
  ]
});

watch(
  commissions,
  () => {
    table.items.value.splice(0, table.items.value.length);

    commissions.value.forEach(item => {
      const actions = [
        {
          icon: mdiPencil,
          text: "Edit Commission",
          action: () => edit(item)
        }
      ];

      if (!item.paid) {
        actions.push({
          icon: mdiDelete,
          text: "Delete Commission",
          action: () => destroy(item)
        });
      }

      table.items.value.push({
        ...item,
        key: JSON.stringify(item),
        isErrored: Boolean(item.errors),
        actions,
        rowClass: item.errors ? "bg-error text-white" : null
      });
    });
  },
  { deep: true, immediate: true }
);

function destroy(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Commission?",
    subtitle: "This cannot be undone",
    func: async () => {
      await deleteCommission(item.id);
      emit("refresh");
    }
  });
}

async function edit(item) {
  const value = Commission({
    id: item.id,
    policy: transaction.value.policy,
    advisor: item.appointmentCase.advisor,
    payable: item.payable,
    assignedPayable: item.assignedPayable,
    payor: item.payor,
    appointmentCase: item.appointmentCase,
    commissionType: item.commissionType,
    paymentType: item.paymentType,
    percent: item.percent * 100,
    startYear: item.startYear,
    endYear: item.endYear
  });

  const res = await dialog.showDialog({
    component: markRaw(CommissionDialog),
    modelValue: value
  });

  if (res?.commission) emit("refresh");
}
</script>
