<template>
  <chat-view
    title="Comp Builder Chat"
    :card-props="{ color: 'transparent', tile: false, flat: true }"
    :pusher-id="pusherId"
    :messages="messages"
    :create-func="sendChatMessage"
    @remove-message="removeFromMessages"
    @new-note="newNote"
  />
</template>

<script setup>
import {
  Note,
  NoteToCreateRequest,
  setNoteFromRequest
} from "@/models/Note";
import ChatView from "@/components/shared/chat/ChatView.vue";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed } from "vue";
import { useCarrierView } from "@/stores/carrier-view";

const props = defineProps({
  compBuilderKey: { type: String, required: true }
});

const snackbar = useSnackbarStore();
const user = useUserStore();
const carrierView = useCarrierView();

const { loginable } = storeToRefs(user);
const { compBuilders } = storeToRefs(carrierView);

const compBuilder = computed(
  () => compBuilders.value.by_id[props.compBuilderKey]
);

const pusherId = computed(() => `private-${compBuilder.value.id}-CompBuilder`);

const messages = computed(() => {
  const notes = compBuilder.value.notes.toSorted(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  return [
    {
      note: `Hi ${loginable.value.name}, feel free to make notes here.`,
      ownable: { name: "BackNine" }
    },
    ...notes
  ];
});

function removeFromMessages({ id }) {
  const index = compBuilders.value.by_id[props.compBuilderKey].notes.findIndex(
    m => m.id === id
  );
  if (index === -1) return;
  compBuilders.value.by_id[props.compBuilderKey].notes.splice(index, 1);
}

async function sendChatMessage(note) {
  const newNote = Note({
    documents: note.files,
    email: note.sendEmail,
    phoneCall: note.sendPhoneCall,
    note: note.message,
    notableId: compBuilder.value.id,
    notableType: "CompBuilder"
  });
  try {
    const res = await createNote(NoteToCreateRequest(newNote));
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}
function newNote({ note: rawNote }) {
  compBuilders.value.by_id[props.compBuilderKey].notes.push(
    setNoteFromRequest(rawNote)
  );
}
</script>
