<template>
  <v-data-table
    v-if="niprSubscribed"
    class="transparent-data-table"
    data-testid="advisor-profile-licenses-table"
    mobile-breakpoint="sm"
    item-class="rowClass"
    :mobile="null"
    :loading="fetchingLicenses"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :disable-pagination="true"
    hide-default-footer
    :items-per-page="-1"
  >
    <template #[`item.availableForQuoteAndApply`]="{ item }">
      <v-tooltip v-if="item.availableForQuoteAndApply" location="top">
        <template #activator="{ props }">
          <v-icon
            data-testid="is-quote-and-apply-ready"
            v-bind="props"
            color="accent"
            :icon="mdiCheck"
          />
        </template>
        <span> This license is usable in Quote & Apply </span>
      </v-tooltip>
    </template>
  </v-data-table>
  <v-alert v-else type="info" class="ma-3" data-testid="nipr-subscribed-alert">
    {{ name }} is not subscribed to NIPR updates due to inactivity.
    <a
      v-if="canRefetchLicenses"
      class="text-white text-decoration-underline"
      @click="advisorView.refetchLicenses"
    >
      Click here to subscribe.
    </a>
  </v-alert>
</template>

<script setup>
import { mdiCheck } from "@mdi/js";
import TableHeader from "@/classes/data-table/TableHeader";

import { watch } from "vue";
import { parseErrorMessage, timestampFormatter } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useTable } from "@/composables/table.composable";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useUserStore } from "@/stores/user";

const snackbar = useSnackbarStore();
const advisorView = useAdvisorProfileView();
const user = useUserStore();

const { name, licenses, fetchingLicenses, niprSubscribed } =
  storeToRefs(advisorView);

const canRefetchLicenses = user.isGroupTwoPlus;

const table = useTable({
  headers: [
    new TableHeader({ text: "State", value: "state", map: "state" }),
    new TableHeader({ text: "Number", value: "number", map: "number" }),
    new TableHeader({ text: "Type(s)", value: "typeText", map: "typeText" }),
    new TableHeader({
      text: "Effective",
      value: "effectiveDate",
      map: "effectiveDate"
    }),
    new TableHeader({
      text: "Expiration",
      value: "expirationDate",
      map: "expirationDate"
    }),
    new TableHeader({
      text: "Updated",
      value: "updatedAt",
      map: "updatedAt"
    }),
    new TableHeader({
      text: "Quote & Apply",
      value: "availableForQuoteAndApply",
      map: "availableForQuoteAndApply",
      align: "center"
    })
  ]
});

async function getData() {
  if (!niprSubscribed.value) return;
  try {
    await advisorView.getLicenses();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function setItems() {
  table.items.value.splice(0, table.items.value.length);
  licenses.value.forEach(item => {
    table.items.value.push({
      ...item,
      state: `${item.state} ${item.active ? "" : "- (Inactive)"}`,
      expirationDate: timestampFormatter(item.expirationDate, "sole-day"),
      effectiveDate: timestampFormatter(item.effectiveDate, "sole-day"),
      updatedAt: timestampFormatter(item.updatedAt),
      rowClass: item.active ? "" : "warning text-white",
      key: item.id
    });
  });
}

watch(licenses, setItems, { immediate: true, deep: true });

getData();
</script>
