import { getHttpClient } from "@/http-client";
import {
  setContractingQuestionExplanationFromRaw,
  setRawFromContractingQuestionExplanation
} from "@/models/AgentSettings";
const baseURL = "api/boss/explanations";

export async function createExplanation(questionId, explanation) {
  const body = {
    contracting_answer_id: questionId,
    ...setRawFromContractingQuestionExplanation(explanation)
  };

  const { data } = await getHttpClient().post(baseURL, { explanation: body });
  return setContractingQuestionExplanationFromRaw(data.explanation);
}

export async function updateExplanation(explanationId, explanation) {
  await getHttpClient().put(`${baseURL}/${explanationId}`, {
    explanation: setRawFromContractingQuestionExplanation(explanation)
  });

  return explanation;
}

export function deleteExplanation(explanationId) {
  return getHttpClient().delete(`${baseURL}/${explanationId}`);
}
