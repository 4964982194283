<template>
  <v-card flat color="section">
    <v-card-title>Website Learning Series</v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <v-list style="width: 100%" class="py-0 larger-list">
          <v-list-item
            v-for="{ key, title, subtitle, link, completed } in tutorialItems"
            :key="key"
            :to="completed ? undefined : link"
            exact
          >
            <template #prepend>
              <v-icon
                v-if="completed"
                class="opacity-100"
                color="success"
                style="font-size: 24px"
                :icon="mdiCheckboxMarkedCircleOutline"
              />
              <v-icon
                v-else
                color="error"
                class="opacity-100"
                style="font-size: 24px"
                :icon="mdiCircleOffOutline"
              />
            </template>

            <v-list-item-title>
              {{ title }}
              <span class="text-grey ml-1">
                {{ completed ? "(Complete)" : "(Incomplete)" }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle> {{ subtitle }} </v-list-item-subtitle>

            <template #append>
              <v-tooltip v-if="completed" location="top">
                <template #activator="{ props: templateProps }">
                  <app-button
                    v-bind="templateProps"
                    :icon="mdiRefresh"
                    density="comfortable"
                    variant="text"
                    :data-testid="`reset-tutorial-${title}`"
                    @click="resetTutorial({ title, key })"
                  />
                </template>
                <span> Reset {{ title }} </span>
              </v-tooltip>
            </template>
          </v-list-item>
        </v-list>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { AVAILABLE_SERIES } from "@/composables/series-composable";
import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { computed, markRaw } from "vue";
import {
  mdiCheckboxMarkedCircleOutline,
  mdiCircleOffOutline,
  mdiRefresh
} from "@mdi/js";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const user = useUserStore();
const { tutorials } = storeToRefs(user);
const dialog = useDialogStore();
const tutorialItems = computed(() => {
  return [
    {
      title: "Welcome Tour",
      subtitle:
        "On first login, users are prompted with a brief explanation on the first page and site navigation",
      key: "home",
      completed: Boolean(tutorials.value.home),
      link: {
        name: "Home"
      }
    },
    {
      title: "Walkthrough",
      subtitle:
        "One of the onboarding items. The walkthrough explains available tooling, contracting, and Quote & Apply",
      key: "series_walkthrough",
      completed: Boolean(tutorials.value.series_walkthrough),
      link: {
        name: "HelpCenterSeries",
        params: { id: AVAILABLE_SERIES.WALKTHROUGH }
      }
    }
  ];
});

async function resetTutorial({ title, key }) {
  await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Reset ${title}?`,
    subtitle: `This will mark ${title} as incomplete`,
    func: () => {
      tutorials.value[key] = false;
      return user.saveTutorials();
    }
  });
}
</script>
