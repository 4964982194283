import { ContractParty } from "@/models/ContractParty";
import {
  getContractParty,
  updateContractParty,
  deleteContractParty,
  getSensitiveContractPartyInformation,
  updateContractPartyBuild,
  updateContractPartyDriversLicense,
  updateContractPartyEmployer,
  updateContractPartyHomeAddress,
  updateContractPartyOccupation,
  updateContractPartyAccess,
  createContractPartyAccess,
  removeContractPartyAccess
} from "@/api/contract-party.service";

import { defineStore } from "pinia";

export const useContractPartyView = defineStore("contract-party-view", {
  state: () => ({ ...ContractParty(), todoCount: 0 }),
  getters: {
    isIndividual() {
      return this.type === "Individual";
    },
    isEntity() {
      return this.type === "Entity";
    },
    displayName() {
      if (this.isIndividual) {
        return this.firstName + " " + this.lastName;
      } else return this.name;
    }
  },
  actions: {
    async initialize(id) {
      const typelessClient = await getContractParty(id);
      this.$patch(ContractParty(typelessClient));
    },
    fetchSensitiveInfo() {
      return getSensitiveContractPartyInformation(this.id);
    },
    deleteContractParty() {
      return deleteContractParty(this.id);
    },
    createAccess(advisor) {
      const body = {
        email: true,
        agent: false,
        ownable_id: advisor.id,
        ownable_type: advisor.type
      };

      return createContractPartyAccess(this.id, body);
    },
    updateAccess(accessId, attribute) {
      const access = this.room.find(a => a.contractPartyAccessId === accessId);
      if (!access) return;
      const body = {
        email: { email: access.email },
        agent: { agent: access.agent }
      }[attribute];

      return updateContractPartyAccess(this.id, accessId, body);
    },
    deleteAccess(accessId) {
      const access = this.room.find(a => a.contractPartyAccessId === accessId);
      if (!access) return;

      return removeContractPartyAccess(this.id, accessId);
    },
    addressBody(addressObj, attributes) {
      return attributes.reduce((acc, attr) => {
        const value = {
          street: { street_address: addressObj?.street_address },
          city: { city: addressObj?.city },
          state: { state: addressObj?.state },
          zip: { zip: addressObj?.zip }
        }[attr];

        return { ...acc, ...value };
      }, {});
    },
    async updateOccupation(body) {
      const res = await updateContractPartyOccupation(this.id, body);
      this.occupationId = res.id;
      return res;
    },
    baseAttributes() {
      const attributes = {
        assets: { assets: this.assets },
        birthdate: { birthdate: this.birthdate },
        email: { email: this.email },
        firstName: { first_name: this.firstName },
        gender: { gender: this.gender },
        income: { income: this.income },
        lastName: { last_name: this.lastName },
        liabilities: { liabilities: this.liabilities },
        maritalStatus: { marital_status: this.maritalStatus },
        middleName: { middle_name: this.middleName },
        phoneMobile: { phone_mobile: this.phoneMobile },
        phoneWork: { phone_work: this.phoneWork },
        ssn: { party_id: this.ssn }
      };

      return {
        attributes,
        update: attribute => updateContractParty(this.id, attributes[attribute])
      };
    },
    employerAttributes() {
      const employerAddress = attrs => ({
        employer_address: this.addressBody(this.employerAddress, attrs)
      });

      const attributes = {
        employerName: { employer_name: this.employerName },
        employerAddress: employerAddress(["street", "city", "state", "zip"]),
        employerCity: employerAddress(["city"]),
        employerState: employerAddress(["state"]),
        employerStreet: employerAddress(["street"]),
        employerZip: employerAddress(["zip"])
      };

      return {
        attributes,
        update: attribute =>
          updateContractPartyEmployer(this.id, attributes[attribute])
      };
    },
    occupationAttributes() {
      const attributes = {
        occupation: { occupation: this.occupation }
      };
      return {
        attributes,
        update: attribute => this.updateOccupation(attributes[attribute])
      };
    },
    driversLicenseAttributes() {
      const attributes = {
        driversLicenseNumber: {
          drivers_license_number: this.driversLicenseNumber || null
        },
        driversLicenseState: {
          drivers_license_state: this.driversLicenseState || null
        }
      };

      return {
        attributes,
        update: attribute =>
          updateContractPartyDriversLicense(this.id, attributes[attribute])
      };
    },
    buildAttributes() {
      const attributes = {
        height: { height: this.height },
        weight: { weight: this.weight }
      };

      return {
        attributes,
        update: attribute =>
          updateContractPartyBuild(this.id, attributes[attribute])
      };
    },
    homeAddressAttributes() {
      const homeAddress = attrs => this.addressBody(this.homeAddress, attrs);

      const attributes = {
        homeAddress: homeAddress(["street", "city", "state", "zip"]),
        homeStreet: homeAddress(["street"]),
        homeCity: homeAddress(["city"]),
        homeState: homeAddress(["state"]),
        homeZip: homeAddress(["zip"])
      };

      return {
        attributes,
        update: attribute =>
          updateContractPartyHomeAddress(this.id, attributes[attribute])
      };
    },
    updateIndividualAttribute(attribute) {
      const groups = [
        this.baseAttributes(),
        this.employerAttributes(),
        this.occupationAttributes(),
        this.driversLicenseAttributes(),
        this.buildAttributes(),
        this.homeAddressAttributes()
      ];

      const requestConfig = groups.find(group => {
        return attribute in group.attributes;
      });
      if (!requestConfig) throw "Invalid attribute";
      return requestConfig.update(attribute);
    },
    updateEntityAttribute(attribute) {
      const businessAddress = attrs => {
        return {
          addresses_attributes: [
            {
              id: this.businessAddress.id,
              ...this.addressBody(this.businessAddress, attrs)
            }
          ]
        };
      };

      const body = {
        formationDate: { birthdate: this.formationDate },
        email: { email: this.email },
        name: { name: this.name },
        irrevocable: { irrevocable: this.irrevocable },
        tin: { party_id: this.tin },
        phoneWork: { phone_work: this.phoneWork },
        businessAddress: businessAddress(["street", "city", "state", "zip"]),
        businessStreet: businessAddress(["street"]),
        businessCity: businessAddress(["city"]),
        businessState: businessAddress(["state"]),
        businessZip: businessAddress(["zip"])
      }[attribute];

      return updateContractParty(this.id, body);
    }
  }
});
