import { setNoteFromRequest } from "@/models/Note";
import { Address, setAddressFromRaw } from "@/models/Address";

import { getRouterLink } from "@/util/helpers";

export function AdvisorProfile(model = {}) {
  return {
    accountableId: model?.accountableId || "",
    accountableName: model?.accountableName || "",
    accountableRouterLink: model?.accountableRouterLink || null,
    accountableType: model?.accountableType || "",
    advisorCount: model?.advisorCount || 0,
    approvedDomainUrl: model?.approvedDomainUrl || "",
    assignsToId: model?.assignsToId || null,
    assignsToIsSigner: model?.assignsToIsSigner || false,
    assignsToName: model?.assignsToName || "",
    assignsToRouterLink: model?.assignsToRouterLink || null,
    assignsToType: model?.assignsToType || "",
    avatar: model?.avatar || "",
    businessAddress: Address(model?.businessAddress),
    description: model?.description || "",
    discovery: model?.discovery || "",
    deceasedAt: model?.deceasedAt || null,
    email: model?.email || "",
    firstUsersSource: model?.firstUsersSource || "", // agent only
    goals: AdvisorProfileGoals(model?.goals),
    id: model?.id || 0,
    marketingManagerId: model?.marketingManagerId || null,
    marketingManagerName: model?.marketingManagerName || "",
    niprSubscribed: Boolean(model?.niprSubscribed),
    name: model?.name || "",
    notes: model?.notes || [],
    npn: model?.npn || 0,
    phoneMobile: model?.phoneMobile || "",
    phoneWork: model?.phoneWork || "",
    referralCode: model?.referralCode || "",
    signerId: model?.signerId || null,
    signerName: model?.signerName || null,
    signerRouterLink: model?.signerRouterLink || null,
    signerType: model?.signerType || null,
    title: model?.title || "", // agent only
    type: model?.type || null
  };
}

export function AdvisorProfileAdapter(unmappedProfile) {
  let profile;
  if (unmappedProfile.type === "Agent") {
    profile = AgentProfile(unmappedProfile);
  } else if (unmappedProfile.type === "Agency") {
    profile = AgencyProfile(unmappedProfile);
  } else {
    throw new Error("Invalid profile type");
  }

  const model = AdvisorProfile();
  model.accountableId = profile.accountableId;
  model.accountableName = profile.accountableName;
  model.accountableRouterLink = profile.accountableRouterLink;
  model.accountableType = profile.accountableType;
  model.advisorCount = profile.advisorCount;
  model.approvedDomainUrl = profile.approvedDomainUrl;
  model.assignsToId = profile.assignsToId;
  model.assignsToIsSigner = profile.assignsToIsSigner;
  model.assignsToName = profile.assignsToName;
  model.assignsToRouterLink = profile.assignsToRouterLink;
  model.assignsToType = profile.assignsToType;
  model.avatar = profile.avatar;
  model.businessAddress = profile.businessAddress;
  model.deceasedAt = profile.deceasedAt;
  model.description = profile.description;
  model.discovery = profile.discovery;
  model.email = profile.email;
  model.goals = profile.goals;
  model.id = profile.id;
  model.marketingManagerId = profile.marketingManagerId;
  model.marketingManagerName = profile.marketingManagerName;
  model.name = profile.name;
  model.niprSubscribed = Boolean(profile.niprSubscribed);
  model.notes = profile.notes;
  model.npn = profile.npn;
  model.phoneMobile = profile.phoneMobile;
  model.phoneWork = profile.phoneWork;
  model.referralCode = profile.referralCode;
  model.signerId = profile.signerId;
  model.signerName = profile.signerName;
  model.signerRouterLink = profile.signerRouterLink;
  model.signerType = profile.signerType;
  model.type = profile.type;

  if (model.type === "Agent") {
    model.firstUsersSource = profile.firstUsersSource;
    model.title = profile.title;
  }

  return model;
}

export function AgentProfile(model = {}) {
  return {
    accountableId: model?.accountableId || "",
    accountableName: model?.accountableName || "",
    accountableRouterLink: model?.accountableRouterLink || null,
    accountableType: model?.accountableType || "",
    advisorCount: model?.advisorCount || 0,
    approvedDomainUrl: model?.approvedDomainUrl || "",
    assignsToId: model?.assignsToId || null,
    assignsToIsSigner: model?.assignsToIsSigner || false,
    assignsToName: model?.assignsToName || "",
    assignsToRouterLink: model?.assignsToRouterLink || null,
    assignsToType: model?.assignsToType || "",
    avatar: model?.avatar || "",
    businessAddress: Address(model?.businessAddress),
    description: model?.description || "",
    deceasedAt: model?.deceasedAt || null,
    discovery: model?.discovery || "",
    email: model?.email || "",
    firstUsersSource: model?.firstUsersSource || "", // agent only
    goals: AdvisorProfileGoals(model?.goals),
    id: model?.id || 0,
    marketingManagerId: model?.marketingManagerId || null,
    marketingManagerName: model?.marketingManagerName || "",
    name: model?.name || "",
    niprSubscribed: Boolean(model?.niprSubscribed),
    notes: model?.notes || [],
    npn: model?.npn || 0,
    phoneMobile: model?.phoneMobile || "",
    phoneWork: model?.phoneWork || "",
    referralCode: model?.referralCode || "",
    signerId: model?.signerId || null,
    signerName: model?.signerName || null,
    signerRouterLink: model?.signerRouterLink || null,
    signerType: model?.signerType || null,
    title: model?.title || "", // agent only
    type: "Agent"
  };
}

export function setAgentProfileFromRequest(raw = {}) {
  const model = AgentProfile();

  model.accountableId = raw?.accountable?.id;
  model.accountableName = raw?.accountable?.name;
  model.accountableType = raw?.accountable?.type;
  model.advisorCount = raw?.advisor_count;
  model.approvedDomainUrl = raw?.approved_domain_url;
  model.assignsToId = raw?.assigns?.id;
  model.assignsToIsSigner = Boolean(raw?.assigns?.signer);
  model.assignsToName = raw?.assigns?.name;
  model.assignsToType = raw?.assigns?.type;
  model.avatar = raw?.avatar_url;
  model.deceasedAt = raw?.deceased_at;
  model.description = raw?.description;
  model.discovery = raw?.discovery;
  model.email = raw?.email;
  model.firstUsersSource = raw?.first_users_source;
  model.id = raw?.id;
  model.marketingManagerId = raw?.marketing_manager?.id;
  model.marketingManagerName = raw?.marketing_manager?.name;
  model.name = `${raw.first_name} ${raw.last_name}`;
  model.niprSubscribed = Boolean(raw?.nipr_subscribed);
  model.npn = raw?.npn;
  model.phoneMobile = raw?.phones?.mobile;
  model.phoneWork = raw?.phones?.work;
  model.referralCode = raw?.referral_uid;
  model.signerId = raw?.signer?.id;
  model.signerName = raw?.signer?.name;
  model.signerType = raw?.signer?.type;
  model.title = raw?.title;

  if (model.signerType && model.signerId) {
    model.signerRouterLink = getRouterLink(model.signerType, model.signerId);
  }

  if (model.accountableType && model.accountableId) {
    model.accountableRouterLink = getRouterLink(
      model.accountableType,
      model.accountableId
    );
  }

  if (model.assignsToType && model.assignsToId) {
    model.assignsToRouterLink = getRouterLink(
      model.assignsToType,
      model.assignsToId
    );
  }

  if (raw?.addresses) {
    model.businessAddress = setAddressFromRaw(
      raw.addresses.find(a => a.address_type === "business")
    );
  }

  if (raw?.goals) {
    model.goals = setAdvisorProfileGoalsFromRaw(raw.goals);
  }

  model.notes = raw?.notes?.map(setNoteFromRequest);

  return model;
}

export function AgencyProfile(model = {}) {
  return {
    accountableId: model?.accountableId || "",
    accountableName: model?.accountableName || "",
    accountableRouterLink: model?.accountableRouterLink || null,
    accountableType: model?.accountableType || "",
    advisorCount: model?.advisorCount || 0,
    assignsToId: model?.assignsToId || null,
    assignsToIsSigner: model?.assignsToIsSigner || false,
    assignsToName: model?.assignsToName || "",
    assignsToRouterLink: model?.assignsToRouterLink || null,
    assignsToType: model?.assignsToType || "",
    avatar: model?.avatar || "",
    businessAddress: Address(model?.businessAddress),
    description: model?.description || "",
    discovery: model?.discovery || "",
    email: model?.email || "",
    goals: AdvisorProfileGoals(model?.goals),
    id: model?.id || 0,
    marketingManagerId: model?.marketingManagerId || null,
    marketingManagerName: model?.marketingManagerName || "",
    name: model?.name || "",
    niprSubscribed: Boolean(model?.niprSubscribed),
    notes: model?.notes || [],
    npn: model?.npn || 0,
    phoneMobile: model?.phoneMobile || "",
    phoneWork: model?.phoneWork || "",
    referralCode: model?.referralCode || "",
    signerId: model?.signerId || null,
    signerName: model?.signerName || null,
    signerRouterLink: model?.signerRouterLink || null,
    signerType: model?.signerType || null,
    type: "Agency"
  };
}

export function setAgencyProfileFromRequest(raw = {}) {
  const model = AgencyProfile();
  model.accountableId = raw?.accountable?.id;
  model.accountableName = raw?.accountable?.name;
  model.accountableType = raw?.accountable?.type;
  model.advisorCount = raw?.advisor_count;
  model.assignsToId = raw?.assigns?.id;
  model.assignsToName = raw?.assigns?.name;
  model.assignsToType = raw?.assigns?.type;
  model.assignsToIsSigner = Boolean(raw?.assigns?.signer);
  model.avatar = raw?.avatar_url;

  model.npn = raw?.npn;
  model.marketingManagerName = raw?.marketing_manager?.name;
  model.marketingManagerId = raw?.marketing_manager?.id;
  model.description = raw?.description;
  model.discovery = raw?.discovery;
  model.email = raw?.email;
  model.name = raw?.name;
  model.niprSubscribed = raw?.nipr_subscribed;
  model.phoneMobile = raw?.phones?.mobile;
  model.phoneWork = raw?.phones?.work;
  model.id = raw?.id;
  model.referralCode = raw?.referral_uid;
  model.signerId = raw?.signer?.id;
  model.signerName = raw?.signer?.name;
  model.signerType = raw?.signer?.type?.model;

  if (model.signerType) {
    model.signerRouterLink = getRouterLink(model.signerType, model.signerId);
  }

  if (model.accountableRouterLink) {
    model.accountableRouterLink = getRouterLink(
      model.accountableType,
      model.accountableId
    );
  }

  if (model.assignsToRouterLink) {
    model.assignsToRouterLink = getRouterLink(
      model.assignsToType,
      model.assignsToId
    );
  }

  if (raw?.addresses) {
    model.businessAddress = setAddressFromRaw(
      raw.addresses.find(a => a.address_type === "business")
    );
  }

  if (raw?.goals) {
    model.goals = setAdvisorProfileGoalsFromRaw(raw.goals);
  }

  model.notes = raw?.notes?.map(rawNote => setNoteFromRequest(rawNote));

  return model;
}

function AdvisorProfileGoals(model = {}) {
  return {
    placedCases: model?.placedCases || 0,
    placedCasesGoal: model?.placedCasesGoal || 0,
    placementRatio: model?.placementRatio || 0,
    placementRatioGoal: model?.placementRatioGoal || 0,
    pendingCases: model?.pendingCases || 0,
    pendingCasesGoal: model?.pendingCasesGoal || 0,
    yearToDatePlacedCases: model?.yearToDatePlacedCases || 0,
    yearToDatePlacedCasesGoal: model?.yearToDatePlacedCasesGoal || 0,

    pendingAllProductionCredit: model?.pendingAllProductionCredit || 0,
    pendingLifeProductionCredit: model?.pendingLifeProductionCredit || 0,
    pendingAnnuityProductionCredit: model?.pendingAnnuityProductionCredit || 0,
    pendingLtcProductionCredit: model?.pendingLtcProductionCredit || 0,
    pendingDisabilityProductionCredit:
      model?.pendingDisabilityProductionCredit || 0,
    activeAllProductionCredit: model?.activeAllProductionCredit || 0,
    activeLifeProductionCredit: model?.activeLifeProductionCredit || 0,
    activeAnnuityProductionCredit: model?.activeAnnuityProductionCredit || 0,
    activeLtcProductionCredit: model?.activeLtcProductionCredit || 0,
    activeDisabilityProductionCredit:
      model?.activeDisabilityProductionCredit || 0
  };
}

function setAdvisorProfileGoalsFromRaw(raw = {}) {
  const goals = AdvisorProfileGoals();

  const misc = raw?.misc;
  if (misc) {
    goals.placedCases = misc.placed_cases;
    goals.pendingCases = misc.pending_cases;
    goals.placementRatio = misc.placement_ratio;
    goals.yearToDatePlacedCases = misc.ytd_placed_cases;
  }

  const pending = raw?.individual?.pending;

  if (pending) {
    goals.pendingAllProductionCredit = pending.all;
    goals.pendingLifeProductionCredit = pending.life;
    goals.pendingAnnuityProductionCredit = pending.annuity;
    goals.pendingLtcProductionCredit = pending.ltc;
    goals.pendingDisabilityProductionCredit = pending.disability;
  }

  const active = raw?.individual?.active;

  if (active) {
    goals.activeAllProductionCredit = active.all;
    goals.activeLifeProductionCredit = active.life;
    goals.activeAnnuityProductionCredit = active.annuity;
    goals.activeLtcProductionCredit = active.ltc;
    goals.activeDisabilityProductionCredit = active.disability;
  }

  return goals;
}
