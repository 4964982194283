<template>
  <div class="pa-3 bg-section">
    <chip-group-field
      v-model="categories"
      class="mb-3"
      v-bind="categoriesValidation"
      label="Categories"
      @update:model-value="updateCategories"
    >
      <template #label-append>
        <active-save-indicator
          class="ml-3"
          :controller="savingBuffer.categories.controller.value"
        />
      </template>
      <template #chips>
        <v-chip
          v-for="category in SUPPORT_TICKET_CATEGORIES"
          :key="category"
          :value="category"
          :text="category"
          :data-testid="`details-category-${category}`"
        />
      </template>
    </chip-group-field>

    <textarea-field
      v-model="description"
      label="Description"
      data-testid="details-Description"
      v-bind="descriptionValidation"
      @update:model-value="updateDescription"
    >
      <template #append-inner>
        <active-save-indicator
          :controller="savingBuffer.description.controller.value"
        />
      </template>
    </textarea-field>
    <agent-search
      v-model="assignable"
      label="Assignable"
      data-testid="details-Assignable"
      better-return
      v-bind="assignableValidation"
      @update:model-value="updateAssignable"
    >
      <template #append-inner>
        <active-save-indicator
          :controller="savingBuffer.assignable.controller.value"
        />
      </template>
    </agent-search>
  </div>
</template>

<script setup>
import ChipGroupField from "@/components/shared/ChipGroupField.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { SUPPORT_TICKET_CATEGORIES } from "@/models/SupportTicket";
import { useSupportTicketView } from "@/stores/support-ticket-view";
import { useActiveSave } from "@/composables/active-save.composable";
import { storeToRefs } from "pinia";
import useVuelidate from "@vuelidate/core";
import { someTextValidator, computedValidation } from "@/util/helpers";

const supportTicketView = useSupportTicketView();

const { description, assignable, categories } = storeToRefs(supportTicketView);

const v$ = useVuelidate(
  {
    description: {
      required: v => someTextValidator(true, v, 2)
    },
    assignable: {
      required: v => Boolean(v?.id)
    },
    categories: {
      required: v => Boolean(v?.length)
    }
  },
  {
    description,
    assignable,
    categories
  },
  {
    $scope: null,
    $autoDirty: true
  }
);

const descriptionValidation = computedValidation(v$.value.description, {
  required: "Required"
});
const assignableValidation = computedValidation(v$.value.assignable, {
  required: "Required"
});
const categoriesValidation = computedValidation(v$.value.categories, {
  required: "Required"
});

const savingBuffer = {
  description: useActiveSave(),
  assignable: useActiveSave(),
  categories: useActiveSave()
};

function updateDescription() {
  savingBuffer.description.debounceUpdate(() => {
    if (!descriptionValidation.value.success) return;
    return supportTicketView.updateDescription();
  });
}

function updateAssignable() {
  savingBuffer.assignable.debounceUpdate(() => {
    if (!assignableValidation.value.success) return;
    return supportTicketView.updateAssignable();
  });
}

function updateCategories() {
  savingBuffer.categories.debounceUpdate(() => {
    if (!categoriesValidation.value.success) return;
    return supportTicketView.updateCategories();
  });
}
</script>
