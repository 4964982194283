import { getHttpClient } from "@/http-client";
import { setPaymentTableItemFromRequest } from "@/models/Payment";

const baseUrl = "api/boss/payments";
export function createPayment(params) {
  return getHttpClient().post(`${baseUrl}`, params);
}

export async function getPayments(params, cancelToken) {
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  const { data } = res;

  return {
    items: data.payments.map(setPaymentTableItemFromRequest),
    meta: data.meta,
    stats: data.meta.stats
  };
}
export function emailPayments(body) {
  return getHttpClient().post(`${baseUrl}/email`, body);
}
