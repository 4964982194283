<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    item-value="additional.id"
    data-testid="affiliations-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :search="affiliationsTable.search"
    :footer-props="table.footerProps.value"
  >
    <template #top>
      <v-card class="pa-3 mx-3" flat>
        <v-row class="ma-0" align="center">
          <h1 class="text-h5">Affiliations</h1>
          <v-spacer />
          <text-field
            v-model="affiliationsTable.search"
            data-testid="affiliations-table-search"
            data-lpignore="true"
            variant="outlined"
            dense
            label="Search"
            :prepend-inner-icon="mdiMagnify"
            hide-details
          />
          <app-button
            data-testid="affiliations-table-create"
            variant="text"
            class="table-btn"
            density="comfortable"
            :icon="mdiPlusCircle"
            @click="showCreateAffiliationDialog"
          />
        </v-row>
      </v-card>
    </template>
    <template
      #[`item.data-table-expand`]="{
        item,
        isExpanded,
        toggleExpand,
        internalItem
      }"
    >
      <app-button
        :icon="mdiPencil"
        color="success"
        variant="text"
        density="comfortable"
        data-testid="edit"
        @click="editAffiliation(item.additional)"
      />
      <app-button
        :icon="mdiDelete"
        color="error"
        variant="text"
        density="comfortable"
        data-testid="delete"
        @click="destroyAffiliation(item)"
      />
      <app-button
        :icon="isExpanded(internalItem) ? mdiChevronUp : mdiChevronDown"
        data-testid="expand"
        size="small"
        variant="text"
        density="comfortable"
        @click="toggleExpand(internalItem)"
      />
    </template>
    <template #expanded-row="{ columns, item }">
      <td :colspan="columns.length" class="py-4">
        <advisors-table
          :affiliation="{ name: item.additional.name, id: item.additional.id }"
        />
      </td>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";

import AdvisorsTable from "@/components/advisors/AdvisorsTable.vue";
import { deleteAffiliation, getAffiliations } from "@/api/affiliation.service";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import {
  mdiMagnify,
  mdiPlusCircle,
  mdiPencil,
  mdiDelete,
  mdiChevronUp,
  mdiChevronDown
} from "@mdi/js";
import { markRaw } from "vue";
import CreateAffiliationDialog from "@/dialogs/CreateAffiliationDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Linked Advisor",
      value: "ownable",
      map: "ownable.name",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "data-table-expand"
    })
  ]
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

async function getData() {
  try {
    const items = await getAffiliations();
    table.items.value.splice(0, table.items.value.length);
    table.items.value.push(...items);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function showCreateAffiliationDialog() {
  const res = await dialog.showDialog({
    component: markRaw(CreateAffiliationDialog)
  });
  if (res?.created) getData();
}

async function editAffiliation(affiliation) {
  const res = await dialog.showDialog({
    component: markRaw(CreateAffiliationDialog),
    affiliation
  });
  if (res?.created) getData();
}

async function destroyAffiliation(item) {
  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    func: () => deleteAffiliation(item.additional.id),
    title: "Are you sure that you want to delete this Affiliation?",
    subtitle: "This cannot be undone and can mess up agents affiliations"
  });
  if (res?.confirm) getData();
}

getData();

const { affiliationsTable } = storeToRefs(useTableStore());
</script>
