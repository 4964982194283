import { getHttpClient } from "@/http-client";
import { setOccupationFromRaw } from "@/models/AgentSettings";
const baseUrl = "api/boss/occupations";

export async function createOccupation(occupation) {
  const { data } = await getHttpClient().post(baseUrl, {
    occupation
  });
  return setOccupationFromRaw(data.occupation);
}

export async function updateOccupation(occupationId, occupation) {
  const { data } = await getHttpClient().put(`${baseUrl}/${occupationId}`, {
    occupation
  });
  return setOccupationFromRaw(data.occupation);
}

export function deleteOccupation(occupationId) {
  return getHttpClient().delete(`${baseUrl}/${occupationId}`);
}
