import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/idp";

export async function firelightSamlResponse(eappUuid = null) {
  const params = {}
  if (eappUuid) params.eapp_uuid = eappUuid;
  const { data } = await getHttpClient().get(`${baseUrl}/firelight`, { params });

  return {
    samlUrl: data.saml_url,
    samlResponse: data.saml_response
  };
}
