<template>
  <v-card v-cloak>
    <v-card-title class="px-6 mb-4">
      {{ title }}
    </v-card-title>
    <v-card-text class="py-3">
      <text-field
        v-if="documentId"
        v-model="name"
        label="Name"
        data-testid="document-name"
        :success="nameValidation.success"
        :error-messages="nameValidation.errorMessages"
      />
      <file-drag-and-drop
        v-if="!documentId"
        v-model="file"
        data-testid="file"
        :multiple="multiple"
        :success="fileValidation.success"
        :error-messages="fileValidation.errorMessages"
      />
      <select-field
        v-if="categories.length"
        v-model="category"
        class="mt-4"
        label="Category"
        data-testid="category"
        :items="categories"
        :success="categoryValidation.success"
        :error-messages="categoryValidation.errorMessages"
      />
      <v-alert
        v-if="showCategoryWarning"
        type="warning"
        data-testid="multiple-alert"
      >
        All files will be registered as {{ categoryText }}, please ensure this
        is correct.
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="upload-file-confirm"
        :loading="saving"
        @click="uploadFile"
      >
        {{ props.documentId ? "Save" : "Upload" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import { computed, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation } from "@/util/helpers";

const props = defineProps({
  title: { type: String, default: null },
  accept: { type: String, default: null },
  multiple: Boolean,
  categories: {
    type: Array,
    default: () => []
  },
  documentId: {
    type: [Number, String],
    required: false,
    default: 0
  },
  documentCategoryValue: {
    type: String,
    required: false,
    default: ""
  },
  documentNameValue: {
    type: String,
    required: false,
    default: ""
  },
  func: {
    type: Function,
    required: true
  }
});

const name = ref(props.documentNameValue || "");
const category = ref(props.documentCategoryValue || null);
const file = ref(null);
const saving = ref(false);
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const v$ = useVuelidate(
  {
    name: {
      required: v => Boolean(!props.documentId || v)
    },
    file: {
      required: v => Boolean(props.documentId || v),
      validSize: v => {
        if (props.documentId) return true;
        if (Array.isArray(v)) return !v.some(file => file?.size === 0);
        return v?.size > 0;
      }
    },
    category: {
      required: v => {
        if (!props.categories.length) return true;
        const compareValues = Object.keys(props.categories[0]).includes(
          "value"
        );
        return props.categories.some(c => {
          let value = c;
          if (compareValues) value = c.value;
          return value === v;
        });
      }
    }
  },
  {
    name,
    file,
    category
  }
);

const categoryText = computed(() => {
  if (!props.categories.length) return;
  return props.categories.find(c => {
    if (typeof c === "string") return c === category.value;
    return c.value === category.value;
  })?.text;
});

const showCategoryWarning = computed(() => {
  if (!props.multiple || category.value === undefined) return false;
  if (!Array.isArray(file.value)) return false;
  return file.value.length > 1;
});

const nameValidation = computedValidation(v$.value.name, {
  required: "Required"
});

const fileValidation = computedValidation(v$.value.file, {
  required: "Required"
});

const categoryValidation = computedValidation(
  v$.value.category,
  {
    required: "Required"
  },
  { displaySuccessIfEmpty: true }
);

async function uploadFile() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    saving.value = true;
    await props.func({
      category: category.value,
      file: file.value,
      name: name.value
    });
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
