<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    data-testid="trainings-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :search="search"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5">Trainings</h1>
        <v-spacer />
        <text-field
          v-model="search"
          hide-details
          label="Search"
          data-lpignore="true"
          :prepend-inner-icon="mdiMagnify"
          data-testid="trainings-table-search"
        />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="trainings-table-refresh"
              :loading="table.loading.value"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
    </template>
    <template #[`item.actions`]="{ item }">
      <div class="flex-row flex-nowrap">
        <app-button
          color="accent"
          data-testid="edit-training"
          class="inline-block"
          density="comfortable"
          variant="text"
          :icon="mdiPencil"
          @click="editTraining(item.additional)"
        />
        <app-button
          color="error"
          data-testid="delete-training"
          class="inline-block"
          density="comfortable"
          variant="text"
          :icon="mdiDelete"
          @click="destroyTraining(item.additional)"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script setup>
import { TableOptions } from "@/classes/data-table/TableOptions";
import TableHeader from "@/classes/data-table/TableHeader";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { markRaw, ref } from "vue";
import { mdiDelete, mdiMagnify, mdiPencil, mdiRefresh } from "@mdi/js";
import { getTrainings, deleteTraining } from "@/api/trainings.service";

import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import TrainingDialog from "@/components/trainings/TrainingDialog.vue";

useHead({
  title: "Trainings"
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const search = ref("");

const table = useTable({
  getData: async () => {
    const items = await getTrainings();
    return { items };
  },
  options: TableOptions({ sortBy: [{ key: "name", order: "asc" }] }),
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Instructions",
      value: "instructions",
      map: "instructions",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ]
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function editTraining(training) {
  const res = await dialog.showDialog({
    component: markRaw(TrainingDialog),
    training
  });

  if (!res?.newTraining) return;

  getData();
}

function destroyTraining(training) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Training",
    subtitle: "This cannot be undone",
    func: async () => {
      await deleteTraining(training.id);
      await getData();
    }
  });
}

getData();
</script>
