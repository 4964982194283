<template>
  <autocomplete-field
    v-bind="$attrs"
    ref="inputSearch"
    v-model="item"
    v-model:search="searchText"
    autocomplete="false"
    return-object
    no-filter
    hide-no-data
    :item-title="itemTextGenerator"
    :prepend-inner-icon="prependInnerIcon"
    :loading="loading || externalLoading"
    :placeholder="placeholder"
    :label="label"
    :items="items"
    :clearable="clearable"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
    @click:clear="emit('update:model-value', {})"
  >
    <template
      v-if="$slots['append-inner'] || activeSaveController"
      #append-inner
    >
      <slot name="append-inner" />
      <active-save-indicator
        v-if="activeSaveController"
        :controller="activeSaveController"
      />
    </template>
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }} <v-spacer /> <slot name="message" />
      </v-row>
    </template>
  </autocomplete-field>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import {
  advisorSearch,
  simpleAdvisorSearch,
  simpleAdvisorPaymentSearch,
  advisorAppointmentSearch
} from "@/api/search.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiAccount } from "@mdi/js";
import { ref, toRef, unref, watch } from "vue";

const props = defineProps({
  modelValue: { type: Object, required: false, default: null },
  clearable: {
    type: Boolean,
    default: true
  },
  label: {
    required: false,
    default: "Advisor",
    type: String
  },
  placeholder: {
    required: false,
    default: "Search Advisors",
    type: String
  },
  simple: Boolean,
  employee: Boolean,
  payment: Boolean,
  appointment: Boolean,
  externalLoading: Boolean,
  prependInnerIcon: {
    type: String,
    required: false,
    default: mdiAccount
  },
  carrierId: { type: [Number, String], default: null },
  activeSaveController: {
    type: Object,
    required: false,
    default: null
  }
});
const snackbar = useSnackbarStore();

const modelValue = toRef(props, "modelValue");
const emit = defineEmits(["update:model-value", "blur"]);

const item = ref(null);
const items = ref([]);
const searchText = ref(null);
const loading = ref(false);
const inputSearch = ref(null); // templateref

if (modelValue.value?.id) {
  items.value.push(unref(modelValue));
  item.value = unref(modelValue);
}

async function searchForAdvisor() {
  if (!searchText.value) return;

  if (
    items.value.some(a => itemTextGenerator(a) === searchText.value) &&
    items.value.length === 1
  ) {
    return;
  }

  if (itemTextGenerator(item.value) === searchText.value) return;

  loading.value = true;
  let searchFunc = advisorSearch;
  if (props.payment) {
    searchFunc = simpleAdvisorPaymentSearch;
  } else if (props.simple) {
    searchFunc = simpleAdvisorSearch;
  } else if (props.appointment) {
    searchFunc = value => advisorAppointmentSearch(value, props.carrierId);
  } else if (props.employee) {
    searchFunc = value => advisorSearch(value, { employee: true });
  }
  try {
    const result = await searchFunc(searchText.value);
    items.value.splice(0, items.value.length);
    items.value.push(...result);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

let timer;
function debounceAndSearch() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(searchForAdvisor, 200);
}

function focus() {
  if (!inputSearch.value?.$el) return;
  const input = inputSearch.value.$el.querySelector("input");
  if (!input?.focus) return;
  input.focus();
}

function itemTextGenerator(val) {
  if (!val?.email) return val?.name;
  return `${val.name} · ${val.email}`;
}

watch(item, value => {
  if (!value?.id && !props.clearable) return;
  if (value === props.value) return;
  emit("update:model-value", value);
});

watch(modelValue, value => {
  items.value.splice(0, items.value.length);
  if (value?.id) {
    items.value.push(value);
    item.value = value;
  } else {
    item.value = null;
  }
});

watch(searchText, debounceAndSearch);
defineExpose({ focus });
</script>
