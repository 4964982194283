<template>
  <v-row v-if="updatable" class="ma-0 mx-3">
    <v-spacer />
    <app-button
      class="text-none rounded-pill"
      color="primary"
      data-testid="add-review"
      text="Add Review"
      variant="outlined"
      :prepend-icon="mdiPlus"
      @click="addReview"
    />
  </v-row>
</template>

<script setup>
import EmployeeReviewDialog from "@/components/employees/EmployeeReviewDialog.vue";
import { useDialogStore } from "@/stores/dialog";
import { useEmployeeView } from "@/stores/employee-view";
import { mdiPlus } from "@mdi/js";
import { storeToRefs } from "pinia";
import { markRaw } from "vue";

const employeeView = useEmployeeView();

const { updatable } = storeToRefs(employeeView);
const dialog = useDialogStore();

function addReview() {
  dialog.showDialog({
    component: markRaw(EmployeeReviewDialog),
    persistent: true
  });
}
</script>
