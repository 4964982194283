import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { onBeforeUnmount, watch } from "vue";
import isHoliday, { HOLIDAYS } from "@/util/is-holiday";
import { useTheme } from "vuetify/lib/framework.mjs";

export const useThemeManager = () => {
  const user = useUserStore();
  const { darkMode, id } = storeToRefs(user);

  const theme = useTheme();

  function handleDarkModeChange() {
    if (darkMode.value === null) initAutoDarkMode();
    else destroyAutoDarkMode();
    processDarkModeSelection();
  }

  function initHolidayColors() {
    const holiday = isHoliday();
    if (holiday === HOLIDAYS.VALENTINES) {
      theme.themes.value.light.colors.primary = "#e6a6be";
      theme.themes.value.dark.colors.primary = "#e6a6be";
    }
  }

  function processDarkModeSelection() {
    let dark = darkMode.value;
    if (dark === null) {
      dark = Boolean(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    }

    theme.global.name.value = dark ? "dark" : "light";
  }
  function initAutoDarkMode() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", processDarkModeSelection);
  }
  function destroyAutoDarkMode() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", processDarkModeSelection);
  }

  onBeforeUnmount(destroyAutoDarkMode);

  watch(darkMode, handleDarkModeChange);
  watch(
    id,
    () => {
      handleDarkModeChange();
      processDarkModeSelection();
      initHolidayColors();
    },
    { immediate: true }
  );
};
