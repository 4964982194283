<template>
  <v-card flat>
    <v-card-title>Access Options</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <checkbox-field
              label="Skip Round Robin License Check"
              data-testid="skip-round-robin-license-check"
              readonly
              hide-details
              class="pt-0 mt-0"
              :model-value="skipRoundRobinLicenseCheck"
              @click="confirmSkip"
            />
          </div>
          <div>
            Provides one round robin agent with access to the eApp, but doesn't
            make them the agent, and doesn't require them to have a license in
            the state of the application.
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useDialogStore } from "@/stores/dialog";
import { markRaw } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const approvedDomain = useApprovedDomainView();
const dialog = useDialogStore();
const { skipRoundRobinLicenseCheck } = storeToRefs(approvedDomain);

function confirmSkip() {
  const initialValue = skipRoundRobinLicenseCheck.value;
  let title = "Skip Round Robin License Check";
  let subtitle =
    "Please confirm your intent to not require the round robin agent to have a license in the state of the application";

  if (initialValue) {
    title = "Require Round Robin License Check";
    subtitle =
      "Please confirm your intent to require the round robin agent to have a license in the state of the application";
  }

  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title,
    subtitle,
    func: async () => {
      try {
        skipRoundRobinLicenseCheck.value = !initialValue;
        await approvedDomain.updateApprovedDomain("skipRoundRobinLicenseCheck");
      } catch (e) {
        skipRoundRobinLicenseCheck.value = initialValue;
        throw e;
      }
    }
  });
}
</script>
