<template>
  <v-card flat color="section">
    <v-card-title>Trusted Senders</v-card-title>
    <v-card-text class="pb-0">
      Allow additional email addresses to respond to emails sent from BackNine
    </v-card-text>
    <v-card-text>
      <v-row class="ma-0 my-3" align="start" style="max-width: 45rem">
        <basic-email-input
          v-model="newEmail"
          label="Email"
          required
          dense
          hide-details
          :data-testid="`trusted-senders-${module}-email-input`"
          :success="Boolean(newEmail) && emailIsValid !== false"
          :error-messages="emailIsValid === false ? ['Invalid Email'] : []"
          @valid="emailIsValid = $event"
        />
        <app-button
          color="primary"
          class="text-none ml-3"
          text="Add Email"
          :data-testid="`trusted-senders-${module}-add`"
          :prepend-icon="mdiPlus"
          :loading="loading"
          @click="addEmail"
        />
      </v-row>
      <h4>Currently Permitted Emails:</h4>
      <v-list
        v-if="store.trustedSenders.length"
        color="transparent"
        style="max-width: 30rem"
      >
        <v-list-item
          v-for="(email, index) in store.trustedSenders"
          :key="email"
          class="px-0"
        >
          <v-list-item-title
            :data-testid="`trusted-sender-${module}-${index}-title`"
          >
            {{ email }}
          </v-list-item-title>

          <template #append>
            <app-button
              :icon="mdiDelete"
              color="error"
              variant="text"
              :data-testid="`trusted-sender-${module}-${index}-delete`"
              @click="deleteEmail(email)"
            />
          </template>
        </v-list-item>
      </v-list>
      <span v-else>
        No emails have been supplied. Only {{ store.email }} may respond to your
        email chains.
      </span>
    </v-card-text>
  </v-card>
</template>

<script setup>
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { markRaw, ref } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import { mdiPlus, mdiDelete } from "@mdi/js";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const props = defineProps({
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const newEmail = ref("");
const loading = ref(false);
const emailIsValid = ref(null);

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
async function addEmail() {
  if (!newEmail.value || !emailIsValid.value) return;
  loading.value = true;
  try {
    const email = newEmail.value;
    await store.addExternalResponder(email);
    newEmail.value = null;
    emailIsValid.value = null;
    snackbar.showSuccessSnackbar({
      message: `Successfully added ${email}`
    });
    await store.getExternalResponders();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
function deleteEmail(email) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    func: async () => {
      await store.deleteExternalResponder(email);
      await store.getExternalResponders();
    },
    title: `Are you sure that you want remove email responding from ${email}?`,
    subtitle:
      "This email will no longer be permitted to respond to past, present, or future email chains until added again."
  });
}

async function getExternalResponders() {
  try {
    await store.getExternalResponders();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getExternalResponders();
</script>
