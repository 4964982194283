<template>
  <v-card flat tile>
    <v-card-title> Basic Info</v-card-title>
    <v-card-text> Tell us more about yourself. </v-card-text>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <agency-profile :readonly="props.readonly" />
        </v-col>
        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title> Basic Information </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="6">
                  <text-field
                    :model-value="legalName"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiDomain"
                    label="Legal Name"
                    disabled
                    :success="Boolean(legalName)"
                  />
                </v-col>
                <v-col v-if="user.isGroupTwoPlus" cols="12" md="6">
                  <marketing-manager-search
                    v-model="marketingManager"
                    :prepend-inner-icon="mdiAccountTie"
                    data-testid="agency-marketing-manager"
                    label="Marketing Manager"
                    :disabled="props.readonly"
                    :success="marketingManagerValidation.success"
                    :error-messages="marketingManagerValidation.errorMessages"
                    @update:model-value="saveMarketingManager"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="
                          savingBuffer.marketingManager.controller.value
                        "
                      />
                    </template>
                  </marketing-manager-search>
                </v-col>
                <v-col cols="12" md="6">
                  <npn-input
                    v-model="npn"
                    validate
                    :disabled="hasNpn || props.readonly"
                    :success="npnValidation.success"
                    :error-messages="npnValidation.errorMessages"
                    data-testid="agency-npn"
                    @update:model-value="saveNpn"
                    @valid="validNpn = $event"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.npn.controller.value"
                      />
                    </template>
                  </npn-input>
                </v-col>
                <v-col cols="12" md="6">
                  <tin-input
                    v-model="tin"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiPound"
                    label="TIN"
                    data-testid="agency-tin"
                    :disabled="props.readonly || (hasTin && !user.isGroupFour)"
                    :success="tinValidation.success"
                    :error-messages="tinValidation.errorMessages"
                    @update:model-value="saveTin"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.tin.controller.value"
                      />
                    </template>
                  </tin-input>
                </v-col>
                <v-col cols="12" md="6">
                  <select-field
                    v-model="legalStructure"
                    :prepend-inner-icon="mdiPound"
                    label="Legal Structure"
                    data-testid="agency-legal-structure"
                    :disabled="props.readonly"
                    :items="LEGAL_STRUCTURES"
                    :success="legalStructureValidation.success"
                    :error-messages="legalStructureValidation.errorMessages"
                    @update:model-value="saveLegalStructure"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="
                          savingBuffer.legalStructure.controller.value
                        "
                      />
                    </template>
                  </select-field>
                </v-col>
                <v-col cols="12" md="6">
                  <phone-input
                    v-model="phoneWork"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiDeskphone"
                    label="Work #"
                    data-testid="agency-phone-work"
                    :disabled="props.readonly"
                    :success="phoneWorkValidation.success"
                    :error-messages="phoneWorkValidation.errorMessages"
                    @update:model-value="savePhoneWork"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.phoneWork.controller.value"
                      />
                    </template>
                  </phone-input>
                </v-col>
                <v-col v-if="agent.id" cols="12" md="6">
                  <text-field
                    v-model="title"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiAccount"
                    data-testid="agent-title"
                    :label="`${agent.name}'s Title at ${legalName}`"
                    :disabled="props.readonly"
                    :success="titleValidation.success"
                    :error-messages="titleValidation.errorMessages"
                    @update:model-value="saveTitle"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.title.controller.value"
                      />
                    </template>
                  </text-field>
                </v-col>
                <v-col v-if="user.isGroupTwoPlus" cols="12" md="6">
                  <autocomplete-field
                    v-model="category"
                    label="Category"
                    :prepend-inner-icon="mdiShape"
                    data-testid="agency-category"
                    clearable
                    :items="ADVISOR_CATEGORIES"
                    :success="Boolean(category)"
                    @update:model-value="saveCategory"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.category.controller.value"
                      />
                    </template>
                  </autocomplete-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title> Business Address </v-card-title>
            <v-card-text>
              <address-item
                data-testid="agency-business-address"
                :readonly="props.readonly"
                :address="businessAddress"
                @edit="editBusinessAddress"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import AddressItemDialog from "@/dialogs/AddressItemDialog.vue";
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import NpnInput from "@/components/shared/NpnInput.vue";
import TinInput from "@/components/shared/TinInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import AddressItem from "@/components/settings/account-details/AddressItem.vue";
import AgencyProfile from "@/components/settings/account-details/AgencyProfile.vue";

import { LEGAL_STRUCTURES, ADVISOR_CATEGORIES } from "@/data/advisor-data";

import { required } from "@vuelidate/validators";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { tin as tinValidator } from "@/util/vuelidateValidators";
import { ref, computed, onMounted, markRaw } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useAgencySettingsStore } from "@/stores/agency-settings";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import useVuelidate from "@vuelidate/core";
import { validationComputeV2 } from "@/util/helpers";
import { Address } from "@/models/Address";
import { useSettingsViewStore } from "@/stores/settings-view";
import { storeToRefs } from "pinia";

import {
  mdiDomain,
  mdiAccountTie,
  mdiPound,
  mdiDeskphone,
  mdiAccount,
  mdiShape
} from "@mdi/js";

const props = defineProps({
  readonly: Boolean
});
const savingBuffer = {
  phoneWork: useActiveSave(),
  tin: useActiveSave(),
  legalStructure: useActiveSave(),
  marketingManager: useActiveSave(),
  npn: useActiveSave(),
  businessAddress: useActiveSave(),
  title: useActiveSave(),
  category: useActiveSave()
};

const settings = useSettingsViewStore();

const agency = useAgencySettingsStore(settings.currentAgencyId);

const {
  tin,
  legalName,
  phoneWork,
  businessAddress,
  legalStructure,
  npn,
  marketingManager,
  category
} = storeToRefs(agency);

const agent = useAgentSettingsStore();

const { title } = storeToRefs(agent);
const validNpn = ref(Boolean(npn.value) || null);
const hasNpn = ref(Boolean(agency.npn));
const hasTin = ref(Boolean(agency.tin));
const user = useUserStore();
const dialog = useDialogStore();

const v$ = useVuelidate(
  {
    tin: { required, valid: tinValidator },
    phoneWork: {
      required,
      validLength: val => {
        if (!val) return false;
        return val.match(/\d/g).join("").length === 10;
      }
    },
    businessAddress: {
      required: v => v.street_address && v.city && v.state && v.zip
    },
    legalStructure: { required },
    marketingManager: { required },
    npn: { required, validNpn: validNpn.value === true },
    title: { required: v => Boolean(v) || !agent.id }
  },
  {
    tin,
    phoneWork,
    businessAddress,
    legalStructure,
    npn,
    title,
    marketingManager
  },
  { $autoDirty: true, $scope: false }
);

const phoneWorkValidation = computed(() => {
  const model = v$.value.phoneWork;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "validLength",
      message: "Phone numbers must be 10 digits in length"
    }
  ]);
});
const marketingManagerValidation = computed(() => {
  const model = v$.value.marketingManager;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const npnValidation = computed(() => {
  const model = v$.value.npn;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "validNpn", message: "Must be a valid NPN" }
  ]);
});
const titleValidation = computed(() => {
  const model = v$.value.title;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const tinValidation = computed(() => {
  const model = v$.value.tin;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "valid", message: "Invalid TIN" }
  ]);
});

const legalStructureValidation = computed(() => {
  const model = v$.value.legalStructure;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

function saveMarketingManager() {
  if (!marketingManagerValidation.value.success) return;
  savingBuffer.marketingManager.update(agency.updateMarketingManager);
  validate();
}

function saveNpn() {
  if (!npnValidation.value.success) return;
  savingBuffer.npn.debounceUpdate(agency.updateNpn);
  validate();
}
function saveTin() {
  if (!tinValidation.value.success) return;
  savingBuffer.tin.debounceUpdate(agency.updateTin);
  validate();
}

function saveLegalStructure() {
  if (!legalStructureValidation.value.success) return;
  savingBuffer.legalStructure.update(agency.updateLegalStructure);
  validate();
}

function savePhoneWork() {
  if (!phoneWorkValidation.value.success) return;
  savingBuffer.phoneWork.debounceUpdate(agency.updatePhoneWork);
  validate();
}

function saveTitle() {
  if (!titleValidation.value.success) return;
  savingBuffer.title.debounceUpdate(agent.updateTitle);
  validate();
}

function saveCategory() {
  savingBuffer.category.update(agency.updateCategory);
  validate();
}

async function editBusinessAddress() {
  const res = await dialog.showDialog({
    component: markRaw(AddressItemDialog),
    excludeDates: true,
    scrollable: true,
    newAddress: Address({
      ...businessAddress.value,
      addressType: "business"
    }),
    updateFunc: agency.updateBusinessAddress
  });
  if (!res?.address?.id) return;
  agency.storeBusinessAddress(res.address);
  validate();
}

async function validate() {
  const isValid = await v$.value.$validate();

  agency.setValidPage({
    page: "agency-basics",
    valid: Boolean(isValid)
  });
}

validate();

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
</script>
