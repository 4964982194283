export function Training(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    instructions: model?.instructions
  };
}

export function setTrainingFromRaw(raw = {}) {
  const training = Training();
  training.name = raw?.name;
  training.id = raw?.id;
  training.instructions = raw?.instructions;
  return training;
}
