import {
  getAgencySettings,
  updateAgency,
  updateAgencyCommunicationPreferences,
  getAgencyExternalResponders,
  addAgencyExternalResponder,
  deleteAgencyExternalResponder,
  updateAddressRecord,
  deleteAgency,
  uploadAgencyDocument
} from "@/api/agencies.service";
import { setActiveEft, createNewEft } from "@/api/eft.service";
import { deleteConnection } from "@/api/connections.service";
import { deleteEo } from "@/api/eos.service";
import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";
import { createLogin, destroyLogin } from "@/api/logins.service";
import { deleteDocument } from "@/api/documents.service";

import { getCommunicationPreferenceRequest } from "@/models/CommunicationPreferences";
import { AgencySettings } from "@/models/AgencySettings";
import { ErrorsOmissions } from "@/models/Eo";

export const AGENCY_SETTINGS = "agency";

export const useAgencySettingsStore = id =>
  defineStore(`agency-settings-${id}`, {
    state: () => AgencySettings(),
    getters: { type: () => "Agency" },
    actions: {
      async getAgencyContractingData(id) {
        this.$reset();
        this.loading = true;
        const agency = await getAgencySettings(id);
        this.$patch(agency);
        this.loading = false;
      },
      async getExternalResponders() {
        const emails = await getAgencyExternalResponders(this.id);
        this.trustedSenders.splice(0, this.trustedSenders.length);
        this.trustedSenders.push(...emails);
      },
      addExternalResponder(email) {
        return addAgencyExternalResponder(this.id, email);
      },
      deleteExternalResponder(email) {
        return deleteAgencyExternalResponder(this.id, email);
      },
      async deleteConnection(id) {
        await deleteConnection(id);
        delete this.connections[id];
      },

      async destroyEo() {
        await deleteEo(this.eo.id);
        this.eo = ErrorsOmissions();
      },
      updateCommissionSchedules() {
        return updateAgency(this.id, {
          schedule: {
            annuity: this.commissions.current.annuity,
            disability: this.commissions.current.disability,
            life: this.commissions.current.life,
            linked_benefit: this.commissions.current.linkedBenefit,
            ltc: this.commissions.current.ltc,
            variable_life: this.commissions.current.variableLife
          }
        });
      },
      updateHideCommission() {
        return updateAgency(this.id, {
          hide_commission: this.hideCommission
        });
      },
      updateCommissionLock() {
        return updateAgency(this.id, {
          commission_lock: this.lockCommissions
        });
      },
      updateAnnualization() {
        return updateAgency(this.id, {
          annualization: this.annualization
        });
      },
      updateHierarchy() {
        const reqConnections = [];

        Object.keys(this.connections).forEach(connectionId => {
          const connection = this.connections[connectionId];
          reqConnections.push({
            hierarchy_order: connection.hierarchyOrder,
            id: connection.id,
            hierarchy: connection.hierarchy
          });
        });

        return updateAgency(this.id, {
          connections_attributes: reqConnections
        });
      },
      async createEft(eft) {
        const newEft = await createNewEft(eft);
        this.addNewEft(newEft);
      },
      updateActiveEft(id) {
        Object.keys(this.efts).forEach(otherEft => {
          this.efts[otherEft].current = 0;
        });
        this.efts[id].current = 1;

        return setActiveEft(this.efts[id]);
      },
      updateMarketingManager() {
        return updateAgency(this.id, {
          marketing_manager_id: this.marketingManager.marketing_manager_id
        });
      },
      updateNpn() {
        return updateAgency(this.id, { npn: this.npn });
      },
      updateName() {
        return updateAgency(this.id, { name: this.name });
      },
      updateCategory() {
        return updateAgency(this.id, { category: this.category });
      },
      updateTin() {
        return updateAgency(this.id, { tin: this.tin });
      },
      updateLegalStructure() {
        return updateAgency(this.id, {
          legal_structure: this.legalStructure
        });
      },
      updatePhoneWork() {
        return updateAgency(this.id, { phone_work: this.phoneWork });
      },
      updateEmail() {
        return updateAgency(this.id, { email: this.email });
      },
      // used by AddressItemDialog
      updateBusinessAddress(addressId, businessAddress) {
        return updateAddressRecord(this.id, addressId, businessAddress);
      },
      updateCommunicationPreference(type) {
        return updateAgencyCommunicationPreferences(
          this.id,
          getCommunicationPreferenceRequest(this.communicationPreferences, type)
        );
      },
      setValidPage({ page, valid }) {
        this.pageValidation[page] = valid;
      },
      addNewEft(value) {
        Object.keys(this.efts).forEach(eftId => {
          this.efts[eftId].current = 0;
        });
        this.efts[value.id] = value;
      },
      addEft(value) {
        Object.keys(this.efts).forEach(eftId => {
          this.efts[eftId].current = 0;
        });
        this.efts[value.id] = value;
      },
      addRelatedDocument(relatedDocument) {
        this.relatedDocuments[relatedDocument.uid] = relatedDocument;
      },
      async deleteRelatedDocument(id) {
        await deleteDocument(id);
        delete this.relatedDocuments[id];
      },
      storeMarketingManager(value) {
        this.marketingManager = value;
      },
      storeNpn(value) {
        this.npn = value;
      },
      storeName(value) {
        this.name = value;
      },
      storeTin(value) {
        this.tin = value;
      },
      storeLegalStructure(value) {
        this.legalStructure = value;
      },
      storePhoneWork(value) {
        this.phoneWork = value;
      },
      storeEmail(value) {
        this.email = value;
      },
      storeBusinessAddress(value) {
        const { street_address, state, city, zip } = value;
        this.businessAddress.street_address = street_address;
        this.businessAddress.state = state;
        this.businessAddress.city = city;
        this.businessAddress.zip = zip;
      },
      addConnection(connection) {
        this.connections[connection.id] = connection;
      },
      updateConnection(connection) {
        this.connections[connection.id] = connection;
      },
      setHierarchy(newHierarchy) {
        const allConnectionIds = [...Object.keys(this.connections)];
        newHierarchy.forEach((connection, index) => {
          this.connections[connection.id].hierarchy = 1;
          this.connections[connection.id].hierarchyOrder = index + 1;
          const connectionIndex = allConnectionIds.findIndex(
            val => +val === +connection.id
          );
          allConnectionIds.splice(connectionIndex, 1);
        });

        allConnectionIds.forEach(connection => {
          this.connections[connection].hierarchy = 0;
          this.connections[connection].hierarchyOrder = null;
        });
      },
      async createLogin(payload) {
        const user = useUserStore();
        const login = await createLogin(payload);
        if (user.login.id === login.id) {
          const userStore = useUserStore();
          userStore.addUserLogin(login);
        }
        this.logins[login.id] = login;
      },
      async deleteLogin(id) {
        const user = useUserStore();
        await destroyLogin(id);
        if (id === user.id) {
          const userStore = useUserStore();
          userStore.removeUserLogin(id);
        }
        if (this.primaryLogin.id === id) {
          this.primaryLogin = null;
        }

        delete this.logins[id];
      },
      delete() {
        return deleteAgency(this.id);
      },
      async uploadDocument(file) {
        const document = await uploadAgencyDocument(this.id, file);
        this.addRelatedDocument(document);
      }
    }
  })();
