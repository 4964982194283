<template>
  <div class="preliminary-card">
    <v-card>
      <v-col>
        <v-row class="ma-0" align="center" justify="center">
          <app-logo large />
        </v-row>
      </v-col>
      <v-card-title class="justify-center font-weight-bold text-h5 mb-6">
        Bridging into carrier app. Please wait a moment.
      </v-card-title>
      <v-card-text>
        <v-progress-circular indeterminate />
      </v-card-text>
      <v-card-text>
        Taking a while?
        <a
          :class="{ 'text-grey': loading }"
          data-testid="force-bridge"
          @click="bridgeIntoCarrierApp"
        >
          Click Here
        </a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import AppLogo from "@/components/AppLogo.vue";
import { useHead } from "@unhead/vue";
import { executeFirelightSSO } from "@/components/settings/security/sso.composable";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { useRouter } from "vue-router";
import { ref } from "vue";

useHead({
  title: "Carrier Bridge"
});
const snackbar = useSnackbarStore();
const router = useRouter();
const loading = ref(false);
const props = defineProps({
  eappUuid: {
    type: String,
    default: ""
  }
});

async function bridgeIntoCarrierApp() {
  if (loading.value) return;
  try {
    loading.value = true;
    await executeFirelightSSO(props.eappUuid);
  } catch (e) {
    console.error(e);
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    await router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}
</script>
