<template>
  <v-card color="section" flat outlined>
    <v-card-title class="text-h4">Details</v-card-title>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="text-h6">General Settings</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12">
                <h5>
                  Product Types (click to enable/disable)
                  <app-button
                    color="accent"
                    data-testid="settings-product-type-edit"
                    variant="text"
                    density="comfortable"
                    :icon="lockedProductTypes ? mdiPencil : mdiContentSave"
                    :loading="savingBuffer.productTypes.controller.saving"
                    @click="toggleLockedProductType"
                  />
                </h5>
                <v-chip-group
                  v-model="enabledProductTypes"
                  multiple
                  mandatory
                  selected-class="bg-primary"
                  column
                  @hover="showLockedText"
                >
                  <v-chip
                    v-for="product in availableProductTypes"
                    :key="product.title"
                    :data-testid="`settings-product-type-toggle-${product.title}`"
                    :disabled="lockedProductTypes"
                    :value="product.value"
                    :text="product.title"
                  />
                  <v-col class="pa-0" align="center" justify="center">
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.productTypes.controller.value"
                    />
                  </v-col>
                </v-chip-group>
              </v-col>
              <v-col cols="12">
                <textarea-field
                  v-model="greeting"
                  :prepend-inner-icon="mdiHandshake"
                  label="Greeting"
                  rows="2"
                  data-testid="settings-greeting"
                  :success="Boolean(greeting)"
                  @update:model-value="saveAttribute('greeting')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.greeting.controller.value"
                    />
                  </template>
                </textarea-field>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12">
                <textarea-field
                  v-model="introEmail"
                  label="Intro Email"
                  rows="13"
                  :prepend-inner-icon="mdiEmail"
                  persistent-hint
                  data-testid="settings-intro-email"
                  :hint="INTRO_HINT"
                  :success="Boolean(introEmail)"
                  @update:model-value="saveAttribute('introEmail')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.introEmail.controller.value"
                    />
                  </template>
                </textarea-field>
              </v-col>
              <v-col cols="12">
                <textarea-field
                  v-model="disclosure"
                  :prepend-inner-icon="mdiInformation"
                  label="Disclosure"
                  rows="2"
                  data-testid="settings-disclosure"
                  :success="Boolean(disclosure)"
                  @update:model-value="saveAttribute('disclosure')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.disclosure.controller.value"
                    />
                  </template>
                </textarea-field>
              </v-col>
              <v-col
                v-if="verbosePricingPlan.show_override_phone"
                cols="12"
                lg="6"
              >
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="overridePhone"
                    icon
                    class="mt-0"
                    :prepend-icon="mdiDeskphone"
                    hint="Override the default phone number"
                    persistent-hint
                    data-testid="settings-override-phone"
                    :success="Boolean(overridePhone)"
                    @update:model-value="saveAttribute('overridePhone')"
                  >
                    <template #label>
                      Override Phone
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.overridePhone.controller.value
                        "
                      />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <v-col v-if="overridePhone" cols="12" lg="6">
                <phone-input
                  v-model="phoneNumber"
                  data-lpignore="true"
                  label="Phone Number"
                  :prepend-inner-icon="mdiPhone"
                  persistent-hint
                  data-testid="settings-phone-number"
                  hint="The phone number the user sees."
                  :success="phoneNumberValidation.success"
                  :error-messages="phoneNumberValidation.errorMessages"
                  @update:model-value="updatePhoneNumber"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.phoneNumber.controller.value"
                    />
                  </template>
                </phone-input>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12" lg="6">
                <select-field
                  v-model="defaultMode"
                  :prepend-inner-icon="mdiCalendar"
                  class="mt-0"
                  persistent-hint
                  data-testid="settings-default-mode"
                  label="Default Premium Mode"
                  hint="The default premium mode the user sees. The user can still change the premium mode."
                  :items="MODES"
                  :success="Boolean(defaultMode)"
                  @update:model-value="saveAttribute('defaultMode')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.defaultMode.controller.value"
                    />
                  </template>
                </select-field>
              </v-col>
              <template v-if="!isSignUpOrSelectAgent">
                <v-col cols="12" lg="6">
                  <div class="checkbox-width">
                    <checkbox-field
                      v-model="useOwnersSchedulingLink"
                      :prepend-icon="mdiCalendar"
                      dense
                      class="mt-0"
                      persistent-hint
                      hint=" "
                      data-testid="settings-use-owners-scheduling-link"
                      :success="Boolean(useOwnersSchedulingLink)"
                      @update:model-value="
                        saveAttribute('useOwnersSchedulingLink')
                      "
                    >
                      <template #message>
                        When enabled users can schedule appointments utilizing
                        {{ ownable.name }}'s default scheduling link.
                        <router-link
                          :to="{
                            name:
                              ownable.typeModel === 'Agent'
                                ? 'AgentEdit'
                                : 'AgencyEdit',
                            params: {
                              id: ownable.id
                            },
                            query: {
                              page: 'profile'
                            }
                          }"
                          >Add/Edit default scheduling link</router-link
                        >
                      </template>
                      <template #label>
                        Use {{ ownable.name }}'s Default Scheduling link
                        <active-save-indicator
                          class="ml-2"
                          :controller="
                            savingBuffer.useOwnersSchedulingLink.controller
                              .value
                          "
                        />
                      </template>
                    </checkbox-field>
                  </div>
                </v-col>
                <v-col v-if="!useOwnersSchedulingLink" cols="12" lg="6">
                  <text-field
                    v-model="customSchedulingLink"
                    data-lpignore="true"
                    label="Custom Scheduling Link"
                    :prepend-inner-icon="mdiCalendar"
                    prefix="https://"
                    clearable
                    persistent-hint
                    hint=" "
                    data-testid="settings-custom-scheduling-link"
                    :success="customSchedulingLinkValidation.success"
                    :error-messages="
                      customSchedulingLinkValidation.errorMessages
                    "
                    @click:clear="updateCustomSchedulingLink"
                    @update:model-value="updateCustomSchedulingLink"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.customSchedulingLink.controller.value
                        "
                      />
                    </template>
                    <template #message="{ message }">
                      <v-row class="ma-0">
                        <template v-if="message.length > 1">{{
                          message
                        }}</template>
                        <a v-else href="https://calendly.com/" target="_blank"
                          >Click here to sign up for Calendly.</a
                        >
                      </v-row>
                    </template>
                  </text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <div class="checkbox-width">
                    <checkbox-field
                      v-model="chat"
                      icon
                      class="mt-0"
                      :prepend-icon="mdiMessage"
                      hint=" "
                      data-testid="settings-chat"
                      persistent-hint
                      :success="Boolean(chat)"
                      @update:model-value="saveAttribute('chat')"
                    >
                      <template #label>
                        Chat Assistant
                        <active-save-indicator
                          class="ml-2"
                          :controller="savingBuffer.chat.controller.value"
                        />
                      </template>
                      <template #message>
                        Enable a Chat Assistant to prompt clients to schedule a
                        meeting with you. This requires a scheduling link.
                        <router-link
                          :to="{
                            name:
                              ownable.typeModel === 'Agent'
                                ? 'AgentEdit'
                                : 'AgencyEdit',
                            params: {
                              id: ownable.id
                            },
                            query: {
                              page: 'profile'
                            }
                          }"
                          >Add/Edit default scheduling link.</router-link
                        >
                        <a
                          href="https://app.back9ins.com/help-center/articles/57"
                          target="_blank"
                        >
                          Learn about Chat</a
                        >.
                      </template>
                    </checkbox-field>
                  </div>
                </v-col>
              </template>
              <v-col cols="12" lg="6">
                <select-field
                  v-model="startPage"
                  :prepend-inner-icon="mdiBookOpenPageVariant"
                  label="Start Page"
                  hint="The first page the user sees. The default is Apply, but Quote & Apply can be used as a Life Insurance Calculator (Life Insurance Calculator), a sign up page for your downline to sign up with BackNine (Agent Sign Up), display a list of your agents to then use Quote & Apply (Select Agent Dropdown), or a referral form (Refer)."
                  persistent-hint
                  data-testid="settings-start-page"
                  :items="startPageItems"
                  :success="Boolean(startPage)"
                  @update:model-value="saveAttribute('startPage')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.startPage.controller.value"
                    />
                  </template>
                </select-field>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12" lg="6">
                <select-field
                  v-model="limitExam"
                  :items="LIMIT_EXAM_ITEMS"
                  :prepend-inner-icon="mdiCalendarWeek"
                  class="mt-0"
                  success
                  persistent-hint
                  data-testid="settings-limit-exam"
                  label="Limit To No Exam Products"
                  hint="Filter quotes available by exam requirements"
                  @update:model-value="saveAttribute('limitExam')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.limitExam.controller.value"
                    />
                  </template>
                </select-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="!isSignUpOrSelectAgent" cols="12">
        <v-card flat>
          <v-card-title class="text-h6">Death Benefit Defaults</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="accidentalDeathDefault"
                  :prepend-inner-icon="mdiCash"
                  label="Accidental Death Default"
                  data-testid="settings-accidental-death-default"
                  :decimal-length="0"
                  :success="Boolean(accidentalDeathDefault)"
                  @update:model-value="saveAttribute('accidentalDeathDefault')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="
                        savingBuffer.accidentalDeathDefault.controller.value
                      "
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="finalExpenseDefault"
                  :prepend-inner-icon="mdiCash"
                  label="Final Expense Default"
                  data-testid="settings-final-expense-default"
                  :decimal-length="0"
                  :success="Boolean(finalExpenseDefault)"
                  @update:model-value="saveAttribute('finalExpenseDefault')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="
                        savingBuffer.finalExpenseDefault.controller.value
                      "
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="permanentDefault"
                  :prepend-inner-icon="mdiCash100"
                  label="Term/ROP Term/Permanent Default"
                  data-testid="settings-permanent-default"
                  :decimal-length="0"
                  :success="Boolean(permanentDefault)"
                  @update:model-value="saveAttribute('permanentDefault')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="
                        savingBuffer.permanentDefault.controller.value
                      "
                    />
                  </template>
                </currency-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="text-h6">Advanced</v-card-title>
          <v-card-text>
            The default settings are recommended for most agents. Adjust these
            settings with caution.
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <advisor-search
                  v-model="marketingAs"
                  class="has-appended-button"
                  :prepend-inner-icon="mdiBullhorn"
                  label="Market As"
                  persistent-hint
                  hint="Determines who client correspondence and webpage title comes from. If locked, Market As won't change even when the agent changes who their commissions are paid to."
                  data-testid="settings-marketing-as"
                  :clearable="false"
                  :success="Boolean(marketingAs)"
                  @update:model-value="saveAttribute('marketingAs')"
                >
                  <template #append-inner>
                    <app-button
                      key="MarketAsLock"
                      variant="text"
                      density="comfortable"
                      :icon="marketAsLock ? mdiLock : mdiLockOpenVariantOutline"
                      data-testid="settings-toggle-market-as-lock"
                      @mousedown.stop.prevent="toggleMarketAsLock"
                      @touchstart.stop.prevent="toggleMarketAsLock"
                    />
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.marketingAs.controller.value"
                    />
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.marketAsLock.controller.value"
                    />
                  </template>
                </advisor-search>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="marketAsAgency"
                    :prepend-icon="mdiDomain"
                    class="mt-0"
                    success
                    hint="Hides the agent's name and shows the Commissions Assigned To's name"
                    persistent-hint
                    data-testid="settings-market-as-agency"
                    @update:model-value="saveAttribute('marketAsAgency')"
                  >
                    <template #label>
                      Market as Agency
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.marketAsAgency.controller.value
                        "
                      />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="emailAgent"
                    :prepend-icon="mdiEmail"
                    class="mt-0"
                    success
                    hint="Email the agent on eApp correspondence."
                    persistent-hint
                    data-testid="settings-email-agent"
                    @update:model-value="saveAttribute('emailAgent')"
                  >
                    <template #label>
                      Email Agent
                      <active-save-indicator
                        class="ml-2"
                        :controller="savingBuffer.emailAgent.controller.value"
                      />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12" lg="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="test"
                    :prepend-icon="mdiDevTo"
                    label="Test"
                    class="mt-0"
                    success
                    hint="Applications, Exams, and DocuSigns are deleted nightly."
                    persistent-hint
                    data-testid="settings-test"
                    @update:model-value="saveAttribute('test')"
                  >
                    <template #label>
                      Test
                      <active-save-indicator
                        class="ml-2"
                        :controller="savingBuffer.test.controller.value"
                      />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <v-col v-if="test && !isSignUpOrSelectAgent" cols="12" lg="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="hideTestModeBanner"
                    :prepend-icon="mdiDevTo"
                    class="mt-0"
                    success
                    hint="Hides the 'This Quote & Apply Widget is in Test Mode' banner at the top of the widget"
                    persistent-hint
                    data-testid="settings-hide-test-mode-banner"
                    @update:model-value="saveAttribute('hideTestModeBanner')"
                  >
                    <template #label>
                      Hide Test Mode Banner
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.hideTestModeBanner.controller.value
                        "
                      />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="activeFirstInput"
                    :prepend-icon="mdiImport"
                    class="mt-0"
                    success
                    hint="Causes the first input on Quote & Apply to be active when Quote & Apply loads. This can cause the page to scroll (i.e. jump) if Quote & Apply is below the fold. This setting is only used when Quote & Apply is embedded and not in a modal."
                    persistent-hint
                    data-testid="settings-active-first-input"
                    @update:model-value="saveAttribute('activeFirstInput')"
                  >
                    <template #label>
                      Active First Input
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.activeFirstInput.controller.value
                        "
                      />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <template v-if="!isWhiteglove && !isSignUpOrSelectAgent">
                <v-col cols="12" lg="6">
                  <div class="checkbox-width">
                    <checkbox-field
                      v-model="captureEmail"
                      :prepend-icon="mdiEmail"
                      class="mt-0"
                      hint="Force the user to provide their email before viewing quotes."
                      persistent-hint
                      data-testid="settings-capture-email"
                      :success="Boolean(captureEmail)"
                      @update:model-value="saveAttribute('captureEmail')"
                    >
                      <template #label>
                        Require Email Before Viewing Quotes
                        <active-save-indicator
                          class="ml-2"
                          :controller="
                            savingBuffer.captureEmail.controller.value
                          "
                        />
                      </template>
                    </checkbox-field>
                  </div>
                </v-col>
                <v-col cols="12" lg="6">
                  <div class="checkbox-width">
                    <checkbox-field
                      v-model="capturePhone"
                      :prepend-icon="mdiPhone"
                      dense
                      class="mt-0"
                      hint="Force the user to provide their phone number before viewing quotes"
                      persistent-hint
                      data-testid="settings-capture-phone"
                      :success="Boolean(capturePhone)"
                      @update:model-value="saveAttribute('capturePhone')"
                    >
                      <template #label>
                        Require Phone Before Viewing Quotes
                        <active-save-indicator
                          class="ml-2"
                          :controller="
                            savingBuffer.capturePhone.controller.value
                          "
                        />
                      </template>
                    </checkbox-field>
                  </div>
                </v-col>
              </template>
              <v-col v-if="showHideFromSearchEngines" cols="12" lg="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="hideFromSearchEngines"
                    :prepend-icon="mdiSearchWeb"
                    dense
                    class="mt-0"
                    success
                    hint="Instructs search engines to not index this Quote & Apply website by removing it from BackNine's sitemap.xml and adding to the disallow list for robots.txt."
                    persistent-hint
                    data-testid="settings-hide-from-search-engines"
                    @update:model-value="saveAttribute('hideFromSearchEngines')"
                  >
                    <template #label>
                      Hide from Search Engines
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.hideFromSearchEngines.controller.value
                        "
                      />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <advisor-search
                  v-model="assignable"
                  class="has-appended-button"
                  :prepend-inner-icon="mdiClipboardAccount"
                  label="Assignable"
                  hint="The assignable is given access to the eApp. The assignable becomes a connection for agents who register with this Quote & Apply. If locked, the assignable won't change even when the agent changes who their commissions are paid to. If the pricing plan of the Quote & Apply website is Affiliate, the assignable is who is paid."
                  persistent-hint
                  data-testid="settings-assignable"
                  :clearable="false"
                  :success="Boolean(assignable)"
                  @update:model-value="saveAttribute('assignable')"
                >
                  <template #append-inner>
                    <app-button
                      v-if="!isWhiteglove"
                      key="AssignableLock"
                      :icon="
                        assignableLock ? mdiLock : mdiLockOpenVariantOutline
                      "
                      variant="text"
                      density="comfortable"
                      style="z-index: 3"
                      data-testid="settings-assignable-lock"
                      @mousedown.stop.prevent="toggleAssignableLock"
                      @touchstart.stop.prevent="toggleAssignableLock"
                    />
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.assignableLock.controller.value"
                    />
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.assignable.controller.value"
                    />
                  </template>
                </advisor-search>
              </v-col>
              <v-col v-if="!isSignUpOrSelectAgent" cols="12" lg="6">
                <select-field
                  v-model="overrideState"
                  clearable
                  :prepend-inner-icon="mdiHomeGroup"
                  label="Override State"
                  hint="Set a default state instead of defaulting to the state of user's IP address."
                  persistent-hint
                  data-testid="settings-override-state"
                  :items="availableStates"
                  :success="Boolean(overrideState)"
                  @update:model-value="saveAttribute('overrideState')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.overrideState.controller.value"
                    />
                  </template>
                </select-field>
              </v-col>
              <v-col cols="12" lg="6">
                <text-field
                  v-model="embedLocation"
                  :placeholder="domain"
                  data-lpignore="true"
                  :prepend-inner-icon="mdiCodeTags"
                  prefix="https://"
                  label="Embed Location (optional)"
                  :success="Boolean(embedLocation)"
                  persistent-hint
                  hint="Indicate where you have embedded Quote & Apply"
                  data-testid="settings-embed-location"
                  @update:model-value="saveAttribute('embedLocation')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.embedLocation.controller.value"
                    />
                  </template>
                </text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- GROUP 2+ -->
      <v-col v-if="user.isGroupTwoPlus" cols="12">
        <v-card flat>
          <v-card-title class="text-h6">Admin - Group 2 +</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <select-field
                  v-model="approvedDomainPricingPlan"
                  :prepend-inner-icon="mdiFloorPlan"
                  label="Pricing Plan"
                  item-title="name"
                  item-value="id"
                  data-testid="settings-pricing-plan"
                  :disabled="savingBuffer.pricingPlan.saving"
                  :loading="savingBuffer.pricingPlan.saving"
                  :items="pricingPlanOptions"
                  :success="Boolean(pricingPlan)"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.pricingPlan.controller.value"
                    />
                  </template>
                </select-field>
              </v-col>
              <v-col v-if="!isWhiteglove" cols="12" lg="6">
                <advisor-search
                  v-model="billable"
                  :prepend-inner-icon="mdiOfficeBuilding"
                  label="Billable"
                  data-testid="settings-billable"
                  :clearable="false"
                  :success="Boolean(billable)"
                  @update:model-value="saveAttribute('billable')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.billable.controller.value"
                    />
                  </template>
                </advisor-search>
              </v-col>
              <v-col cols="12" lg="6">
                <text-field
                  :model-value="domain"
                  readonly
                  data-lpignore="true"
                  :prepend-inner-icon="mdiChartAreaspline"
                  label="URL"
                  persistent-hint
                  hint=" "
                  data-testid="settings-domain"
                  :success="Boolean(domain)"
                  @click="editDomain"
                  @update:model-value="saveAttribute('domain')"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import { STATES } from "@/data/states";

import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { startPageItems } from "@/data/filter-options";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, ref, onMounted, nextTick, markRaw } from "vue";

import useVuelidate from "@vuelidate/core";
import { url } from "@vuelidate/validators";

import {
  MODES,
  LIMIT_EXAM_ITEMS,
  INTRO_HINT,
  PRODUCT_TYPES
} from "@/models/ApprovedDomain";

import {
  mdiBookOpenPageVariant,
  mdiBullhorn,
  mdiCalendar,
  mdiCalendarWeek,
  mdiCash,
  mdiCash100,
  mdiChartAreaspline,
  mdiClipboardAccount,
  mdiCodeTags,
  mdiContentSave,
  mdiDeskphone,
  mdiDevTo,
  mdiDomain,
  mdiEmail,
  mdiFloorPlan,
  mdiHandshake,
  mdiHomeGroup,
  mdiImport,
  mdiInformation,
  mdiLock,
  mdiLockOpenVariantOutline,
  mdiMessage,
  mdiOfficeBuilding,
  mdiPencil,
  mdiPhone,
  mdiSearchWeb
} from "@mdi/js";
import TextEditDialog from "@/dialogs/TextEditDialog.vue";

const emit = defineEmits(["refresh"]);

const user = useUserStore();
const approvedDomain = useApprovedDomainView();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const {
  accidentalDeathDefault,
  activeFirstInput,
  assignable,
  assignableLock,
  billable,
  captureEmail,
  capturePhone,
  chat,
  customDomain,
  customSchedulingLink,
  defaultMode,
  disclosure,
  domain,
  emailAgent,
  embedLocation,
  finalExpenseDefault,
  greeting,
  hideFromSearchEngines,
  hideTestModeBanner,
  introEmail,
  isWhiteglove,
  limitExam,
  marketAsAgency,
  marketAsLock,
  marketingAs,
  overridePhone,
  overrideState,
  permanentDefault,
  phoneNumber,
  pricingPlan,
  pricingPlanOptions,
  productTypes,
  startPage,
  test,
  useOwnersSchedulingLink,
  verbosePricingPlan,
  ownable,
  isSignUpOrSelectAgent
} = storeToRefs(approvedDomain);

const lockedProductTypes = ref(true);
const lockedTooltipVisible = ref(false);

const isMounted = ref(false);
onMounted(() => nextTick(() => (isMounted.value = true)));

const savingBuffer = {
  accidentalDeathDefault: useActiveSave(),
  activeFirstInput: useActiveSave(),
  assignable: useActiveSave(),
  assignableLock: useActiveSave(),
  billable: useActiveSave(),
  captureEmail: useActiveSave(),
  capturePhone: useActiveSave(),
  chat: useActiveSave(),
  customSchedulingLink: useActiveSave(),
  defaultMode: useActiveSave(),
  disclosure: useActiveSave(),
  emailAgent: useActiveSave(),
  finalExpenseDefault: useActiveSave(),
  greeting: useActiveSave(),
  hideFromSearchEngines: useActiveSave(),
  hideTestModeBanner: useActiveSave(),
  introEmail: useActiveSave(),
  limitExam: useActiveSave(),
  marketAsAgency: useActiveSave(),
  marketAsLock: useActiveSave(),
  marketingAs: useActiveSave(),
  overridePhone: useActiveSave(),
  overrideState: useActiveSave(),
  permanentDefault: useActiveSave(),
  phoneNumber: useActiveSave(),
  productTypes: useActiveSave(),
  pricingPlan: useActiveSave(),
  startPage: useActiveSave(),
  test: useActiveSave(),
  useOwnersSchedulingLink: useActiveSave(),
  embedLocation: useActiveSave()
};

const v$ = useVuelidate(
  {
    phoneNumber: {
      validPhoneNumber: value => value?.length === 14
    },
    embedLocation: {
      validLink: value => {
        if (!value) return true;
        if (value.includes("http://") || value.includes("https://"))
          return false;
        return url.$validator(`https://${value}`);
      }
    },
    customSchedulingLink: {
      validLink(value) {
        if (!value) return true;
        if (value.includes("http://") || value.includes("https://"))
          return false;
        return url.$validator(`https://${value}`);
      }
    }
  },
  {
    phoneNumber,
    embedLocation,
    customSchedulingLink
  },
  { $scope: null, $autoDirty: true }
);

const enabledProductTypes = computed({
  get() {
    return Object.keys(productTypes.value).filter(k => productTypes.value[k]);
  },
  set(v) {
    for (const key of Object.keys(productTypes.value)) {
      productTypes.value[key] = v.includes(key);
    }
  }
});

const availableProductTypes = computed(() => {
  const available = [];
  for (const pt of PRODUCT_TYPES) {
    if (pt.value in productTypes.value) available.push(pt);
  }

  return available;
});

const showHideFromSearchEngines = computed(() =>
  Boolean(customDomain.value?.id)
);
const availableStates = computed(() =>
  STATES.filter(s => ownable.value?.insuranceLicenses?.includes(s.value))
);

const approvedDomainPricingPlan = computed({
  get() {
    return pricingPlan.value;
  },
  async set(v) {
    try {
      if (!isMounted.value) return;
      await approvedDomain.updateApprovedDomain("pricingPlan", v);
      pricingPlan.value = v;
      emit("refresh");
    } catch (e) {
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    }
  }
});

const customSchedulingLinkValidation = computedValidation(
  v$.value.customSchedulingLink,
  { validLink: "Invalid Link" }
);
const phoneNumberValidation = computedValidation(v$.value.phoneNumber, {
  validPhoneNumber: "Phone numbers must be 10 digits in length"
});

function toggleMarketAsLock() {
  marketAsLock.value = !marketAsLock.value;
  saveAttribute("marketAsLock");
}
function toggleAssignableLock() {
  assignableLock.value = !assignableLock.value;
  saveAttribute("assignableLock");
}

function updateCustomSchedulingLink() {
  if (!customSchedulingLinkValidation.value.success) return;
  saveAttribute("customSchedulingLink");
}

function updatePhoneNumber() {
  if (!phoneNumberValidation.value.success) return;
  saveAttribute("phoneNumber");
}

function showLockedText() {
  if (!lockedProductTypes.value) return;
  lockedTooltipVisible.value = true;
}
function toggleLockedProductType() {
  if (!lockedProductTypes.value) {
    saveAttribute("productTypes", 0);
  }
  lockedProductTypes.value = !lockedProductTypes.value;
}
function saveAttribute(attribute, timeout = 300) {
  if (!isMounted.value) return;
  savingBuffer[attribute].debounceUpdate(
    () => approvedDomain.updateApprovedDomain(attribute),
    timeout
  );
}
function editDomain() {
  const oldDomain = domain.value;
  dialog.showDialog({
    component: markRaw(TextEditDialog),
    title: "Edit Domain",
    label: "Domain",
    subtitle:
      "Change the domain of this Quote & Apply website. This will change the continue URL for all related eApps with matching resume links.",
    modelValue: domain.value,
    rules: {
      required: v => Boolean(v)
    },
    rulesMessages: [{ key: "required", message: "Required" }],
    func: async v => {
      domain.value = v;
      try {
        await approvedDomain.updateApprovedDomain("domain");
      } catch (e) {
        domain.value = oldDomain;
        throw e;
      }
    }
  });
}
</script>
