import { getHttpClient } from "@/http-client";
import {
  setTransactionCaseSearchItemFromRequest,
  setCaseTransactionPartyFromRequest,
  setCaseTransactionFromRequest,
  setSimulatedTransactionPaymentFromRequest,
  setSimulatedTransactionCommissionFromRequest
} from "@/models/Transaction";
const baseUrl = "api/boss/transactions";

export function createTransaction(params) {
  const query = new URLSearchParams();
  query.append("create_payments", true);
  return getHttpClient().post(`${baseUrl}?${query.toString()}`, params);
}

export function deleteTransaction(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export async function simulateTransaction(body) {
  let transaction;
  let isErrored = false;
  try {
    const { data } = await getHttpClient().post(`${baseUrl}?simulate`, body);
    transaction = data.transaction;
  } catch (e) {
    if (!e?.response?.data?.transaction) throw e;
    transaction = e.response.data.transaction;
    isErrored = true;
  }

  return {
    forecast: {
      backnine: transaction.forecast.backnine,
      total: transaction.forecast.total
    },
    payments: transaction.payments.map(
      setSimulatedTransactionPaymentFromRequest
    ),
    errors: transaction.errors,
    commissions: transaction.commissions.map(
      setSimulatedTransactionCommissionFromRequest
    ),
    finalOverride: transaction.override,
    isErrored
  };
}

let caseTransactionCancel = null;
export async function getCaseTransactionsAndParties(params) {
  if (caseTransactionCancel !== null) {
    caseTransactionCancel.cancel("Operation canceled due to new request.");
  }
  caseTransactionCancel = getHttpClient().CancelToken.source();

  const { data } = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: caseTransactionCancel.token
  });
  return {
    transactions: data.transactions.map(setCaseTransactionFromRequest),
    parties: data.totals.map(setCaseTransactionPartyFromRequest)
  };
}

export const revertTransaction = transactionId => {
  return getHttpClient().post(`${baseUrl}/${transactionId}/revert`);
};

export const reflowTransaction = async transactionId => {
  try {
    await getHttpClient().post(`${baseUrl}/${transactionId}/reflow`);
  } catch (e) {
    if (!e?.response?.data?.transaction) throw e;
    const errors = e.response.data.transaction.errors;
    throw `Reflow was unsuccessful. No data was modified due to: ${errors}`;
  }
};

export const auditTransaction = transactionId => {
  return getHttpClient().post(`${baseUrl}/${transactionId}/audit`);
};

export async function searchCaseTransactions(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/search_cases`, {
    params
  });

  return data.cases
    .map(setTransactionCaseSearchItemFromRequest)
    .toSorted((a, b) => new Date(a.policyDate) - new Date(b.policyDate));
}
