<template>
  <autocomplete-field
    v-bind="$attrs"
    ref="searchRef"
    v-model="inputValue"
    v-model:search="search"
    v-click-outside="{ handler: () => emit('blur') }"
    no-filter
    autocomplete="off"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    :data-testid="props.dataTestid"
    :items="items"
    :loading="loading"
    @update:list-index="val => (focusedIndex = val)"
  >
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <!-- eslint-disable-next-line vue/no-unused-vars -->
    <template #item="{ props: { title, ...templateProps }, item }">
      <v-list-item
        v-bind="templateProps"
        :to="item.raw.attrs?.to"
        @keydown.enter="onEnterClick(item.raw)"
        @click="emit('clicked')"
      >
        <v-list-item-title>
          <div>
            <span
              v-for="(t, index) in item.raw.texts"
              :key="`part-${t.title}-${index}`"
              :data-testid="`part-${index}`"
              :class="t.classes"
              >{{ t.title }}</span
            >
          </div>
        </v-list-item-title>

        <template v-if="focusedIndex === item.raw.index" #append>
          <p class="caption mb-0 ml-3">
            Select
            <span class="bg-secondary pa-1 rounded">
              <v-icon
                size="small"
                class="text-white"
                :icon="mdiKeyboardReturn"
              />
            </span>
          </p>
        </template>
      </v-list-item>
    </template>
    <template v-if="props.showNavHelpers" #append-item>
      <v-sheet elevation="2" style="position: sticky; bottom: 0">
        <v-list-item>
          <v-list-item-title>
            <span class="secondary pa-1 mr-1" style="border-radius: 4px">
              <v-icon size="small" class="text-white" :icon="mdiArrowUp" />
            </span>
            <span class="secondary pa-1" style="border-radius: 4px">
              <v-icon size="small" class="text-white" :icon="mdiArrowDown" />
            </span>
            <span class="caption"> To Navigate </span>
            <span class="secondary pa-1 ml-3" style="border-radius: 4px">
              <v-icon
                size="small"
                class="text-white"
                :icon="mdiKeyboardReturn"
              />
            </span>
            <span class="caption"> Select</span>
          </v-list-item-title>
        </v-list-item>
      </v-sheet>
    </template>
  </autocomplete-field>
</template>

<script setup>
import { searchGlobally } from "@/api/boss.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, watch, toRef, computed } from "vue";

import { mdiKeyboardReturn, mdiArrowUp, mdiArrowDown } from "@mdi/js";
import { useRouter } from "vue-router";

const props = defineProps({
  showNavHelpers: Boolean,
  asRouterLink: Boolean,
  dataTestid: {
    type: String,
    required: false,
    default: null
  },
  modelValue: {
    type: Object,
    required: false,
    default: () => null
  }
});

const emit = defineEmits(["update:model-value", "blur", "clicked"]);

const router = useRouter();
const snackbar = useSnackbarStore();

const focusedIndex = ref(0);
const items = ref([]);
const loading = ref(false);
const search = ref("");
const searchItem = ref(props.modelValue);

const propValue = toRef(props, "modelValue");

if (propValue.value) items.value.push(propValue.value);

let timer;
watch(search, v => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => globallySearch(v), 200);
});

const inputValue = computed({
  get: () => {
    if (searchItem.value)
      return `${searchItem.value.type}-${searchItem.value.id}`;
    return null;
  },
  set(v) {
    if (props.asRouterLink && v) handleRouteChange(v);
    searchItem.value = items.value.find(i => `${i.type}-${i.id}` === v);
  }
});

function handleRouteChange(v) {
  router.push(v.attrs.to);
}

watch(searchItem, v => {
  if (propValue.value?.type === v?.type && propValue.value?.id === v?.id)
    return;

  emit("update:model-value", searchItem.value);
});
watch(propValue, v => {
  if (v?.type === searchItem.value?.type && v?.id === searchItem.value?.id)
    return;
  searchItem.value = v;
});

async function globallySearch(value) {
  try {
    if (!value) return;
    if (items.value.some(i => i.text === value)) return;
    if (value.length < 2) return;
    loading.value = true;

    const res = await searchGlobally(value);
    if (!res) return;

    focusedIndex.value = 0;
    items.value.splice(0, items.value.length);
    res.forEach((item, index) => {
      const matchIndex = item.text.toLowerCase().indexOf(value.toLowerCase());
      const texts = [];
      if (matchIndex === -1) {
        texts.push({ title: item.text, classes: "font-weight-light" });
      } else {
        const start = item.text.slice(0, matchIndex);
        const match = item.text.slice(matchIndex, matchIndex + value.length);
        const end = item.text.slice(matchIndex + value.length);

        if (start) {
          texts.push({
            title: start,
            classes: "font-weight-light"
          });
        }
        if (match) {
          texts.push({
            title: match,
            match: true,
            classes: "font-weight-bold"
          });
        }
        if (end) {
          texts.push({
            title: end,
            classes: "font-weight-light"
          });
        }
      }
      const attrs = {};
      if (props.asRouterLink) {
        attrs.to = item.routerLink;
      }
      items.value.push({
        title: item.text,
        type: item.type,
        id: item.id,
        texts,
        value: `${item.type}-${item.id}`,
        index,
        attrs
      });
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const searchRef = ref(null);

function focus() {
  searchRef.value.$el.querySelector("input").focus();
}

function clear() {
  inputValue.value = null;
  items.value.splice(0, items.value.length);
}

function onEnterClick(item) {
  emit("clicked");
  router.push(item.attrs.to);
}

defineExpose({ focus, clear });
</script>
