<template>
  <div>
    <v-row v-if="!disabled" align="center" class="ma-3">
      <agent-search
        v-model="newAgent"
        hide-details
        clearable
        label="Agent"
        data-testid="commission-split-agent-search"
        :disabled="disabled"
      />
      <app-button
        class="text-none ml-2"
        color="accent"
        data-testid="commission-split-agent-create"
        text="Add"
        :prepend-icon="mdiPlus"
        :loading="creatingCommissionSplit"
        :disabled="disabled"
        @click="createCommissionSplit"
      />
    </v-row>
    <v-list v-if="hasCommissionSplits">
      <v-list-item v-for="split in commissionSplits" :key="split.id">
        <template #prepend>
          <v-img
            :src="split.agent.avatar"
            height="50"
            width="50"
            class="mr-3"
          />
        </template>

        <v-list-item-title>
          <v-row class="pa-3" align="center">
            {{ split.agent.name }}
            <app-button
              v-if="!disabled"
              :icon="mdiDelete"
              color="error"
              variant="text"
              density="comfortable"
              :data-testid="`commission-split-delete-${split.agent.name}`"
              :loading="split.loading"
              @click="deleteCommissionSplit(split)"
            />
            <v-spacer />
            <active-save-indicator
              :controller="savingBuffer[split.id].controller.value"
              class="mr-2"
            />
            {{ split.splitPercent }}%
          </v-row></v-list-item-title
        >
        <v-slider
          v-if="!disabled"
          v-model="split.splitPercent"
          min="1"
          step="1"
          max="99"
          color="primary"
          class="mx-3"
          hide-details
          :data-testid="`commission-split-slider-${split.agent.name}`"
          :disabled="disabled"
          @update:model-value="updateCommissionSplit(split)"
        />
      </v-list-item>
      <div class="px-4">
        <v-divider />
        <v-row class="pa-3">
          <v-spacer />
          <h3
            class="pt-3"
            :class="{
              'text-success': validPercents,
              'text-error': !validPercents
            }"
          >
            {{ advisorName }}'s Split: {{ agentSplit }}%
          </h3>
        </v-row>
      </div>
    </v-list>
    <v-row
      v-else-if="!disabled"
      style="height: 100px"
      align="center"
      justify="center"
    >
      <p style="text-align: center">
        No Commission Splits Found. You can add an agent to split commissions
        with above.
      </p>
    </v-row>
  </div>
</template>

<script setup>
import AgentSearch from "@/components/shared/AgentSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiPlus, mdiDelete } from "@mdi/js";
import { useActiveSave } from "@/composables/active-save.composable";
import { ref, watch, computed, toRef, unref, markRaw } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const props = defineProps({
  modelValue: { type: Array, required: true },
  advisorName: { type: String, default: null },
  updateFunc: { type: Function, required: true },
  createFunc: { type: Function, required: true },
  deleteFunc: { type: Function, required: true },
  disabled: Boolean
});

const emit = defineEmits(["update:model-value"]);

const modelValue = toRef(props, "modelValue");

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const savingBuffer = {};
unref(modelValue).forEach(i => {
  savingBuffer[i.id] = useActiveSave();
});

const newAgent = ref(null);
const commissionSplits = ref(unref(modelValue));
const creatingCommissionSplit = ref(false);

watch(commissionSplits, v => emit("update:model-value", v), { deep: true });

const hasCommissionSplits = computed(() => {
  return Boolean(commissionSplits.value.length);
});
const accumulatedSplits = computed(() => {
  const reducer = (accumulator, current) => accumulator + current.splitPercent;
  return commissionSplits.value.reduce(reducer, 0);
});
const agentSplit = computed(() => {
  return 100 - accumulatedSplits.value;
});
const validPercents = computed(() => {
  return agentSplit.value >= 1;
});

function updateCommissionSplit(cs) {
  if (props.disabled) return;
  if (!validPercents.value) {
    snackbar.showErrorSnackbar({
      message:
        "Commission Splits Exceed 100%, You must decrease an agents commission split."
    });
    return;
  }

  // Only can change split percent for now
  savingBuffer[cs.id].debounceUpdate(() => props.updateFunc(cs));
}
function deleteCommissionSplit(commissionSplit) {
  if (props.disabled) return;

  const func = async () => {
    await props.deleteFunc(commissionSplit);
    const index = commissionSplits.value.findIndex(
      s => s.id === commissionSplit.id
    );
    commissionSplits.value.splice(index, 1);
  };
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Are you sure that you want to remove ${commissionSplit.agent.name} from ${props.advisorName}'s commission splits?`,
    subtitle: "This cannot be undone",
    func
  });
}
async function createCommissionSplit() {
  if (props.disabled) return;
  if (!newAgent.value?.id) return;
  if (2 + accumulatedSplits.value > 100) {
    snackbar.showErrorSnackbar({
      message: `Commission Splits will exceed 100% by adding ${newAgent.value.name}, You must decrease an agents commission split.`
    });
    return;
  }
  creatingCommissionSplit.value = true;
  try {
    const commissionSplit = await props.createFunc(newAgent.value);
    commissionSplits.value.push(commissionSplit);

    newAgent.value = null;

    savingBuffer[commissionSplit.id] = useActiveSave();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 5000
    });
  } finally {
    creatingCommissionSplit.value = false;
  }
}
</script>
