import { getHttpClient } from "@/http-client";
import { setQueuedTransactionFromRequest } from "@/models/QueuedTransaction";
const baseURL = "/api/boss/queued-transactions";

export async function getQueuedTransactions(params) {
  const { data } = await getHttpClient().get(baseURL, {
    params
  });

  return {
    items: data.transactions.map(setQueuedTransactionFromRequest),
    meta: data.meta
  };
}

export function toggleIgnoreQueuedTransaction(id, ignore) {
  return getHttpClient().put(`${baseURL}/${id}`, { ignore });
}
