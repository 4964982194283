<template>
  <v-card flat tile>
    <v-card-title>Training (Optional)</v-card-title>
    <v-card-text> {{ subtitle }} </v-card-text>
    <v-card-text>
      <v-row>
        <v-col v-if="!store.isSaas" cols="12" md="6">
          <training-item
            title="AML"
            :training="store.aml"
            :expires-in-months="24"
            @create="createNewAml"
            @delete="deleteCurrentAml"
            @valid="handleValid"
          >
            You're required to complete an
            <strong>Anti-Money-Laundering course</strong> every 2 years for
            certain carrier appointments. Please add your AML below. If you need
            to take a new course, most agents can complete a free AML course at
            <a href="https://aml.limra.com" target="_blank">
              https://aml.limra.com
            </a>
            and upload a screenshot of their completed course. You can learn
            more about AML
            <a
              href="https://app.back9ins.com/help-center/articles/12"
              target="_blank"
            >
              here.
            </a>
          </training-item>
        </v-col>
        <v-col cols="12" md="6">
          <training-item
            title="Reg 187"
            :training="store.reg187"
            @create="createNewReg187"
            @delete="deleteReg187"
          >
            Reg 187 training is
            <strong>
              required to sell life insurance and annuities in the state of New
              York
            </strong>
            before taking the application. Please add your Reg 187 training
            below. If you need to take a Reg 187 course, you can purchase it
            from websites such as
            <a href="https://www.webce.com" target="blank">
              https://webce.com
            </a>
            and upload a screenshot of your completed course. Please note
            product specific training is also required before taking the
            application. View the product within BOSS to view product specific
            training.
          </training-item>
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import TrainingItem from "@/components/settings/training/TrainingItem.vue";

import { TRAININGS } from "@/models/AgentSettings";
import { useDialogStore } from "@/stores/dialog";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { computed, markRaw, onMounted } from "vue";
import CreateTrainingDialog from "@/dialogs/CreateTrainingDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const store = useAgentSettingsStore();
const subtitle = computed(() => {
  if (store.isSaas) return "Add you Reg 187 training here.";
  return "Add your AML and Reg 187 training here.";
});

const dialog = useDialogStore();
async function createNewAml() {
  const res = await dialog.showDialog({
    component: markRaw(CreateTrainingDialog),
    advisorId: store.id,
    scrollable: true,
    expiresInMonths: 24,
    title: "AML",
    category: TRAININGS.AML
  });
  if (!res?.training) return;
  store.setAml(res.training);
}
async function createNewReg187() {
  const res = await dialog.showDialog({
    component: markRaw(CreateTrainingDialog),
    advisorId: store.id,
    scrollable: true,
    title: "Reg 187",
    category: TRAININGS.REG_187
  });
  if (!res?.training) return;
  store.setReg187(res.training);
}
function deleteCurrentAml() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Training",
    subtitle: "This cannot be undone",
    func: store.deleteAml
  });
}

function deleteReg187() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Training",
    subtitle: "This cannot be undone",
    func: store.deleteReg187
  });
}

function handleValid(valid) {
  store.setValidPage({ page: "trainings", valid: Boolean(valid) });
}

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
</script>
