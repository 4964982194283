import { getHttpClient } from "@/http-client";
import { setCompBuilderFromRequest } from "@/models/CompBuilder";
const baseURL = "api/boss/comp_builders";

export async function createCompBuilder(comp_builder) {
  const { data } = await getHttpClient().post(baseURL, { comp_builder });
  return setCompBuilderFromRequest(data.comp_builder);
}
export function updateCompBuilder(id, comp_builder) {
  return getHttpClient().put(`${baseURL}/${id}`, { comp_builder });
}
export function deleteCompBuilder(id) {
  return getHttpClient().delete(`${baseURL}/${id}`);
}
