import { getHttpClient } from "@/http-client";
import { setApplicationQuestionFromRequest } from "@/models/FormMapping";
const baseUrl = "api/boss/application_questions";
/**
 * @param name
 * @param smart
 * @returns {Promise<ApplicationQuestion[] | Error>}
 */
export const getApplicationQuestionsByName = async params => {
  const { data } = await getHttpClient().get(`${baseUrl}/search`, {
    params
  });

  return data.questions.map(setApplicationQuestionFromRequest);
};
