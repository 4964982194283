<template>
  <v-row dense class="ma-0">
    <v-col cols="12">
      <h1 class="pl-1">BackNine Office Holidays</h1>
    </v-col>
    <v-col
      v-for="holiday in holidays"
      :key="holiday.text"
      cols="6"
      md="3"
      sm="4"
    >
      <v-card height="100%">
        <v-card-title>
          <v-col cols="12" class="text-grey pa-0">
            <timestamp-formatter
              :model-value="holiday.date"
              parser="sole-day"
              format="full-localize"
            />
          </v-col>
          <timestamp-formatter
            :model-value="holiday.date"
            parser="sole-day"
            format="full-month-date"
          />
        </v-card-title>
        <v-card-text class="pb-3 mt-n1">
          {{ holiday.title }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { getHolidays } from "@/api/boss.service";
import { useHead } from "@unhead/vue";
import { ref } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers.js";

useHead({ title: "Holidays" });

const holidays = ref([]);
const snackbar = useSnackbarStore();
async function fetchHolidays() {
  try {
    holidays.value = await getHolidays();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

fetchHolidays();
</script>
