import { getOauthProviderLink, getSamlLink } from "@/api/auth.service";
import { firelightSamlResponse } from "@/api/idp.service";
import { useInstanceStore } from "@/stores/instance";

function ssoFormItem(key, value) {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = key;
  input.value = value;
  return input;
}

function generateRelayStateItem(value) {
  return ssoFormItem("RelayState", value);
}

async function generateCsrfSsoItem() {
  const instance = useInstanceStore();
  // ensure up-to-date token
  await instance.setCsrfToken();
  return ssoFormItem("authenticity_token", instance.csrfToken);
}

function generateProviderItem(provider) {
  return ssoFormItem("provider", provider);
}

export function generateSsoOriginParameter() {
  const locationUrl = new URL(location);
  const redirect = locationUrl.searchParams.get("redirect");

  let origin = null;
  if (redirect) {
    origin = `${location.origin}${redirect}`;
  } else if (
    ["/sign-in", "/sign-up", "/join/"].some(p =>
      location.pathname.startsWith(p)
    )
  ) {
    origin = location.origin;
  } else {
    origin = location.href;
  }
  return origin;
}

async function ssoAction(actionLink, provider, type, email = null) {
  const form = document.createElement("form");
  form.method = "POST";

  const origin = generateSsoOriginParameter();

  const link = new URL(actionLink);

  form.appendChild(await generateCsrfSsoItem());
  // used for SAML
  if (type === "saml") {
    form.appendChild(generateProviderItem(provider));
    const relayState = btoa(
      JSON.stringify({ origin, referrer: document.referrer })
    );
    form.appendChild(generateRelayStateItem(relayState));
  } else {
    link.searchParams.append("origin", origin);
    if (email) link.searchParams.append("email", email);
  }

  form.action = link.toString();
  if (import.meta.env.VITE_COMPONENT_TESTING) {
    if (document.getElementById("sso-form")) {
      document.body.removeChild(document.getElementById("sso-form"));
    }
    form.id = "sso-form";
    document.body.appendChild(form);
  } else {
    document.body.appendChild(form);
    form.submit();
  }
}

// Execute Actions are used for Form POST sign ins
export const executeSamlSso = provider =>
  ssoAction(getSamlLink(), provider, "saml");

export const executeOAuthSso = (provider, email = null) =>
  ssoAction(getOauthProviderLink(provider), provider, "oauth", email);

export const executeMicrosoftSso = () => executeOAuthSso("microsoft");
export const executeWealthboxSso = () => executeOAuthSso("wealthbox");

export async function executeFirelightSSO(eappUuid = null) {
  const { samlUrl, samlResponse } = await firelightSamlResponse(eappUuid);

  const form = document.createElement("form");
  form.id = "firelight-saml-form";
  form.method = "POST";
  form.action = samlUrl;

  const input = document.createElement("input");
  input.type = "hidden";
  input.name = "SamlResponse";
  input.value = samlResponse;

  form.appendChild(input);
  document.body.appendChild(form);

  if (!import.meta.env.VITE_COMPONENT_TESTING) form.submit();
}

export async function executeStratecisionSSO() {
  const form = document.createElement("form");
  form.id = "stratecision-sso-form";
  form.style.display = "none";
  form.method = "POST";
  form.action = "https://www.ltcselect.com";

  const inputAction = document.createElement("input");
  inputAction.type = "hidden";
  inputAction.name = "Action";
  inputAction.value = "SetLogin";
  form.appendChild(inputAction);

  const inputAccountName = document.createElement("input");
  inputAccountName.type = "hidden";
  inputAccountName.name = "AccountName";
  inputAccountName.value = "b9user";
  form.appendChild(inputAccountName);

  const inputPassword = document.createElement("input");
  inputPassword.type = "hidden";
  inputPassword.name = "Password";
  inputPassword.value = "b9pw";
  form.appendChild(inputPassword);

  document.body.appendChild(form);
  if (!import.meta.env.VITE_COMPONENT_TESTING) form.submit();
}
