import { Transaction } from "@/models/Transaction";
import { defineStore } from "pinia";
export const useTransactionCreate = defineStore("transaction-create", {
  state: () => ({
    simulationErrorMessage: "",
    transaction: Transaction(),
    forecast: {},
    payments: [],
    commissions: [],
    transactions: [],
    parties: [],
    loading: false,
    simulatingTransaction: false,
    simulatedTransaction: false,
    loadingCaseTransactions: false,
    loadedCaseTransactions: false,
    preserveFieldValues: true,
    finalOverride: null,
    fetchingPolicy: false,
    fetchedPolicy: false
  }),
  getters: {
    backnineForecast() {
      return this.forecast?.backnine || [];
    },
    totalForecast() {
      return this.forecast?.total || [];
    }
  }
});
