<template>
  <v-card color="section">
    <v-card-title>Product Details</v-card-title>
    <v-card-text>
      <v-row dense>
        <template v-if="isGroupFour">
          <v-col cols="12" lg="4" md="6">
            <text-field
              v-model="name"
              label="Name"
              data-lpignore="true"
              data-testid="name"
              :prepend-inner-icon="mdiCubeOutline"
              v-bind="nameValidation"
              @update:model-value="updateName"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.name.controller.value"
                />
              </template>
            </text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <select-field
              v-model="line"
              data-testid="line"
              label="Line"
              placeholder="Select Line"
              :prepend-inner-icon="mdiCube"
              :items="LINES"
              v-bind="lineValidation"
              @update:model-value="updateLine"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.line.controller.value"
                />
              </template>
            </select-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <select-field
              v-model="linePercentage"
              data-testid="line-percentage"
              label="Line Percentage"
              :prepend-inner-icon="mdiPercent"
              :disabled="isAnnuity"
              :items="LINE_PERCENTAGES"
              v-bind="linePercentageValidation"
              @update:model-value="updateLinePercentage"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.linePercentage.controller.value"
                />
              </template>
            </select-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <text-field
              v-model="activeYears"
              data-lpignore="true"
              data-testid="active-years"
              label="Active Years"
              :prepend-inner-icon="mdiCalendar"
              v-bind="activeYearsValidation"
              @update:model-value="updateActiveYears"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.activeYears.controller.value"
                />
              </template>
            </text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <text-field
              label="BackNine Policy Fee"
              data-testid="backnine-policy-fee"
              disabled
              success
              :prepend-inner-icon="mdiCurrencyUsd"
              :model-value="125"
            />
          </v-col>

          <v-col v-if="isGroupFour" cols="12" lg="4" md="6">
            <div class="checkbox-width mb-3">
              <autocomplete-field
                v-model="training"
                dense
                data-testid="product-training"
                item-title="name"
                return-object
                clearable
                persistent-hint
                hint=" "
                label="Product Training"
                :active-save-controller="savingBuffer.training.controller"
                :prepend-inner-icon="mdiSchool"
                :loading="loadingTrainingItems"
                :items="trainingItems"
                :success="Boolean(training?.id)"
                @update:model-value="updateTraining"
              >
                <template #item="{ props: templateProps, item }">
                  <v-list-item
                    v-bind="templateProps"
                    lines="three"
                    style="max-width: 40rem"
                    :title="item.title"
                    :subtitle="item.raw.instructions"
                  />
                </template>
                <template #message>
                  <a
                    role="button"
                    data-testid="create-new-training"
                    @click="createNewTraining"
                  >
                    Add New Training?
                  </a>
                </template>
              </autocomplete-field>
            </div>
          </v-col>

          <v-col v-if="showAgeNearest" cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <checkbox-field
                v-model="ageNearest"
                dense
                label="Age Nearest"
                hide-details
                class="mt-1"
                data-testid="age-nearest"
                :success="Boolean(ageNearest)"
                @update:model-value="updateAgeNearest"
              >
                <template #label>
                  Age Nearest
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer.ageNearest.controller.value"
                  />
                </template>
              </checkbox-field>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width mb-3">
              <checkbox-field
                v-model="eDelivery"
                dense
                hide-details
                class="mt-1"
                data-testid="e-delivery"
                :success="eDelivery"
                @update:model-value="updateEDelivery"
              >
                <template #label>
                  Carrier eDelivery
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer.eDelivery.controller.value"
                  />
                </template>
              </checkbox-field>
            </div>
          </v-col>
          <v-col v-if="showConversion" cols="12">
            <textarea-field
              v-model="conversion"
              label="Conversion"
              data-testid="conversion"
              :prepend-inner-icon="mdiText"
              :success="Boolean(conversion)"
              @update:model-value="updateConversion"
            >
              <template #append-inner>
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer.conversion.controller.value"
                />
              </template>
            </textarea-field>
          </v-col>
        </template>
        <v-col cols="12">
          <textarea-field
            v-model="freeWithdrawals"
            label="Free Withdrawals"
            data-testid="free-withdrawals"
            :success="Boolean(freeWithdrawals)"
            :prepend-inner-icon="mdiText"
            @update:model-value="updateFreeWithdrawals"
          >
            <template #append-inner>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.freeWithdrawals.controller.value"
              />
            </template>
          </textarea-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <template v-if="isGroupFour">
      <v-card-title>Product Percentages</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="streetFirst"
              data-testid="street-first"
              data-lpignore="true"
              label="Street First"
              :max="200"
              :decimal-length="4"
              :prepend-inner-icon="mdiPercent"
              v-bind="streetFirstValidation"
              @update:model-value="updateStreetFirst"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.streetFirst.controller.value"
                />
              </template>
            </decimal-input>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="streetExcess"
              data-lpignore="true"
              label="Street Excess"
              :max="100"
              :decimal-length="4"
              data-testid="street-excess"
              :prepend-inner-icon="mdiPercent"
              v-bind="streetExcessValidation"
              @update:model-value="updateStreetExcess"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.streetExcess.controller.value"
                />
              </template>
            </decimal-input>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="streetRenewal"
              data-lpignore="true"
              label="Street Renewal"
              :max="100"
              :decimal-length="4"
              data-testid="street-renewal"
              :prepend-inner-icon="mdiPercent"
              v-bind="streetRenewalValidation"
              @update:model-value="updateStreetRenewal"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.streetRenewal.controller.value"
                />
              </template>
            </decimal-input>
          </v-col>
          <v-col v-if="showPua" cols="12" lg="4" md="6">
            <decimal-input
              v-model="streetPua"
              data-lpignore="true"
              label="Street PUA"
              :max="100"
              :decimal-length="4"
              data-testid="street-pua"
              :prepend-inner-icon="mdiPercent"
              v-bind="streetPuaValidation"
              @update:model-value="updateStreetPua"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.streetPua.controller.value"
                />
              </template>
            </decimal-input>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="totalFirst"
              data-lpignore="true"
              label="Total First"
              :max="300"
              :decimal-length="4"
              data-testid="total-first"
              :prepend-inner-icon="mdiPercent"
              v-bind="totalFirstValidation"
              @update:model-value="updateTotalFirst"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.totalFirst.controller.value"
                />
              </template>
            </decimal-input>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="totalExcess"
              data-lpignore="true"
              label="Total Excess"
              :max="200"
              :decimal-length="4"
              data-testid="total-excess"
              :prepend-inner-icon="mdiPercent"
              v-bind="totalExcessValidation"
              @update:model-value="updateTotalExcess"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.totalExcess.controller.value"
                />
              </template>
            </decimal-input>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="totalRenewal"
              data-lpignore="true"
              label="Total Renewal"
              :max="200"
              :decimal-length="4"
              data-testid="total-renewal"
              :prepend-inner-icon="mdiPercent"
              v-bind="totalRenewalValidation"
              @update:model-value="updateTotalRenewal"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.totalRenewal.controller.value"
                />
              </template>
            </decimal-input>
          </v-col>
          <v-col v-if="showPua" cols="12" lg="4" md="6">
            <decimal-input
              v-model="totalPua"
              data-lpignore="true"
              label="Total PUA"
              :max="200"
              :decimal-length="4"
              data-testid="total-pua"
              :prepend-inner-icon="mdiPercent"
              v-bind="totalPuaValidation"
              @update:model-value="updateTotalPua"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.totalPua.controller.value"
                />
              </template>
            </decimal-input>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <text-field
              v-model="chargeback"
              data-lpignore="true"
              label="Chargeback"
              data-testid="chargeback"
              :prepend-inner-icon="mdiCreditCard"
              :success="Boolean(chargeback)"
              @update:model-value="updateChargeback"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.chargeback.controller.value"
                />
              </template>
            </text-field>
          </v-col>
          <v-col v-if="showSurrenderPeriod" cols="12" lg="4" md="6">
            <integer-input
              v-model="surrenderPeriod"
              data-lpignore="true"
              label="Surrender Period"
              data-testid="surrender-period"
              :prepend-inner-icon="mdiCalendar"
              :success="Boolean(surrenderPeriod)"
              @update:model-value="updateSurrenderPeriod"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.surrenderPeriod.controller.value"
                />
              </template>
            </integer-input>
          </v-col>
          <v-col v-if="showRollingTarget" cols="12" lg="4" md="6">
            <text-field
              v-model="rollingTarget"
              data-lpignore="true"
              label="Rolling Target"
              data-testid="rolling-target"
              :prepend-inner-icon="mdiSack"
              :success="Boolean(rollingTarget)"
              @update:model-value="updateRollingTarget"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.rollingTarget.controller.value"
                />
              </template>
            </text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
    </template>

    <v-card-title> Product Guides </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="4">
          <select-field
            v-model="advisorGuide"
            label="Advisor Guide"
            clearable
            :item-title="guideTitle"
            data-testid="advisor-guide"
            :prepend-inner-icon="mdiAccountTie"
            :items="advisorGuides"
            :success="Boolean(advisorGuide)"
            return-object
            persistent-hint
            hint=" "
            @update:model-value="updateAdvisorGuide"
          >
            <template #append-inner>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.advisorGuide.controller.value"
              />
            </template>
            <template #message>
              <a
                data-testid="upload-new-advisor-guide"
                role="button"
                @click="uploadNewAdvisorGuide"
                >Upload New Advisor Guide</a
              >
            </template>
          </select-field>
        </v-col>
        <v-col cols="12" md="4">
          <select-field
            v-model="consumerGuide"
            label="Consumer Guide"
            clearable
            :item-title="guideTitle"
            data-testid="consumer-guide"
            :prepend-inner-icon="mdiAccount"
            :success="Boolean(consumerGuide)"
            :items="consumerGuides"
            return-object
            persistent-hint
            hint=" "
            @update:model-value="updateConsumerGuide"
          >
            <template #append-inner>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.consumerGuide.controller.value"
              />
            </template>
            <template #message>
              <a
                data-testid="upload-new-consumer-guide"
                role="button"
                @click="uploadNewConsumerGuide"
                >Upload New Consumer Guide</a
              >
            </template>
          </select-field>
        </v-col>
        <v-col cols="12" md="4">
          <select-field
            v-model="underwritingGuide"
            label="Underwriting Guide"
            clearable
            :item-title="guideTitle"
            data-testid="underwriting-guide"
            :prepend-inner-icon="mdiStethoscope"
            :success="Boolean(underwritingGuide)"
            :items="underwritingGuides"
            return-object
            persistent-hint
            hint=" "
            @update:model-value="updateUnderwritingGuide"
          >
            <template #append-inner>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.underwritingGuide.controller.value"
              />
            </template>
            <template #message>
              <a
                data-testid="upload-new-underwriting-guide"
                role="button"
                @click="uploadNewUnderwritingGuide"
                >Upload New Underwriting Guide</a
              >
            </template>
          </select-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import DecimalInput from "@/components/shared/DecimalInput.vue";
import DocumentDialog from "@/dialogs/DocumentDialog.vue";
import TrainingDialog from "@/components/trainings/TrainingDialog.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { LINES, LINE_PERCENTAGES } from "@/models/Product";

import { storeToRefs } from "pinia";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, markRaw, ref, watch } from "vue";
import { useProductView } from "@/stores/product-view";
import useVuelidate from "@vuelidate/core";
import {
  mdiCubeOutline,
  mdiCube,
  mdiCalendar,
  mdiCurrencyUsd,
  mdiPercent,
  mdiCreditCard,
  mdiSack,
  mdiText,
  mdiAccountTie,
  mdiAccount,
  mdiStethoscope,
  mdiSchool
} from "@mdi/js";
import { computedValidation } from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { CARRIER_DOCUMENT_CATEGORY } from "@/models/Carrier";
import { uploadCarrierDocument } from "@/api/carrier.service";
import { setProductAdvisorGuideFromDocument } from "@/models/Product";
import { timestampFormatter, parseErrorMessage } from "@/util/helpers";
import { getTrainings } from "@/api/trainings.service";
import { useSnackbarStore } from "@/stores/snackbar";

const productView = useProductView();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const { isGroupFour } = storeToRefs(useUserStore());

const trainingItems = ref([]);
const loadingTrainingItems = ref(false);

const {
  guides,
  showConversion,
  conversion,
  line,
  linePercentage,
  planType,
  name,
  activeYears,
  streetFirst,
  streetExcess,
  streetRenewal,
  streetPua,
  totalFirst,
  totalExcess,
  totalRenewal,
  totalPua,
  chargeback,
  surrenderPeriod,
  rollingTarget,
  ageNearest,
  eDelivery,
  advisorGuide,
  underwritingGuide,
  consumerGuide,
  freeWithdrawals,
  carrier,
  training
} = storeToRefs(productView);

const v$ = useVuelidate(
  {
    name: { required: Boolean },
    planType: { required: Boolean },
    line: { required: Boolean },
    linePercentage: { required: Boolean },
    activeYears: { required: Boolean },
    streetFirst: { required: v => v || v === 0 },
    streetExcess: { required: v => v || v === 0 },
    streetRenewal: { required: v => v || v === 0 },
    streetPua: { required: v => !showPua.value || v || v === 0 },
    totalFirst: { required: v => v || v === 0 },
    totalExcess: { required: v => v || v === 0 },
    totalRenewal: { required: v => v || v === 0 },
    totalPua: { required: v => !showPua.value || v || v === 0 }
  },
  {
    name,
    planType,
    line,
    linePercentage,
    activeYears,
    streetFirst,
    streetExcess,
    streetRenewal,
    streetPua,
    totalFirst,
    totalExcess,
    totalRenewal,
    totalPua
  },
  { $scope: false, $autoDirty: true }
);

const savingBuffer = {
  activeYears: useActiveSave(),
  ageNearest: useActiveSave(),
  carrier: useActiveSave(),
  eDelivery: useActiveSave(),
  chargeback: useActiveSave(),
  line: useActiveSave(),
  linePercentage: useActiveSave(),
  name: useActiveSave(),
  planType: useActiveSave(),
  rollingTarget: useActiveSave(),
  streetExcess: useActiveSave(),
  streetFirst: useActiveSave(),
  streetRenewal: useActiveSave(),
  streetPua: useActiveSave(),
  surrenderPeriod: useActiveSave(),
  totalExcess: useActiveSave(),
  totalFirst: useActiveSave(),
  totalRenewal: useActiveSave(),
  totalPua: useActiveSave(),
  training: useActiveSave(),
  conversion: useActiveSave(),
  advisorGuide: useActiveSave(),
  consumerGuide: useActiveSave(),
  underwritingGuide: useActiveSave(),
  freeWithdrawals: useActiveSave()
};

const nameValidation = computedValidation(v$.value.name, {
  required: "Required"
});
const lineValidation = computedValidation(v$.value.line, {
  required: "Required"
});
const linePercentageValidation = computedValidation(v$.value.linePercentage, {
  required: "Required"
});
const activeYearsValidation = computedValidation(v$.value.activeYears, {
  required: "Required"
});
const streetFirstValidation = computedValidation(v$.value.streetFirst, {
  required: "Required"
});
const streetExcessValidation = computedValidation(v$.value.streetExcess, {
  required: "Required"
});
const streetRenewalValidation = computedValidation(v$.value.streetRenewal, {
  required: "Required"
});
const streetPuaValidation = computedValidation(v$.value.streetPua, {
  required: "Required"
});
const totalFirstValidation = computedValidation(v$.value.totalFirst, {
  required: "Required"
});
const totalExcessValidation = computedValidation(v$.value.totalExcess, {
  required: "Required"
});
const totalRenewalValidation = computedValidation(v$.value.totalRenewal, {
  required: "Required"
});
const totalPuaValidation = computedValidation(v$.value.totalPua, {
  required: "Required"
});

const underwritingGuides = computed(() =>
  guides.value.filter(
    g => g.category === CARRIER_DOCUMENT_CATEGORY.UNDERWRITING_GUIDE
  )
);
const consumerGuides = computed(() =>
  guides.value.filter(
    g => g.category === CARRIER_DOCUMENT_CATEGORY.CONSUMER_PRODUCT_GUIDE
  )
);
const advisorGuides = computed(() =>
  guides.value.filter(
    g => g.category === CARRIER_DOCUMENT_CATEGORY.ADVISOR_PRODUCT_GUIDE
  )
);
const showPua = computed(() => planType.value === "Whole Life");
const showAgeNearest = computed(() =>
  ["linked_benefit", "life", "variable_life", "ltc"].includes(line.value)
);
const showRollingTarget = computed(() =>
  ["linked_benefit", "life", "variable_life"].includes(line.value)
);
const showSurrenderPeriod = computed(() =>
  ["annuity", "linked_benefit", "life", "variable_life"].includes(line.value)
);
const isAnnuity = computed(() => line.value === "annuity");
watch(line, () => {
  if (isAnnuity.value) linePercentage.value = 5;
});

function updateName() {
  savingBuffer.name.debounceUpdate(productView.updateName);
}

function updateLine() {
  savingBuffer.line.debounceUpdate(productView.updateLine);
}

function updateLinePercentage() {
  savingBuffer.linePercentage.debounceUpdate(productView.updateLinePercentage);
}

function updateActiveYears() {
  savingBuffer.activeYears.debounceUpdate(productView.updateActiveYears);
}

function updateStreetFirst() {
  savingBuffer.streetFirst.debounceUpdate(productView.updateStreetFirst);
}

function updateStreetExcess() {
  savingBuffer.streetExcess.debounceUpdate(productView.updateStreetExcess);
}

function updateStreetRenewal() {
  savingBuffer.streetRenewal.debounceUpdate(productView.updateStreetRenewal);
}

function updateStreetPua() {
  savingBuffer.streetPua.debounceUpdate(productView.updateStreetPua);
}

function updateTotalFirst() {
  savingBuffer.totalFirst.debounceUpdate(productView.updateTotalFirst);
}

function updateTotalExcess() {
  savingBuffer.totalExcess.debounceUpdate(productView.updateTotalExcess);
}

function updateTotalRenewal() {
  savingBuffer.totalRenewal.debounceUpdate(productView.updateTotalRenewal);
}

function updateTotalPua() {
  savingBuffer.totalPua.debounceUpdate(productView.updateTotalPua);
}

function updateChargeback() {
  savingBuffer.chargeback.debounceUpdate(productView.updateChargeback);
}

function updateSurrenderPeriod() {
  savingBuffer.surrenderPeriod.debounceUpdate(
    productView.updateSurrenderPeriod
  );
}

function updateRollingTarget() {
  savingBuffer.rollingTarget.debounceUpdate(productView.updateRollingTarget);
}

function updateAgeNearest() {
  savingBuffer.ageNearest.debounceUpdate(productView.updateAgeNearest);
}

function updateEDelivery() {
  savingBuffer.eDelivery.debounceUpdate(productView.updateEDelivery);
}

function updateConversion() {
  savingBuffer.conversion.debounceUpdate(productView.updateConversion);
}

function updateAdvisorGuide() {
  savingBuffer.advisorGuide.debounceUpdate(() =>
    productView.updateAdvisorGuide()
  );
}

function updateConsumerGuide() {
  savingBuffer.consumerGuide.debounceUpdate(() =>
    productView.updateConsumerGuide()
  );
}

function updateUnderwritingGuide() {
  savingBuffer.underwritingGuide.debounceUpdate(() =>
    productView.updateUnderwritingGuide()
  );
}

function updateFreeWithdrawals() {
  savingBuffer.freeWithdrawals.debounceUpdate(
    productView.updateFreeWithdrawals
  );
}

function updateTraining() {
  savingBuffer.training.update(productView.updateTraining);
}

function guideTitle(guide) {
  const text = [guide.name];

  if (guide.createdAt)
    text.push(
      `(Uploaded ${timestampFormatter(guide.createdAt, "none", "date-time")})`
    );

  return text.join(" · ");
}

function uploadNewAdvisorGuide() {
  uploadNewGuide(
    "Upload New Advisor Guide",
    CARRIER_DOCUMENT_CATEGORY.ADVISOR_PRODUCT_GUIDE,
    productView.updateAdvisorGuide
  );
}

function uploadNewConsumerGuide() {
  uploadNewGuide(
    "Upload New Consumer Guide",
    CARRIER_DOCUMENT_CATEGORY.CONSUMER_PRODUCT_GUIDE,
    productView.updateConsumerGuide
  );
}

function uploadNewUnderwritingGuide() {
  uploadNewGuide(
    "Upload New Underwriting Guide",
    CARRIER_DOCUMENT_CATEGORY.UNDERWRITING_GUIDE,
    productView.updateUnderwritingGuide
  );
}

function uploadNewGuide(title, category, updateGuideFunc) {
  dialog.showDialog({
    component: markRaw(DocumentDialog),
    title,
    categories: [category],
    documentCategoryValue: category,
    func: async ({ category, file }) => {
      const newDoc = await uploadCarrierDocument(carrier.value.id, {
        category,
        file
      });

      const newGuide = setProductAdvisorGuideFromDocument(newDoc);
      guides.value.push(newGuide);

      return updateGuideFunc(newGuide);
    }
  });
}

async function retrieveTrainings() {
  try {
    loadingTrainingItems.value = true;
    trainingItems.value = await getTrainings();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingTrainingItems.value = false;
  }
}

async function createNewTraining() {
  const res = await dialog.showDialog({
    component: markRaw(TrainingDialog)
  });
  if (!res?.newTraining) return;
  training.value = res.newTraining;
  updateTraining();
  retrieveTrainings();
}

if (isGroupFour.value) retrieveTrainings();
</script>
