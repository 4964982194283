<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-timeline v-if="reviews.length" align-top dense>
        <v-timeline-item
          v-for="({ reviewDate, review, reviewer }, index) in reviews"
          :key="index"
          :data-testid="`review-${index}`"
          dot-color="primary"
        >
          <v-card variant="outlined">
            <v-card-title v-if="reviewDate" data-testid="review-title">
              {{ timestampFormatter(reviewDate, "sole-day") }}
            </v-card-title>
            <v-card-subtitle data-testid="review-reviewed-by">
              {{ reviewer }}
            </v-card-subtitle>
            <v-card-text data-testid="review-content">
              {{ review }}
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <p v-else data-testid="no-reviews">No Reviews</p>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { timestampFormatter } from "@/util/helpers";
import { useEmployeeView } from "@/stores/employee-view";
import { storeToRefs } from "pinia";
const employeeView = useEmployeeView();

const { reviews } = storeToRefs(employeeView);
</script>
