import { Document, setDocumentFromRequest } from "@/models/Document";

export function ErrorsOmissions(model = {}) {
  return {
    id: model?.id,
    file: model?.file,
    startDate: model?.startDate,
    expirationDate: model?.expirationDate,
    carrier: model?.carrier,
    policyNumber: model?.policyNumber || null,
    caseLimit: model?.caseLimit || null,
    aggregateLimit: model?.aggregateLimit || null,
    eoDocument: Document(model?.eoDocument)
  };
}

export function setErrorsOmissionsFromRaw(model = {}) {
  const eo = ErrorsOmissions();

  eo.id = model?.id;
  eo.file = model?.agentFile;
  eo.startDate = model?.dates?.expiration_date;
  eo.expirationDate = model?.dates?.expiration_date;
  eo.carrier = model?.carrier;
  eo.policyNumber = model?.policy_number || null;
  eo.caseLimit = model?.case_limit || null;
  eo.aggregateLimit = model?.aggregate_limit || null;
  eo.expired = model?.expired;
  eo.eoDocument = setDocumentFromRequest(model?.eo_document);

  return eo;
}

export function setRawFromErrorsOmissions(eo, policyHolder, applyToAll) {
  return {
    eo_document: [eo.file || null],
    start_date: eo.startDate || null,
    expiration_date: eo.expirationDate || undefined,
    carrier: eo.carrier || undefined,
    policy_number: eo.policyNumber || undefined,
    case_limit: eo.caseLimit || undefined,
    aggregate_limit: eo.aggregateLimit || undefined,
    current: 1,
    insurable_id: policyHolder.id,
    insurable_type: policyHolder.type,
    assigning_agents: applyToAll
  };
}
