<template>
  <v-card class="ma-3">
    <v-card-title class="text-h5"> Create a Lead </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <text-field
            v-model="lead.firstName"
            data-testid="first-name"
            data-lpignore="true"
            label="First Name"
            :prepend-inner-icon="mdiAccount"
            :error-messages="firstNameValidation.errors"
            :success="firstNameValidation.success"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="lead.lastName"
            data-testid="last-name"
            data-lpignore="true"
            label="Last Name"
            :prepend-inner-icon="mdiAccountMultiple"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="lead.email"
            data-testid="email"
            data-lpignore="true"
            label="Email"
            :prepend-inner-icon="mdiEmail"
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <marketing-manager-search
            v-model="lead.marketingManager"
            data-testid="marketing-manager"
            :success="marketingManagerValidation.success"
            :error-messages="marketingManagerValidation.errorMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Additional Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <phone-input
            v-model="lead.phoneWork"
            data-lpignore="true"
            data-testid="work-phone"
            label="Work Phone"
            :prepend-inner-icon="mdiDeskphone"
            :success="Boolean(lead.phoneWork)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <phone-input
            v-model="lead.phoneMobile"
            data-lpignore="true"
            data-testid="mobile-phone"
            :prepend-inner-icon="mdiCellphone"
            label="Mobile Phone"
            :success="Boolean(lead.phoneMobile)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <phone-input
            v-model="lead.phoneFax"
            data-lpignore="true"
            label="Fax"
            data-testid="fax-phone"
            :prepend-inner-icon="mdiFax"
            :success="Boolean(lead.phoneFax)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="lead.birthdate"
            label="Birthdate"
            data-testid="birthdate"
            :prepend-inner-icon="mdiCalendar"
            :success="Boolean(lead.birthdate)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="lead.gender"
            label="Gender"
            data-testid="gender"
            :prepend-inner-icon="mdiGenderMaleFemale"
            :success="Boolean(lead.gender)"
            :items="GENDERS"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="lead.category"
            label="Category"
            data-testid="category"
            :prepend-inner-icon="mdiCube"
            :items="LEAD_CATEGORIES"
            :success="Boolean(lead.category)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="lead.followUpDate"
            label="Follow Up"
            data-testid="follow-up-date"
            :prepend-inner-icon="mdiCalendarAccount"
            :success="Boolean(lead.followUpDate)"
          />
        </v-col>

        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <checkbox-field
              v-model="lead.subscribed"
              class="mt-1"
              label="Subscribed"
              data-testid="subscribed"
              :success="Boolean(lead.subscribed)"
            />
          </div>
        </v-col>

        <v-col cols="12">
          <h2 class="mb-2 text-body-1">Home Address</h2>
          <basic-address-input
            v-model:street-address-model="lead.homeAddress.street_address"
            v-model:city-model="lead.homeAddress.city"
            v-model:state-model="lead.homeAddress.state"
            v-model:zip-model="lead.homeAddress.zip"
            autofill-label="Home Address"
            autofill-placeholder="Home Address"
            data-testid="home-address"
            :street-address-validation="homeStreetAddressValidation"
            :city-validation="homeCityValidation"
            :state-validation="homeStateValidation"
            :zip-validation="homeZipValidation"
            @autofill="handleHomeAutofill"
          />
        </v-col>

        <v-col cols="12">
          <h2 class="mb-2 text-body-1">Business Address</h2>
          <basic-address-input
            v-model:street-address-model="lead.businessAddress.street_address"
            v-model:city-model="lead.businessAddress.city"
            v-model:state-model="lead.businessAddress.state"
            v-model:zip-model="lead.businessAddress.zip"
            autofill-label="Business Address"
            autofill-placeholder="Business Address"
            data-testid="business-address"
            :street-address-validation="businessStreetAddressValidation"
            :city-validation="businessCityValidation"
            :state-validation="businessStateValidation"
            :zip-validation="businessZipValidation"
            @autofill="handleBusinessAutofill"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <app-button
        class="text-none"
        color="primary"
        data-testid="create-lead"
        :loading="loading"
        @click="save"
      >
        <v-icon class="mr-1" :icon="mdiPlus" /> Create Lead
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";

import {
  LEAD_CATEGORIES,
  NewLead,
  NewLeadToCreateRequest
} from "@/models/Lead";
import { genders as GENDERS } from "@/data/advisor-data";

import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { useHead } from "@unhead/vue";
import useVuelidate from "@vuelidate/core";

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiEmail,
  mdiDeskphone,
  mdiCellphone,
  mdiFax,
  mdiCalendar,
  mdiGenderMaleFemale,
  mdiCube,
  mdiCalendarAccount,
  mdiPlus
} from "@mdi/js";
import { ref } from "vue";
import { createLead } from "@/api/leads.service";
import { useRouter } from "vue-router";
import { useSnackbarStore } from "@/stores/snackbar";

useHead({ title: "Create Lead" });

const snackbar = useSnackbarStore();
const router = useRouter();
const lead = ref(NewLead());
const loading = ref(false);

const v$ = useVuelidate(
  {
    lead: {
      marketingManager: { required: v => Boolean(v?.id) },
      email: { required: Boolean },
      firstName: { required: v => someTextValidator(true, v, 2) },
      lastName: { required: v => someTextValidator(true, v, 2) },
      homeAddress: {
        street_address: { required: true },
        city: { required: true },
        state: { required: true },
        zip: { required: true }
      },
      businessAddress: {
        street_address: { required: true },
        city: { required: true },
        state: { required: true },
        zip: { required: true }
      }
    }
  },
  {
    lead
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const firstNameValidation = computedValidation(v$.value.lead.firstName, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.lead.lastName, {
  required: "Required"
});
const emailValidation = computedValidation(v$.value.lead.email, {
  required: "Required"
});
const marketingManagerValidation = computedValidation(
  v$.value.lead.marketingManager,
  {
    required: "Required"
  }
);

const homeStreetAddressValidation = computedValidation(
  v$.value.lead.homeAddress.street_address,
  { required: "Required" }
);
const homeCityValidation = computedValidation(v$.value.lead.homeAddress.city, {
  required: "Required"
});
const homeStateValidation = computedValidation(
  v$.value.lead.homeAddress.state,
  {
    required: "Required"
  }
);
const homeZipValidation = computedValidation(v$.value.lead.homeAddress.zip, {
  required: "Required"
});

const businessStreetAddressValidation = computedValidation(
  v$.value.lead.businessAddress.street_address,
  { required: "Required" }
);
const businessCityValidation = computedValidation(
  v$.value.lead.businessAddress.city,
  { required: "Required" }
);
const businessStateValidation = computedValidation(
  v$.value.lead.businessAddress.state,
  { required: "Required" }
);
const businessZipValidation = computedValidation(
  v$.value.lead.businessAddress.zip,
  {
    required: "Required"
  }
);

function handleAutofill(components, model) {
  lead.value[model].street_address = components.street_address;
  lead.value[model].city = components.city;
  lead.value[model].state = components.state;
  lead.value[model].zip = components.zip;
}

const handleBusinessAutofill = components =>
  handleAutofill(components, "businessAddress");

const handleHomeAutofill = components =>
  handleAutofill(components, "homeAddress");

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    loading.value = true;
    const id = await createLead(NewLeadToCreateRequest(lead.value));
    if (router) router.push({ name: "LeadView", params: { id } });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 5000
    });
  } finally {
    loading.value = false;
  }
}
</script>
