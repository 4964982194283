<template>
  <v-card flat class="ma-1">
    <v-data-table-server
      v-model:sort-by="table.options.value.sortBy"
      v-model:items-per-page="table.options.value.itemsPerPage"
      v-model:page="table.options.value.page"
      must-sort
      data-testid="cases-table"
      class="transparent-data-table"
      :mobile="null"
      mobile-breakpoint="sm"
      :headers="table.tableHeaders.value"
      :items="table.mappedItems.value"
      :items-length="table.itemsLength.value"
      :loading="table.loading.value"
      :footer-props="table.footerProps.value"
      :items-per-page-options="table.itemsPerPageOptions"
      @update:options="updateOptions"
    >
      <template #top>
        <v-row class="ma-0 pa-3" align="center" dense>
          <h1 class="text-h5">Cases</h1>
          <v-row class="ma-0" justify-sm="end" align="center">
            <table-stats :stats="stats" @hint="handleHint" />
            <v-tooltip v-if="table.filter.value.view !== 'all'" location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiFileExport"
                  variant="text"
                  density="comfortable"
                  class="table-btn"
                  data-testid="cases-table-email-data"
                  v-bind="templateProps"
                  @click="confirmAndEmailData"
                />
              </template>
              <span>Email Data</span>
            </v-tooltip>
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiRefresh"
                  variant="text"
                  density="comfortable"
                  class="table-btn"
                  data-testid="cases-table-refresh-data"
                  v-bind="templateProps"
                  @click="getData"
                />
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
        </v-row>
        <v-divider />
        <table-filter
          :header-props="{
            class: 'mt-0 pa-3',
            rounded: false
          }"
          :model-value="table.filter.value"
          :headers="table.filterHeaders.value"
          :loading="table.loading.value"
          @update="updateFilter"
        />
        <v-divider />
      </template>
      <template #bottom>
        <table-footer
          v-model:page="table.options.value.page"
          v-model:items-per-page="table.options.value.itemsPerPage"
          :items-per-page-options="table.itemsPerPageOptions"
          :items-length="table.itemsLength.value"
        />
      </template>
      <template #[`header.fy_premium`]="{ sortBy, column }">
        <inverted-sort
          :column="column"
          :sort-by="sortBy"
          @update:sort-by="updateSortBy"
        />
      </template>
      <template #[`item.created`]="{ item }">
        <timestamp-formatter :model-value="item.created" />
      </template>
      <template #[`item.conversion_expiry`]="{ item }">
        <timestamp-formatter
          :model-value="item.conversion_expiry"
          parser="sole-day"
        />
      </template>
      <template #[`item.inforce`]="{ item }">
        <timestamp-formatter :model-value="item.inforce" parser="sole-day" />
      </template>
      <template #[`item.closed`]="{ item }">
        <timestamp-formatter :model-value="item.closed" parser="sole-day" />
      </template>
      <template #[`item.product`]="{ item }">
        <div class="truncate">{{ item.product }}</div>
      </template>
      <template #[`item.status`]="{ item }">
        <div class="truncate">{{ item.status }}</div>
      </template>
      <template #[`item.insured`]="{ item }">
        <v-tooltip left>
          <template #activator="{ props: templateProps }">
            <router-link
              :to="getRouterLink('Case', item.additional.id)"
              class="text-none"
              v-bind="templateProps"
            >
              {{ item.insured }}
            </router-link>
          </template>
          <span>View Case</span>
        </v-tooltip>
      </template>
      <template #[`item.fy_premium`]="{ item }">
        <span>
          <currency-formatter
            :model-value="item.fy_premium"
            :decimal-length="0"
          />
        </span>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup>
import { ARTICLES } from "@/data/articles";
import {
  statusFilterOptions,
  lineFilterOptions,
  activeStatusFilter,
  inactiveStatusFilter,
  pendingStatusFilter,
  allStatusFilterOptions
} from "@/data/filter-options";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";
import { useUserStore } from "@/stores/user";

import InvertedSort from "@/components/shared/InvertedSort.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import TableStats from "@/components/shared/data-table/TableStats.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { TableOptions } from "@/classes/data-table/TableOptions";

import { STATES } from "@/data/states";

import { emailCases, getCases } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";

import { computed, markRaw } from "vue";
import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { useHead } from "@unhead/vue";

import {
  mdiBriefcase,
  mdiCurrencyUsd,
  mdiFileExport,
  mdiRefresh
} from "@mdi/js";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import HelpCenterReader from "@/components/help-center/HelpCenterReader.vue";

useHead({ title: "Cases" });

const PLAN_TYPES = [
  { title: "Accidental Death", value: "Accidental Death" },
  { title: "Critical Illness", value: "Critical Illness" },
  { title: "DIA", value: "DIA" },
  { title: "Disability", value: "Disability" },
  { title: "FIA", value: "FIA" },
  { title: "Final Expense", value: "Final Expense" },
  { title: "Fixed", value: "Fixed" },
  { title: "Guaranteed Issue Whole Life", value: "GIWL" },
  { title: "IUL", value: "IUL" },
  { title: "LTC", value: "LTC" },
  { title: "MYGA", value: "MYGA" },
  { title: "SPIA", value: "SPIA" },
  { title: "Term", value: "TRM" },
  { title: "Term - Non Medical", value: "TRM - Non Medical" },
  { title: "Term - ROP", value: "TRM - ROP" },
  { title: "UL", value: "UL" },
  { title: "VUL", value: "VUL" },
  { title: "Whole Life", value: "Whole Life" },
  {
    title: "Whole Life - Non Participating",
    value: "Whole Life - Non Participating"
  },
  { title: "Other", value: "Other" }
];

const user = useUserStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const { casesTable } = storeToRefs(useTableStore());
function generateTable() {
  const headers = [
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "insured.name",
      sortFilterMap: "contract_parties.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Advisor",
      value: "advisor",
      map: "advisor.name",
      sortFilterMap: [
        { key: "advisor_id", value: "id" },
        { key: "advisor_type", value: "type" }
      ],
      displayMap: "name",
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      selectableOptions: statusFilterOptions,
      showInTableIf: ({ status }) => !status,
      sortFilterMap: "status",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrier_name",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Product",
      value: "product",
      map: "product_name",
      sortFilterMap: "products.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Policy Number",
      value: "policy",
      map: "policy_number",
      sortFilterMap: "cases.policy_number",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "FY Premium",
      value: "fy_premium",
      map: "first_year",
      sortFilterMap: "cases.first_year",
      ...TableHeader.IS_INVERTED_SORT,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "line.text",
      selectableOptions: lineFilterOptions,
      sortFilterMap: "products.line",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Created",
      value: "created",
      map: "created_at",
      sortFilterMap: "cases.created_at",
      showInTableIf: ({ status }) => !status || status === "pending",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "Case Manager",
          value: "case_manager_name",
          map: "case_manager_name",
          sortFilterMap: [{ key: "case_manager_id", value: "id" }],
          ...TableHeader.IS_CASE_MANAGER_SEARCH_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_QUERY_FILTER
        })
      : null,
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "Case Concierge",
          value: "case_concierge_name",
          map: "case_concierge_name",
          sortFilterMap: [{ key: "case_concierge_id", value: "id" }],
          ...TableHeader.IS_CASE_MANAGER_SEARCH_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_QUERY_FILTER,
          ...TableHeader.IS_ADDITIONAL
        })
      : null,
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "Manager",
          value: "marketing_manager",
          map: "marketing_manager",
          sortFilterMap: [
            { key: "marketing_manager_id", value: "marketing_manager_id" }
          ],
          ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_QUERY_FILTER
        })
      : null,
    new TableHeader({
      text: "Shared With",
      value: "additionalAdvisor",
      map: "additional_advisor",
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Specific Status",
      value: "caseStatus",
      map: "caseStatus",
      selectableOptionsFn: specificStatusFunc,
      sortFilterMap: "cases.status",
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "Inforce Date",
      value: "inforce",
      map: "inforce_date",
      sortFilterMap: "cases.inforce_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      showIf: ({ status }) => status === "active"
    }),
    new TableHeader({
      text: "Closed Date",
      value: "closed",
      map: "closed_date",
      sortFilterMap: "cases.closed_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      showIf: ({ status }) => status === "inactive"
    }),
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "White Glove",
          value: "whiteGlove",
          map: "whiteGlove",
          sortFilterMap: "white_glove",
          selectableOptions: [
            { title: "Yes", value: true },
            { title: "No", value: false }
          ],
          ...TableHeader.SEND_FALSE,
          ...TableHeader.IS_SELECT_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_QUERY_FILTER,
          ...TableHeader.IS_ADDITIONAL
        })
      : null,
    new TableHeader({
      text: "Death Benefit",
      value: "death_benefit",
      map: "death_benefit",
      sortFilterMap: "death_benefit",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CURRENCY
    }),
    new TableHeader({
      text: "Production Credit",
      value: "premium",
      map: "premium",
      sortFilterMap: "premium",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CURRENCY
    }),
    new TableHeader({
      text: "Conversion Expiry",
      value: "conversion_expiry",
      map: "conversion_expiry",
      sortFilterMap: "cases.conversion_expiry",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      futureDateValues: true,
      showIf: ({ status }) => status === "active",
      showInTableIf: ({ conversion_expiry }) =>
        Boolean(
          conversion_expiry?.value?.start || conversion_expiry?.value?.finish
        )
      // show conversion expiry in the table if it's used
    }),
    new TableHeader({
      text: "Client",
      value: "client",
      map: "client",
      sortFilterMap: "any_contract_party_name",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "State",
      value: "state",
      map: "state",
      sortFilterMap: "state",
      selectableOptions: STATES,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE
    }),
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "Product Types",
          value: "plan_type",
          map: "plan_type",
          selectableOptions: PLAN_TYPES,
          ...TableHeader.IS_ADDITIONAL,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_SELECT_FILTER_TYPE,
          ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
          ...TableHeader.IS_QUERY_FILTER
        })
      : null
  ].filter(Boolean);

  if (user.isGroupTwoPlus) {
    headers.unshift(
      new TableHeader({
        text: "View",
        value: "view",
        map: "view",
        sortFilterMap: "view",
        selectableOptions: [
          { title: "All Cases", value: "all" },
          { title: "My Cases", value: "my" }
        ],
        filterOrder: 1,
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_MANDATORY
      })
    );
  }

  const filter = {
    status: null
  };

  if (user.isGroupTwoPlus) {
    filter.status = "pending";
    filter.view = user.isGroupFour ? "all" : "my";
  }

  if (casesTable.value.filter) {
    Object.keys(casesTable.value.filter).forEach(key => {
      if (!headers.find(header => header.value === key)) return;
      filter[key] = casesTable.value.filter[key];
    });
  }

  let options = TableOptions({ sortBy: [{ key: "created", order: "desc" }] });
  if (Object.keys(casesTable.value.options).length) {
    options = casesTable.value.options;
  } else if (Object.values(casesTable.value.filter).length) {
    options = TableOptions({
      sortBy: [getDefaultSortsByStatus(casesTable.value.filter.status)]
    });
  }

  return useTable({
    filter,
    headers,
    options,
    getData: getCases,
    emailData: emailCases
  });
}
const table = generateTable();

const stats = computed(() => {
  const cases = {
    text: "Cases",
    icon: mdiBriefcase,
    type: "loading",
    dataTestid: "stat-cases"
  };
  const premium = {
    text: "FY Premium",
    icon: mdiCurrencyUsd,
    type: "loading",
    dataTestid: "stat-fy-premium"
  };
  const deathBenefit = {
    text: "Total Death Benefit",
    icon: mdiCurrencyUsd,
    type: "loading",
    dataTestid: "stat-death-benefit"
  };
  const productionCredit = {
    text: "Production Credit",
    icon: mdiCurrencyUsd,
    hint: true,
    hintType: "PRODUCTION_CREDIT",
    type: "loading",
    dataTestid: "stat-production-credit"
  };

  if (table.itemsLength.value || table.itemsLength.value === 0) {
    cases.type = "number";
    cases.value = table.itemsLength.value;
  }

  Object.keys(table.stats.value).forEach(stat => {
    if (stat.indexOf("premium") > -1) {
      premium.type = "dollar";
      premium.value = table.stats.value[stat];
    } else if (stat.indexOf("production") > -1) {
      productionCredit.type = "dollar";
      productionCredit.value = table.stats.value[stat];
    } else if (stat.indexOf("death_benefit") > -1) {
      deathBenefit.type = "dollar";
      deathBenefit.value = table.stats.value[stat];
    }
  });
  return [cases, premium, productionCredit, deathBenefit];
});

function confirmAndEmailData() {
  if (table.filter.value.view === "all") return;
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Email Data?",
    subtitle: "Please confirm you would like this data emailed to you.",
    func: () => emailData()
  });
}
function handleHint({ hintType }) {
  if (hintType === "PRODUCTION_CREDIT") {
    dialog.showDialog({
      component: markRaw(HelpCenterReader),
      id: ARTICLES.PRODUCTION_CREDIT
    });
  }
}

function specificStatusFunc(filters) {
  const dict = {
    active: activeStatusFilter,
    pending: pendingStatusFilter,
    inactive: inactiveStatusFilter
  };
  return dict[filters.status] || allStatusFilterOptions;
}

async function emailData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    table.emailData(additionalFilter);
    snackbar.showSuccessSnackbar({
      message: `You will receive a copy of this data in your inbox shortly.`,
      timeout: 6000
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  }
}

async function getData() {
  try {
    const additionalFilter = { stats: true };
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      color: "error"
    });
  }
}

function updateSortBy(newOptions) {
  table.options.value.sortBy = newOptions;
  updateOptions();
}

function updateOptions() {
  casesTable.value.options = table.options.value;
  getData();
}

function getDefaultSortsByStatus(status) {
  let sortBy = { key: "created", order: "desc" };

  if (status === "active") {
    sortBy.key = "inforce";
  } else if (status === "pending") {
    sortBy.key = "created";
  } else if (status === "inactive") {
    sortBy.key = "closed";
  }

  return sortBy;
}

function updateFilter(filter) {
  let unequalStatuses = false;
  if (table.filter.value.status || filter.status) {
    unequalStatuses = table.filter.value.status !== filter.status;
  }
  table.filter.value = filter;
  if (unequalStatuses) {
    table.options.value.sortBy = [getDefaultSortsByStatus(filter.status)];

    table.filter.value.inforce = null;
    table.filter.value.closed = null;
  }
  casesTable.value.filter = table.filter.value;
  table.resetPage();
  getData();
}
</script>
