<template>
  <v-card flat tile>
    <v-card-title>Commission</v-card-title>
    <v-card-text>
      Modify Commission Assignment, Payment Method, and Commission Splits.
      <a target="_blank" @click="showCommissionFeaturesArticle">
        Learn about Commission Features.
      </a>
    </v-card-text>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <commission-payment-method
            :module="AGENCY_SETTINGS"
            :disabled="props.readonly"
          />
        </v-col>
        <v-col cols="12">
          <commission-rates
            v-if="canSeeCommissionRates"
            :disabled="props.readonly || agency.lockCommissions"
            :module="AGENCY_SETTINGS"
          />
        </v-col>
        <v-col cols="12">
          <commission-admin
            :readonly="props.readonly"
            :is-viewing-self="props.isViewingSelf"
            :module="AGENCY_SETTINGS"
          />
        </v-col>
      </v-row>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script setup>
import CommissionPaymentMethod from "@/components/settings/commissions/CommissionPaymentMethod.vue";
import CommissionAdmin from "@/components/settings/commissions/CommissionAdmin.vue";
import CommissionRates from "@/components/settings/commissions/CommissionRates.vue";
import { ARTICLES } from "@/data/articles";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useDialogStore } from "@/stores/dialog";
import { computed, markRaw, onMounted, watch } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import HelpCenterReader from "@/components/help-center/HelpCenterReader.vue";
const props = defineProps({
  readonly: Boolean,
  isViewingSelf: Boolean
});

const settings = useSettingsViewStore();
const agency = useAgencySettingsStore(settings.currentAgencyId);
const dialog = useDialogStore();

function showCommissionFeaturesArticle() {
  dialog.showDialog({
    component: markRaw(HelpCenterReader),
    id: ARTICLES.COMMISSION_FEATURES
  });
}

// TODO: Defer to payment method for this?
const validEft = computed(() => {
  const eftOptions = Object.values(agency.efts);
  const activeEft = eftOptions.find(
    val => val.current === 1 && val.eftDocument
  );
  return Boolean(activeEft?.id) && !activeEft?.errors;
});

const canSeeCommissionRates = computed(() => !props.isViewingSelf);

watch(validEft, () => setValidPage());

function setValidPage() {
  agency.setValidPage({ page: "agency-commissions", valid: validEft.value });
}

setValidPage();

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
</script>
