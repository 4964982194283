<template>
  <v-card>
    <v-card-title> {{ model.id ? "Update" : "Create" }} Training </v-card-title>
    <v-card-text>
      <text-field
        v-model="model.name"
        label="Name"
        data-testid="training-name"
        v-bind="nameValidation"
      />
      <textarea-field
        v-model="model.instructions"
        label="Instructions"
        data-testid="training-instructions"
        v-bind="instructionsValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="training-save"
        :loading="creating"
        @click="save"
      >
        {{ model.id ? "Update" : "Create" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { Training } from "@/models/Training";
import useVuelidate from "@vuelidate/core";
import {
  someTextValidator,
  computedValidation,
  parseErrorMessage
} from "@/util/helpers";
import { ref } from "vue";
import { useDialogStore } from "@/stores/dialog";
import { createTraining, updateTraining } from "@/api/trainings.service";
import { useSnackbarStore } from "@/stores/snackbar";

const props = defineProps({
  training: {
    type: Object,
    required: false,
    default: null
  }
});
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const model = ref(Training(props.training));
const creating = ref(false);

const v$ = useVuelidate(
  {
    model: {
      name: { required: v => someTextValidator(true, v, 2) },
      instructions: { required: v => someTextValidator(true, v, 2) }
    }
  },
  { model },
  { $scope: null, $autoDirty: true }
);

const nameValidation = computedValidation(v$.value.model.name, {
  required: "Required"
});
const instructionsValidation = computedValidation(v$.value.model.instructions, {
  required: "Required"
});

async function save() {
  try {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    creating.value = true;
    let newTraining;
    if (model.value.id) {
      newTraining = await update();
    } else {
      newTraining = await create();
    }
    dialog.closeDialog({ newTraining });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creating.value = false;
  }
}

function create() {
  return createTraining({
    name: model.value.name,
    instructions: model.value.instructions
  });
}

function update() {
  return updateTraining(model.value.id, {
    name: model.value.name,
    instructions: model.value.instructions
  });
}
</script>
