<template>
  <v-row dense style="position: relative">
    <v-col cols="12">
      <v-card id="quote-request" flat>
        <table-filter
          class="mx-2"
          :model-value="table.filter.value"
          :headers="table.filterHeaders.value"
          :loading="table.loading.value"
          @update="updateFilter"
        />
        <v-card-text>
          <v-data-table-server
            v-model:sort-by="table.options.value.sortBy"
            v-model:items-per-page="table.options.value.itemsPerPage"
            v-model:page="table.options.value.page"
            must-sort
            data-testid="quote-table"
            :mobile="null"
            mobile-breakpoint="sm"
            :headers="table.tableHeaders.value"
            :items="table.mappedItems.value"
            :items-length="table.itemsLength.value"
            :loading="table.loading.value"
            :footer-props="table.footerProps.value"
            :items-per-page-options="table.itemsPerPageOptions"
            @update:options="updateOptions"
          >
            <template #bottom>
              <table-footer
                v-model:page="table.options.value.page"
                v-model:items-per-page="table.options.value.itemsPerPage"
                :items-per-page-options="table.itemsPerPageOptions"
                :items-length="table.itemsLength.value"
              />
            </template>

            <template #[`item.insured`]="{ item }">
              <router-link
                :to="{
                  name: 'QuoteView',
                  params: { id: item.additional.id }
                }"
                class="a"
              >
                {{ item.insured }}
              </router-link>
            </template>
            <template #[`item.createdAt`]="{ item }">
              <timestamp-formatter :model-value="item.createdAt" />
            </template>
            <template #[`item.followUp`]="{ item }">
              <timestamp-formatter :model-value="item.followUp" />
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { TableOptions } from "@/classes/data-table/TableOptions";
import { parseErrorMessage } from "@/util/helpers";
import { getQuoteTable } from "@/api/quotes.service";
import { STATES } from "@/data/states";
import { lineFilterOptions } from "@/data/filter-options";
import { STATUSES } from "@/models/Quote";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";
import { mdiSackPercent } from "@mdi/js";

const { quotesTable } = storeToRefs(useTableStore());
const user = useUserStore();

function generateTable() {
  const headers = [
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "insured_name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "individuals.name"
    }),
    new TableHeader({
      text: "Agent",
      value: "agent",
      map: "agent_name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "agents.name"
    }),
    new TableHeader({
      text: "State",
      value: "state",
      map: "state",
      selectableOptions: STATES,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "quotes.state"
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      selectableOptions: STATUSES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      sortFilterMap: "status"
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "line",
      selectableOptions: lineFilterOptions,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "quotes.line"
    }),
    new TableHeader({
      text: "Assigned To",
      value: "assignedTo",
      map: "assignable.name",
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      sortFilterMap: [
        { key: "assignable_id", value: "id" },
        { key: "assignable_type", value: "type" }
      ]
    }),
    user.isGroupThreePlus
      ? new TableHeader({
          text: "View",
          value: "view",
          map: "view",
          ...TableHeader.IS_SELECT_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          selectableOptions: user.isGroupTwoPlus
            ? [
                { title: "All Quote Requests", value: "all" },
                { title: "My Quote Requests", value: "my" }
              ]
            : [],
          ...TableHeader.IS_QUERY_FILTER,
          ...TableHeader.IS_ADDITIONAL,
          ...TableHeader.IS_MANDATORY,
          sortFilterMap: "view",
          filterOrder: 1
        })
      : null,
    new TableHeader({
      text: "Shared With",
      value: "sharedWith",
      map: "sharedWith",
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ]
    }),
    user.isGroupThreePlus
      ? new TableHeader({
          text: "Manager",
          value: "marketingManagerName",
          map: "marketing_manager_name",
          showIf: ({ view }) => view === "all",
          ...TableHeader.IS_STRING_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          sortFilterMap: "marketing_managers.name"
        })
      : null,
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "quotes.created_at"
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUp",
      map: "follow_up_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      sortFilterMap: "quotes.follow_up_at"
    })
  ].filter(Boolean);

  let filter = {};
  if (user.isGroupThreePlus) filter.view = "all";
  if (quotesTable.value.filter) {
    Object.keys(quotesTable.value.filter).forEach(key => {
      if (!headers.find(header => header.value === key)) return;
      filter[key] = quotesTable.value.filter[key];
    });
  }

  let options = TableOptions({ sortBy: [{ key: "createdAt", order: "desc" }] });
  if (Object.keys(quotesTable.value.options).length) {
    options = quotesTable.value.options;
  }

  return useTable({
    filter,
    options,
    headers,
    getData: getQuoteTable
  });
}

const table = generateTable();

const stats = computed(() => {
  const conversion = {
    text: "Conversion",
    icon: mdiSackPercent,
    type: "loading"
  };
  const total = { text: "Total", type: "loading" };
  Object.keys(table.stats.value).forEach(stat => {
    if (stat.indexOf("conversion") > -1) {
      conversion.value = Math.trunc(table.stats.value[stat] * 100);
      conversion.type = "percent";
    } else if (stat.indexOf("total") > -1) {
      total.value = Math.trunc(table.stats.value[stat]);
      total.type = "number";
    }
  });
  return [conversion, total];
});

async function getData() {
  try {
    let additionalFilters = {};
    if (!user.isGroupThreePlus) additionalFilters.view = "my";
    await table.getData(additionalFilters);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
function updateOptions(options) {
  quotesTable.value.options = options;
  getData();
}
function updateFilter(filter) {
  table.filter.value = filter;
  quotesTable.value.filter = filter;
  table.resetPage();
  getData();
}

defineExpose({ getData, stats });
</script>
