<template>
  <div v-if="saving || saved || error" data-testid="saving-indicator">
    <v-fade-transition mode="out-in">
      <v-progress-circular
        v-if="saving"
        key="saving"
        indeterminate
        :size="small ? 16 : 24"
        width="2"
      />
      <v-icon
        v-else-if="saved"
        key="saved"
        color="success"
        :icon="mdiCheckboxMarkedCircleOutline"
        :size="small ? 16 : 24"
      />
      <v-tooltip v-else-if="error" key="error" location="bottom">
        <template #activator="{ props: templateProps }">
          <div v-bind="templateProps">
            <v-icon
              color="error"
              :size="small ? 16 : 24"
              :icon="mdiAlertCircleOutline"
            />
          </div>
        </template>
        <span>Save failed because {{ errorMessage.substr(0, 200) }} </span>
      </v-tooltip>
    </v-fade-transition>
  </div>
</template>

<script setup>
import { mdiCheckboxMarkedCircleOutline, mdiAlertCircleOutline } from "@mdi/js";
import { computed, isRef, toRef } from "vue";

const props = defineProps({
  controller: { type: Object, required: true },
  small: Boolean
});

let controller;
// eslint-disable-next-line vue/no-setup-props-destructure
if (isRef(props.controller)) controller = props.controller;
else controller = toRef(props.controller);

const saved = computed(() => controller.value?.saved);
const saving = computed(() => controller.value?.saving);
const error = computed(() => controller.value?.error);
const errorMessage = computed(() =>
  typeof controller.value?.errorMessage === "string"
    ? controller.value.errorMessage
    : ""
);
</script>
