import { getHttpClient } from "@/http-client";

import {
  setIndividualFromRequest,
  setEntityFromRequest,
  setContractPartyAccessFromRequest,
  setContractPartySearchItemFromRequest,
  setContractPartyTableItemFromRequest
} from "@/models/ContractParty";

const baseUrl = "api/boss/contract_parties";

/**
 * Fetch a contract party
 * @param {number} id
 */
export async function getContractParty(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);

  if (data.contract_party.type === "individual") {
    return setIndividualFromRequest(data.contract_party);
  } else if (data.contract_party.type === "entity") {
    return setEntityFromRequest(data.contract_party);
  }
  throw "Invalid contract party type";
}

export function deleteContractParty(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export const createContractPartyAccess = async (contractPartyId, client) => {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${contractPartyId}/accesses`,
    { client }
  );

  return setContractPartyAccessFromRequest(data.client);
};

export const updateContractPartyAccess = async (
  contractPartyId,
  accessId,
  client
) => {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${contractPartyId}/accesses/${accessId}`,
    { client }
  );

  return setContractPartyAccessFromRequest(data.client);
};

export const removeContractPartyAccess = (contractPartyId, accessId) => {
  return getHttpClient().delete(
    `${baseUrl}/${contractPartyId}/accesses/${accessId}`
  );
};

let cancelToken = null;
export async function getContractParties(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  const { contract_parties, meta } = response.data;

  return {
    items: contract_parties.map(setContractPartyTableItemFromRequest),
    meta
  };
}

export function emailContractParties(params) {
  return getHttpClient().post(`${baseUrl}/email`, params);
}

export async function searchForContractParty(name) {
  const { data } = await getHttpClient().post(`${baseUrl}/search`, {
    name
  });
  return data.map(r => setContractPartySearchItemFromRequest(r));
}

export async function createContractParty(body) {
  const { data } = await getHttpClient().post(baseUrl, {
    contract_party: body
  });

  if (data.contract_party.type === "individual") {
    return setIndividualFromRequest(data.contract_party);
  } else if (data.contract_party.type === "entity") {
    return setEntityFromRequest(data.contract_party);
  }
  throw "Invalid contract party type";
}

export function updateContractParty(id, contract_party) {
  return getHttpClient().put(`${baseUrl}/${id}`, { contract_party });
}

export function updateContractPartyBuild(contractPartyId, params) {
  return getHttpClient().put(`${baseUrl}/${contractPartyId}/build`, params);
}

export async function updateContractPartyOccupation(contractPartyId, params) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${contractPartyId}/occupation`,
    params
  );

  return { id: data.id };
}

export function updateContractPartyHomeAddress(contractPartyId, home_address) {
  return getHttpClient().put(`${baseUrl}/${contractPartyId}/home_address`, {
    home_address
  });
}

export function updateContractPartyEmployer(contractPartyId, params) {
  return getHttpClient().put(`${baseUrl}/${contractPartyId}/employer`, params);
}

export function updateContractPartyDriversLicense(contractPartyId, params) {
  return getHttpClient().put(
    `${baseUrl}/${contractPartyId}/drivers_license`,
    params
  );
}

export async function getSensitiveContractPartyInformation(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/sensitive`);
  return data.party_id;
}
