<template>
  <v-card class="ma-1" elevation="0">
    <v-card-text>
      <v-expansion-panels v-if="user.isGroupFour" class="mb-3">
        <v-expansion-panel data-testid="missing-product-alias-panel">
          <v-expansion-panel-title>
            Missing Product Aliases
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <missing-product-aliases-table />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        must-sort
        data-testid="products-table"
        :mobile="null"
        mobile-breakpoint="sm"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :loading="table.loading.value"
        :footer-props="table.footerProps.value"
        :items-per-page-options="table.itemsPerPageOptions"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Products</h1>
            <v-spacer />
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiRefresh"
                  variant="text"
                  density="comfortable"
                  class="table-btn"
                  v-bind="templateProps"
                  data-testid="products-table-refresh"
                  @click="getData"
                />
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <table-filter
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.firstYear`]="{ item }">
          <percentage-formatter
            :model-value="item.firstYear"
            :decimal-length="null"
          />
        </template>
        <template #[`item.renewal`]="{ item }">
          <percentage-formatter
            :model-value="item.renewal"
            :decimal-length="null"
          />
        </template>
        <template #[`item.excess`]="{ item }">
          <percentage-formatter
            :model-value="item.excess"
            :decimal-length="null"
          />
        </template>
        <template #[`item.product`]="{ item }">
          <router-link
            class="text-none"
            :class="{ 'text-accent': item.additional.qaEnabled }"
            :to="{
              name: 'ProductView',
              params: {
                id: item.additional.id
              }
            }"
          >
            <v-icon
              v-if="item.additional.qaEnabled"
              color="accent"
              :icon="mdiRocketLaunch"
            />
            {{ item.product }}
          </router-link>
        </template>
      </v-data-table-server>
      <v-icon color="accent" :icon="mdiRocketLaunch" /> = Quote & Apply Product
    </v-card-text>
  </v-card>
</template>

<script setup>
import MissingProductAliasesTable from "@/components/products/MissingProductAliasesTable.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { TableOptions } from "@/classes/data-table/TableOptions";

import { LINES } from "@/models/Product";

import { parseErrorMessage } from "@/util/helpers";
import { getCommissions } from "@/api/commissions.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { PERMISSION } from "@/constants/permissions.constants";
import { mdiRefresh, mdiRocketLaunch } from "@mdi/js";

useHead({ title: "Products" });

const table = useTable({
  headers: [
    new TableHeader({
      text: "Product",
      value: "product",
      map: "name",
      sortFilterMap: "products.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName",
      sortFilterMap: "carriers.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "First Year Up To Target",
      value: "firstYear",
      map: "firstYear"
    }),
    new TableHeader({
      text: "Renewal",
      value: "renewal",
      map: "renewal",
      showIf: ({ line }) =>
        ["disability", "life", "ltc", "variable_life"].includes(line)
    }),
    new TableHeader({
      text: "Excess of Target",
      value: "excess",
      map: "excess",
      showIf: ({ line }) => ["variable_life", "life"].includes(line)
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "line",
      sortFilterMap: "line",
      selectableOptions: LINES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_MANDATORY,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Quote & Apply Product",
      value: "qaEnabled",
      sortFilterMap: "qa_enabled",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_ADDITIONAL
    })
  ],
  getData: getCommissions,
  options: TableOptions({
    sortBy: [
      { key: "carrierName", order: "asc" },
      { key: "product", order: "asc" }
    ]
  })
});

const filter = {
  line: "life"
};

const user = useUserStore();
if (user.permissions[PERMISSION.ACCOUNTING]) {
  table.headers.value.push(
    new TableHeader({
      text: "Primary Product",
      value: "primaryProduct",
      sortFilterMap: "primary_product",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.SEND_FALSE
    })
  );
  filter.primaryProduct = true;
}

table.filter.value = filter;

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}
</script>
