<template>
  <div class="preliminary-card">
    <v-card>
      <v-col>
        <v-row class="ma-0" align="center" justify="center">
          <app-logo large />
        </v-row>
      </v-col>

      <v-card-text v-if="errorMessage" data-testid="error">
        {{ errorMessage }}
      </v-card-text>
      <template v-else>
        <v-card-title class="justify-center font-weight-bold text-h5 mb-6">
          Bridging into your eApp. Please wait a moment.
        </v-card-title>
        <v-card-text>
          <v-progress-circular indeterminate />
        </v-card-text>
        <v-card-text>
          Taking a while?
          <a
            :class="{ 'text-grey': loading }"
            data-testid="force-bridge"
            @click="initiate"
          >
            Click Here
          </a>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script setup>
import AppLogo from "@/components/AppLogo.vue";
import { useHead } from "@unhead/vue";
import { convertToEappFromToken } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { ref } from "vue";

const props = defineProps({
  token: {
    type: String,
    required: true
  },
  error: {
    type: String,
    required: false,
    default: ""
  }
});

useHead({
  title: "Carrier Bridge"
});
const snackbar = useSnackbarStore();
const loading = ref(false);

const errorMessage = ref(props.error);

async function initiate() {
  if (loading.value) return;
  try {
    loading.value = true;
    const res = await convertToEappFromToken(props.token);
    location.replace(res.url);
  } catch (e) {
    errorMessage.value = parseErrorMessage(e);
    snackbar.showErrorSnackbar({ message: errorMessage.value });
  } finally {
    loading.value = false;
  }
}
</script>
