import { Document, setDocumentFromRequest } from "@/models/Document";

export const ACCOUNT_TYPE_OPTIONS = ["Checking", "Savings"];
export const SAMPLE_ROUTING_NUMBER = "122105278";
export const SAMPLE_ACCOUNT_NUMBER = "0000000016";

export const EFT_ERRORS = {
  INVALID_ACCOUNT_STATUS: "INVALID ACCOUNT STATUS",
  INVALID_ACCOUNT_OWNER_MATCH_SCORE: "INVALID ACCOUNT OWNER MATCH SCORE"
};

export function Eft(model = {}) {
  return {
    file: model?.file || null,
    nameOnCheck: model?.nameOnCheck || null,
    accountType: model?.accountType || null,
    routingNumber: model?.routingNumber || null,
    accountNumber: model?.accountNumber || null,
    payingTo: model?.payingTo || null,
    current: model?.current || 0,
    id: model?.id || null,
    ownableId: model?.ownableId || null,
    ownableType: model?.ownableType || null,
    eftDocument: model.eftDocument ? Document(model?.eftDocument) : null,
    errors: model?.errors || null
  };
}

export function setEftFromRaw(raw = {}) {
  const eft = Eft();
  eft.file = raw?.file;
  eft.nameOnCheck = raw?.account_owner;
  eft.accountType = raw?.account_type;
  eft.routingNumber = raw?.routing_number;
  eft.accountNumber = raw?.account_number;
  eft.payingTo = raw?.paying_to;
  eft.id = raw?.id;
  eft.current = raw?.current;
  if (raw?.eft_document) {
    eft.eftDocument = setDocumentFromRequest(raw.eft_document);
  }
  eft.errors = raw?.errors;
  return eft;
}

export function setRawFromEft(eft = {}) {
  return {
    account_number: eft.accountNumber,
    account_owner: eft.nameOnCheck,
    account_type: eft.accountType,
    current: eft.current,
    document: eft.file,
    ownable_id: eft.ownableId,
    ownable_type: eft.ownableType,
    payment_type: eft.paymentMethod,
    routing_number: eft.routingNumber
  };
}
