<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <chat-view
        title="Notes"
        :checkboxes="checkboxes"
        :messages="messages"
        :pusher-id="`private-${id}-Quote`"
        :create-func="sendChatMessage"
        @new-note="handleNewNote"
        @remove-message="removeFromMessages"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import ChatView from "@/components/shared/chat/ChatView.vue";
import {
  setNoteFromRequest,
  Note,
  NoteToCreateRequest
} from "@/models/Note";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useQuoteViewStore } from "@/stores/quote-view";
import { computed } from "vue";

const checkboxes = [
  { label: "Send Email", dataTestid: "email", key: "sendEmail", default: true }
];

const quoteView = useQuoteViewStore();
const user = useUserStore();
const snackbar = useSnackbarStore();

const { notes, id } = storeToRefs(quoteView);

const messages = computed(() => {
  return [
    {
      note: `Hi ${user.loginable.name}, feel free to make notes here.`,
      ownable: { name: "BackNine" }
    },
    ...notes.value.toSorted(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    )
  ];
});

function removeFromMessages(message) {
  const index = notes.value.findIndex(v => v.id === message.id);
  if (index === -1) return;
  notes.value.splice(index, 1);
}

async function sendChatMessage(note) {
  const newNote = Note({
    documents: note.files,
    email: note.sendEmail,
    phoneCall: note.sendPhoneCall,
    note: note.message,
    notableId: id.value,
    notableType: "Quote"
  });
  try {
    const res = await createNote(NoteToCreateRequest(newNote));
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}

function handleNewNote({ note: rawNote } = {}) {
  notes.value.push(setNoteFromRequest(rawNote));
}
</script>
