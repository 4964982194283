<template>
  <v-card>
    <v-card-title> Create Employee </v-card-title>
    <v-card-text>
      <advisor-search
        v-model="employee.ownable"
        label="New Employee"
        data-testid="ownable-search"
        :prepend-inner-icon="mdiAccountTie"
        v-bind="agentValidation"
      />
      <v-row dense>
        <v-col cols="12">
          <advisor-search
            v-model="employee.manager"
            label="Manager"
            data-testid="manager-search"
            :prepend-inner-icon="mdiAccountSupervisor"
            v-bind="managerValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="employee.department"
            label="Department"
            data-testid="department-select"
            :prepend-inner-icon="mdiDomain"
            :items="DEPARTMENTS"
            v-bind="departmentValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="employee.title"
            label="Title"
            data-testid="title-select"
            :items="titles"
            :disabled="!employee.department"
            :prepend-inner-icon="mdiFormatTitle"
            v-bind="titleValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="employee.contractType"
            label="Contract Type"
            data-testid="contract-type-select"
            :prepend-inner-icon="mdiCalculator"
            :items="CONTRACT_TYPES"
            v-bind="contractTypeValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <integer-input
            v-model="employee.maxCases"
            mask="####"
            label="Max Cases (Optional)"
            clearable
            hide-details="auto"
            data-testid="max-cases-input"
            :prepend-inner-icon="mdiBriefcase"
            v-bind="maxCasesValidation"
          />
        </v-col>
      </v-row>
      <date-input
        v-model="employee.anniversary"
        label="BackNine Anniversary"
        data-testid="anniversary-input"
        v-bind="anniversaryValidation"
      />
      <date-input
        v-model="employee.birthdate"
        label="Birthdate"
        data-testid="birthdate-input"
        :loading="checkingRequirements"
        :disabled="
          !requirements.birthdate ||
          !employee.ownable?.id ||
          checkingRequirements
        "
        v-bind="birthdateValidation"
      />
      <phone-input
        v-model="employee.phoneMobile"
        label="Mobile"
        data-testid="phone-mobile-input"
        :loading="checkingRequirements"
        :prepend-inner-icon="mdiCellphone"
        :disabled="
          !requirements.phoneMobile ||
          !employee.ownable?.id ||
          checkingRequirements
        "
        v-bind="phoneMobileValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="create-button"
        :loading="saving"
        @click="create"
      >
        Create
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import DateInput from "@/components/shared/DateInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";

import {
  mdiDomain,
  mdiCellphone,
  mdiFormatTitle,
  mdiCalculator,
  mdiBriefcase,
  mdiAccountTie,
  mdiAccountSupervisor
} from "@mdi/js";

import { computed, ref, watch } from "vue";
import {
  Employee,
  DEPARTMENTS,
  CONTRACT_TYPES,
  getTitlesForDepartment,
  EmployeeToCreateRequest
} from "@/models/Employee";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";
import { useDialogStore } from "@/stores/dialog";
import { createEmployee } from "@/api/employees.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { getAgentSettings } from "@/api/agents.service";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const employee = ref(Employee());
const saving = ref(false);
const requirements = ref({ birthdate: true, phoneMobile: true });
const checkingRequirements = ref(false);

const v$ = useVuelidate(
  {
    employee: {
      title: { required: Boolean },
      department: { required: Boolean },
      anniversary: { required: Boolean },
      manager: { required: v => Boolean(v?.id) },
      ownable: { required: v => Boolean(v?.id) },
      contractType: { required: Boolean },
      phoneMobile: {
        required: v => !requirements.value.phoneMobile || Boolean(v)
      },
      birthdate: { required: v => !requirements.value.birthdate || Boolean(v) },
      maxCases: {
        required: v => [null, undefined].includes(v) || (v >= 1 && v <= 2000)
      }
    }
  },
  { employee },
  { $autoDirty: true, $scope: null }
);

const titleValidation = computedValidation(v$.value.employee.title, {
  required: "Required"
});
const departmentValidation = computedValidation(v$.value.employee.department, {
  required: "Required"
});
const anniversaryValidation = computedValidation(
  v$.value.employee.anniversary,
  {
    required: "Required"
  }
);
const managerValidation = computedValidation(v$.value.employee.manager, {
  required: "Required"
});
const agentValidation = computedValidation(v$.value.employee.ownable, {
  required: "Required"
});
const phoneMobileValidation = computedValidation(
  v$.value.employee.phoneMobile,
  {
    required: "Required"
  }
);
const contractTypeValidation = computedValidation(
  v$.value.employee.contractType,
  {
    required: "Required"
  }
);
const maxCasesValidation = computedValidation(v$.value.employee.maxCases, {
  required: "Required"
});
const birthdateValidation = computedValidation(v$.value.employee.birthdate, {
  required: "Required"
});

const titles = computed(() => {
  if (!employee.value.department) return [];
  return getTitlesForDepartment(employee.value.department);
});

async function create() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  try {
    saving.value = true;
    const body = EmployeeToCreateRequest(employee.value);
    if (!requirements.value.birthdate) delete body.birthdate;
    if (!requirements.value.phoneMobile) delete body.phone_mobile;
    await createEmployee(body);
    dialog.closeDialog({ refresh: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

async function checkDataRequirements() {
  requirements.value.birthdate = true;
  requirements.value.phoneMobile = true;
  employee.value.birthdate = null;
  employee.value.phoneMobile = null;

  if (!employee.value.ownable?.id) return;
  try {
    checkingRequirements.value = true;
    const agentData = await getAgentSettings(employee.value.ownable.id);
    requirements.value.birthdate = !agentData.birthdate;
    requirements.value.phoneMobile = !agentData.phoneMobile;
    employee.value.birthdate = agentData.birthdate;
    employee.value.phoneMobile = agentData.phoneMobile;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    checkingRequirements.value = false;
  }
}

watch(
  () => employee.value.department,
  () => {
    employee.value.title = null;
  }
);

watch(() => employee.value.ownable?.id, checkDataRequirements);
</script>
