import { getHttpClient } from "@/http-client";
import { setVendorFromRequest } from "@/models/Vendor";
const baseUrl = "/api/boss/vendors";

export async function getVendors({ carrier, type, available } = {}) {
  const params = new URLSearchParams();
  if (type) params.append("type", type);
  if (carrier) params.append("carrier_id", carrier.id);
  if ([true, false].includes(available)) params.append("available", available);
  const res = await getHttpClient().get(baseUrl, { params });

  if (!res?.data) return;

  return res.data.map(setVendorFromRequest);
}

export const saveVendor = (id, body) => {
  return getHttpClient().put(`${baseUrl}/${id}`, body);
};
