<template>
  <div class="preliminary-card">
    <v-scroll-y-transition mode="out-in">
      <v-form v-if="renderTwoFactor" key="setup" @submit.prevent="enrollIn2FA">
        <v-card>
          <v-col>
            <v-row class="ma-0" align="center" justify="center">
              <app-logo large />
            </v-row>
          </v-col>
          <v-card-title class="justify-center">
            Set Up Two Factor Authentication
          </v-card-title>
          <v-card-subtitle class="text-center">
            Two Factor Authentication (2FA) apps require you to scan a QR code
            or enter a code. See below for the QR code and code.
          </v-card-subtitle>
          <v-card-text>
            <v-card light elevation="4" max-height="15rem" max-width="15rem">
              <v-card-text>
                <v-img data-testid="qr-code" :src="otp.qr" contain />
              </v-card-text>
            </v-card>
            <pre data-testid="secret">{{ otp.secret }}</pre>
            <div class="mt-3">
              <text-field
                v-model="enrollValue"
                label="Verification Code"
                inputmode="numeric"
                persistent-hint
                autofocus
                data-testid="two-factor"
                hint="Please enter the verification code generated by your authenticator app"
                :prepend-inner-icon="mdiTwoFactorAuthentication"
                :success="Boolean(enrollValue)"
                :disabled="loading"
              />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center" class="ma-0">
              <app-button
                class="text-none w-100"
                color="primary"
                type="submit"
                variant="elevated"
                data-testid="submit"
                :loading="loading"
              >
                Enroll
              </app-button>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-form v-else key="ask" @submit.prevent="setUpTwoFactor">
        <v-card>
          <v-col>
            <v-row class="ma-0" align="center" justify="center">
              <app-logo large />
            </v-row>
          </v-col>
          <v-card-title class="justify-center">
            Set up Two Factor Authentication
          </v-card-title>
          <v-card-subtitle class="text-center">
            Two Factor Authentication (2FA) adds an extra layer of security to
            your account which will help verify your identity when you sign in.
          </v-card-subtitle>
          <v-card-text data-testid="alert-text">
            <v-alert type="warning" class="text-left">
              Due to your access level, you are required to configure 2FA before
              {{ requiredDate }}. On that date, you will not be able to sign
              into BOSS until this step is complete.
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center" class="ma-0">
              <app-button
                class="text-none w-100"
                color="primary"
                type="submit"
                variant="elevated"
                data-testid="setup-2fa"
                :disabled="settingUpLater"
                :loading="loading"
              >
                Set up 2FA
              </app-button>
            </v-row>
          </v-card-actions>
          <v-card-text v-if="!otpRequiredNow" class="pt-0">
            Don't want to enroll?
            <a data-testid="setup-later" @click="setupLater"> Set up Later </a>
          </v-card-text>
        </v-card>
      </v-form>
    </v-scroll-y-transition>
  </div>
</template>

<script setup>
import { mdiTwoFactorAuthentication } from "@mdi/js";
import AppLogo from "@/components/AppLogo.vue";
import { parseErrorMessage, timestampFormatter } from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import {
  createTwoFactorData,
  generateTwoFactorData,
  setTwoFactorDataLater
} from "@/api/security.service";

import { computed, ref } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import {
  authenticateAndSetDetails,
  handleRedirectAfterAuthentication
} from "@/api/auth.service";
import { useHead } from "@unhead/vue";

useHead({
  title: "Two Factor Enrollment"
});

const renderTwoFactor = ref(false);
const loading = ref(false);
const settingUpLater = ref(false);
const enrollValue = ref("");
const otp = ref({ qr: null, secret: null });

const snackbar = useSnackbarStore();
const user = useUserStore();
const { otp_required_on, otpRequiredNow } = storeToRefs(user);

const requiredDate = computed(() =>
  timestampFormatter(otp_required_on.value, "sole-day", "full-date")
);

async function setUpTwoFactor() {
  loading.value = true;
  try {
    const { secret, qr_code } = await generateTwoFactorData();
    otp.value.qr = qr_code;
    otp.value.secret = secret;
    renderTwoFactor.value = true;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const router = useRouter();
async function setupLater() {
  try {
    settingUpLater.value = true;
    await setTwoFactorDataLater();
    await authenticateAndSetDetails();
    await handleRedirectAfterAuthentication(router);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    settingUpLater.value = false;
  }
}

async function enrollIn2FA() {
  try {
    loading.value = true;
    await createTwoFactorData(enrollValue.value);
    await authenticateAndSetDetails();
    await handleRedirectAfterAuthentication(router);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
