<template>
  <v-row v-if="showSensitiveData" align="center" class="ma-0">
    <p class="mb-0 mr-1">{{ content || "No Content" }}</p>
    <v-progress-circular
      size="12"
      width="2"
      :model-value="progress"
      style="transform: scaleY(-1) rotate(90deg)"
    />
  </v-row>
  <v-row v-else class="ma-0" align="center">
    <component
      :is="loadingContent ? 'p' : 'a'"
      class="mb-0 mr-1"
      @click="fetchSensitiveInfo"
    >
      <template v-if="type === 'ssn'"> ***-**-****</template>
      <template v-else-if="type === 'tin'"> **-*******</template>
    </component>
    <v-progress-circular
      indeterminate
      :style="{ opacity: +loadingContent }"
      size="12"
      width="2"
    />
  </v-row>
</template>

<script setup>
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { computed, onBeforeUnmount, ref } from "vue";

const MAX_VIEWABLE_SECONDS = 30;
const props = defineProps({
  type: { type: String, required: true },
  fetchFunc: { type: Function, required: true }
});
const snackbar = useSnackbarStore();
const showSensitiveData = ref(false);
const content = ref("");
const loadingContent = ref(false);
const viewableTime = ref(0);

const progress = computed(
  () => (viewableTime.value / MAX_VIEWABLE_SECONDS) * 100
);

let interval;
async function fetchSensitiveInfo() {
  loadingContent.value = true;

  try {
    const info = await props.fetchFunc();

    showSensitiveData.value = true;
    content.value = info;
    viewableTime.value = MAX_VIEWABLE_SECONDS;
    if (interval) clearInterval(interval);
    interval = setInterval(() => {
      viewableTime.value -= 1;
      if (viewableTime.value > 0) return;
      clearInterval(interval);
      content.value = "";
      showSensitiveData.value = false;
    }, 1000);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingContent.value = false;
  }
}

onBeforeUnmount(() => {
  if (interval) clearInterval(interval);
});
</script>
