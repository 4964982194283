import { Employee } from "@/models/Employee";
import {
  getEmployee,
  createEmployeeReview,
  updateEmployee
} from "@/api/employees.service";

import { defineStore } from "pinia";
import parse from "date-fns/parse";

export const useEmployeeView = defineStore("employee-view", {
  state: () => Employee(),
  getters: {
    name: s => `${s.firstName} ${s.lastName}`
  },
  actions: {
    async initialize(id) {
      this.$reset();
      const res = await getEmployee(id);
      this.$patch(res);
      this.sortReviews();
    },
    sortReviews() {
      this.reviews.sort(
        (a, b) =>
          parse(b.reviewDate, "yyyy-MM-dd", new Date()) -
          parse(a.reviewDate, "yyyy-MM-dd", new Date())
      );
    },
    async createEmployeeReview(reviewText) {
      const result = await createEmployeeReview(this.id, reviewText);
      this.reviews.push(result);
      this.sortReviews();
    },
    updateMaxCases() {
      let max_cases = this.maxCases;
      if (![null, undefined].includes(max_cases)) max_cases = +this.maxCases;
      return updateEmployee(this.id, { max_cases });
    },
    updateTitle() {
      return updateEmployee(this.id, { title: this.title });
    },
    updateManager() {
      return updateEmployee(this.id, { manager_id: this.manager.id });
    }
  }
});
