<template>
  <v-data-table
    :search="search"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    class="transparent-data-table bg-section"
    data-testid="case-documents-table"
    :mobile="null"
    mobile-breakpoint="sm"
  >
    <template v-if="user.isGroupTwoPlus" #top>
      <v-row class="ma-0 pa-3" align="center">
        <v-spacer />
        <text-field
          v-model="search"
          data-lpignore="true"
          hide-details
          label="Search"
          :prepend-inner-icon="mdiMagnify"
        />
        <app-button
          class="text-none ml-1"
          color="accent"
          data-testid="upload-new-document"
          :prepend-icon="mdiPlus"
          text="Add Document"
          @click="createCaseDocument"
        />
      </v-row>
    </template>

    <template #[`item.actions`]="{ item }">
      <div class="flex-row">
        <app-button
          color="primary"
          :icon="mdiDownload"
          variant="text"
          density="comfortable"
          data-testid="download-case-document"
          v-bind="downloadCaseDocument(item.additional)"
        />
        <app-button
          v-if="user.isGroupTwoPlus"
          :icon="mdiPencil"
          variant="text"
          density="comfortable"
          color="accent"
          data-testid="update-case-document"
          @click="updateCaseDocument(item.additional)"
        />
        <app-button
          v-if="user.isGroupTwoPlus"
          :icon="mdiDelete"
          variant="text"
          density="comfortable"
          color="error"
          data-testid="delete-case-document"
          @click="deleteCaseDocument(item.additional)"
        />
      </div>
    </template>
    <template #[`item.category`]="{ item }">
      {{ documentCategory(item.category) }}
    </template>
    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter format="date-time" :model-value="item.createdAt" />
    </template>
  </v-data-table>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import DocumentDialog from "@/dialogs/DocumentDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { downloadFileAsLink } from "@/util/helpers";
import { useUserStore } from "@/stores/user";

import { CASE_DOCUMENT_CATEGORIES } from "@/models/Case";
import TableHeader from "@/classes/data-table/TableHeader";

import {
  deleteDocument,
  getDocumentUrl,
  updateDocument
} from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";

import { useTable } from "@/composables/table.composable";
import { uploadCaseFile } from "@/api/cases.service";
import { storeToRefs } from "pinia";
import { useCaseViewStore } from "@/stores/case-view";
import { markRaw, ref } from "vue";

import {
  mdiMagnify,
  mdiPlus,
  mdiDownload,
  mdiPencil,
  mdiDelete
} from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { id: caseId, documents } = storeToRefs(useCaseViewStore());
const { mdAndDown } = useDisplay();
const user = useUserStore();
const dialog = useDialogStore();
const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Publish Date",
      value: "createdAt",
      map: "createdAt",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "File Size",
      value: "fileSize",
      map: "fileSize",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ]
});
const search = ref("");
function documentCategory(category) {
  return CASE_DOCUMENT_CATEGORIES.find(v => v.value === category)?.title;
}

function downloadCaseDocument(item) {
  return downloadFileAsLink(getDocumentUrl(item.uid), mdAndDown.value);
}

async function deleteCaseDocument(row) {
  if (!user.isGroupTwoPlus) return;
  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Remove ${row.name} from this Case?`,
    subtitle: "This cannot be undone",
    func: () => deleteDocument(row.uid)
  });

  if (res?.confirm) {
    const index = table.items.value.findIndex(({ uid }) => row.uid === uid);
    if (index === -1) return;
    table.items.value.splice(index, 1);
  }
}

async function updateCaseDocument(row) {
  if (!user.isGroupTwoPlus) return;
  dialog.showDialog({
    component: markRaw(DocumentDialog),
    scrollable: true,
    categories: CASE_DOCUMENT_CATEGORIES,
    title: "Update Case Document",
    documentId: row.uid,
    documentNameValue: row.name,
    documentCategoryValue: row.category,
    single: true,
    func: async ({ name, category }) => {
      await updateDocument(row.uid, { name, category });

      const index = table.items.value.findIndex(v => v.uid === row.uid);
      if (index === -1) return;
      table.items.value[index].name = name;
      table.items.value[index].category = category;
    }
  });
}

function createCaseDocument() {
  if (!user.isGroupTwoPlus) return;

  dialog.showDialog({
    component: markRaw(DocumentDialog),
    scrollable: true,
    title: "Add Case Document",
    categories: CASE_DOCUMENT_CATEGORIES,
    multiple: true,
    func: async ({ file: files, category }) => {
      for (let i = 0; i < files.length; i++) {
        const document = await uploadCaseFile({
          caseId: caseId.value,
          file: files[i],
          category
        });

        table.items.value.unshift(document);
      }
    }
  });
}

function setItems() {
  table.items.value.splice(0, table.items.value.length);
  table.items.value.push(...documents.value);
}

setItems();
</script>
