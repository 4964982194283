import axios from "axios";
import { useInstanceStore } from "@/stores/instance";
import { useSnackbarStore } from "@/stores/snackbar";
import { captureMessage } from "@sentry/vue";

function setCsrfHeaders(httpClient, token) {
  httpClient.defaults.headers.post["X-CSRF-Token"] = token;
  httpClient.defaults.headers.put["X-CSRF-Token"] = token;
  httpClient.defaults.headers.patch["X-CSRF-Token"] = token;
  httpClient.defaults.headers.delete["X-CSRF-Token"] = token;
}

function addCancelToken(httpClient) {
  httpClient.CancelToken = axios.CancelToken;
  httpClient.isCancel = axios.isCancel;
}

async function handleError(error) {
  const routerModule = await import("@/router");
  const router = routerModule.default;
  if (error.config) {
    const config = error.config;
    if (config.url.includes("sign-in") && config.method === "post") {
      return Promise.reject(error);
    }
    if (config.url.includes("auth") && config.method === "post") {
      return Promise.reject(error);
    }
  }

  const redirectableStatuses = [
    {
      status: 401,
      message: "Unauthorized",
      result: () => {
        let query = router.currentRoute.value.query;
        query.redirect = router.currentRoute.value.fullPath;
        if (query.redirect === "/") delete query.redirect;
        return router.push({ name: "SignIn", query });
      }
    },
    {
      status: 403,
      message: "Forbidden",
      result: () => {
        return router.push({ name: "Home" });
      }
    },
    {
      status: 429,
      message: "You've sent too many requests too quickly",
      result: () => {}
    }
  ];
  const errorStatus = redirectableStatuses.find(
    c => c.status === error.response?.status
  );
  if (!errorStatus) {
    if (error?.response?.data) {
      captureMessage(`${error.config.method} ${error.config.url}`, {
        level: "error",
        contexts: {
          response: error.response.data
        }
      });
    }
    return Promise.reject(error);
  }

  const snackbar = useSnackbarStore();
  snackbar.showErrorSnackbar({
    message: errorStatus.message,
    timeout: 5000
  });

  errorStatus.result();
  return Promise.reject(error);
}

function isCsrfError(error) {
  return error.response?.status === 412;
}

async function handleCsrfError(httpClient, error) {
  const instance = useInstanceStore();
  const originalRequest = error.config;
  if (originalRequest._retry) return Promise.reject(error);
  originalRequest._retry = true;
  try {
    await instance.setCsrfToken();
    setCsrfHeaders(httpClient, instance.csrfToken);
    originalRequest.headers["X-CSRF-Token"] = instance.csrfToken;
    return httpClient(originalRequest);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getHttpClient = () => {
  const instance = useInstanceStore();

  const $httpClient = axios.create({
    withCredentials: true,
    baseURL: instance.apiUrl
  });

  addCancelToken($httpClient);
  setCsrfHeaders($httpClient, instance.csrfToken);

  $httpClient.interceptors.response.use(
    response => response,
    error => {
      if ($httpClient.isCancel(error)) return null;
      if (isCsrfError(error)) return handleCsrfError($httpClient, error);
      return handleError(error);
    }
  );

  return $httpClient;
};

// The difference is that this does not run the "handleError" function.
export const getAuthHttpClient = () => {
  const instance = useInstanceStore();

  const $httpClient = axios.create({
    withCredentials: true,
    baseURL: instance.apiUrl
  });

  addCancelToken($httpClient);
  setCsrfHeaders($httpClient, instance.csrfToken);

  $httpClient.interceptors.response.use(
    response => response,
    error => {
      if ($httpClient.isCancel(error)) return null;
      if (isCsrfError(error)) return handleCsrfError($httpClient, error);
      return Promise.reject(error);
    }
  );

  return $httpClient;
};
