<template>
  <v-card>
    <v-card-title>
      <div class="flex-row align-center">
        Create Support Ticket
        <v-spacer />
        <router-link
          class="text-body-2"
          :to="{ name: 'HelpCenter' }"
          target="_blank"
        >
          View Existing Support Tickets
          <v-icon :icon="mdiOpenInNew" />
        </router-link>
      </div>
    </v-card-title>

    <div class="py-3 px-6">
      <chip-group-field
        v-model="ticket.categories"
        class="mb-3"
        v-bind="categoriesValidation"
        label="Categories"
      >
        <template #chips>
          <v-chip
            v-for="category in SUPPORT_TICKET_CATEGORIES"
            :key="category"
            :value="category"
            :text="category"
            :data-testid="`category-${category}`"
          />
        </template>
      </chip-group-field>

      <textarea-field
        v-model="ticket.description"
        v-bind="descriptionValidation"
        label="Description"
        data-testid="description"
      />
      <file-drag-and-drop
        v-model="ticket.documents"
        label="Supporting Documents (Optional)"
        multiple
        data-testid="documents"
      />
    </div>
    <v-card-actions>
      <app-button
        class="text-none"
        variant="outlined"
        text="Cancel"
        @click="dialog.closeDialog()"
      />
      <app-button
        class="text-none"
        variant="elevated"
        text="Submit Ticket"
        color="primary"
        data-testid="create"
        :loading="loading"
        @click="create"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ref, markRaw } from "vue";
import {
  NewSupportTicket,
  NewSupportTicketToCreateRequest,
  SUPPORT_TICKET_CATEGORIES
} from "@/models/SupportTicket";
import { createSupportTicket } from "@/api/support-tickets.service";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import ChipGroupField from "@/components/shared/ChipGroupField.vue";
import { useDialogStore } from "@/stores/dialog";
import {
  parseErrorMessage,
  computedValidation,
  someTextValidator
} from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import useVuelidate from "@vuelidate/core";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { useRouter } from "vue-router";
import { mdiOpenInNew } from "@mdi/js";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const router = useRouter();
const ticket = ref(NewSupportTicket());
const loading = ref(false);

const v$ = useVuelidate(
  {
    ticket: {
      description: {
        required: v => someTextValidator(true, v, 2)
      },
      categories: {
        required: v => Boolean(v?.length)
      }
    }
  },
  {
    ticket
  },
  {
    $scope: null,
    $autoDirty: true
  }
);

const descriptionValidation = computedValidation(v$.value.ticket.description, {
  required: "Required"
});
const categoriesValidation = computedValidation(v$.value.ticket.categories, {
  required: "Required"
});

async function create() {
  try {
    loading.value = true;
    const isValid = await v$.value.$validate();
    if (!isValid) return;
    const result = await createSupportTicket(
      NewSupportTicketToCreateRequest(ticket.value)
    );
    const route = router.resolve({
      name: "SupportTicketView",
      params: { id: result.id }
    });

    await dialog.showDialog({
      component: markRaw(ConfirmationDialog),
      title: "View Ticket",
      subtitle: "Would you like to view the created ticket?",
      confirmHref: route.href,
      cancelText: "No",
      confirmText: "Yes"
    });
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
