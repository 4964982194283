<template>
  <chat-view
    title="Advisor Notes"
    :pusher-id="pusherId"
    :messages="notes"
    :create-func="sendChatMessage"
    @remove-message="removeFromMessages"
    @new-note="newNote"
  />
</template>

<script setup>
import {
  Note,
  NoteToCreateRequest,
  setNoteFromRequest
} from "@/models/Note";
import ChatView from "@/components/shared/chat/ChatView.vue";
import { createNote } from "@/api/notes.service";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";

const snackbar = useSnackbarStore();
const advisorProfile = useAdvisorProfileView();
const { pusherId, notes, id, type } = storeToRefs(advisorProfile);

function removeFromMessages(message) {
  const index = notes.value.findIndex(({ id }) => id === message.id);
  if (index > -1) notes.value.splice(index, 1);
}

async function sendChatMessage(note) {
  const newNote = new Note({
    documents: note.files,
    email: 0,
    phoneCall: false,
    note: note.message,
    notableId: id.value,
    notableType: type.value
  });

  try {
    const result = await createNote(NoteToCreateRequest(newNote));
    return result;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}

function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}
</script>
