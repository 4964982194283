<template>
  <v-card>
    <v-card-title>Change Access</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" :md="availableAccessLevels.length > 1 ? 6 : 12">
          <select-field
            v-model="currentLogin"
            label="Current Login"
            item-title="name"
            success
            data-testid="current-login"
            return-object
            :prepend-inner-icon="mdiAccount"
            :items="logins"
          />
        </v-col>
        <v-col v-if="availableAccessLevels.length > 1" cols="12" md="6">
          <select-field
            v-model="accessLevel"
            label="Group Level"
            success
            data-testid="current-group"
            :prepend-inner-icon="mdiShieldLock"
            :items="availableAccessLevels"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-5">
      <v-spacer />
      <app-button
        class="text-none mr-1"
        variant="outlined"
        text="Cancel"
        @click="dialog.closeDialog()"
      />
      <app-button
        class="text-none"
        color="primary"
        variant="elevated"
        data-testid="apply-changes"
        text="Apply"
        :loading="loading"
        @click="apply"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { switchLogin } from "@/api/logins.service";
import { updateUser } from "@/api/users.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { parseErrorMessage } from "@/util/helpers";
import { mdiAccount, mdiShieldLock } from "@mdi/js";
import { ref } from "vue";

const user = useUserStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const availableAccessLevels = [];
for (let i = 1; i <= user.highest_group; ++i) {
  availableAccessLevels.push({ title: i, value: i });
}

const currentLogin = ref({
  name: user.loginable.name,
  id: user.login.id
});
const accessLevel = ref(user.group);
const loading = ref(false);

const logins = user.logins.toSorted((a, b) => a.name.localeCompare(b.name));

async function apply() {
  loading.value = true;
  let applying = false;
  try {
    if (currentLogin.value.id !== user.login.id) {
      applying = true;
      await switchLogin({ id: currentLogin.value.id });
    }
    if (user.group !== accessLevel.value) {
      applying = true;
      await updateUser(user.id, { group: accessLevel.value });
    }

    if (applying) {
      snackbar.showInfoSnackbar({
        message: "Applying Changes. Refreshing Page",
        timeout: 2000
      });
      if (!import.meta.env.VITE_COMPONENT_TESTING) location.reload();
    }
    dialog.closeDialog({ applying });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
