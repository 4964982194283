<template>
  <v-card flat class="ma-3">
    <v-card-title class="text-h5">Create Product</v-card-title>
    <v-divider />
    <v-card variant="flat">
      <v-card-title>Product Details</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" lg="4" md="6">
            <text-field
              v-model="product.name"
              autofocus
              label="Name"
              data-lpignore="true"
              data-testid="product-name"
              :prepend-inner-icon="mdiCubeOutline"
              :success="nameValidation.success"
              :error-messages="nameValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <carrier-search
              v-model="product.carrier"
              data-testid="product-carrier"
              :success="carrierValidation.success"
              :error-messages="carrierValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <autocomplete-field
              v-model="product.line"
              label="Line"
              placeholder="Select Line"
              data-testid="product-line"
              :prepend-inner-icon="mdiCube"
              :items="LINES"
              :success="lineValidation.success"
              :error-messages="lineValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <autocomplete-field
              v-model="product.linePercentage"
              label="Line Percentage"
              data-testid="product-line-percentage"
              :prepend-inner-icon="mdiPercent"
              :disabled="isAnnuity"
              :items="LINE_PERCENTAGES"
              :success="linePercentageValidation.success"
              :error-messages="linePercentageValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <integer-input
              v-model="product.activeYears"
              label="Active Years"
              data-testid="product-active-years"
              :prepend-inner-icon="mdiCalendar"
              :success="activeYearsValidation.success"
              :error-messages="activeYearsValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <text-field
              v-model="product.chargeback"
              label="Chargeback"
              data-lpignore="true"
              data-testid="product-chargeback"
              :prepend-inner-icon="mdiCreditCard"
              :success="Boolean(product.chargeback)"
            />
          </v-col>
          <v-col v-if="showSurrenderPeriod" cols="12" lg="4" md="6">
            <integer-input
              v-model="product.surrenderPeriod"
              label="Surrender Period"
              data-testid="product-surrender-period"
              :prepend-inner-icon="mdiCalendar"
              :success="Boolean(product.surrenderPeriod)"
            />
          </v-col>
          <v-col v-if="showRollingTarget" cols="12" lg="4" md="6">
            <text-field
              v-model="product.rollingTarget"
              label="Rolling Target"
              data-lpignore="true"
              data-testid="product-rolling-target"
              :prepend-inner-icon="mdiSack"
              :success="Boolean(product.rollingTarget)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <checkbox-field
                v-model="product.eDelivery"
                label="Carrier eDelivery"
                data-testid="product-e-delivery"
                class="mt-1"
                dense
                hide-details
                :success="product.eDelivery"
              />
            </div>
          </v-col>
          <v-col v-if="showAgeNearest" cols="12" lg="4" md="6">
            <div class="checkbox-width">
              <checkbox-field
                v-model="product.ageNearest"
                label="Age Nearest"
                class="mt-1"
                data-testid="product-age-nearest"
                dense
                hide-details
                :success="Boolean(product.ageNearest)"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card variant="flat">
      <v-card-title> Product API </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <cacheable-search-list
              v-model="product.type"
              label="Type"
              data-testid="product-type"
              cache-fetch-key="getProductMeta"
              cache-items-key="productTypes"
              :success="typeValidation.success"
              :error-messages="typeValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <cacheable-search-list
              v-model="product.traits"
              label="Traits"
              multiple
              chips
              small-chips
              deletable-chips
              data-testid="product-traits"
              cache-fetch-key="getProductMeta"
              cache-items-key="productTraits"
              :success="traitsValidation.success"
              :error-messages="traitsValidation.errorMessages"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <text-field
              v-model="product.minAge"
              data-lpignore="true"
              label="Min Age"
              data-testid="min-age"
              v-bind="minAgeValidation"
            />
          </v-col>
          <v-col cols="12" md="6">
            <text-field
              v-model="product.maxAge"
              data-lpignore="true"
              label="Max Age"
              data-testid="max-age"
              v-bind="maxAgeValidation"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card variant="flat">
      <v-card-title>Product Percentages</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="product.streetFirst"
              label="Street First"
              data-testid="product-street-first"
              :prepend-inner-icon="mdiPercent"
              :max="200"
              :decimal-length="4"
              :success="streetFirstValidation.success"
              :error-messages="streetFirstValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="product.streetExcess"
              label="Street Excess"
              data-testid="product-street-excess"
              :prepend-inner-icon="mdiPercent"
              :max="100"
              :decimal-length="4"
              :success="streetExcessValidation.success"
              :error-messages="streetExcessValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="product.streetRenewal"
              label="Street Renewal"
              data-testid="product-street-renewal"
              :prepend-inner-icon="mdiPercent"
              :max="100"
              :decimal-length="4"
              :success="streetRenewalValidation.success"
              :error-messages="streetRenewalValidation.errorMessages"
            />
          </v-col>
          <v-col v-if="showPua" cols="12" lg="4" md="6">
            <decimal-input
              v-model="product.streetPua"
              label="Street PUA"
              data-testid="product-street-pua"
              :prepend-inner-icon="mdiPercent"
              :max="100"
              :decimal-length="4"
              :success="streetPuaValidation.success"
              :error-messages="streetPuaValidation.errorMessages"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="product.totalFirst"
              label="Total First"
              data-testid="product-total-first"
              :prepend-inner-icon="mdiPercent"
              :max="300"
              :decimal-length="4"
              :success="totalFirstValidation.success"
              :error-messages="totalFirstValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="product.totalExcess"
              label="Total Excess"
              data-testid="product-total-excess"
              :prepend-inner-icon="mdiPercent"
              :max="200"
              :decimal-length="4"
              :success="totalExcessValidation.success"
              :error-messages="totalExcessValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <decimal-input
              v-model="product.totalRenewal"
              label="Total Renewal"
              data-testid="product-total-renewal"
              :prepend-inner-icon="mdiPercent"
              :max="200"
              :decimal-length="4"
              :success="totalRenewalValidation.success"
              :error-messages="totalRenewalValidation.errorMessages"
            />
          </v-col>
          <v-col v-if="showPua" cols="12" lg="4" md="6">
            <decimal-input
              v-model="product.totalPua"
              label="Total PUA"
              data-testid="product-total-pua"
              :prepend-inner-icon="mdiPercent"
              :max="200"
              :decimal-length="4"
              :success="totalPuaValidation.success"
              :error-messages="totalPuaValidation.errorMessages"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-actions>
      <app-button
        class="text-none"
        color="primary"
        data-testid="product-create"
        :loading="loading"
        @click="createProduct"
      >
        <v-icon class="mr-1" :icon="mdiPlus" />
        Create Product
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import DecimalInput from "@/components/shared/DecimalInput.vue";
import CacheableSearchList from "@/components/shared/CacheableSearchList.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import {
  LINES,
  LINE_PERCENTAGES,
  ProductCreate,
  ProductCreateToCreateRequest
} from "@/models/Product";

import { productCreate } from "@/api/products.service";
import { parseErrorMessage, computedValidation } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { computed, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "vue-router";
import {
  mdiCubeOutline,
  mdiCube,
  mdiPercent,
  mdiCalendar,
  mdiCreditCard,
  mdiSack,
  mdiPlus
} from "@mdi/js";

useHead({
  title: "Create Product"
});

const snackbar = useSnackbarStore();
const loading = ref(false);
const product = ref(ProductCreate());
const router = useRouter();

// Ask Reid
const showPua = computed(() => product.value.type === "whole_life");
const percentValidator = (v, min, max) => v !== null && v <= max && v >= min;

const showAgeNearest = computed(() =>
  ["life", "linked_benefit", "variable_life", "ltc"].includes(
    product.value.line
  )
);

const showRollingTarget = computed(() =>
  ["life", "linked_benefit", "variable_life"].includes(product.value.line)
);

const showSurrenderPeriod = computed(() =>
  ["life", "linked_benefit", "variable_life", "annuity"].includes(
    product.value.line
  )
);

const isAnnuity = computed(() => product.value.line === "annuity");

const v$ = useVuelidate(
  {
    product: {
      name: { required: v => Boolean(v) },
      carrier: { id: { required: v => Boolean(v) } },
      line: { required: v => Boolean(v) },
      linePercentage: { required: v => Boolean(v) },
      activeYears: { required: v => Boolean(v) },
      minAge: {
        required: v => (isAnnuity.value ? Boolean(v) || v === 0 : true)
      },
      maxAge: {
        required: v => (isAnnuity.value ? Boolean(v) || v === 0 : true)
      },
      streetFirst: { required: v => percentValidator(v, 0, 200) },
      streetExcess: { required: v => percentValidator(v, 0, 100) },
      streetRenewal: { required: v => percentValidator(v, 0, 100) },
      streetPua: {
        required: v => !showPua.value || percentValidator(v, 0, 100)
      },
      type: { required: v => Boolean(v) },
      traits: { required: v => v.length >= 1 },
      totalFirst: { required: v => percentValidator(v, 0, 300) },
      totalExcess: { required: v => percentValidator(v, 0, 200) },
      totalRenewal: { required: v => percentValidator(v, 0, 200) },
      totalPua: {
        required: v => !showPua.value || percentValidator(v, 0, 200)
      }
    }
  },
  { product },
  { $scope: false, $autoDirty: true }
);

const nameValidation = computedValidation(v$.value.product.name, {
  required: "Required"
});

const carrierValidation = computedValidation(v$.value.product.carrier.id, {
  required: "Required"
});

const lineValidation = computedValidation(v$.value.product.line, {
  required: "Required"
});

const linePercentageValidation = computedValidation(
  v$.value.product.linePercentage,
  { required: "Required" }
);

const activeYearsValidation = computedValidation(v$.value.product.activeYears, {
  required: "Required"
});

const streetFirstValidation = computedValidation(v$.value.product.streetFirst, {
  required: "Required"
});

const streetExcessValidation = computedValidation(
  v$.value.product.streetExcess,
  { required: "Required" }
);

const streetRenewalValidation = computedValidation(
  v$.value.product.streetRenewal,
  { required: "Required" }
);

const streetPuaValidation = computedValidation(v$.value.product.streetPua, {
  required: "Required"
});

const totalFirstValidation = computedValidation(v$.value.product.totalFirst, {
  required: "Required"
});

const totalExcessValidation = computedValidation(v$.value.product.totalExcess, {
  required: "Required"
});

const totalRenewalValidation = computedValidation(
  v$.value.product.totalRenewal,
  { required: "Required" }
);

const totalPuaValidation = computedValidation(v$.value.product.totalPua, {
  required: "Required"
});

const typeValidation = computedValidation(v$.value.product.type, {
  required: "Required"
});

const traitsValidation = computedValidation(v$.value.product.traits, {
  required: "Required"
});

const minAgeValidation = computedValidation(v$.value.product.minAge, {
  required: "Required"
});

const maxAgeValidation = computedValidation(v$.value.product.maxAge, {
  required: "Required"
});

watch(showAgeNearest, () => (product.value.ageNearest = null));
watch(showRollingTarget, () => (product.value.rollingTarget = null));
watch(showSurrenderPeriod, () => (product.value.surrenderPeriod = null));
watch(isAnnuity, v => (product.value.linePercentage = v ? 5 : null));
watch(showPua, () => {
  product.value.streetPua = null;
  product.value.totalPua = null;
});

async function createProduct() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  try {
    const id = await productCreate({
      product: ProductCreateToCreateRequest(product.value)
    });

    if (router?.push) router.push({ name: "ProductView", params: { id } });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  } finally {
    loading.value = false;
  }
}
</script>
